import React from 'react';
import { AutoDockingController, AutoDockingStatus } from '../../navigation/autoDocking/useAutoDockingController';
import './index.scss';

type IConfig = {
	title: string;
	label: string;
	icon: string;
	actionRequired: React.ReactNode;
};
const DockingStatusConfig: Record<
	AutoDockingController['status']['stage'],
	Partial<IConfig & Record<AutoDockingController['status']['state'], Partial<IConfig>>> | null
> = {
	IDLE: null,
	STARTING: null,
	DETECTING: {
		title: 'Docking',
		icon: 'Docking-NavigatingIcon.svg',
		actionRequired: (
			<>
				Release the <span>P</span> key to stop.
			</>
		),
		IN_PROGRESS: {
			label: 'Detecting the dock...',
		},
		SUCCESS: {
			label: 'Dock detection Successful.',
		},
		FAILED: {
			title: 'Docking Failed',
			icon: 'Detection Failed.svg',
			actionRequired: (
				<>
					Reposition and press <span>P</span> to try again.
				</>
			),
		},
	},
	DOCKING: {
		title: 'Docking',
		icon: 'Docking-Positioning.svg',
		IN_PROGRESS: {
			label: 'Navigating into the dock...',
			actionRequired: (
				<>
					Release the <span>P</span> key to stop.
				</>
			),
		},
		SUCCESS: {
			title: 'Docking Successful',
			// label: 'The GoBe Robot is now docked.',
			icon: 'Docking-Successful.svg',
			actionRequired: (
				<>
					Release the <span>P</span> key to dismiss.
				</>
			),
		},
		FAILED: {
			title: 'Docking Failed',
			// label: 'The GoBe Robot docking failed',
			actionRequired: (
				<>
					Reposition and press <span>P</span> to try again.
				</>
			),
		},
	},
	STOPPING: null,
};

function getConfig({ stage, state }: AutoDockingController['status']) {
	const config = DockingStatusConfig[stage];
	if (config === null) return config;

	return {
		title: config[state]?.title ?? config.title,
		label: config[state]?.label ?? config.label,
		icon: config[state]?.icon ?? config.icon,
		actionRequired: config[state]?.actionRequired ?? config.actionRequired,
	} as IConfig;
}

type Props = {
	status: AutoDockingStatus;
};
export default function AutoDockingStatusIndicator({ status }: Props) {
	const config = getConfig(status);

	if (config === null) return null;

	// const statusMessage = statusConverter(`DOCK ${stage} ${state}`);
	return (
		<div className="dockingContainer">
			<div className="dockingTitle">{config.title}</div>
			<div className="dockingStatus">{config.label}</div>
			<div className="dockingIconWrapper">
				<img alt="" src={`../../assets/images/${config.icon}`} />
			</div>
			{config.actionRequired}
		</div>
	);
}
