import React from 'react';
import './index.scss';

type Props = {
	isVisible: boolean;
	onClickBackToRoster: () => void;
	onClickTryAgain: () => void;
};

export default function RobotUnavailableOverlay({
	onClickTryAgain,
	onClickBackToRoster,
	isVisible,
}: Props) {
	return isVisible ? (
		<div className="failedConnectionContainer">
			<div className="warningIconWrapper">
				<img alt="" src="../../assets/images/white-warning.svg" />
			</div>
			<div className="warningText">The Robot is unavailable, please try again later</div>
			<div className="backAgainButtonsContainer">
				<div className="circleTextContainer">
					<div className="greenCircle blackCircle" onClick={() => onClickBackToRoster()}>
						<div className="iconWrapper rotateNinety">
							<img src="../../assets/images/white-chevron.svg" alt="" />
						</div>
					</div>
					<div>Back</div>
				</div>
				<div className="circleTextContainer">
					<div className="whiteCircle blackCircle" onClick={() => onClickTryAgain()}>
						<div className="iconWrapper">
							<img src="../../assets/images/refresh.svg" alt="" />
						</div>
					</div>
					<div>Try again</div>
				</div>
			</div>
		</div>
	) : null;
}
