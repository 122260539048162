import React, { FC } from 'react';
import isAuthenticated from '../../components/Authentication/Authenticated';
import MainContent from '../../components/MainContent/MainContent';
import classes from './Helpcenter.module.css';
import classNames from 'classnames';

const HelpcenterPage: FC = () => {
	return (
		<MainContent
			title="Help center"
			renderContent={history => {
				return <div className={classNames(classes.helpCenterContent, 'ion-padding')} />;
			}}
		/>
	);
};

export default isAuthenticated(HelpcenterPage, 'HelpcenterPage');
