import React from 'react';
import './index.scss';
import { PrimaryCameraState, RobotPrimaryCamera } from '../../../../types';

type Props = {
	primaryCameraState: PrimaryCameraState;
};
const NextCameraAction: Record<RobotPrimaryCamera, { icon: string; text: string }> = {
	[RobotPrimaryCamera.ZOOM_CAM]: {
		icon: '../../assets/images/add-outline.svg',
		text: 'Zooming in ...',
	},
	[RobotPrimaryCamera.WIDE_CAM]: {
		icon: '../../assets/images/remove-outline.svg',
		text: 'Zooming out ...',
	},
};
export default function ZoomTransition({ primaryCameraState }: Props) {
	const isSwitchingPrimaryCamera = primaryCameraState.isChangingPrimaryCameraTo !== null;
	if (!isSwitchingPrimaryCamera) return null;

	const { icon, text } = NextCameraAction[primaryCameraState.isChangingPrimaryCameraTo!];
	return (
		<div className={'zoomTransitionContainer'}>
			<div className="zoomTransitionLoading" />
			<img className="zoomIcon" src={icon} alt="" />
			<div className="zoomTransitionText">{text}</div>
		</div>
	);
}
