import {
	INSERT_NEW_DEVICE_GROUP,
	FETCH_DEVICE_GROUPS_BEGIN,
	FETCH_DEVICE_GROUPS_SUCCESS,
	FETCH_DEVICE_GROUPS_FAILURE,
	FETCH_DEVICE_GROUPS_BY_ORGANIZATION,
	SET_DEVICE_GROUPS_SORT_PARAMS,
	UPDATE_DEVICE_GROUP,
	UPDATE_DEVICE_GROUP_DEVICES,
	UPDATE_DEVICE_GROUP_USER_GROUPS,
	DELETE_DEVICE_GROUP,
	DELETE_DEVICE_GROUP_DEVICE,
	RESET_APP,
	CREATED_NEW_DEVICE_GROUP,
	DEVICES_ADDED_TO_GROUP_STATE,
	CHANGE_ADD_DEVICES_TO_GROUP_STATE,
} from '../actions/types';
import { DeviceGroup } from '../types/types';

export interface initialDeviceGroupState {
	deviceGroupsByOrganizationId: Record<string, Record<string, DeviceGroup>>;
	items: Array<DeviceGroup>;
	loading: boolean;
	error: string | null;
	sortParams: Record<string, string>;
	createdGroup: DeviceGroup | null;
	addRobotsCount: number;
	addDevicesOpen: boolean;
}

const initialState: initialDeviceGroupState = {
	deviceGroupsByOrganizationId: {},
	items: [],
	loading: false,
	error: null,
	sortParams: { key: 'deviceGroupName', order: 'asc' },
	createdGroup: null,
	addRobotsCount: 0,
	addDevicesOpen: false,
};

export default (state = initialState, action: Record<string, any>): initialDeviceGroupState => {
	let orgId: string;
	let deviceGroup: DeviceGroup;
	let idx;
	let groups = {} as Record<string, DeviceGroup>;
	let deviceGroupDevices: any;
	switch (action.type) {
		case FETCH_DEVICE_GROUPS_BEGIN:
			return {
				...state,
				loading: true,
				error: null,
			};
		case FETCH_DEVICE_GROUPS_SUCCESS:
			groups = {};
			let items = state.items;
			orgId = '';
			action.payload.forEach((element: DeviceGroup) => {
				orgId = element.orgId;
				groups[element.deviceGroupId] = element;

				if (!items.find(item => item.deviceGroupId === element.deviceGroupId)) {
					items.push(element);
				}
			});
			return {
				...state,
				loading: false,
				items: items,
				deviceGroupsByOrganizationId: {
					...state.deviceGroupsByOrganizationId,
					[orgId]: groups,
				},
			};
		case FETCH_DEVICE_GROUPS_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload.error,
				items: [],
				deviceGroupsByOrganizationId: {},
			};
		case FETCH_DEVICE_GROUPS_BY_ORGANIZATION:
			if (
				action.payload.deviceGroupsByOrganization != null &&
				action.payload.deviceGroupsByOrganization.length > 0
			) {
				orgId = action.payload.deviceGroupsByOrganization[0].orgId;
				groups[orgId] = action.payload.deviceGroupsByOrganization;
			} else {
				groups = {};
			}

			return {
				...state,
				loading: false,
				items: action.payload.deviceGroupsByOrganization,
				deviceGroupsByOrganizationId: groups,
			};

		case SET_DEVICE_GROUPS_SORT_PARAMS:
			return {
				...state,
				sortParams: action.payload.sortParams,
			};

		case INSERT_NEW_DEVICE_GROUP:
			orgId = action.payload.orgId;
			state.items.push(action.payload);
			return {
				...state,
				loading: false,
				items: [...state.items],
				deviceGroupsByOrganizationId: {
					...state.deviceGroupsByOrganizationId,
					[orgId]: {
						...state.deviceGroupsByOrganizationId[orgId],
						[action.payload.deviceGroupId]: action.payload,
					},
				},
			};
		case UPDATE_DEVICE_GROUP:
			deviceGroup = action.payload;
			orgId = deviceGroup.orgId;
			idx = state.items.findIndex(gr => gr.deviceGroupId === deviceGroup.deviceGroupId);

			state.deviceGroupsByOrganizationId[orgId][deviceGroup.deviceGroupId] = deviceGroup;
			state.items.splice(idx, 1);

			return {
				...state,
				loading: false,
				items: [...state.items, deviceGroup],
				deviceGroupsByOrganizationId: {
					...state.deviceGroupsByOrganizationId,
					[orgId]: state.deviceGroupsByOrganizationId[orgId],
				},
			};

		case UPDATE_DEVICE_GROUP_DEVICES:
			deviceGroupDevices = action.payload;
			orgId = deviceGroupDevices.orgId;
			idx = state.items.findIndex(
				gr => gr.deviceGroupId === deviceGroupDevices.deviceGroupId
			);
			if (idx < 0) return state;
			deviceGroup = state.items[idx];

			if (
				deviceGroupDevices.addedDevicesIds &&
				deviceGroupDevices.addedDevicesIds.length > 0
			) {
				deviceGroup.devicesIds = Array.isArray(deviceGroup.devicesIds)
					? deviceGroup.devicesIds
					: [];
				deviceGroupDevices.addedDevicesIds.forEach((id: string) => {
					if (!deviceGroup.devicesIds.includes(id)) deviceGroup.devicesIds.push(id);
				});
			}

			if (
				deviceGroupDevices.removedDevicesIds &&
				deviceGroupDevices.removedDevicesIds.length > 0
			) {
				for (let i = 0; i < deviceGroupDevices.removedDevicesIds.length; i++) {
					const index = deviceGroup.devicesIds?.indexOf(
						deviceGroupDevices.removedDevicesIds[i]
					);
					if (index > -1) {
						deviceGroup.devicesIds.splice(index, 1);
					}
				}
			}

			state.items[idx] = deviceGroup;
			state.deviceGroupsByOrganizationId[orgId][deviceGroup.deviceGroupId] = deviceGroup;

			return {
				...state,
				loading: false,
				items: [...state.items],
				deviceGroupsByOrganizationId: {
					...state.deviceGroupsByOrganizationId,
					[orgId]: {
						...state.deviceGroupsByOrganizationId[orgId],
					},
				},
			};
		case UPDATE_DEVICE_GROUP_USER_GROUPS:
			let deviceGroupsId = action.payload;
			let organizationId = deviceGroupsId.orgId;

			if (
				deviceGroupsId.addedDeviceGroupsIds &&
				deviceGroupsId.addedDeviceGroupsIds.length > 0
			) {
				for (let i = 0; i < deviceGroupsId.addedDeviceGroupsIds.length; i++) {
					let deviceGroup =
						state.deviceGroupsByOrganizationId[organizationId][
							deviceGroupsId.addedDeviceGroupsIds[i]
						];

					deviceGroup.userGroupsIds = Array.isArray(deviceGroup.userGroupsIds)
						? deviceGroup.userGroupsIds
						: [];

					const index = deviceGroup.userGroupsIds?.indexOf(deviceGroupsId.userGroupId);

					if (index === -1) {
						deviceGroup.userGroupsIds.push(deviceGroupsId.userGroupId);
					}

					state.deviceGroupsByOrganizationId[organizationId][
						deviceGroupsId.addedDeviceGroupsIds[i]
					] = deviceGroup;
				}
			}

			if (
				deviceGroupsId.removedDeviceGroupsIds &&
				deviceGroupsId.removedDeviceGroupsIds.length > 0
			) {
				for (let i = 0; i < deviceGroupsId.removedDeviceGroupsIds.length; i++) {
					let deviceGroup =
						state.deviceGroupsByOrganizationId[organizationId][
							deviceGroupsId.removedDeviceGroupsIds[i]
						];

					const index = deviceGroup.userGroupsIds?.indexOf(deviceGroupsId.userGroupId);

					if (index > -1) {
						deviceGroup.userGroupsIds.splice(index, 1);
					}
				}
			}

			return {
				...state,
				loading: false,
				items: [...state.items],
				deviceGroupsByOrganizationId: {
					...state.deviceGroupsByOrganizationId,
				},
			};

		case DELETE_DEVICE_GROUP:
			const deletedGroup = action.payload;
			orgId = deletedGroup.orgId;
			idx = state.items.findIndex(gr => gr.deviceGroupId === deletedGroup.deviceGroupId);
			if (idx < 0) return state;
			state.items.splice(idx, 1);

			delete state.deviceGroupsByOrganizationId[orgId][deletedGroup.deviceGroupId];
			return {
				...state,
				loading: false,
				items: [...state.items],
				deviceGroupsByOrganizationId: {
					...state.deviceGroupsByOrganizationId,
					[orgId]: state.deviceGroupsByOrganizationId[orgId],
				},
			};

		case DELETE_DEVICE_GROUP_DEVICE:
			const device = action.payload.device;
			orgId = action.payload.oldOrgId || device.orgId;
			if (device.deviceGroupsIds && device.deviceGroupsIds.length > 0) {
				idx = state.items.findIndex(d => d.deviceGroupId === device.deviceGroupsIds[0]);
				if (idx < 0) return state;
				deviceGroup = state.items[idx];

				const index = deviceGroup.devicesIds.findIndex(d => d === device.deviceId);
				if (index < 0) return state;
				deviceGroup.devicesIds.splice(index, 1);

				state.items[idx] = deviceGroup;
				state.deviceGroupsByOrganizationId[orgId][deviceGroup.deviceGroupId] = deviceGroup;

				return {
					...state,
					items: [...state.items],
					deviceGroupsByOrganizationId: {
						...state.deviceGroupsByOrganizationId,
						[orgId]: state.deviceGroupsByOrganizationId[orgId],
					},
				};
			}

			return state;

		case RESET_APP:
			return {
				...state,
				deviceGroupsByOrganizationId: {},
				items: [],
				loading: false,
				error: null,
			};

		case CREATED_NEW_DEVICE_GROUP:
			return {
				...state,
				createdGroup: action.payload.createdGroup,
			};
		case DEVICES_ADDED_TO_GROUP_STATE:
			return {
				...state,
				addRobotsCount: action.payload.addRobotsCount,
			};
		case CHANGE_ADD_DEVICES_TO_GROUP_STATE:
			return {
				...state,
				addDevicesOpen: action.payload.deviceGroups,
			};
		default:
			return state;
	}
};
