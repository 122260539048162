import React, { FC, Fragment, useEffect, useState } from 'react';
import EditImageModal from '../EditImageModal/EditImageModal';
import isAuthenticated from '../Authentication/Authenticated';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';

import classes from './RobotEdit.module.css';

import { injectIntl, FormattedMessage } from 'react-intl';
import Messages from './RobotEdit.messages';

import { Device, UVDMongoDevice } from '../../types/types';
import { useTypedSelector } from '../../reducers';
import { equalityFnc, getStateEntityByOrgId } from '../../utils/conformState';
import RobotCard from './RobotCard';
import RobotActivity from './RobotActivity';
import RobotDetails from './RobotDetails';
import RobotServiceInfo from './RobotServiceInfo';
import robotAvatar from '../../assets/images/robotAvatar.svg';
import { publish } from '../../actions/publish';
import { toLowerCaseFirstLetter } from '../../utils/snakeCaseToCamelCase';

const deviceIcons: Record<string, string> = {
	beam: '../../../assets/img/beam-logo-white.svg',
	gobe: '../../../assets/icons/GOBE-ROBOTS-logo-neg.svg',
	uvd: '../../../assets/img/uvd-logo-white.svg',
};

interface RobotEditProps {
	editableDeviceId: string;
	onClose: () => void;
	mongoDevices: any;
	devicesByOrganizationId: any;
	orgId: string;
}

const RobotEdit: FC<RobotEditProps> = props => {
	const { editableDeviceId, onClose, mongoDevices, devicesByOrganizationId, orgId } = props;
	// const username: string = useTypedSelector(state => state.accountState.user.username);
	const selectedOrganizationId: string = useTypedSelector(
		state => state.selectedOrganizationState.organization.orgId
	);
	const spinoutType = useTypedSelector(state => state.versionState.spinoutType) as string;
	const username = useTypedSelector(state => state.accountState.user.username);
	const encodedUser = window.btoa(username);

	const [editPicOpen, setEditPicOpen] = useState(false);
	// initially set values
	if (devicesByOrganizationId[selectedOrganizationId][editableDeviceId]) {
		let newValues = devicesByOrganizationId[selectedOrganizationId][editableDeviceId];
		newValues.battery = newValues.battery
			? newValues.battery
			: newValues.status
			? JSON.parse(newValues.status)?.battery
			: null;
		newValues.level = newValues?.battery?.level
			? newValues.battery.level
			: newValues.status
			? JSON.parse(newValues.status)?.battery.level
			: null;
		newValues.online = newValues?.online
			? newValues.online
			: newValues.status
			? JSON.parse(newValues.status)?.online
			: false;
		newValues.currentState = newValues?.currentState
			? toLowerCaseFirstLetter(newValues.currentState)
			: newValues.status
			? toLowerCaseFirstLetter(JSON.parse(newValues.status)?.currentState)
			: null;
		devicesByOrganizationId[selectedOrganizationId][editableDeviceId] = newValues;
	}
	const [device, setDevice] = useState<UVDMongoDevice>(
		devicesByOrganizationId[selectedOrganizationId][editableDeviceId]
	);
	const robot = useTypedSelector(
		state =>
			getStateEntityByOrgId(state, 'deviceState', {
				entityId: editableDeviceId,
				orgId,
				propertyOptions: ['online', 'pictureLink', 'name', 'deviceGroupsIds'],
			}),
		(left, right) => equalityFnc(left, right)
	);

	const onSavePicture = (link: any) => {
		publish(`microservice/${selectedOrganizationId}/${encodedUser}/updateDeviceInfo`, {
			requestId: 'updateDeviceInfoId',
			data: {
				device_id: editableDeviceId,
				deviceGroupsIds: Array.isArray(robot.deviceGroupsIds)
					? robot.deviceGroupsIds
					: [robot.deviceGroupsIds],
				picture_link: link,
			},
		});
		setEditPicOpen(false);
	};

	useEffect(() => {
		if (!devicesByOrganizationId[selectedOrganizationId][editableDeviceId]) return;

		if (mongoDevices) {
			const mongoDevice = mongoDevices[editableDeviceId];
			// this will work for both beam & gobe only as mongo device will be null in this case.
			if (mongoDevice === undefined) {
				setDevice(devicesByOrganizationId[selectedOrganizationId][editableDeviceId]);
				return;
			}

			setDevice({
				...devicesByOrganizationId[selectedOrganizationId][editableDeviceId],
				installedAt: mongoDevice.installedAt,
				name: mongoDevice.name,
				uvdStatus: mongoDevice.status,
				lastService: mongoDevice.lastService,
				softwareVersion: mongoDevice.softwareVersion,
				batteryPercentage: mongoDevice.batteryPercentage,
				package: mongoDevice.package,
				softwareVersionDate: mongoDevice.softwareVersionDate,
				serialNumber: mongoDevice.serialNumber,
			});
		}
	}, [mongoDevices, devicesByOrganizationId]);

	return (
		<Fragment>
			<div className={classes.leftSide}>
				<RobotCard
					orgId={selectedOrganizationId}
					robotId={editableDeviceId}
					onClose={onClose}
					setEditPicOpen={setEditPicOpen}
					device={device}
				/>
				{spinoutType === 'uvd' ? (
					<RobotServiceInfo
						orgId={selectedOrganizationId}
						robotId={editableDeviceId}
						device={device}
					/>
				) : (
					<RobotActivity
						orgId={selectedOrganizationId}
						robotId={editableDeviceId}
						device={device}
					/>
				)}
			</div>
			<div className={classes.rightSide}>
				<RobotDetails robotId={editableDeviceId} onClose={onClose} device={device} />
			</div>
			{editPicOpen ? (
				<EditImageModal
					isOpen={editPicOpen}
					title={
						<FormattedMessage {...Messages.editPicTitle} values={{ item: 'Robot' }} />
					}
					subTitle={device.name}
					pictureLink={robot.pictureLink}
					noImg={robotAvatar}
					onSave={onSavePicture}
					onDismiss={() => setEditPicOpen(false)}
				/>
			) : null}
		</Fragment>
	);
};

const mapStateToProps = (state: any) => ({
	mongoDevices: state.deviceState.mongoDevices,
	devicesByOrganizationId: state.deviceState.devicesByOrganizationId,
});

export default injectIntl(
	isAuthenticated(connect(mapStateToProps, { setParameter })(RobotEdit), 'RobotEdit')
);
