import { defineMessages } from 'react-intl';

export default defineMessages({
	name: {
		id: 'UsersPage.name',
		defaultMessage: 'Name',
		description: 'User name',
	},
	email: {
		id: 'InviteModal.email',
		defaultMessage: 'Email Address',
		description: 'User email',
	},
	groups: {
		id: 'UsersPage.userGroups',
		defaultMessage: 'User Groups',
		description: 'User groups',
	},
	dateAdded: {
		id: 'UsersPage.dateAdded',
		defaultMessage: 'Date Added',
		description: 'Date added',
	},
	privileges: {
		id: 'UsersPage.privileges',
		defaultMessage: 'Privileges',
		description: 'Privileges',
	},
	latestSession: {
		id: 'UsersPage.latestSession',
		defaultMessage: 'Latest session',
		description: 'Latest session',
	},
	nrGroups: {
		id: 'nrGroups',
		defaultMessage: '{number} Groups',
		description: 'Number of groups',
	},
	noUsers: {
		id: 'User.noUsers',
		defaultMessage: 'No Users here!',
		description: 'No Users here!',
	},
	noUsersHint: {
		id: 'User.addGroup',
		defaultMessage: "You haven't invited any Users yet!",
		description: "You haven't invited any Users yet!",
	},
	admin: {
		id: 'admin',
		defaultMessage: 'Admin',
		description: 'Admin user',
	},
});
