import React, { FC, useCallback, useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { IonLabel, IonRouterLink, IonIcon } from '@ionic/react';
import { openOutline } from 'ionicons/icons';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';

import classes from './Robots.module.css';
import classNames from 'classnames';
import Messages from './Robots.messages';

import isAuthenticated from '../Authentication/Authenticated';
import { Device } from '../../types/types';
import ListGrid from '../ListGrid/ListGrid';
import RobotName from './RobotName';
import RobotGroupName from './RobotGroupName';
import RobotStatus from './RobotStatus';
import RobotBattLevel from './RobotBattLevel';
import RobotJoinedDate from './RobotJoinedDate';
import RobotDefaultProps from './RobotDefaultProps';
import RobotMoveGroup from '../RobotMoveGroup/RobotMoveGroup';
import { styled } from '@material-ui/styles';
import { Divider, MenuItem } from '@material-ui/core';
import Disinfection from './Disinfection';

import Battery from '../Battery/Battery';
import { getDifferenceInDays } from '../../utils/formatTime';
import { useTypedSelector } from '../../reducers';
import { b64EncodeUnicode } from '../../utils/encoding';
import { publish } from '../../actions/publish';
import EllipsisText from '../EllipsisText/EllipsisText';
interface RobotsProps {
	robotHeaders: Array<Record<string, any>>;
	setSelectedDeviceId: any;
	setIsEditing: any;
	data: Array<any>;
}
const StyledMenuItem = styled(MenuItem)(() => ({
	paddingLeft: 10,
	borderRadius: 5,
}));
const RobotsList: FC<RobotsProps> = (props: any) => {
	const {
		data,
		setSelectedDeviceId,
		setIsEditing,
		robotHeaders,
		selectRobots,
		selectedRobots,
		handleOnCheck,
		checkAll,
		unCheckAll,
	} = props;
	const [pageSize] = useState(11);
	const [showTransfer, setShowTransfer] = useState(false);
	const [selectedRobot, setSelectedRobot] = useState();
	const username = useTypedSelector(state => state.accountState.user.username);
	const encodedUser = b64EncodeUnicode(username);

	const formatTemplate = useCallback(
		(item: any, property: string) => {
			switch (property) {
				case 'name':
					return (
						<RobotName
							selectRobots={selectRobots}
							robotId={item.deviceId}
							orgId={item.orgId}
							dataCy={'robot_list_row_name_' + item.deviceId}
						/>
					);
				case 'deviceGroupName':
					return (
						<div
							data-cy={'robot_list_row_groupname_' + item.deviceId}
							className={classes.groupContainer}
						>
							<IonLabel
								className={
									item?.online
										? classes.groupNameLb
										: (classes.groupNameLb, classes.textGrey)
								}
							>
								<EllipsisText text={item?.deviceGroupName} />
							</IonLabel>
						</div>
					);
				case 'currentState':
					return <RobotStatus currentState={item.currentState} online={item.online} />;

				case 'level':
					return (
						<RobotBattLevel
							level={item.level}
							battery={item.battery}
							online={item.online}
						/>
					);
				case 'dateJoined':
					return <RobotJoinedDate robotId={item.deviceId} orgId={item.orgId} />;
				case 'battery':
					if (!item.mongoDevice) {
						return <></>;
					}
					return (
						<div className={classes.batteryContainer}>
							<Battery
								batteryPercent={
									item.mongoDevice.status &&
									item.mongoDevice.status.battery_level != null
										? item.mongoDevice.status.battery_level
										: 40
								}
								online={true} //change this it will be always online
								charging={
									item.mongoDevice.status && item.mongoDevice.status.charging
										? item.mongoDevice.status.charging
										: false
								}
							/>
							<IonLabel className={classes.batteryPercentage}>
								{item.mongoDevice.status?.battery_level}%
							</IonLabel>
						</div>
					);
				case 'location':
					return (
						<div data-cy={'robot_list_row_location_' + item.deviceId}>
							<IonLabel
								className={
									item?.online
										? classes.groupNameLb
										: (classes.groupNameLb, classes.textGrey)
								}
							>
								{item?.location?.length > 15
									? item?.location.substring(0, 15) + '...'
									: item?.location}
							</IonLabel>
						</div>
					);

				case 'disinfections':
					if (!item.mongoDevice) {
						return <></>;
					}
					return <Disinfection device={item.mongoDevice} />;
				case 'inUseSince':
					if (!item.mongoDevice) {
						return <></>;
					}
					return (
						<IonLabel className={classes.inputLb}>
							{getDifferenceInDays(
								item.mongoDevice.installedAt
									? new Date(item.mongoDevice.installedAt)
									: new Date()
							)}{' '}
							days
						</IonLabel>
					);
				default:
					return (
						<RobotDefaultProps
							robotId={item.deviceId}
							orgId={item.orgId}
							property={property}
						/>
					);
			}
		},
		[selectRobots]
	);

	const onEdit = (device: Device) => {
		setSelectedDeviceId(device.deviceId);
		setIsEditing(true);
	};

	const unassignRobotFromGroup = (device: Device) => {
		publish(`microservice/${device.orgId}/${encodedUser}/updateDeviceGroupDevices`, {
			requestId: 'updateDeviceGroupDevicesId',
			data: {
				deviceGroupId: device.deviceGroupsIds[0],
				remove: [device.deviceId],
			},
		});
	};

	const check = (item: any) => {
		return selectedRobots.some((robot: any) => robot == item);
	};
	return (
		<>
			<ListGrid
				id="robot-list-grid"
				dataCy="robot_list"
				headers={robotHeaders}
				data={data}
				pageSize={pageSize}
				itemTemplate={(item, property) => {
					return formatTemplate(item, property);
				}}
				moreContent={(item: any, onClosePopover: any) => {
					return (
						<>
							<StyledMenuItem onClick={() => onEdit(item)}>
								<FormattedMessage {...Messages.edit} />
							</StyledMenuItem>
							<Divider />
							<StyledMenuItem
								onClick={() => {
									setShowTransfer(true);
									setSelectedRobot(item);
									onClosePopover();
								}}
								data-cy="move_robot_popover"
							>
								<FormattedMessage {...Messages.moveTo} />
							</StyledMenuItem>
							{item?.deviceGroupsIds && item?.deviceGroupsIds?.length > 0 ? (
								<>
									<Divider />
									<StyledMenuItem
										onClick={() => {
											unassignRobotFromGroup(item);
											onClosePopover();
										}}
									>
										<FormattedMessage {...Messages.unassignRobotFromGroup} />
									</StyledMenuItem>
								</>
							) : null}
						</>
					);
				}}
				noDataTemplate={
					<>
						<IonLabel className={classes.noData}>
							<FormattedMessage {...Messages.noRobots} />
						</IonLabel>
						<IonLabel className={classNames(classes.noData, classes.noDataHint)}>
							<FormattedMessage {...Messages.noRobotsHint} />
						</IonLabel>
					</>
				}
				checkAll={() => checkAll(data)}
				handleOnCheck={handleOnCheck}
				unCheckAll={unCheckAll}
				checkItem={check}
				onRowClick={selectRobots ? () => {} : onEdit}
				sortType="SET_DEVICES_SORT_PARAMS"
				listType="Robots"
			/>
			{showTransfer && (
				<RobotMoveGroup
					isOpen={selectedRobot && showTransfer}
					onDismiss={() => {
						setShowTransfer(false);
						setSelectedRobot(undefined);
					}}
					robot={selectedRobot}
				/>
			)}
		</>
	);
};

const mapStateToProps = (state: any) => ({
	deviceGroups: state.deviceGroupsState,
	selectedOrganizationType: state.selectedOrganizationState.organization.orgType,
	orgId: state.selectedOrganizationState.organization.orgId,
});

const enhance = compose(connect(mapStateToProps, { setParameter }));

export default injectIntl(isAuthenticated(enhance(RobotsList), 'RobotsList'));
