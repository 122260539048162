import { defineMessages } from 'react-intl';

export default defineMessages({
	userDetails: {
		id: 'Users.userDetails',
		defaultMessage: 'USer details',
		description: 'User details',
	},
	name: {
		id: 'Users.name',
		defaultMessage: 'Details',
		description: 'Details',
	},
	email: {
		id: 'Users.email',
		defaultMessage: 'Email',
		description: 'Email',
	},
	administrator: {
		id: 'Users.administrator',
		defaultMessage: 'Administrator',
		description: 'Is user role administrator?',
	},
	dateAdded: {
		id: 'Users.dateAdded',
		defaultMessage: 'Date Added',
		description: 'Date added',
	},
	lastLogin: {
		id: 'Users.lastLogin',
		defaultMessage: 'Last Login',
		description: 'Date added',
	},
	userGroups: {
		id: 'Users.userGroups',
		defaultMessage: 'User Groups',
		description: 'User group names',
	},
	robotGroup: {
		id: 'Users.robotGroup',
		defaultMessage: 'Robot Group',
		description: 'Robot group names',
	},
	timeAgo: {
		id: 'Users.timeAgo',
		defaultMessage: 'Time ago',
		description: 'Time ago',
	},
	softwareVersion: {
		id: 'Users.softwareVersion',
		defaultMessage: 'Software version used',
		description: 'Software version used',
	},
	linkedTo: {
		id: 'Users.linkedTo',
		defaultMessage: 'Linked to',
		description: 'Linked to',
	},
	no: {
		id: 'Users.no',
		defaultMessage: 'No',
		description: 'No',
	},
	yes: {
		id: 'Users.yes',
		defaultMessage: 'Yes',
		description: 'Yes',
	},
	assignedTo: {
		id: 'Users.assignedTo',
		defaultMessage: 'Assigned To',
		description: 'Assigned To',
	},
});
