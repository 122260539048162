import { IonIcon } from '@ionic/react';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { injectIntl } from 'react-intl';
import isAuthenticated from '../../components/Authentication/Authenticated';

import classNames from 'classnames';
import { useTypedSelector } from '../../reducers';
import classes from './AddGuestReservation.module.css';

import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Button, Grid, InputLabel, styled, TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { checkmarkCircleOutline, closeCircleOutline, reload } from 'ionicons/icons';
import _ from 'lodash';
import moment from 'moment';

interface AddGuestReservationFormProps {
	onSubmit: (data: any) => void;
	onCancel: () => void;
	orgId: string;
	refetchGuestReservationsData: any;
	originalReservationProps?: any;
}

const AddGuestReservationForm: FC<AddGuestReservationFormProps> = (props: any) => {
	const {
		intl,
		orgId,
		refetchGuestReservationsData,
		selectedDeviceId,
		originalReservationProps,
	} = props;
	const format = intl.formatMessage;
	const getFormattedDuration = (startingAt: string, endingAt: string) => {
		const durationValue = moment
			.duration(moment(endingAt).diff(moment(startingAt)))
			.asMinutes();
		const labelString = '' + durationValue + ' min';
		return { label: labelString, value: durationValue };
	};
	const userEmail: string = useTypedSelector(state => state.accountState.user.username);
	const userName: string = `${useTypedSelector(
		state => state.accountState.user.firstName
	)} ${useTypedSelector(state => state.accountState.user.lastName)}`;
	const devices = useTypedSelector(state => state.deviceState.devicesByOrganizationId);
	const [formValues, setFormValues] = useState<any>({
		subject: originalReservationProps?.subject ?? '',
		host: originalReservationProps?.host ?? userEmail ?? '',
		hostName: originalReservationProps?.hostName ?? userName ?? '',
		guestEmail: originalReservationProps?.guest ?? '',
		guestName: originalReservationProps?.guestName ?? '',
		startDate: originalReservationProps?.startingAt
			? new Date(originalReservationProps?.startingAt)
			: new Date(),
		startTime: (() => {
			const time = originalReservationProps?.startingAt
				? new Date(originalReservationProps?.startingAt)
				: new Date();
			time.setSeconds(0, 0);
			return time;
		})(),
		duration: originalReservationProps
			? getFormattedDuration(
					originalReservationProps.startingAt,
					originalReservationProps.endingAt
			  )
			: '',

		robot: (() => {
			let robot;
			if (originalReservationProps?.serialNumber && devices?.[orgId]) {
				robot = Object.values(devices[orgId]).find(robot => {
					return robot.deviceId === originalReservationProps?.serialNumber;
				});
			}
			return robot
				? {
						label: `${robot.name} ${
							robot.location !== '' && !!robot.location ? '- ' + robot.location : ''
						}`,
						value: robot.deviceId,
				  }
				: { label: '', value: '' };
		})(),
	});
	const isGuestReservationPossible = useMemo<boolean>(
		() => moment(formValues?.startTime).add(formValues?.duration?.value, 'minutes') > moment(),
		[formValues, formValues?.startTime, formValues?.duration]
	);
	const { handleSubmit, control, errors, formState, register } = useForm();
	const handleTextFieldChange = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		const { name, value } = event.target;
		let input = { [name]: value };
		switch (name) {
			case 'host':
				if (value !== userEmail && formValues.hostName === userName)
					input = {
						...input,
						hostName: '',
					};
				break;
			case 'robot':
				setRobotOptionsSearchTerm(value);
				input = { robot: { label: value, value: null } } as any;
				break;
		}
		setFormValues({
			...formValues,
			...input,
		});
	};

	const getIsGuestReservationAvailableQuery = gql`
		query isGuestReservationAvailableQuery(
			$serialNumber: String!
			$startingAt: DateTime!
			$endingAt: DateTime!
			$guest: String!
			$isEdit: Boolean!
			$originalSerial: String!
			$originalStartingAt: DateTime!
			$originalGuest: String!
		) {
			isGuestReservationAvailable(
				serialNumber: $serialNumber
				startingAt: $startingAt
				endingAt: $endingAt
				guest: $guest
				isEdit: $isEdit
				originalSerial: $originalSerial
				originalStartingAt: $originalStartingAt
				originalGuest: $originalGuest
			)
		}
	`;
	const [
		isGuestReservationAvailable,
		{
			called: isGuestReservationAvailableQueryCalled,
			loading: isGuestReservationAvailableDataLoading,
			data: isGuestReservationAvailableData,
			error: isGuestReservationAvailableDataError,
		},
	] = useLazyQuery(getIsGuestReservationAvailableQuery);

	const addGuestReservationMutation = gql`
		mutation addGuestReservationMutation(
			$orgId: String!
			$serialNumber: String!
			$host: String!
			$hostName: String!
			$subject: String!
			$startingAt: DateTime!
			$endingAt: DateTime!
			$guest: String!
			$guestName: String!
		) {
			addGuestReservation(
				orgId: $orgId
				serialNumber: $serialNumber
				host: $host
				hostName: $hostName
				subject: $subject
				startingAt: $startingAt
				endingAt: $endingAt
				guest: $guest
				guestName: $guestName
			) {
				guest
				guestName
				host
				hostName
				serialNumber
				startingAt
				endingAt
				createdAt
				subject
			}
		}
	`;
	const [
		addGuestReservation,
		{
			called: addGuestReservationMutationCalled,
			loading: addGuestReservationMutationLoading,
			data: addGuestReservationData,
			error: addGuestReservationError,
		},
	] = useMutation(addGuestReservationMutation);

	const editGuestReservationMutation = gql`
		mutation editGuestReservationMutation(
			$originalSerialNumber: String!
			$originalGuest: String!
			$originalStartingAt: DateTime!
			$orgId: String!
			$newSerialNumber: String!
			$newStartingAt: DateTime!
			$newEndingAt: DateTime!
			$newHost: String!
			$newHostName: String!
			$newGuest: String!
			$newGuestName: String!
			$newSubject: String!
		) {
			editGuestReservation(
				originalSerialNumber: $originalSerialNumber
				originalGuest: $originalGuest
				originalStartingAt: $originalStartingAt
				orgId: $orgId
				newSerialNumber: $newSerialNumber
				newStartingAt: $newStartingAt
				newEndingAt: $newEndingAt
				newHost: $newHost
				newHostName: $newHostName
				newGuest: $newGuest
				newGuestName: $newGuestName
				newSubject: $newSubject
			) {
				guest
				guestName
				host
				hostName
				serialNumber
				startingAt
				endingAt
				createdAt
				subject
			}
		}
	`;

	const [editedGuestReservation] = useMutation(editGuestReservationMutation, {
		onCompleted: data => {
			refetchGuestReservationsData();
		},
	});
	const [robotOptionsSearchTerm, setRobotOptionsSearchTerm] = useState<string | null>(null);
	const robotOptions = useMemo(() => {
		let robots: any[] = [];

		if (devices?.[orgId]) {
			robots = _.map(Object.values(devices[orgId]), device => {
				return {
					label: `${device.name} ${
						device.location !== '' && !!device.location ? '- ' + device.location : ''
					}`,
					value: device.deviceId,
				};
			});
		}
		if (robotOptionsSearchTerm)
			robots = robots.filter(
				robot =>
					robot.label.toLowerCase().includes(robotOptionsSearchTerm.toLowerCase()) ||
					robot.value.toLowerCase().includes(robotOptionsSearchTerm)
			);
		return robots;
	}, [devices, orgId, robotOptionsSearchTerm]);

	useEffect(() => {
		if (formValues?.robot && formValues?.startTime && formValues?.duration) {
			isGuestReservationAvailable({
				variables: {
					startingAt: moment(formValues?.startTime),
					serialNumber: formValues?.robot?.value,
					endingAt: moment(formValues?.startTime).add(
						formValues?.duration?.value,
						'minutes'
					),
					guest: formValues?.guestEmail,
					isEdit: originalReservationProps ? true : false,
					originalSerial: originalReservationProps?.serialNumber ?? '',
					originalStartingAt:
						originalReservationProps?.startingAt ?? moment(formValues?.startTime),
					originalGuest: originalReservationProps?.guest ?? '',
				},
			});
		}
	}, [formValues]);

	useEffect(() => {
		if (selectedDeviceId && devices?.[orgId]) {
			setFormValues({
				...formValues,
				robot: {
					label: devices[orgId]?.[selectedDeviceId]?.name,
					value: devices[orgId]?.[selectedDeviceId]?.deviceId,
				},
			});
		}
	}, [selectedDeviceId, devices, orgId]);

	const onFormSubmit = handleSubmit(() => {
		// Check if reservation is still possible
		if (moment(formValues?.startTime).add(formValues?.duration?.value, 'minutes') <= moment()) {
			setFormValues({ ...formValues });
			return;
		}
		//if this is an edit, then send with this:
		if (originalReservationProps) {
			editedGuestReservation({
				variables: {
					originalSerialNumber: originalReservationProps.serialNumber,
					originalGuest: originalReservationProps.guest,
					originalStartingAt: originalReservationProps.startingAt,
					orgId,
					newSerialNumber: formValues?.robot?.value,
					newStartingAt: moment(formValues?.startTime),
					newEndingAt: moment(formValues?.startTime).add(
						formValues?.duration?.value,
						'minutes'
					),
					newHost: formValues?.host,
					newHostName: formValues?.hostName,
					newGuest: formValues?.guestEmail,
					newGuestName: formValues?.guestName,
					newSubject: formValues?.subject,
				},
			}).then(data => {
				refetchGuestReservationsData();
				props.onCancel();
			});
		} else {
			addGuestReservation({
				variables: {
					serialNumber: formValues?.robot?.value,
					host: formValues?.host,
					hostName: formValues?.hostName,
					startingAt: moment(formValues?.startTime),
					endingAt: moment(formValues?.startTime).add(
						formValues?.duration?.value,
						'minutes'
					),
					guest: formValues?.guestEmail,
					guestName: formValues?.guestName,
					subject: formValues?.subject,
					orgId,
				},
			}).then(data => {
				refetchGuestReservationsData();
				props.onCancel();
			});
		}
	});

	const getCustomStyle = useCallback(() => {
		const color = getComputedStyle(document.documentElement).getPropertyValue(
			'--ion-color-primary'
		);
		return {
			'& label.Mui-focused': {
				color: color,
			},
			'& .MuiInput-underline:after': {
				borderBottomColor: color,
			},
			'& .MuiOutlinedInput-root': {
				'&:hover fieldset': {
					borderColor: color,
				},
				'&.Mui-focused fieldset': {
					borderColor: color,
				},
			},
		};
	}, []);

	const CustomTextField = useMemo(
		() =>
			styled(TextField)(() => {
				return getCustomStyle();
			}),
		[]
	);
	const CustomDatePicker = useMemo(
		() =>
			styled(DatePicker)(() => {
				return getCustomStyle();
			}),
		[]
	);
	const CustomTimePicker = useMemo(
		() =>
			styled(TimePicker)(() => {
				return getCustomStyle();
			}),
		[]
	);

	return (
		<form onSubmit={onFormSubmit} className={classes.addForm}>
			<Grid container spacing={4}>
				<Grid item xs={12}>
					<CustomTextField
						required
						id="subject-input"
						label="Subject"
						placeholder="Subject"
						variant="outlined"
						fullWidth={true}
						name="subject"
						value={formValues?.subject}
						onChange={handleTextFieldChange}
						data-cy="guest_reservation_subject_input"
						autoFocus
					/>
				</Grid>
				<Grid item xs={12}>
					<InputLabel htmlFor="guest-email-input">
						<Typography className={classes.inputLabel} variant="body1">
							<IonIcon className={classes.icon} src="./assets/icons/Gobe-pilot.svg" />
							Who will use the robot?
						</Typography>
					</InputLabel>
				</Grid>
				<Grid item xs={8}>
					<CustomTextField
						required
						id="guest-email-input"
						name="guestEmail"
						label="Guest email"
						placeholder="Guest email*"
						variant="outlined"
						type="email"
						fullWidth={true}
						helperText="We’ll send the invitation to this address"
						onChange={handleTextFieldChange}
						value={formValues?.guestEmail}
						data-cy="guest_reservation_guest_email_input"
					/>
				</Grid>
				<Grid item xs={4}>
					<CustomTextField
						required
						id="guest-name-input"
						name="guestName"
						label="Guest name"
						placeholder="Guest name"
						variant="outlined"
						fullWidth={true}
						onChange={handleTextFieldChange}
						value={formValues?.guestName}
						data-cy="guest_reservation_guest_name_input"
					/>
				</Grid>
				<Grid item xs={12}>
					<InputLabel htmlFor="organizer-email-input">
						<Typography className={classes.inputLabel} variant="body1">
							<IonIcon
								className={classes.icon}
								src="./assets/icons/Robot-with-host.svg"
							/>
							Who will be the host?
						</Typography>
					</InputLabel>
				</Grid>
				<Grid item xs={8}>
					<CustomTextField
						required
						id="organizer-email-input"
						name="host"
						label="Organizer email"
						placeholder="Organizer email*"
						variant="outlined"
						fullWidth={true}
						helperText="We’ll send the invitation also to this address"
						onChange={handleTextFieldChange}
						value={formValues?.host}
						data-cy="guest_reservation_organizer_email_input"
					/>
				</Grid>
				<Grid item xs={4}>
					<CustomTextField
						required
						id="organizer-name-input"
						name="hostName"
						label="Organizer name"
						placeholder="Organizer name"
						variant="outlined"
						fullWidth={true}
						helperText="Shared with the guest"
						onChange={handleTextFieldChange}
						value={formValues?.hostName}
						data-cy="guest_reservation_organizer_name_input"
					/>
				</Grid>
				<Grid item xs={12}>
					<InputLabel htmlFor="robot-select">
						<Typography className={classes.inputLabel} variant="body1">
							Which robot to use?
						</Typography>
					</InputLabel>
				</Grid>
				<Grid item xs={12}>
					<Autocomplete
						id="robot-select"
						value={formValues?.robot}
						options={robotOptions}
						getOptionLabel={option => option?.label}
						disabled={!!selectedDeviceId}
						disableClearable
						onChange={(e, value) => {
							setFormValues({
								...formValues,
								robot: value,
							});
						}}
						renderInput={params => (
							<CustomTextField
								{...params}
								label="Robot"
								name="robot"
								required
								fullWidth
								variant="outlined"
								onChange={handleTextFieldChange}
								error={formValues?.robot?.value === null}
								helperText={
									formValues?.robot?.value === null ? 'Robot not found!' : ' '
								}
							/>
						)}
						data-cy="guest_reservation_select_robot_dropdown"
					/>
				</Grid>
				<Grid item xs={12}>
					<InputLabel htmlFor="start-date-picker">
						<Typography className={classes.inputLabel} variant="body1">
							When to have the session?
						</Typography>
					</InputLabel>
				</Grid>
				<Grid item xs={4}>
					<CustomDatePicker
						label="Start date"
						value={formValues?.startDate}
						onChange={(date: MaterialUiPickersDate) => {
							setFormValues({
								...formValues,
								startDate: date,
								startTime: date,
							});
						}}
						animateYearScrolling
						inputVariant="outlined"
						format="YYYY-MM-DD"
						disablePast
						name="startDate"
						id="start-date-picker"
						required
						showTodayButton
						data-cy="guest_reservation_date_picker"
					/>
				</Grid>
				<Grid item xs={4}>
					<CustomTimePicker
						clearable
						label="Start time"
						value={formValues?.startTime}
						onChange={(date: MaterialUiPickersDate) => {
							setFormValues({
								...formValues,
								startTime: date,
							});
						}}
						inputVariant="outlined"
						format="HH:mm"
						name="startTime"
						required
						helperText="Your local time"
						data-cy="guest_reservation_time_picker"
					/>
				</Grid>
				<Grid item xs={4}>
					<Autocomplete
						id="duration-select"
						value={formValues?.duration}
						disableClearable
						options={[
							{ label: '10 mins', value: 10 },
							{ label: '15 mins', value: 15 },
							{ label: '20 mins', value: 20 },
							{ label: '25 mins', value: 25 },
							{ label: '30 mins', value: 30 },
							{ label: '45 mins', value: 45 },
							{ label: '1 hour', value: 60 },
							{ label: '1 hour 30 mins', value: 90 },
							{ label: '2 hours', value: 120 },
							{ label: '2 hours 30 mins', value: 150 },
							{ label: '3 hours', value: 180 },
							{ label: '3 hours 30 mins', value: 210 },
							{ label: '4 hours', value: 240 },
							{ label: '4 hours 30 mins', value: 270 },
							{ label: '5 hours', value: 300 },
							{ label: '5 hours 30 mins', value: 330 },
							{ label: '6 hours', value: 360 },
							{ label: '8 hours', value: 480 },
						]}
						getOptionLabel={option => option.label}
						onChange={(e, value) => {
							setFormValues({
								...formValues,
								duration: value,
							});
						}}
						renderInput={params => (
							<CustomTextField
								{...params}
								label="Duration"
								name="duration"
								required
								fullWidth
								variant="outlined"
								onChange={handleTextFieldChange}
							/>
						)}
						data-cy="guest_reservation_duration_dropdown"
					/>
				</Grid>
			</Grid>
			{formValues.robot?.value && isGuestReservationAvailableQueryCalled && (
				<div
					className={classes.reservationMsgContainer}
					data-cy="guest_reservation_availability_message"
				>
					{!(
						isGuestReservationAvailableData &&
						'isGuestReservationAvailable' in isGuestReservationAvailableData
					) ? (
						<Typography className={classes.reservationCheckingMsg}>
							<IonIcon icon={reload} />
							Checking if the robot is available in the selected time frame.
						</Typography>
					) : isGuestReservationAvailableData?.isGuestReservationAvailable &&
					  isGuestReservationPossible ? (
						<Typography className={classes.reservationAvailableMsg}>
							<IonIcon icon={checkmarkCircleOutline} />
							The robot is available in the selected time frame.
						</Typography>
					) : (
						<Typography className={classes.reservationUnavailableMsg}>
							<IonIcon icon={closeCircleOutline} />
							The robot is not available in the selected time frame.
						</Typography>
					)}
				</div>
			)}
			<Grid container justifyContent="center" spacing={4} style={{ marginTop: 30 }}>
				<Grid item>
					<Button
						variant="outlined"
						size="large"
						style={{ borderRadius: '25px' }}
						type="button"
						onClick={() => props.onCancel()}
						className={classes.cancelBtn}
						data-cy="guest_reservation_cancel"
					>
						Cancel
					</Button>
				</Grid>
				<Grid item>
					<Button
						variant="outlined"
						type="submit"
						size="large"
						style={{ borderRadius: '25px' }}
						className={classNames(
							!(
								isGuestReservationAvailableData?.isGuestReservationAvailable &&
								isGuestReservationPossible
							)
								? classes.disabledSubmitBtn
								: classes.submitBtn
						)}
						disabled={
							!(
								isGuestReservationAvailableData?.isGuestReservationAvailable &&
								isGuestReservationPossible
							)
						}
						data-cy="guest_reservation_invite"
					>
						Invite
					</Button>
				</Grid>
			</Grid>
		</form>
	);
};

export default injectIntl(isAuthenticated(AddGuestReservationForm, 'AddGuestReservationForm'));
