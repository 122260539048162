import { IonButton, IonCol, IonModal, IonRow } from '@ionic/react';
import React, { FC, useEffect, useState } from 'react';
import './index.scss';
interface AgreementProps {
	agreement: { value: string; description: string; message: string };
	isOpen: any;
	cancelAgreementClick: Function;
	agreeContinueClick: Function;
	isSafetyAgreement?: string;
}

const AgreementsFrame: FC<AgreementProps> = props => {
	const {
		agreement,
		isOpen,
		cancelAgreementClick,
		agreeContinueClick,
		isSafetyAgreement,
	} = props;

	const [isModalOpenState, setIsModalOpenState] = useState<any>();

	useEffect(() => {
		setIsModalOpenState(isOpen || false);
	}, [isOpen]);

	return (
		<IonModal
			backdropDismiss={false}
			swipeToClose
			show-backdrop
			isOpen={isModalOpenState}
			cssClass="addModal"
		>
			<iframe
				className="agreementFrame"
				style={{
					margin: '20px',
					position: 'sticky',
					bottom: 100,
					zIndex: 1000,
					left: 'calc(50% - 325px)',
					boxSizing: 'border-box',
					height: '-webkit-fill-available',
					maxHeight: '80vh',
				}}
				title={agreement.value}
				id={agreement.value}
				frameBorder="0"
				src={
					'data:text/html;charset=utf-8;http-equiv=content-type,' +
					escape(
						agreement.description
							.replace('72pt 72pt 72pt 72pt', '0')
							.replace(/<body /g, `<body id="${'body-' + agreement.value}" `)
							.replace(/href/g, ' target="_blank" href')
							.replace(
								/<\/body>/g,
								`<script>
								let height;
								const sendPostMessage = () => {
									if(document.getElementsByTagName('html')[0].offsetHeight === 0)
									{
										setTimeout(() => {sendPostMessage},100);
									}
									if (height !== document.getElementsByTagName('html')[0].offsetHeight) {
										height = document.getElementsByTagName('html')[0].offsetHeight;
										window.parent.postMessage({
										${agreement.value}: height
										}, '*');
									}
								}
								window.onload = () => sendPostMessage();
								</script></body>`
							)
					)
				}
			/>
			{isSafetyAgreement ? (
				<IonRow className="safety-agreement">
					<IonCol>
						<IonButton className="saftety-btn" onClick={() => agreeContinueClick()}>
							Agree
						</IonButton>
						<IonButton
							className="saftety-btn-outline"
							fill="outline"
							onClick={() => cancelAgreementClick()}
						>
							Cancel
						</IonButton>
					</IonCol>
				</IonRow>
			) : (
				<IonRow className="btnRow">
					<IonCol>
						<IonButton
							expand="block"
							shape="round"
							type="submit"
							className="transparent"
							onClick={() => cancelAgreementClick()}
						>
							Cancel
						</IonButton>
					</IonCol>
					<IonCol className="submitCol">
						<IonButton
							expand="block"
							shape="round"
							fill="outline"
							onClick={() => agreeContinueClick()}
						>
							Agree and Continue
						</IonButton>
					</IonCol>
				</IonRow>
			)}
		</IonModal>
	);
};

export default AgreementsFrame;
