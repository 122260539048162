export interface Size {
	height: number;
	width: number;
}
export type IActiveNavInput = 'keyboard' | 'joystick' | 'auto-docking';
export type RtpReceiverID = 'primaryCam' | 'navCam';
export enum RobotPrimaryCamera {
	WIDE_CAM = 'wide_cam',
	ZOOM_CAM = 'zoom_cam',
}
export interface PrimaryCameraState {
	currentPrimaryCamera: RobotPrimaryCamera;
	isChangingPrimaryCameraTo: RobotPrimaryCamera | null;
}

export enum SessionInfoProtocol {
	V3 = 'v3',
	V2 = 'v2',
}

export type CloudLogLevel = 'log' | 'info' | 'debug' | 'error';
