import { SET_ACTIONSTATE, UPDATE_STATE, RESET_APP } from '../actions/types';

// TODO: actions to be array
const initialState = {
	actions: {
		id: '',
		description: '',
		state: '',
	},
};

export default (state = initialState, action) => {
	switch (action.type) {
		case SET_ACTIONSTATE:
			return {
				...state,
				actions: action.payload.insertActionState,
			};
		case UPDATE_STATE:
			return {
				...state,
				actions: {
					...state.actions,
					state: action.payload.state,
				},
			};

		case RESET_APP:
			return {
				...state,
				id: '',
				description: '',
				state: '',
			};

		default:
			return state;
	}
};
