import classNames from 'classnames';
import React from 'react';
import { Reservation, IDeviceCurrentState } from '../../../../../../utils/statusConverter';
import { DefaultStatusIcon } from './DefaultStatusIcon';
import '../../index.scss';
import { ReservationStatusIcon } from './ReservationStatusIcon';

interface Props {
	canCall: boolean;
	onClickStartSession: () => void;
	nextReservation: Reservation;
	deviceStatus: IDeviceCurrentState;
	isCalling: boolean;
	deviceName: string;
	isPermanentDevice: boolean;
}

export const NameColumn = ({
	canCall,
	onClickStartSession,
	nextReservation,
	deviceStatus,
	isCalling,
	deviceName,
	isPermanentDevice,
}: Props) => {
	return (
		<div
			className={classNames('rosterListRowCell rosterListNameCell', {
				pointerCursor: canCall,
			})}
			onClick={() => {
				if (canCall) {
					onClickStartSession();
				} else {
					return;
				}
			}}
		>
			<div className="rosterListStatusIconWrapper">
				{!!nextReservation ? (
					<ReservationStatusIcon
						reservation={nextReservation}
						deviceStatus={deviceStatus}
						isPermanentDevice={isPermanentDevice}
						isCalling={isCalling}
					/>
				) : (
					<DefaultStatusIcon
						status={deviceStatus}
						isGuest={false}
						isCalling={isCalling}
					/>
				)}
			</div>
			<span className="bodyStrong" style={{ marginLeft: 8 }}>
				{deviceName}
			</span>
		</div>
	);
};
