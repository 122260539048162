import React, { FC, useState, useEffect } from 'react';
import { IonLabel, IonRouterLink, IonIcon } from '@ionic/react';
import { openOutline } from 'ionicons/icons';

import classes from './Robots.module.css';
import { mongodbClient } from '../../providers/mongodbClient';

import { injectIntl, FormattedMessage } from 'react-intl';
import Messages from './Robots.messages';
import { UVDMongoDevice } from '../../types/types';
import { useTypedSelector } from '../../reducers';

interface DisinfectionDefaultProps {
	device: UVDMongoDevice;
}

const Disinfection: FC<DisinfectionDefaultProps> = props => {
	const { device } = props;

	const [disinfections, setDisinfections] = useState(0);

	const mongoDbConfig = useTypedSelector(state => state.mongoDbConfigState.mongoDbConfig);

	useEffect(() => {
		if (!device.serialNumber) return;
		var filter = {
			robotId: device.serialNumber,
		};
		mongodbClient
			.countCollection(filter, mongoDbConfig.collections.disinfectionCollection)
			.then((result: any) => {
				setDisinfections(result);
			})
			.catch((err: any) => {
				console.error(`Failed to count document: ${err}`);
			});
	}, [disinfections, device]);

	return (
		<IonLabel>
			<IonRouterLink
				href={'/disinfection-dashboard/robotId=' + device.serialNumber}
				className={classes.disinfectionsLink}
			>
				{disinfections ? disinfections : 0}{' '}
				<IonIcon icon={openOutline} className={classes.disinfectionRedirectIcon}></IonIcon>
			</IonRouterLink>
		</IonLabel>
	);
};

export default Disinfection;
