import { defineMessages } from 'react-intl';

export default defineMessages({
    oops: {
		id: 'oops',
		defaultMessage: 'Oops!',
		description: 'Oops!',
	},
	success: {
		id: 'success',
		defaultMessage: 'Success!',
		description: 'Success!',
	},
	msg: {
		id: 'msg',
		defaultMessage: 'Sorry, your token expired!',
		description: 'Sorry, your token expired!',
    },
    hint: {
        id: 'hint',
        defaultMessage: "We'll need to re-send your authentication email",
        description: "We'll need to re-send your authentication email",
	},
	msgAccExists: {
		id: 'msg',
		defaultMessage: 'That email address is already in use!',
		description: 'That email address is already in use!',
	},
	hintAccExists: {
        id: 'hint',
        defaultMessage: "Please provide another email address.",
        description: "Please provide another email address.",
	},
	msgAccFail: {
		id: 'msg',
		defaultMessage: 'There was an error while creating your account!',
		description: 'There was an error while creating your account!',
	},
	hintAccFail: {
        id: 'hint',
        defaultMessage: "Please try again later.",
        description: "Please try again later.",
	},
	msgAccCreated: {
		id: 'msg',
		defaultMessage: 'Your account information was successfully submitted.',
		description: 'Your account information was successfully submitted.',
	},
	hintAccCreated: {
        id: 'hint',
        defaultMessage: "Please check your inbox and make sure to click the link in the confirmation email.",
        description: "Please check your inbox and make sure to click the link in the confirmation email.",
	},
	ok: {
		id: 'ok',
		defaultMessage: 'OK',
		description: 'OK',
	},
	cancel: {
		id: 'cancel',
		defaultMessage: 'Cancel',
		description: 'Cancel',
	},
});
