/* eslint-disable camelcase */
import { useEffect, useState, useCallback, useLayoutEffect, useMemo } from 'react';
import PeerConnectionWithSignalling, { PeerConnectionEndReasonCode } from './peerConnection';
import MqttReconnector_Deprecated from './mqttReconnector.deprecated';
import { PrimaryCameraState } from '../../../../types';
import { ISignalingClient } from '../signaling/types';

export type ConnectionState = RTCPeerConnectionState;

type Args = {
	signalingClient: ISignalingClient;
	onDataChannel: (dataChannel: RTCDataChannel) => void;
	onStarted: () => void;
	onEnded: (reason: PeerConnectionEndReasonCode) => void;
};

const useCallerPeerConnection = ({ signalingClient, onDataChannel, onStarted, onEnded }: Args) => {
	/** a memoized peer connection to ensure referential stability. */
	const peerConnection = useMemo(() => new PeerConnectionWithSignalling(signalingClient), []);

	/** container for media tracks added by the remote peer */
	const remoteStream = useMemo(() => new MediaStream(), []);

	const media = usePCMediaStreams(peerConnection);

	// bind PeerConnection object to window, for debugging purposes
	useEffect(() => {
		if (process.env.NODE_ENV !== 'production') {
			(window as any).peerConnection = peerConnection;
			(window as any).sessionID = peerConnection.uuid;
		}
		return () => {
			(window as any).peerConnection = null;
		};
	}, []);

	const [isPeerConnectionPaused, setIsPeerConnectionPaused] = useState(peerConnection.isPaused);

	useLayoutEffect(() => {
		const pc = peerConnection;

		const onPause = () => setIsPeerConnectionPaused(true);
		const onUnPause = () => setIsPeerConnectionPaused(false);

		pc.addEventListener('pause', onPause);
		pc.addEventListener('unpause', onUnPause);

		return () => {
			pc.removeEventListener('pause', onPause);
			pc.removeEventListener('unpause', onUnPause);
		};
	}, []);

	// Kill peer connection when caller component is dieing
	useLayoutEffect(() => {
		console.info('caller.peerConnection.cleanup.registered');
		return () => {
			peerConnection.end('CLEANUP');
			console.info('caller.peerConnection.closed');
			console.info('caller.peerConnection.cleanup.unregistered');
		};
	}, []);

	// listen for connection state changes on the peer connection
	// const [connectionState, setConnectionState] = useState<ConnectionState>(
	// 	peerConnection.connectionState
	// );
	useLayoutEffect(() => {
		// peerConnection.onConnectionStateChange = setConnectionState;
		peerConnection.onEnded = onEnded;
		return () => {
			peerConnection.onConnectionStateChange = null;
			peerConnection.onEnded = null;
		};
	}, [onEnded]);

	// cleanup remote tracks when the caller component is exiting
	useLayoutEffect(() => {
		return () => {
			remoteStream.getTracks().forEach(track => {
				track.stop();
				remoteStream.removeTrack(track);
			});
		};
	}, []);

	// attach external callbacks to the peerConnection
	// As much as possible, we put external callbacks in a separate useEffect.
	// 	So that their reference changes does not affect other logic here due to re-renders
	useLayoutEffect(() => {
		peerConnection.onDataChanel = onDataChannel;
		peerConnection.onStarted = onStarted;

		return () => {
			peerConnection.onDataChanel = null;
			peerConnection.onStarted = null;
		};
	}, [onDataChannel, onStarted]);

	const [primaryCameraState, setPrimaryCameraState] = useState<PrimaryCameraState>(
		peerConnection.primaryCameraState
	);
	useLayoutEffect(() => {
		peerConnection.onPrimaryCameraStateChange = setPrimaryCameraState;
		return () => {
			peerConnection.onPrimaryCameraStateChange = null;
		};
	}, []);

	const startPeerConnection = useCallback((stream: MediaStream) => {
		peerConnection.start(stream).catch(error => {
			console.error('Unable to start peer connection', error);
		});
	}, []);

	const endPeerConnection = useCallback(
		(reason: PeerConnectionEndReasonCode = 'LOCAL_HANGUP') => {
			peerConnection.end(reason);
		},
		[]
	);

	const togglePrimaryCamera = useCallback(() => {
		peerConnection.togglePrimaryCamera().catch(error => console.error(error));
	}, []);

	// ********************************************************************************
	// ********************************************************************************
	// Handle state change for deprecated mqtt reconnector
	const [deprecatedMqttReconnector, setDeprecatedMqttReconnector] = useState<
		MqttReconnector_Deprecated
	>();

	useEffect(() => {
		const mediaTrack = media.primaryMediaStream.getVideoTracks()[0];
		if (!deprecatedMqttReconnector || !media.primaryRTPTransceiver || !mediaTrack) return;

		const rtcRtpReceiver = media.primaryRTPTransceiver.receiver;
		// As a workaround for the legacy implementation, we wait a while before starting the reconnector
		// If we start it immediately, chances are rtcRtpReceiver might have not started receiving bytes yet,
		// 	and might thus trigger an infinite reconnection of the mqtt client
		const timeoutId = setTimeout(() => {
			deprecatedMqttReconnector.start(rtcRtpReceiver, mediaTrack);
		}, 10000);
		return () => {
			clearTimeout(timeoutId);
			deprecatedMqttReconnector.stop();
		};
	}, [deprecatedMqttReconnector, media.primaryMediaStream, media.primaryRTPTransceiver]);
	// ********************************************************************************

	return {
		startPeerConnection,
		endPeerConnection,
		pausePeerConnection: peerConnection.pause,
		unpausePeerConnection: peerConnection.unpause,
		isPeerConnectionPaused,
		togglePrimaryCamera,
		primaryCameraState,
		...media,
	};
};

export default useCallerPeerConnection;

function usePCMediaStreams(pc: PeerConnectionWithSignalling) {
	const [primaryMedia, setPrimaryMedia] = useState<{
		stream: MediaStream;
		transceiver: RTCRtpTransceiver | null;
	}>({ stream: pc.primaryMediaStream, transceiver: null });

	const [navMedia, setNavMedia] = useState<{
		stream: MediaStream;
		transceiver: RTCRtpTransceiver | null;
	}>({ stream: new MediaStream(), transceiver: null });

	useEffect(() => {
		pc.onPrimaryMediaStreamChanged = (stream, transceiver) => {
			setPrimaryMedia({ stream, transceiver });
		};
		pc.onNavMediaStreamChanged = (stream, transceiver) => {
			setNavMedia({ stream, transceiver });
		};
	}, [pc]);

	return {
		primaryMediaStream: primaryMedia.stream,
		primaryRTPTransceiver: primaryMedia.transceiver,

		navMediaStream: navMedia.stream,
		navRTPTransceiver: navMedia.transceiver,
	};
}
