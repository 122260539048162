import { defineMessages } from 'react-intl';

export default defineMessages({
	title: {
		id: 'AddGuestReservation.title',
		defaultMessage: 'Reservation',
		description: 'Reservation',
	},
	cancel: {
		id: 'AddGuestReservation.cancel',
		defaultMessage: 'Cancel',
		description: 'Cancel',
	},
});
