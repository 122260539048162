import { defineMessages } from 'react-intl';

export default defineMessages({
	noAvailable: {
		id: 'UserGroups.createModal.noAvailableUsers',
		defaultMessage: 'No available selected',
		description: 'No available selected',
	},
	selectedUsers: {
		id: 'UserGroups.createModal.selectedUsers',
		defaultMessage: 'Selected users',
		description: 'Selected users',
	},
	noUserGroups: {
		id: 'UserGroups.noUserGroups',
		defaultMessage: 'No User Groups here!',
		description: 'No User Groups here!',
	},
	noUserGroupsHint: {
		id: 'UserGroups.noGroupAdded',
		defaultMessage: "You haven't added any User Groups yet!",
		description: "You haven't added any User Groups yet!",
	},
	addBtnTitle: {
		id: 'UserGroups.addUsers',
		defaultMessage: 'Add users',
		description: 'Add users',
	},
});
