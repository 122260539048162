import React, { FC } from 'react';
import { IonIcon } from '@ionic/react';
import { checkmarkCircle } from 'ionicons/icons';

import { FormattedMessage } from 'react-intl';
import Messages from './PasswordRequirements.messages';
import classes from './PasswordRequirements.module.css';
import classNames from 'classnames';

import {
	checkLength,
	checkRobotPasswordLength,
	checkOneLowerCase,
	checkOneNumeric,
	checkOneUpperCase,
} from '../../utils/validator';

interface PasswordRequirementsProps {
	password: string;
	customContainer?: any;
	checkMarkContainer?: any;
}

interface RobotPasswordRequirementsProps {
	password: string;
	customContainer?: any;
	checkMarkContainer?: any;
}

const PasswordRequirements: FC<PasswordRequirementsProps> = ({
	password,
	customContainer,
	checkMarkContainer,
}) => {
	const validLength = checkLength(password);
	const hasLowerCase = checkOneLowerCase(password);
	const hasUpperCase = checkOneUpperCase(password);
	const hasNumeric = checkOneNumeric(password);

	return !validLength || !hasLowerCase || !hasUpperCase || !hasNumeric ? (
		<div className={classNames(classes.container, customContainer)}>
			<ul className={classes.groupNameItem}>
				<li key="length" className={validLength ? classes.valid : classes.invalid}>
					<FormattedMessage {...Messages.passwordLength} />
					<br />
				</li>
				<li key="lowercase" className={hasLowerCase ? classes.valid : classes.invalid}>
					<FormattedMessage {...Messages.passwordLowerCase} />
					<br />
				</li>
				<li key="uppercase" className={hasUpperCase ? classes.valid : classes.invalid}>
					<FormattedMessage {...Messages.passwordUpperCase} />
					<br />
				</li>
				<li key="numeric" className={hasNumeric ? classes.valid : classes.invalid}>
					<FormattedMessage {...Messages.passwordNumeric} />
					<br />
				</li>
			</ul>
		</div>
	) : (
		<div className={classNames(classes.iconContainer, checkMarkContainer)}>
			<IonIcon size="small" color="primary" icon={checkmarkCircle} />
		</div>
	);
};

export const RobotPasswordRequirements: FC<RobotPasswordRequirementsProps> = ({
	password,
	customContainer,
	checkMarkContainer,
}) => {
	const validLength = checkRobotPasswordLength(password);

	return !validLength ? (
		<div className={classNames(classes.container, customContainer)}>
			<ul className={classes.groupNameItem}>
				<li key="robotPasswordLength" className={validLength ? classes.valid : classes.invalid}>
					<FormattedMessage {...Messages.robotPasswordLength} />
					<br />
				</li>
			</ul>
		</div>
	) : (
		<div className={classNames(classes.iconContainer, checkMarkContainer)}>
			<IonIcon size="small" color="primary" icon={checkmarkCircle} />
		</div>
	);
};

export default PasswordRequirements;