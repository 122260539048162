import React from 'react';

export const OnHoldIcon = () => {
	return (
		<svg
			width="52"
			height="58"
			viewBox="0 0 52 58"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M26.0109 9.11646C14.9971 9.11646 6.04395 18.1793 6.04395 29.3895C6.04395 40.5996 14.9971 49.6625 26.0109 49.6625C37.0248 49.6625 45.9779 40.5996 45.9779 29.3895C45.9779 18.1793 37.0248 9.11646 26.0109 9.11646ZM4.04395 29.3895C4.04395 17.1021 13.8653 7.11646 26.0109 7.11646C38.1566 7.11646 47.9779 17.1021 47.9779 29.3895C47.9779 41.6768 38.1566 51.6625 26.0109 51.6625C13.8653 51.6625 4.04395 41.6768 4.04395 29.3895Z"
				fill="#AAAAAA"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M37.1468 0.820068L39.2325 1.74884L37.26 6.17854C45.8938 10.3637 51.847 19.2119 51.847 29.4505C51.847 43.7254 40.2749 55.2975 26 55.2975C22.4939 55.2975 19.1509 54.5994 16.1024 53.3347L14.1852 57.6402L12.0994 56.7114L14.0341 52.3668C5.78336 48.0498 0.152954 39.4077 0.152954 29.4505C0.152954 15.1756 11.725 3.6035 26 3.6035C29.2266 3.6035 32.3151 4.19474 35.1631 5.27479L37.1468 0.820068ZM26 5.6035C12.8296 5.6035 2.15295 16.2802 2.15295 29.4505C2.15295 42.6208 12.8296 53.2975 26 53.2975C39.1703 53.2975 49.847 42.6208 49.847 29.4505C49.847 16.2802 39.1703 5.6035 26 5.6035Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M21.653 38.0704H23.903C24.1019 38.0704 24.2926 37.9914 24.4333 37.8508C24.5739 37.7101 24.653 37.5193 24.653 37.3204V22.3204C24.653 22.1215 24.5739 21.9308 24.4333 21.7901C24.2926 21.6495 24.1019 21.5704 23.903 21.5704H21.653C21.454 21.5704 21.2633 21.6495 21.1226 21.7901C20.982 21.9308 20.903 22.1215 20.903 22.3204V37.3204C20.903 37.5193 20.982 37.7101 21.1226 37.8508C21.2633 37.9914 21.454 38.0704 21.653 38.0704ZM28.403 38.0704H30.653C30.8519 38.0704 31.0426 37.9914 31.1833 37.8508C31.3239 37.7101 31.403 37.5193 31.403 37.3204V22.3204C31.403 22.1215 31.3239 21.9308 31.1833 21.7901C31.0426 21.6495 30.8519 21.5704 30.653 21.5704H28.403C28.204 21.5704 28.0133 21.6495 27.8726 21.7901C27.732 21.9308 27.653 22.1215 27.653 22.3204V37.3204C27.653 37.5193 27.732 37.7101 27.8726 37.8508C28.0133 37.9914 28.204 38.0704 28.403 38.0704Z"
				fill="#AAAAAA"
			/>
		</svg>
	);
};
