import React, { FC, useState } from 'react';
import { IonGrid, IonRow, IonCol, IonItem, IonCheckbox, IonLabel } from '@ionic/react';

import { injectIntl, FormattedMessage } from 'react-intl';
import isAuthenticated from '../Authentication/Authenticated';

import classes from './ProfileSettings.module.css';
import Messages from './ProfileSettings.messages';
import { useTypedSelector } from '../../reducers';
interface NotificationSettingsSubmenuProps {
	goTo?: () => void;
}

const notifyMeSettings = [
	{
		value: false,
		message: Messages.notificationItems.notifyWhen,
	},
	{
		value: false,
		message: Messages.notificationItems.notifyWhen,
	},
];
const settings = [
	{
		value: false,
		message: Messages.notificationItems.resetPassword,
	},
	{
		value: false,
		message: Messages.notificationItems.addedToRobotGroup,
	},
	{
		value: false,
		message: Messages.notificationItems.addedToUserGroup,
	},
	{
		value: false,
		message: Messages.notificationItems.removedUserGroup,
	},
	{
		value: false,
		message: Messages.notificationItems.addedToOrg,
	},
	{
		value: false,
		message: Messages.notificationItems.removedOrg,
	},
	{
		value: false,
		message: Messages.notificationItems.newUpgrades,
	},

	{
		value: false,
		message: Messages.notificationItems.usersAdmins,
	},
	{
		value: false,
		message: Messages.notificationItems.edited,
	},
	{
		value: false,
		message: Messages.notificationItems.robotAddedRemoved,
	},
	{
		value: false,
		message: Messages.notificationItems.robotRestShut,
	},
	{
		value: false,
		message: Messages.notificationItems.robotSettings,
	},
	{
		value: false,
		message: Messages.notificationItems.robotOutside,
	},
];

const NotificationSettingsSubmenu: FC<NotificationSettingsSubmenuProps> = (props: any) => {
	const account = useTypedSelector(state => state.accountState.user);
	console.log(account);
	let leftSettings = settings.slice(1, settings.length / 2);
	let rightSettings = settings.slice(settings.length / 2, settings.length);

	const [collumCheckedHanddle, setCollumCheckedHanddle] = useState<any>([
		notifyMeSettings[0].value,
		notifyMeSettings[1].value,
	]);

	const convertHtmlTag = (value: any) => {
		return {
			__html: value,
		};
	};

	const checkWholeCollum = (indexOfCollum: any, booleanValue: any) => {
		switch (indexOfCollum) {
			//left collum
			case 0:
				if (notifyMeSettings[indexOfCollum].value === false)
					notifyMeSettings[indexOfCollum].value = true;
				else notifyMeSettings[indexOfCollum].value = false;

				for (let leftItem of leftSettings) {
					leftItem.value = notifyMeSettings[indexOfCollum].value;
				}

				setCollumCheckedHanddle([notifyMeSettings[0].value, notifyMeSettings[1].value]);

				break;
			//right collum
			case 1:
				if (notifyMeSettings[indexOfCollum].value === false)
					notifyMeSettings[indexOfCollum].value = true;
				else notifyMeSettings[indexOfCollum].value = false;

				for (let rightItem of rightSettings) {
					rightItem.value = notifyMeSettings[indexOfCollum].value;
				}

				setCollumCheckedHanddle([notifyMeSettings[0].value, notifyMeSettings[1].value]);
				break;
		}
	};

	let renderNotifications = (item: any, index: number, checkHanddle: boolean) => {
		return (
			<IonItem className={classes.chkContainer} lines="none" key={index}>
				<IonCheckbox checked={checkHanddle} name={'isChecked' + index} />

				<div
					className={classes.innerLabelCheckbox}
					dangerouslySetInnerHTML={convertHtmlTag(item.message.defaultMessage)}
				/>
			</IonItem>
		);
	};

	return (
		<IonGrid className={classes.notificationsGrid}>
			<IonRow className={classes.notifyWhenRow}>
				{notifyMeSettings.map((item: any, index: number) => {
					return (
						<IonCol key={'notifyMe' + index}>
							<IonItem className={classes.chkContainer} lines="none">
								<IonCheckbox
									checked={item.value}
									onClick={() => {
										checkWholeCollum(index, notifyMeSettings[index]?.value);
									}}
								/>
								<IonLabel className={classes.chkContainerPrinciple}>
									<FormattedMessage {...item.message} />
								</IonLabel>
							</IonItem>
						</IonCol>
					);
				})}
			</IonRow>
			<IonRow>
				<IonCol>
					{' '}
					{leftSettings.map((item: any, index: number) =>
						renderNotifications(item, index, collumCheckedHanddle[0])
					)}
				</IonCol>

				{account?.roles ? (
					<IonCol>
						{rightSettings.map((item: any, index: number) =>
							renderNotifications(item, index, collumCheckedHanddle[1])
						)}
					</IonCol>
				) : null}
			</IonRow>
		</IonGrid>
	);
};

export default injectIntl(
	isAuthenticated(NotificationSettingsSubmenu, 'NotificationSettingsSubmenu')
);
