import React, { FC, useState, useCallback, useEffect } from 'react';
import isAuthenticated from '../../components/Authentication/Authenticated';
import MainContent from '../../components/MainContent/MainContent';
import classes from './Settings.module.css';
import classNames from 'classnames';
import Tabs from '../../components/Tabs/Tabs';
import Messages from './Settings.messages';
import { laptop, person } from 'ionicons/icons';
import { IonLabel, IonList, IonItem, IonGrid, IonRow, IonCol } from '@ionic/react';
import { FormattedMessage } from 'react-intl';
import { useTypedSelector } from '../../reducers';
import ProfileSettings from '../../components/ProfileSettings/ProfileSettings';
import PilotAppSettings from '../../components/PilotAppSettings/PilotAppSettings';

const SettingsPage: FC = () => {
	const user = useTypedSelector(state => state.accountState.user);

	const [profile, setProfile] = useState<any>();
	const [currentTab, setCurrentTab] = useState<number>(0);
	const isSso = useTypedSelector(state => state.accountState.user.isSso);
	const tabsData = [
		{
			title: Messages.pilotApp,
			path: 'pilotApp',
			icon: laptop,
			subMenus: [{ message: Messages.audioVideo, path: 'audio/video' }],
		},
		{
			title: Messages.account,
			path: 'profile',
			icon: person,
			subMenus: [
				{ message: Messages.profile, path: 'profile' },
				...(!isSso ? [{ message: Messages.changePassword, path: 'changePassword' }] : []),
				{ message: Messages.agreements, path: 'agreements' },
			],
		},
	];
	const [currentSubMenu, setCurrentSubMenu] = useState<string | undefined>(
		tabsData[0].subMenus.length > 0 ? tabsData[0].subMenus[0].path : tabsData[0].path
	);

	const onTabChange = (selectedTab: any) => {
		let indexOfSelectedTab = tabsData.findIndex((tab: any) => tab.path === selectedTab);

		setCurrentTab(0);
		setTimeout(() => {
			setCurrentTab(indexOfSelectedTab);

			if (
				tabsData[indexOfSelectedTab]?.subMenus &&
				tabsData[indexOfSelectedTab]?.subMenus.length >= 1
			) {
				let firstSubmenu: any = tabsData[indexOfSelectedTab]?.subMenus[0]?.path;

				if (firstSubmenu) setCurrentSubMenu(firstSubmenu);
			}
		}, 10);
	};

	const renderTabContent = useCallback(() => {
		if (!profile) setProfile(user);
		switch (tabsData[currentTab].path) {
			case 'profile':
				return (
					<ProfileSettings
						currentTab={tabsData[currentTab]}
						tabsData={tabsData}
						currentSubMenu={currentSubMenu}
						onSubMenuChange={(name: string) => setCurrentSubMenu(name)}
					/>
				);
			case 'pilotApp':
				return (
					<PilotAppSettings
						currentTab={tabsData[currentTab]}
						tabsData={tabsData}
						currentSubMenu={currentSubMenu}
						onSubMenuChange={(name: string) => setCurrentSubMenu(name)}
					/>
				);
			default:
				return null;
		}
	}, [currentSubMenu, currentTab, profile, tabsData, user]);

	return (
		<MainContent
			title="Settings"
			renderContent={history => {
				return (
					<div className={classes.main}>
						<Tabs
							data={tabsData}
							currentTab={tabsData[currentTab].path}
							onClick={onTabChange}
							content={
								<IonGrid force-overscroll className={classes.tabContentGrid}>
									<IonRow>
										<IonGrid className={classes.contentGrid}>
											<IonRow>
												<IonCol className={classes.leftSide}>
													<IonList
														className={classes.list}
														id="container"
													>
														{tabsData[currentTab].subMenus.map(
															(item: any, index: number) => {
																return (
																	<IonItem
																		id={'row-' + item.path}
																		key={index}
																		lines="none"
																		className={
																			currentSubMenu ===
																			item.path
																				? classNames(
																						classes.selectedItem,
																						'selected'
																				  )
																				: ''
																		}
																		onClick={() => {
																			setCurrentSubMenu(
																				item.path
																			);
																		}}
																	>
																		<IonLabel>
																			<FormattedMessage
																				{...item.message}
																			/>
																		</IonLabel>
																	</IonItem>
																);
															}
														)}
													</IonList>
												</IonCol>
												<IonCol className={classes.rightSide}>
													{renderTabContent()}
													{tabsData[currentTab].subMenus.length < 10 && (
														<div
															className={classes.triangle}
															id="arrow"
														/>
													)}
												</IonCol>
											</IonRow>
										</IonGrid>
									</IonRow>

									<IonRow>
										<IonCol className={classes.leftCol} />
									</IonRow>
								</IonGrid>
							}
							tabBtnClass={classes.setupTabBtn}
						/>
					</div>
				);
			}}
		/>
	);
};

export default isAuthenticated(SettingsPage, 'SettingsPage');
