import React, { FC, ReactNode } from 'react';
import classes from './Checkbox.module.css';

interface CheckboxProps {
	label?: string | ReactNode;
	onChange: any;
	checked?: boolean;
	disabled?: boolean;
	dataCy?: string;
	indeterminate?: boolean;
}

const Checkbox: FC<CheckboxProps> = ({
	label,
	onChange,
	checked,
	dataCy,
	indeterminate,
	disabled,
}) => {
	return (
		<label className={classes.container}>
			{label ? label : null}
			<input
				type="checkbox"
				disabled={disabled}
				onClick={onChange}
				checked={checked}
				data-indeterminate={indeterminate}
			/>
			<span className={classes.checkmark} data-cy={dataCy} />
		</label>
	);
};

export default Checkbox;
