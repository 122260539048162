import { defineMessages } from 'react-intl';

export default defineMessages({
	title: {
		id: 'RobotsPage.transfer',
		defaultMessage: 'Transfer Robot',
		description: 'Transfer Robot',
	},
	cancel: {
		id: 'RobotsPage.cancel',
		defaultMessage: 'Cancel',
		description: 'Cancel',
	},
	save: {
		id: 'RobotsPage.save',
		defaultMessage: 'Add Robots',
		description: 'Add Robots',
	},
	from: {
		id: 'RobotsPage.from',
		defaultMessage: 'From :',
		description: 'From :',
	},
	toOrganization: {
		id: 'RobotsPage.toOrganization',
		defaultMessage: 'To Organization',
		description: 'To Organization',
	},
	transferWarning: {
		id: 'RobotsPage.transferWarning',
		defaultMessage: 'The transferred robot(s) will be unassigned from their Robot Groups and users in this organization will lose access to them.',
		description: 'The transferred robot(s) will be unassigned from their Robot Groups and users in this organization will lose access to them.',
	}
});
