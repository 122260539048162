import {
	INITIALIZE_USER_ROLES,
	INITIALIZE_USER_GROUP_ROLES,
	SET_ACTIVE_ROLE,
	SET_ADMIN_ROLE,
} from '../actions/types';

let role = {
	organizationType: '',
	home: {
		menu: false,
	},
	dashboard: {
		menu: false,
	},
	users: {
		menu: false,
		edit: false,
		add: false,
		delete: false,
		view: false,
	},
	userGroups: {
		menu: false,
		edit: false,
		add: false,
		delete: false,
		view: false,
	},
	organization: {
		menu: false,
		edit: false,
		add: false,
		delete: false,
		view: false,
	},
	bor: {
		menu: false,
		edit: false,
		add: false,
		delete: false,
		view: false,
	},
	spinout: {
		menu: false,
		edit: false,
		add: false,
		delete: false,
		view: false,
	},
	reseller: {
		menu: false,
		edit: false,
		add: false,
		delete: false,
		view: false,
	},
	customer: {
		menu: false,
		edit: false,
		add: false,
		delete: false,
		view: false,
	},
	robots: {
		menu: false,
		edit: false,
		add: false,
		delete: false,
	},
	robotGroups: {
		menu: false,
		edit: false,
		add: false,
		delete: false,
	},
	customers: {
		menu: false,
	},
	help: {
		menu: false,
	},
	settings: {
		menu: false,
	},
};

const initialState = {
	activeRole: role,
	userRole: {},
	userGroupRole: {},
};

function formatRoles(queriedRoles, blankRole) {
	let roles = blankRole;
	let queriedRolesString = queriedRoles.enabledFeatures[0];

	let ot = new RegExp(`organization_type.[a-zA-Z*]*`, 'gm');
	let ota = queriedRolesString.match(ot);
	roles.organizationType = ota[0].split('.')[1];

	for (let page in roles) {
		let re = new RegExp(page + `.[a-zA-Z*]*`, 'gm');
		let queriedRoles = queriedRolesString.match(re);

		if (queriedRoles != null) {
			for (let queriedRole of queriedRoles) {
				let action = queriedRole.split('.')[1];

				if (action === '*') {
					for (let roleActions in roles[page]) {
						roles[page][roleActions] = true;
					}
				} else {
					if (roles[page][action] != null) {
						roles[page][action] = true;
					}
				}
			}
		}
	}

	return roles;
}

export default function getUsersReducer(state = initialState, action, blankRole = role) {
	let roles = {};
	switch (action.type) {
		case INITIALIZE_USER_ROLES:
			roles = formatRoles(action.payload[0], blankRole);
			return {
				...state,
				userRole: roles || {},
			};
		case INITIALIZE_USER_GROUP_ROLES:
			return {
				...state,
				userGroupRole: action.payload || {},
			};
		case SET_ACTIVE_ROLE:
			let activeRole = state.userRole;

			if (Object.keys(state.userGroupRole).length > 0) {
				for (let page in activeRole) {
					for (let action in activeRole[page]) {
						if (
							activeRole[page][action] === false &&
							state.userGroupRole[page][action] === true
						) {
							activeRole[page][action] = true;
						}
					}
				}
			}
			return {
				...state,
				activeRole,
			};
		case SET_ADMIN_ROLE:
			let adminRole = blankRole;
			for (let page in adminRole) {
				for (let action in adminRole[page]) {
					adminRole[page][action] = true;
				}
			}

			return {
				...state,
				activeRole: adminRole,
			};
		default:
			// ALWAYS have a default case in a reducer
			return state;
	}
}
