import { SessionInfo } from '../useSessionInitiator';
import {
	ICEPayload,
	ISignalingClient,
	SDPPayload,
	SIGNALING_CLIENT_KEEPALIVE_INTERVAL,
} from './types';
import { io, Socket } from 'socket.io-client';

/** Events that may be sent and/or received by the socketio client */
enum SignalingEvent {
	Ready = 'ready',
	Keepalive = 'keepalive',
	ICECandidate = 'ice-candidate',
	ICERestartRequest = 'ice-restart-requested',
	SDP = 'sdp',
	Hangup = 'hangup',
	/** Received when the remote peer is now ready to retry the session */
	AvailableForRetry = 'available-for-retry',
}

type HangupEventPayload = { reason: string; willRetry: boolean };

/** A signaling client with socketIO as the mode of transport */
export default class SocketIOSignalingClient implements ISignalingClient {
	private isClosed: boolean = false;

	private incomingKeepaliveTimeoutId: ReturnType<typeof setTimeout> | null = null;
	private outgoingKeepaliveLoopId: ReturnType<typeof setInterval> | null = null;

	onRemoteSDP: ((data: SDPPayload) => void) | null = null;
	onRemoteICECandidate: ((payload: ICEPayload) => void) | null = null;
	onRemoteHangUp: (() => void) | null = null;
	onKeepaliveTimeout: (() => void) | null = null;
	onReconnect: (() => void) | null;
	onRemoteWillRetry: (() => void) | null;
	onRemoteReadyToRetry: (() => void) | null;
	sendReadyForRetry: () => Promise<void>;

	private socketIOClient: Socket;

	constructor(
		public readonly sessionInfo: Omit<SessionInfo, 'signaling'> & {
			signaling: NonNullable<SessionInfo['signaling']>;
		}
	) {
		this.socketIOClient = io(sessionInfo.signaling.url, {
			auth: { token: { accessToken: sessionInfo.signaling.token } },
		})
			.onAny(this.onSocketIOEvent)
			.once('connect', () => {
				console.log('Connected to signaling server');
			})
			.on('disconnect', () => {
				console.log('Disconnected from signaling server');
			})
			.on('connect_error', err => {
				console.error('Error connecting to the signaling server', err);
			});
	}

	private emitSocketIOEvent(
		event: SignalingEvent,
		data: Record<string, unknown> & { to?: never } = {},
		isVolatile: boolean = false
	) {
		if (this.isClosed) {
			console.error('Will not emit event, signaling client is closed', { event });
			return;
		}

		const sender = isVolatile ? this.socketIOClient.volatile : this.socketIOClient;
		sender.emit(event, data);
	}

	private onSocketIOEvent = (event: SignalingEvent, data: Record<string, unknown>) => {
		if (this.isClosed) {
			console.error('Will not handle incoming event, signaling client is closed', { event });
			return;
		}

		switch (event) {
			case SignalingEvent.SDP:
				return this.onRemoteSDP?.(data as SDPPayload);
			case SignalingEvent.ICECandidate:
				return this.onRemoteICECandidate?.(data as ICEPayload);
			case SignalingEvent.Hangup:
				return this._onRemoteHangup?.(data as HangupEventPayload);
			case SignalingEvent.Keepalive:
				return this.resetIncomingKeepaliveTimeout();
			case SignalingEvent.AvailableForRetry:
				return this.onRemoteReadyToRetry?.();
			default:
				console.warn('Unexpected socketIO event', event);
				return;
		}
	};

	private _onRemoteHangup = (data: HangupEventPayload) => {
		const { reason, willRetry } = data;
		console.log('Remote peer hung up', { reason, willRetry });
		if (willRetry) {
			this.onRemoteWillRetry?.();
		} else {
			this.onRemoteHangUp?.();
		}
	};

	start = async () => {
		if (this.isClosed) {
			console.error('Cannot call ready(), signaling client is closed');
			return;
		}

		this.socketIOClient.connect(); // this is async, but that's okay
		this.emitSocketIOEvent(SignalingEvent.Ready); // the event will be sent when the socket is connected
		this.startOutgoingKeepaliveLoop();

		// We will wait for the remote peer to send us the first keepalive message,
		//  before we start counting down the keepalive timeout.
		// This is to ensure that the remote peer supports keepalive.
		// this.resetIncomingKeepaliveTimeout();
	};

	sendSDPToPeer = async (data: SDPPayload) => this.emitSocketIOEvent(SignalingEvent.SDP, data);

	sendICECandidateToPeer = async (data: ICEPayload) =>
		this.emitSocketIOEvent(SignalingEvent.ICECandidate, data);

	sendICERestartRequest = async () => this.emitSocketIOEvent(SignalingEvent.ICERestartRequest);

	sendHangup = async () => this.emitSocketIOEvent(SignalingEvent.Hangup);

	close = async () => {
		if (this.isClosed) {
			console.error('Cannot call close(), signaling client is closed');
			return;
		}

		this.isClosed = true;
		this.socketIOClient.close();
		this.stopIncomingKeepaliveTimeout();
		this.stopOutgoingKeepaliveLoop();
	};

	private startOutgoingKeepaliveLoop = () => {
		this.stopOutgoingKeepaliveLoop();

		this.outgoingKeepaliveLoopId = setInterval(
			() => this.emitSocketIOEvent(SignalingEvent.Keepalive, {}, true),
			SIGNALING_CLIENT_KEEPALIVE_INTERVAL
		);
	};

	private stopOutgoingKeepaliveLoop = () => {
		if (this.outgoingKeepaliveLoopId !== null) {
			clearInterval(this.outgoingKeepaliveLoopId);
		}
		this.outgoingKeepaliveLoopId = null;
	};

	private resetIncomingKeepaliveTimeout = () => {
		this.stopIncomingKeepaliveTimeout();

		this.incomingKeepaliveTimeoutId = setTimeout(() => {
			this.onKeepaliveTimeout?.();
		}, SIGNALING_CLIENT_KEEPALIVE_INTERVAL);
	};

	private stopIncomingKeepaliveTimeout = () => {
		if (this.incomingKeepaliveTimeoutId !== null) {
			clearTimeout(this.incomingKeepaliveTimeoutId);
		}
		this.incomingKeepaliveTimeoutId = null;
	};
}
