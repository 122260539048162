type typeSsoTypes = {
	[key: string]: string;
};

export const ssoTypes: typeSsoTypes = {
	google: 'Google OAuth2',
	saml: 'SAML',
	oidc: 'OpenID (OIDC)',
	azureAdOidc: 'Azure AD - OpenID (OIDC)',
};

export const getSSoTypeString = (ssoType: string, existingSSOType: string = '') => {
	const result = ssoTypes[ssoType];
	return result || (existingSSOType ? 'None - contact support' : 'None');
};
