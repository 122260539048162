import { IDeviceCurrentState } from '../../../../../../utils/statusConverter';
import '../../index.scss';
import React from 'react';

interface Props {
	status: IDeviceCurrentState;
	isGuest: boolean;
}

export const DefaultStatusLabel = ({ status, isGuest }: Props) => {
	switch (status) {
		case 'inSession':
			return <span className="bodyText">{isGuest ? 'My Session' : 'In Session'}</span>;
		case 'available':
			return <span className="bodyText">Available</span>;
		case 'configuring':
			return <span className="bodyText">Configuring</span>;
		case 'onHold':
			return <span className="bodyText">On Hold</span>;
		case 'unavailable':
			return <span className="bodyText">Unavailable</span>;
		case 'updating':
			return <span className="bodyText">Updating</span>;
		case 'offline':
			return (
				<span className="bodyText" style={{ color: '#AAAAAA' }}>
					Offline
				</span>
			);
		default:
			return <span className="bodyText"></span>;
	}
};
