import { useLayoutEffect, useRef, useState } from 'react';
import { throttle } from 'lodash';
import useDrivingImpairment from './useDrivingImpairment';
import { RtpReceiverID } from '../../../types';

function minMax(_number: number, opts: { min: number; max: number }): number {
	return Math.min(opts.max, Math.max(opts.min, _number));
}

/** Period (in milliseconds) for sending navigation commands to remote peer */
const SEND_COMMANDS_PERIOD_MS = 100;
/** If no new commands are received for this consecutive number of times,
 * we stop the loop */
const MAX_NO_NEW_COMMANDS_COUNT = 10;

/**
 * If the _NavController does not receive a new command after this many milliseconds,
 *  it will timeout and stop sending the current command to remote peer.
 */
const INPUT_MAX_DELAY_MS = SEND_COMMANDS_PERIOD_MS * 1.25;

/** Maximum raw speed that may be received from user input (speed slider) */
const MAX_RAW_SPEED = 100;

class _NavController {
	private loopId: ReturnType<typeof setInterval> | undefined;
	private command = {
		linear: 0,
		angular: 0,
		receivedAt: Number.MIN_SAFE_INTEGER,
	};

	private _dataChannel: RTCDataChannel | undefined;
	public onDataChannel(dataChannel: RTCDataChannel) {
		this._dataChannel = dataChannel;
	}

	private enabled = false; // start out as disabled. Must be explicitly enabled!
	private noNewCommandCounter = 0;

	private eventTarget = new EventTarget();

	private _penalty: number = 0;

	constructor(private speed = 0) {
		// prevent invoking datachannel.send() too many times within a short period
		this.loop = throttle(this.loop.bind(this), SEND_COMMANDS_PERIOD_MS / 5, {
			trailing: true,
		});
		this.onSpeedChanged(speed);
	}

	private loop = () => {
		if (!this.enabled) {
			console.debug('abort NavController.loop() -> disabled');
			return;
		}

		if (performance.now() - this.command.receivedAt > INPUT_MAX_DELAY_MS) {
			console.debug(`abort NavController.loop() -> no-new-commands`);
			this.noNewCommandCounter += 1;

			if (this.noNewCommandCounter > MAX_NO_NEW_COMMANDS_COUNT - 1) {
				this.stopLoop();
				this.noNewCommandCounter = 0;
			}

			return;
		}
		this.noNewCommandCounter = 0;

		if (this._dataChannel?.readyState !== 'open') {
			console.debug(
				`abort NavController.loop() -> datachannel.readyState '${this._dataChannel?.readyState}'`
			);
			return;
		}

		const navMessage =
			'NAV ' +
			`${(this.speed * (1 - this._penalty) * this.command.linear).toFixed(6)} ` +
			`${(this.speed * (1 - this._penalty) * this.command.angular).toFixed(6)} ` +
			`${performance.now().toFixed(3)}`;

		// yay! - now we can send the nav-command to the remote peer
		if (this.command.linear === 0 && this.command.angular === 0) {
			this.dispatchNavigationStoppedEvent();
		} else {
			this.dispatchNavigationStartedEvent();
		}

		try {
			this._dataChannel.send(navMessage);
			console.debug('NavController.loop()', navMessage, 'penalty:', this._penalty);
		} catch (error) {
			// TODO: Add some logic to abort after X consecutive send-failures
			console.error('Failed to send NAV command to remote peer');
		}
	};

	/** Idempotent */
	private startLoop = () => {
		if (!this.enabled) return;
		// WARNING: This ensures that we never have multiple `intervals` running at the same time!
		if (this.loopId === undefined) {
			this.loop();
			this.loopId = setInterval(this.loop, SEND_COMMANDS_PERIOD_MS);
		}
	};

	/** Idempotent */
	private stopLoop = () => {
		if (this.loopId !== undefined) {
			clearInterval(this.loopId);
			this.loopId = undefined;
		}
		this.dispatchNavigationStoppedEvent();
	};

	private lastDispatchedNavigationEvent:
		| 'navigation-started'
		| 'navigation-stopped'
		| null = null;

	private dispatchNavigationStartedEvent = () => {
		if (this.lastDispatchedNavigationEvent !== 'navigation-started') {
			this.lastDispatchedNavigationEvent = 'navigation-started';
			console.log('NAV_CONTROLLER event -> navigation-started');
			this.eventTarget.dispatchEvent(new Event('navigation-started'));
		}
	};

	private dispatchNavigationStoppedEvent = () => {
		if (this.lastDispatchedNavigationEvent !== 'navigation-stopped') {
			this.lastDispatchedNavigationEvent = 'navigation-stopped';
			console.log('NAV_CONTROLLER event -> navigation-stopped');
			this.eventTarget.dispatchEvent(new Event('navigation-stopped'));
		}
	};

	public get isNavigationInProgress() {
		// navigation has started if there is a loop sending commands to the remote peer periodically
		return this.loopId !== undefined;
	}

	/**
	 * IMPORTANT: This function is idempotent (and should remain idempotent in future refactoring)
	 * You may call it multiple times in a row, without calling disable() first.
	 */
	public enable = () => {
		console.debug('NavController.enable()');
		this.enabled = true;
	};

	/**
	 * IMPORTANT: This function is idempotent (and should remain idempotent in future refactoring)
	 * You may call it multiple times in a row, without calling enable()
	 */
	public disable = () => {
		if (!this.enabled) return;

		console.debug('NavController.disable()');

		this.stopLoop();
		// emulate STOP command
		this.command = {
			linear: 0,
			angular: 0,
			receivedAt: performance.now(),
		};
		this.loop(); // handle the just-set command immediately

		this.enabled = false;
	};

	public onNavCommand = (command: { linear: number; angular: number }) => {
		if (!this.enabled) {
			console.debug('abort NavController.onNavCommand() -> disabled');
			return;
		}

		const isDifferentCommand =
			this.command.angular !== command.angular || this.command.linear !== command.linear;

		this.command = {
			// we dont ever want the linear/angular components to go beyond -1 and +1; else it will mess up the math
			linear: minMax(command.linear, { min: -1.0, max: +1.0 }),
			angular: minMax(command.angular, { min: -1.0, max: +1.0 }),
			receivedAt: performance.now(),
		};

		if (isDifferentCommand) {
			this.loop(); // handle new command immediately
		}
		this.startLoop(); // start loop if not running already
		// ---> in the next iteration of the loop, this newly set command will be used
	};

	/**
	 * @param rawSpeed Between 0 and `MAX_RAW_SPEED`
	 */
	public onSpeedChanged = (rawSpeed: number) => {
		// A speed change is set immediately, but applied later (if the loop is running).
		// This is regardless of whether the _NavController is enabled or not.
		this.speed = minMax(rawSpeed / MAX_RAW_SPEED, { min: 0, max: 1.0 });
		// Yeah... we won't handle the speed change immediately.
		// But rather, it will be handled on the next iteration of the loop

		if (rawSpeed > MAX_RAW_SPEED) {
			console.warn(
				`Received a speed of ${rawSpeed}, which is beyond expected max: ${MAX_RAW_SPEED}`
			);
		}
	};

	public onPenalty = (penalty: number) => {
		this._penalty = minMax(penalty, { min: 0, max: 1 });
		// Yeah... we won't handle the penalty change immediately.
		// But rather, it will be handled on the next iteration of the loop
	};

	public addEventListener = (
		event: 'navigation-started' | 'navigation-stopped',
		listener: (...args: any[]) => void
	) => {
		this.eventTarget.addEventListener(event, listener);
	};

	public removeEventListener = (
		event: 'navigation-started' | 'navigation-stopped',
		listener: (...args: any[]) => void
	) => {
		this.eventTarget.removeEventListener(event, listener);
	};
}

export type NavController = Omit<
	_NavController,
	// expose only a subset of public methods of _NavController
	'onSpeedChanged' | 'onPenalty' | 'enable' | 'disable' | 'onDataChannel'
>;


export default function useImperativeNavController(props: {
	speed: number | string;
	isPeerConnectionPaused: boolean;
	rtpReceivers: Partial<Record<RtpReceiverID, RTCRtpReceiver>>;
	datachannel?: RTCDataChannel;
	/** Pass in True, if the video can be seen by the user, and there are no overlays, modals etc, obscuring the view */
	isVideoVisible: boolean;
}) {
	const { speed, isPeerConnectionPaused, rtpReceivers, datachannel, isVideoVisible } = props;
	// a ref, because we dont ever change the created instance
	const navController = useRef(new _NavController(Number.parseInt(speed.toString())));

	useLayoutEffect(() => {
		if (datachannel) navController.current.onDataChannel(datachannel);
	}, [datachannel]);

	const [isNavigationInProgress, setIsNavigationInProgress] = useState(
		navController.current.isNavigationInProgress
	);
	useLayoutEffect(() => {
		const controller = navController.current;
		const onNavigationStarted = () => setIsNavigationInProgress(true);
		const onNavigationStopped = () => setIsNavigationInProgress(false);
		controller.addEventListener('navigation-started', onNavigationStarted);
		controller.addEventListener('navigation-stopped', onNavigationStopped);
		return () => {
			controller.removeEventListener('navigation-started', onNavigationStarted);
			controller.removeEventListener('navigation-stopped', onNavigationStopped);
		};
	}, []);

	// track peer connection's paused state, and enable/disable navController
	useLayoutEffect(() => {
		const shouldDisableNavigation = isPeerConnectionPaused || !isVideoVisible;

		if (shouldDisableNavigation) {
			navController.current.disable();
		} else {
			navController.current.enable();
		}
	}, [isPeerConnectionPaused, isVideoVisible]);

	// Deactivate the navController when the implementing component
	// 	is about to be unmounted.
	useLayoutEffect(() => {
		console.debug(`useImperativeNavController -> mounted`);
		const controller = navController.current;
		return () => {
			console.debug(`useImperativeNavController -> unmounted`);
			controller.disable();
		};
	}, []);

	// track base speed at which the navController will operate
	useLayoutEffect(() => {
		navController.current.onSpeedChanged(Number.parseInt(speed.toString()));
	}, [speed]);

	const { penalty, onRtpReceiver } = useDrivingImpairment(15, 10);
	useLayoutEffect(() => navController.current.onPenalty(penalty), [penalty]);

	useLayoutEffect(() => {
		Object.entries(rtpReceivers).forEach(([key, receiver]) => {
			if (receiver) {
				onRtpReceiver(key as RtpReceiverID, receiver);
			}
		});
	}, [onRtpReceiver, rtpReceivers]);

	return {
		navController: navController.current as NavController,
		isNavigationInProgress,
		/** True if the driving-impaired indicator should be shown */
		isDrivingImpaired: isNavigationInProgress && penalty > 0.4,
	};
}
