export const openFullscreen = () => {
	const docElmWithBrowsersFullScreenFunctions = document.documentElement as HTMLElement & {
		mozRequestFullScreen(): Promise<void>;
		webkitRequestFullscreen(): Promise<void>;
		msRequestFullscreen(): Promise<void>;
	};
	if (docElmWithBrowsersFullScreenFunctions) {
		let openFullScreenFunction: () => Promise<void>;

		if (docElmWithBrowsersFullScreenFunctions.requestFullscreen) {
			openFullScreenFunction = docElmWithBrowsersFullScreenFunctions.requestFullscreen;
		} else if (docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen) {
			/* Firefox */
			openFullScreenFunction = docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen;
		} else if (docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen) {
			/* Chrome, Safari and Opera */
			openFullScreenFunction = docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen;
		} else if (docElmWithBrowsersFullScreenFunctions.msRequestFullscreen) {
			/* IE/Edge */
			openFullScreenFunction = docElmWithBrowsersFullScreenFunctions.msRequestFullscreen;
		} else {
			return;
		}

		openFullScreenFunction().catch(error => console.warn('Unable to open fullscreen', error));
	}
};

export const closeFullScreen = () => {
	const docWithBrowsersExitFunctions = document as Document & {
		mozCancelFullScreen(): Promise<void>;
		webkitExitFullscreen(): Promise<void>;
		msExitFullscreen(): Promise<void>;
	};
	let closeFullScreenFunction: () => Promise<void>;

	if (docWithBrowsersExitFunctions.fullscreenElement) {
		if (docWithBrowsersExitFunctions.exitFullscreen) {
			closeFullScreenFunction = docWithBrowsersExitFunctions.exitFullscreen;
		} else if (docWithBrowsersExitFunctions.mozCancelFullScreen) {
			/* Firefox */
			closeFullScreenFunction = docWithBrowsersExitFunctions.mozCancelFullScreen;
		} else if (docWithBrowsersExitFunctions.webkitExitFullscreen) {
			/* Chrome, Safari and Opera */
			closeFullScreenFunction = docWithBrowsersExitFunctions.webkitExitFullscreen;
		} else if (docWithBrowsersExitFunctions.msExitFullscreen) {
			/* IE/Edge */
			closeFullScreenFunction = docWithBrowsersExitFunctions.msExitFullscreen;
		} else {
			return;
		}

		closeFullScreenFunction().catch(error => console.warn('Unable to close fullscreen', error));
	}
};
