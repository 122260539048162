import React from 'react';
import { EmptyIcon } from './EmptyIcon';
import './index.scss';

export const EmptyCardView = () => {
	return (
		<div className="emptyCardWrapper">
			<EmptyIcon />
			<span className="emptyCardText">You do not have access to any robots</span>
		</div>
	);
};
