import React, { FC, Fragment, useState, useEffect, useCallback } from 'react';
import { IonLabel, IonIcon, IonList, IonListHeader, IonAvatar } from '@ionic/react';
import { timeOutline } from 'ionicons/icons';
import InfoCard from '../InfoCard/InfoCard';
import ListGrid from '../ListGrid/ListGrid';
import EditImageModal from '../EditImageModal/EditImageModal';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';
import isAuthenticated from '../Authentication/Authenticated';
import { publish } from '../../actions/publish';
import { sortedCollection } from '../../selectors';

import classes from './RobotGroups.module.css';
import classNames from 'classnames';

import { injectIntl, FormattedMessage } from 'react-intl';
import Messages from './RobotGroups.messages';
import { useTypedSelector } from '../../reducers';
import { b64EncodeUnicode } from '../../utils/encoding';
import RobotMoveGroup from '../RobotMoveGroup/RobotMoveGroup';
import { styled } from '@material-ui/styles';
import { Divider, MenuItem } from '@material-ui/core';
import { Device } from '../../types/types';
import robotGroupAvatar from '../../assets/images/robotGroupAvatar.svg';
import robotOfflineAvatar from '../../assets/images/robotOfflineAvatar.svg';
import robotAvatar from '../../assets/images/robotAvatar.svg';

const robotHeaders = [
	{ title: Messages.robotName, property: 'name' },
	{ title: Messages.location, property: 'location' },
	{ title: Messages.status, property: 'status' },
	{ title: Messages.charge, property: 'charge' },
];

interface RobotGroupEditProps {
	group: any;
	onClose: () => void;
	onEditOpen: () => void;
}
const StyledMenuItem = styled(MenuItem)(() => ({
	paddingLeft: 10,
	borderRadius: 5,
}));
const RobotGroupEdit: FC<RobotGroupEditProps> = (props: any) => {
	const devices = useTypedSelector(state => state.deviceState);
	const { deviceGroups, group, onClose, history } = props;
	const { devicesByOrganizationId, sortParams } = devices;
	const { deviceGroupsByOrganizationId } = deviceGroups;
	const username = useTypedSelector(state => state.accountState.user.username);
	const encodedUser = b64EncodeUnicode(username);

	const [editPicOpen, setEditPicOpen] = useState(false);
	const [selectedGroup, setSelectedGroup] = useState(group);
	const [groupMembers, setGroupMembers] = useState([]);
	const [pageSize] = useState(11);

	const [showTransfer, setShowTransfer] = useState(false);
	const [selectedRobot, setSelectedRobot] = useState();

	useEffect(() => {
		if (!group) return;
		setSelectedGroup({ ...deviceGroupsByOrganizationId[group.orgId][group.deviceGroupId] });
	}, [group, deviceGroupsByOrganizationId]);

	useEffect(() => {
		if (!selectedGroup || !selectedGroup.devicesIds || !selectedGroup.orgId) return;
		const devicesByOrg = devicesByOrganizationId[selectedGroup.orgId]
			? Object.values(devicesByOrganizationId[selectedGroup.orgId])
			: [];

		const members: any = devicesByOrg.filter(
			(u: any) => selectedGroup.devicesIds.findIndex((id: string) => id === u.deviceId) > -1
		);
		setGroupMembers({ ...members });
	}, [selectedGroup, devicesByOrganizationId, selectedGroup?.devicesIds]);

	const unassignRobotFromGroup = (device: Device) => {
		publish(`microservice/${device.orgId}/${encodedUser}/updateDeviceGroupDevices`, {
			requestId: 'updateDeviceGroupDevicesId',
			data: {
				deviceGroupId: group.deviceGroupId,
				remove: [device.deviceId],
			},
		});
	};

	const formatData = useCallback((item: any, property: string) => {
		switch (property) {
			case 'name':
				return (
					<div className={classes.nameContainer}>
						<IonAvatar className={classes.avatar}>
							{item.status == null || !item.online ? (
								<IonIcon
									className={classes.profilePic}
									size="large"
									icon={robotOfflineAvatar}
								/>
							) : item.pictureLink ? (
								<img
									className={classes.profilePic}
									src={item.pictureLink}
									alt="Avatar"
								/>
							) : (
								<IonIcon
									className={classes.profilePic}
									size="large"
									src={robotAvatar}
								/>
							)}
						</IonAvatar>
						<IonLabel
							className={
								item.status == null || !item.online ? classes.offlineColor : ''
							}
						>
							{item.name}
						</IonLabel>
					</div>
				);
			case 'status':
				return (
					<div className={classes.statusContainer}>
						{item.status != null ? (
							item.onCall ? (
								<IonLabel className={classes.statusNameLb} {...Messages.onCall}>
									<FormattedMessage {...Messages.onCall} />
								</IonLabel>
							) : item.online === true ? (
								<IonLabel className={classes.statusNameLb}>
									<FormattedMessage {...Messages.online} />
								</IonLabel>
							) : (
								<IonLabel
									className={classNames(
										classes.statusNameLb,
										classes.offlineColor
									)}
								>
									<FormattedMessage {...Messages.offline} />
								</IonLabel>
							)
						) : (
							<IonLabel
								className={classNames(classes.statusNameLb, classes.offlineColor)}
							>
								<FormattedMessage {...Messages.offline} />
							</IonLabel>
						)}
					</div>
				);
			case 'charge':
				return (
					<div className={classes.chargeContainer}>
						{item.battery != null ? (
							<IonLabel
								className={
									item.status == null || !item.online
										? classes.offlineColor
										: classes.chargeNameLb
								}
							>
								{item.battery.charging === true ? (
									<FormattedMessage {...Messages.charge} />
								) : (
									item.battery.level
								)}
							</IonLabel>
						) : (
							<IonLabel className={classes.chargeNameLb} />
						)}
					</div>
				);
			default:
				return (
					<IonLabel
						className={item.status == null || !item.online ? classes.offlineColor : ''}
					>
						{item[property]}
					</IonLabel>
				);
		}
	}, []);

	const onEdit = async (item: any) => {
		history.push({
			pathname: '/fleetManagement',
			data: { tab: 'robots', orgId: item.orgId, editableRobot: item },
		});
	};

	const onSavePicture = (link: any) => {
		publish(`microservice/${group.orgId}/${encodedUser}/updateDeviceGroupInfo`, {
			data: {
				device_group_id: group.deviceGroupId,
				picture_link: link,
			},
			requestId: 'updateDeviceGroup',
		});
		setEditPicOpen(false);
	};

	return (
		<Fragment>
			<div className={classes.editLeftSide}>
				<InfoCard
					imgUrl={selectedGroup.pictureLink}
					noImg={robotGroupAvatar}
					title={selectedGroup.name}
					subTitle={
						selectedGroup.devicesIds && selectedGroup.devicesIds.length > 0 ? (
							<FormattedMessage
								{...Messages.robotsCount}
								values={{
									count: selectedGroup.devicesIds.length,
								}}
							/>
						) : null
					}
					email={selectedGroup.name}
					onClose={onClose}
					onEdit={props.onEditOpen}
					onEditPic={() => setEditPicOpen(true)}
				/>
				<div className={classes.recentContainer}>
					<IonList lines="none">
						<IonListHeader>
							<IonIcon color="primary" size="small" icon={timeOutline} />
							<IonLabel color="primary" className={classes.recentLb}>
								<FormattedMessage {...Messages.recentActivity} />
							</IonLabel>
						</IonListHeader>
					</IonList>
				</div>
			</div>
			<div className={classes.rightSide}>
				<ListGrid
					headers={robotHeaders}
					data={sortedCollection({
						items: groupMembers,
						sortParams: sortParams,
					})}
					pageSize={pageSize}
					itemTemplate={(item, property) => {
						return formatData(item, property);
					}}
					moreContent={(item: any, onClosePopover: any) => {
						return (
							<>
								<StyledMenuItem onClick={() => onEdit(item)}>
									<FormattedMessage {...Messages.editRobot} />
								</StyledMenuItem>
								<Divider />
								<StyledMenuItem
									onClick={() => {
										setShowTransfer(true);
										setSelectedRobot(item);
										onClosePopover();
									}}
								>
									<FormattedMessage {...Messages.moveRobotTo} />
								</StyledMenuItem>
								{item?.deviceGroupsIds && item?.deviceGroupsIds.length > 0 ? (
									<>
										<Divider />
										<StyledMenuItem
											onClick={() => {
												unassignRobotFromGroup(item);
												onClosePopover();
											}}
										>
											<FormattedMessage
												{...Messages.unassignRobotFromGroup}
											/>
										</StyledMenuItem>
									</>
								) : null}
							</>
						);
					}}
					onRowClick={onEdit}
					sortType="SET_DEVICES_SORT_PARAMS"
					listType="Robots"
				/>
			</div>
			<EditImageModal
				isOpen={editPicOpen}
				title={<FormattedMessage {...Messages.editPicTitle} values={{ item: 'Group' }} />}
				subTitle={selectedGroup.name}
				pictureLink={selectedGroup.pictureLink}
				noImg={robotGroupAvatar}
				onSave={onSavePicture}
				onDismiss={() => setEditPicOpen(false)}
			/>
			{showTransfer && (
				<RobotMoveGroup
					isOpen={selectedRobot && showTransfer}
					onDismiss={() => {
						setShowTransfer(false);
						setSelectedRobot(undefined);
					}}
					robot={selectedRobot}
				/>
			)}
		</Fragment>
	);
};

const mapStateToProps = (state: any) => ({
	client: state.mqttState.client,
	deviceGroups: state.deviceGroupsState,
});

const enhance = compose(connect(mapStateToProps, { setParameter }));

export default injectIntl(isAuthenticated(enhance(RobotGroupEdit), 'RobotGroupEdit'));
