import { defineMessages } from 'react-intl';

export default defineMessages({
	accountSetup: {
		id: 'AccountSetup.accountSetup',
		defaultMessage: 'Profile',
		description: 'Profile',
	},
	profile: {
		id: 'AccountSetup.profile',
		defaultMessage: 'Profile',
		description: 'Profile',
	},
	upload: {
		id: 'AccountManagementPage.upload',
		defaultMessage: 'Upload Picture',
		description: 'Upload Picture',
	},
	web: {
		id: 'AccountManagementPage.web',
		defaultMessage: 'From The Web',
		description: 'From The Web',
	},

	notificationItems: {
		notifyWhen: {
			id: 'notificationItems.notifyWhen',
			defaultMessage: 'Notify me when:',
			description: 'Notify me when:',
		},
		// Group 1
		passwordResetRequired: {
			id: 'notificationItems.passwordResetRequired',
			defaultMessage: 'I need to reset my password',
			description: 'I need to reset my password',
		},
		// Group 2
		userAddedToRobotGroup: {
			id: 'notificationItems.userAddedToRobotGroup',
			defaultMessage: 'I am <b>added</b> to a <b>Robot Group</b>',
			description: 'I am <b>added</b> to a <b>Robot Group</b>',
		},
		userRemovedFromRobotGroup: {
			id: 'notificationItems.userRemovedFromRobotGroup',
			defaultMessage: 'I am <b>added</b> to a <b>Robot Group</b>',
			description: 'I am <b>removed</b> from a <b>Robot Group</b>',
		},
		userAddedToUserGroup: {
			id: 'notificationItems.userAddedToUserGroup',
			defaultMessage: 'I am <b>added</b> to a <b>User Group</b>',
			description: 'I am <b>added</b> to a <b>User Group</b>',
		},
		userRemovedFromUserGroup: {
			id: 'notificationItems.userRemovedFromUserGroup',
			defaultMessage: 'I am <b>removed</b> from a <b>User Group</b>',
			description: 'I am <b>removed</b> from a <b>User Group</b>',
		},

		// Group 3
		userAddedToOrganization: {
			id: 'notificationItems.userAddedToOrganization',
			defaultMessage: 'I am <b>added</b> to a new <b>Organization</b>',
			description: 'I am <b>added</b> to a new <b>Organization</b>',
		},
		userRemovedFromOrganization: {
			id: 'notificationItems.userRemovedFromOrganization',
			defaultMessage: 'I am <b>removed</b> from an <b>Organization</b>',
			description: 'I am <b>removed</b> from an <b>Organization</b>',
		},

		// Group 4
		softwareReleased: {
			id: 'notificationItems.softwareReleased',
			defaultMessage: 'New software upgrades and fixes released',
			description: 'New software upgrades and fixes released',
		},

		// Group 5
		organizationSettingsChange: {
			id: 'notificationItems.organizationSettingsChange',
			defaultMessage: 'My organization’s settings change',
			description: 'My organization’s settings change',
		},

		// Group 6
		usersAdministratorRoleChanged: {
			id: 'notificationItems.usersAdministratorRoleChanged',
			defaultMessage: 'I become or stop being an administrator',
			description: 'I become or stop being an administrator',
		},
		administratorRolesChangedInOrganization: {
			id: 'notificationItems.administratorRolesChangedInOrganization',
			defaultMessage: 'Users become or stop being administrators',
			description: 'Users become or stop being administrators',
		},
		userRobotGroupsEdited: {
			id: 'notificationItems.userRobotGroupsEdited',
			defaultMessage: 'User and robot groups are edited',
			description: 'User and robot groups are edited',
		},
		robotAddedOrRemovedFromOrganization: {
			id: 'notificationItems.robotAddedOrRemovedFromOrganization',
			defaultMessage: 'Robot is added or removed from an organization',
			description: 'Robot is added or removed from an organization',
		},

		// Group 7
		robotShutDownOrRestarted: {
			id: 'notificationItems.robotShutDownOrRestarted',
			defaultMessage: 'Robot is shut down or restarted',
			description: 'Robot is shut down or restarted',
		},
		robotSettingsChanged: {
			id: 'notificationItems.robotSettingsChanged',
			defaultMessage: 'Robot settings are changed',
			description: 'Robot settings are changed',
		},

		// Group 8
		robotOutsideChargingDock: {
			id: 'notificationItems.robotOutsideChargingDock',
			defaultMessage:
				'Robot is left outside the charging dock<br />for an extended period of time',
			description:
				'Robot is left outside the charging dock<br />for an extended period of time',
		},
	},
	profileSettings: {
		id: 'AccountSetup.profileSettings',
		defaultMessage: 'Profile settings',
		description: 'Profile settings',
	},
	notificationSettings: {
		id: 'AccountSetup.notificationSettings',
		defaultMessage: 'Notification settings',
		description: 'Notification settings',
	},
	phone: {
		id: 'AccountSetup.phone',
		defaultMessage: 'Phone ',
		description: 'Phone',
	},
});
