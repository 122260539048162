import _ from 'lodash';
import React from 'react';
import { RoasterDevicesViewProps } from '../ListView';
import { EmptyCardView } from './EmptyCardView';
import { LaunchCard } from './LaunchCard';

export const CardView = ({
	devicesIds,
	deviceGroupsNamesPerDeviceId,
	orgIdPerDeviceId,
	onClickStartSession,
	publishFavouriteDevice,
	preSessionState,
	isLoading,
}: RoasterDevicesViewProps) => {
	return (
		<div className="rosterCardViewContainer">
			{devicesIds.length > 0 ? (
				devicesIds.map(deviceId => (
					<LaunchCard
						key={deviceId}
						deviceId={deviceId}
						orgId={orgIdPerDeviceId[deviceId]}
						deviceGroupsNames={deviceGroupsNamesPerDeviceId[deviceId]}
						onClickStartSession={onClickStartSession}
						publishFavouriteDevice={publishFavouriteDevice}
						isCalling={
							preSessionState?.robotId === deviceId && preSessionState?.loading
						}
					/>
				))
			) : isLoading ? null : (
				<EmptyCardView />
			)}
		</div>
	);
};
