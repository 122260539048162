import { defineMessages } from 'react-intl';

export default defineMessages({
	edit: {
		id: 'edit',
		defaultMessage: 'Edit',
		description: 'Edit reservation',
	},
	resend: {
		id: 'resend',
		defaultMessage: 'Resend',
		description: 'Resend',
	},
	delete: {
		id: 'delete',
		defaultMessage: 'Delete',
		description: 'Delete',
	},
	deleteTitle: {
		id: 'deleteTitle',
		defaultMessage: 'Delete reservation',
		description: 'Delete reservation',
	},
});
