import React, { useEffect, useState } from 'react';
import { IonLabel, IonList, IonButton, IonItem } from '@ionic/react';
import FormInputListItem from '../../components/FormInputListItem/FormInputListItem';
import LoginTemplate from '../../components/LoginTemplate/LoginTemplate';
import { injectIntl, FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';

import classNames from 'classnames';
import classes from './ForgotPassword.module.css';

import Messages from './ForgotPassword.messages';
import { anonymous } from '../../providers/mqtt';
import { useTypedSelector } from '../../reducers';
import { useLocation } from 'react-router-dom';
import { EmailInputLimit, InputLimit } from '../../utils/validator';

const ForgotPasswordPage: React.FC = (props: any) => {
	const { intl } = props;
	const { control, handleSubmit, errors } = useForm();
	const [responseState, setResponseState] = useState<Record<string, any>>({
		text: '',
		error: false,
	});
	const urlSearch = useLocation().search;
	const urlSearchParams = new URLSearchParams(urlSearch);
	const email = urlSearchParams.get('email');

	const passwordState = useTypedSelector(state => state.setPasswordState);

	const spinoutType = useTypedSelector(state => state.versionState.spinoutType) as string;

	useEffect(() => {
		if (passwordState.type === 'resetPassword') {
			if (passwordState.success === 'success') {
				setResponseState({
					text: intl.formatMessage({
						id: 'ForgotPasswordPage.success',
					}),
					error: false,
				});
			} else
				setResponseState({
					text: intl.formatMessage({
						id: 'ForgotPasswordPage.error',
					}),
					error: true,
				});
		}
	}, [intl, passwordState.success, passwordState.type, props.history]);

	const resetPassword = handleSubmit(data => {
		const { email } = data;
		const domainUrl = window.location.hostname;
		anonymous((client: any) => {
			client.mqttClient.publish(
				`microservice/generatePasswordResetToken`,
				JSON.stringify({
					requestId: 'generatePasswordResetTokenId-' + email,
					data: { username: email, spinoutType, domainUrl },
				})
			);
		});
	});

	const goToLogin = () => {
		props.history.push('/');
	};

	return (
		<LoginTemplate
			hintMessage={
				<span className={classes.forgotSpan}>
					{props.intl.formatMessage({
						id: 'ForgotPasswordPage.forgot',
					})}
				</span>
			}
			mainContent={
				<IonList className={classNames(classes.forgotPswList, 'ion-padding')}>
					<IonItem className={classes.hintContainer} lines="none">
						<IonLabel className={responseState.error ? classes.error : classes.success}>
							{responseState.text}
						</IonLabel>
					</IonItem>
					<IonItem className={`${classes.hintContainer} ion-no-padding`} lines="none">
						<IonLabel>
							<FormattedMessage {...Messages.hint} />
						</IonLabel>
					</IonItem>
					<FormInputListItem
						defaultValue={email}
						type="text"
						name="email"
						control={control}
						rules={{ required: true }}
						errorMsg={errors.email && 'Email is invalid'}
						required
						label={props.intl.formatMessage({
							id: 'ForgotPasswordPage.email',
						})}
						placeholderText={props.intl.formatMessage({
							id: 'ForgotPasswordPage.email',
						})}
						maxLength={EmailInputLimit}
					/>
				</IonList>
			}
			btnContent={
				<div className={classes.btnContainer}>
					<IonButton
						className={classNames('transparent', classes.backBtn)}
						shape="round"
						size="large"
						onClick={() => goToLogin()}
					>
						<FormattedMessage {...Messages.backToLogin} />
					</IonButton>
					<IonButton
						className="round"
						shape="round"
						size="large"
						onClick={() => resetPassword()}
					>
						<FormattedMessage {...Messages.reset} />
					</IonButton>
				</div>
			}
		/>
	);
};

const mapStateToProps = (state: any) => ({
	client: state.mqttState.client,
});

export default injectIntl(connect(mapStateToProps, { setParameter })(ForgotPasswordPage));
