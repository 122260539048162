import { defineMessages } from 'react-intl';

export default defineMessages({
	title: {
		id: 'Menu.RobotsPageTitle',
		defaultMessage: 'Add New Robot',
		description: 'Add New Robot',
	},
	cancel: {
		id: 'RobotsPage.cancel',
		defaultMessage: 'Cancel',
		description: 'Cancel',
	},
	save: {
		id: 'RobotsPage.save',
		defaultMessage: 'Save',
		description: 'Save',
	},
});
