import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import isAuthenticated from '../../components/Authentication/Authenticated';

import { setParameter } from '../../actions/setParam';
import LoginTemplate from '../../components/LoginTemplate/LoginTemplate';
import { IonLabel, IonList, IonButton, IonItem } from '@ionic/react';
import classNames from 'classnames';
import classes from './Login.module.css';
import { useLocation } from 'react-router-dom';

const LoginSSOSandboxResultPage: React.FC = (props: any) => {
	const urlSearch = useLocation().search;

	const [customMessage, setCustomMessage] = useState('');
	const ssoSandboxResult = new URLSearchParams(urlSearch).get('sso_sandbox_validate');
	const ssoLoginError = new URLSearchParams(urlSearch).get('err');
	useEffect(() => {
		if (ssoSandboxResult == 'true') {
			setCustomMessage('SSO login using sandbox succeeded.');
		} else {
			if (ssoLoginError) {
				setCustomMessage(
					props.intl.formatMessage({
						id: `Error.SSOLogin.${ssoLoginError}`,
					})
				);
			} else {
				setCustomMessage('SSO login using sandbox failed.');
			}
		}
	}, [ssoSandboxResult, ssoLoginError]);

	const onCloseWindow = () => {
		window.open('', '_parent', '');
		window.close();
	};

	const handlePageClose = useCallback(() => {
		window.localStorage.setItem(
			'testSandboxResult',
			JSON.stringify({
				status: ssoSandboxResult == 'true' ? 'success' : 'error',
				message: customMessage,
			})
		);
	}, [customMessage]);

	useEffect(() => {
		window.addEventListener('beforeunload', handlePageClose, false);
		document.addEventListener('visibilitychange', event => {
			if (document.visibilityState != 'visible') {
				handlePageClose();
				onCloseWindow();
			}
		});

		return () => {
			window.removeEventListener('beforeunload', handlePageClose);
			document.removeEventListener('visibilitychange', event => {
				if (document.visibilityState != 'visible') {
					handlePageClose();
					onCloseWindow();
				}
			});
		};
	}, [handlePageClose]);

	return (
		<LoginTemplate
			mainContent={
				<IonList className={'ion-padding'}>
					<IonItem className={classes.hintContainer} lines="none">
						<IonLabel
							className={ssoSandboxResult == 'true' ? classes.success : classes.error}
						>
							{customMessage}
						</IonLabel>
					</IonItem>
				</IonList>
			}
			btnContent={
				<div>
					<IonButton className="round" shape="round" size="large" onClick={onCloseWindow}>
						Close
					</IonButton>
				</div>
			}
		/>
	);
};

const mapStateToProps = (state: any) => ({
	fetchData: state.fetchDataState,
});

export default injectIntl(
	isAuthenticated(
		connect(mapStateToProps, { setParameter })(LoginSSOSandboxResultPage),
		'LoginSSOSandboxResultPage'
	)
);
