import React, { FC, useState } from 'react';
import { injectIntl } from 'react-intl';
import Select from 'react-select';
import { countries } from 'countries-list';

import classes from '../CustomFormComponents.module.css';
import classNames from 'classnames';

interface CountrySelectProps {
	intl: any;
	errors: any;
	setSelectedValue?: any;
	name: string;
	disabled?: boolean;
	initializedValue?: string;
	menuPlacement?: 'auto' | 'bottom' | 'top' | undefined;
	marginLess?: boolean;
	required?: boolean;
	standardInput?: boolean;
	dataCy?: string;
}

const CountrySelect: FC<CountrySelectProps> = CountrySelectProps => {
	const {
		intl,
		errors,
		name,
		disabled,
		initializedValue,
		menuPlacement,
		marginLess,
		required,
		setSelectedValue,
		standardInput,
		dataCy,
	} = CountrySelectProps;

	const [selectedCountry, setSelectedCountry] = useState(initializedValue);

	const styles = {
		option: (provided: any, state: any) => ({
			...provided,
			fontSize: '15px',
			color: 'var(--ion-text-color)',
			backgroundColor: state.isFocused ? 'var(--form-field-hover-color)' : null,
		}),
		singleValue: (provided: any, state: any) => {
			const opacity = state.isDisabled ? 0.5 : 1;
			const transition = 'opacity 300ms';

			return { ...provided, opacity, transition, fontSize: '15px', marginLeft: 0 };
		},
		valueContainer: (provided: any, state: any) => ({
			...provided,
			paddingLeft: '10px',
			padding: '0',
		}),
	};

	const options: { label: string; value: string }[] = [];
	for (const [code, country] of Object.entries(countries)) {
		options.push({ label: country.name, value: code });
	}

	const defaultValue = initializedValue
		? options.find(option => option.value === initializedValue)
		: null;

	const errorMessage =
		errors?.country?.type === 'required' ? (
			<p className={classes.errorMessage}>Country is required</p> //TODO: Translate (has also not been done in consuming components)
		) : null;

	const requiredIndicator = required ? (
		<span className={classes.requiredIndicator}>*</span>
	) : null;

	const containerClasses = classNames({
		[classes.container]: true,
		[classes.containerStandardInput]: Boolean(standardInput),
		[classes.containerMarginLess]: Boolean(marginLess),
		[classes.containerError]: Boolean(errorMessage),
		[classes.containerSelected]: Boolean(selectedCountry),
		[classes.containerDisabled]: Boolean(disabled),
	});

	const label = standardInput ? null : (
		<div className={classes.label}>
			{intl.formatMessage({
				id: 'Address.country',
			})}
			{requiredIndicator}
		</div>
	);

	return (
		<div className="select-country" data-cy={dataCy}>
			{label}
			<Select
				onChange={(e: any) => {
					setSelectedCountry(e.value);
					setSelectedValue(e.value);
					return e;
				}}
				rules={required ? { required: true } : {}}
				styles={styles}
				options={options}
				menuPlacement={menuPlacement ? menuPlacement : 'auto'}
				name={name}
				placeholder="country"
				isDisabled={disabled}
				defaultValue={defaultValue}
			/>
		</div>
	);
};

export default injectIntl(CountrySelect);
