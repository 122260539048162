import React, { FC } from 'react';
import { IonLabel } from '@ionic/react';
import { FormattedMessage } from 'react-intl';

import classes from './Robots.module.css';
import Messages from './Robots.messages';

interface RobotStatusProps {
	currentState?: string;
	online?: boolean;
}

const RobotStatus: FC<RobotStatusProps> = props => {
	const { currentState, online } = props;
	return (
		<div className={classes.statusContainer}>
			{currentState != null ? (
				currentState === 'inSession' ? (
					<IonLabel className={classes.statusNameLb} {...Messages.onCall}>
						<FormattedMessage {...Messages.onCall} />
					</IonLabel>
				) : currentState === 'available' && online === true ? (
					<IonLabel className={classes.statusNameLb}>
						<FormattedMessage {...Messages.online} />
					</IonLabel>
				) : (
					<IonLabel className={(classes.statusNameLb, classes.textGrey)}>
						<FormattedMessage {...Messages.offline} />
					</IonLabel>
				)
			) : (
				<>
					{online === true ? (
						<IonLabel className={classes.statusNameLb}>
							<FormattedMessage {...Messages.online} />
						</IonLabel>
					) : (
						<IonLabel className={(classes.statusNameLb, classes.textGrey)}>
							<FormattedMessage {...Messages.offline} />
						</IonLabel>
					)}
				</>
			)}
		</div>
	);
};

export default RobotStatus;
