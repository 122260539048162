import React from 'react';
import { IoStar, IoStarOutline } from 'react-icons/io5';
import '../index.scss';

interface Props {
	isFavorite: boolean;
	onClick: any;
}

export const FavoriteColumn = ({ isFavorite, onClick }: Props) => {
	return (
		<div className="rosterListCellXSmall rosterListFavoriteCell" onClick={onClick}>
			{isFavorite ? (
				<IoStar className="iconStarFavorite" />
			) : (
				<IoStarOutline className="iconStar" />
			)}
		</div>
	);
};
