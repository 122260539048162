import React from 'react';

export const InSessionGuestIcon = () => {
	return (
		<svg
			width="53"
			height="57"
			viewBox="0 0 53 57"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M26.5109 8.47644C15.4971 8.47644 6.54395 17.5393 6.54395 28.7494C6.54395 39.9596 15.4971 49.0224 26.5109 49.0224C37.5248 49.0224 46.4779 39.9596 46.4779 28.7494C46.4779 17.5393 37.5248 8.47644 26.5109 8.47644ZM4.54395 28.7494C4.54395 16.4621 14.3653 6.47644 26.5109 6.47644C38.6566 6.47644 48.4779 16.4621 48.4779 28.7494C48.4779 41.0368 38.6566 51.0224 26.5109 51.0224C14.3653 51.0224 4.54395 41.0368 4.54395 28.7494Z"
				fill="#58B255"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M37.6468 0.180054L39.7325 1.10883L37.76 5.53853C46.3938 9.72366 52.347 18.5719 52.347 28.8105C52.347 43.0854 40.7749 54.6575 26.5 54.6575C22.9939 54.6575 19.6509 53.9594 16.6024 52.6947L14.6852 57.0002L12.5994 56.0714L14.5341 51.7268C6.28336 47.4098 0.652954 38.7677 0.652954 28.8105C0.652954 14.5356 12.225 2.96349 26.5 2.96349C29.7266 2.96349 32.8151 3.55472 35.6631 4.63477L37.6468 0.180054ZM26.5 4.96349C13.3296 4.96349 2.65295 15.6402 2.65295 28.8105C2.65295 41.9808 13.3296 52.6575 26.5 52.6575C39.6703 52.6575 50.347 41.9808 50.347 28.8105C50.347 15.6402 39.6703 4.96349 26.5 4.96349Z"
				fill="white"
			/>
			<path
				d="M21.8873 37.8056C21.5995 37.8051 21.3168 37.7296 21.067 37.5867C20.5045 37.268 20.1548 36.6492 20.1548 35.9775V22.3837C20.1548 21.7102 20.5045 21.0933 21.067 20.7745C21.3227 20.6275 21.6133 20.552 21.9082 20.5557C22.2032 20.5594 22.4917 20.6423 22.7437 20.7956L34.3617 27.75C34.6038 27.9018 34.8034 28.1127 34.9418 28.3627C35.0801 28.6128 35.1527 28.8939 35.1527 29.1797C35.1527 29.4655 35.0801 29.7466 34.9418 29.9967C34.8034 30.2467 34.6038 30.4576 34.3617 30.6094L22.7418 37.5656C22.484 37.7215 22.1886 37.8045 21.8873 37.8056Z"
				fill="#58B255"
			/>
		</svg>
	);
};
