import React from 'react';
import './index.scss';

type Props = {
	isVisible: boolean;
	onClickBackToRoster: () => void;
	robotName: string;
};

export default function MediaAccessDeniedOverlay({
	isVisible,
	onClickBackToRoster: backClick,
	robotName,
}: Props) {
	return isVisible ? (
		<div className="mediaAccessDeniedOverlayContainer">
			<div className="warningIconWrapper">
				<img alt="" src="../../assets/images/cloud-offline.svg" />
			</div>
			<div className="warningText">
				{`You must grant access to camera and microphone, in order to connect to ${robotName}.`}
			</div>
			<div className="actionButtonsContainer">
				<div className="circleTextContainer">
					<div className="redCircle blackCircle" onClick={() => backClick()}>
						<div className="iconWrapper rotateNinety">
							<img src="../../assets/images/close.svg" alt="" />
						</div>
					</div>
					<div>End</div>
				</div>
			</div>
		</div>
	) : null;
}
