import React, { FC, useEffect, useMemo, useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import isAuthenticated from '../Authentication/Authenticated';

import classes from './ProfileSettings.module.css';
import classNames from 'classnames';
import { useTypedSelector } from '../../reducers';
import OrganizationAgreementModal from '../OrganizationForm/OrganizationAgreementModal';
import { Agreement } from '../../types/types';
import Table from '../Table/Table';
import { TableCell } from '@material-ui/core';
import Messages from './ProfileSettings.messages';
import { styled } from '@material-ui/styles';
interface AgreementsSettingsSubmenuProps {
	goTo?: () => void;
}
const StyledTableCell = styled(TableCell)(() => ({
	borderColor: '#f1f1f3',
}));

const AgreementsSettingsSubmenu: FC<AgreementsSettingsSubmenuProps> = (props: any) => {
	const acceptedAgreementsByUser: Array<any> = useTypedSelector(
		state => state.accountState.user.acceptedAgreements
	);

	const [selectedAgreement, setSelectedAgreement] = useState<Agreement | null>(null);

	const [modalOpen, setModalOpen] = useState<boolean>(false);

	const openAgreement = (agreement: Agreement) => {
		setSelectedAgreement(agreement);
		setModalOpen(true);
	};
	const rows = useMemo(() => {
		return acceptedAgreementsByUser.filter(
			data =>
				data.type === 'privacy-and-cookie-policy' ||
				data.type === 'terms-of-use' ||
				data.type === 'safety-agreement'
		);
	}, [acceptedAgreementsByUser]);

	const renderLinkCell = (rowData: any) => {
		return (
			<StyledTableCell>
				<div
					className={classNames(classes.inputLbAgreement)}
					key={rowData.type}
					onClick={e => {
						openAgreement(rowData);
					}}
				>
					<FormattedMessage id={`Organizations.${rowData.type}`} />
				</div>
			</StyledTableCell>
		);
	};
	const columns = [
		{
			dataField: 'type',
			renderCell: (rowData: any) => renderLinkCell(rowData),
			formattedMessage: <FormattedMessage {...Messages.agreements} />,
		},
		{
			dataField: 'version',
			formattedMessage: <FormattedMessage {...Messages.version} />,
		},
		{
			dataField: 'dateOfAcceptance',
			formattedMessage: <FormattedMessage {...Messages.acceptedDate} />,
			type: 'date',
		},
		{
			dataField: 'userId',
			formattedMessage: <FormattedMessage {...Messages.acceptedBy} />,
		},
	];

	return (
		<div className={classes.editForm}>
			<Table columns={columns} rows={rows} hasHeader={true} />
			<OrganizationAgreementModal
				open={modalOpen}
				selectedAgreement={selectedAgreement}
				onDismissModal={() => {
					setModalOpen(false);
				}}
			/>
		</div>
	);
};

export default injectIntl(isAuthenticated(AgreementsSettingsSubmenu, 'AgreementsSettingsSubmenu'));
