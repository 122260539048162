import React from 'react';
import Battery from '../../../../../components/battery';
import '../index.scss';

interface Props {
	deviceBattery: { level: string | number; isCharging?: boolean; charging?: boolean };
}

export const BatteryColumn = ({ deviceBattery }: Props) => {
	return (
		<div className="rosterListRowCell rosterListBatteryCell">
			<Battery
				batteryPercent={
					deviceBattery && deviceBattery.level ? deviceBattery.level.toString() : '40'
				}
				online={true}
				charging={
					deviceBattery && deviceBattery?.charging
						? deviceBattery.charging
						: deviceBattery?.isCharging
						? deviceBattery.isCharging
						: false
				}
			/>
			<span className="bodyText" style={{ marginLeft: 8 }}>
				{deviceBattery?.level || 0} %{deviceBattery?.isCharging ? ', Charging' : ''}
			</span>
		</div>
	);
};
