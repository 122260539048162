import React, { FC, useState, useCallback, useEffect } from 'react';

import { injectIntl } from 'react-intl';
import isAuthenticated from '../../components/Authentication/Authenticated';
import { useTypedSelector } from '../../reducers';
import AudioVideoSettings from './AudioVideoSettings';
import ViewsSettings from './ViewsSettings';

interface PilotAppSettingsProps {
	currentSubMenu: string;
	onSubMenuChange: (name: string) => void;
}

const PilotAppSettings: FC<PilotAppSettingsProps> = (props: any) => {
	const { currentSubMenu, onSubMenuChange } = props;

	const [currentItem, setCurrentItem] = useState<string | undefined>(currentSubMenu);

	useEffect(() => {
		let selectedItem = document.getElementById('row-' + currentItem);
		let top = selectedItem ? selectedItem.offsetTop + 30 : 30;
		let arrow = document.getElementById('arrow');
		if (arrow) {
			arrow.style.top = top.toString() + 'px';
		}
	});

	useEffect(() => {
		setCurrentItem(currentSubMenu);
	}, [currentSubMenu]);

	const goToItem = useCallback(
		(itemName: string) => {
			setCurrentItem(itemName);
			onSubMenuChange(itemName);
		},
		[onSubMenuChange]
	);

	switch (currentItem) {
		case 'audio/video':
			return <AudioVideoSettings goTo={goToItem} />;
		case 'views':
			return <ViewsSettings goTo={goToItem} />;
		default:
			return null;
	}
};

export default injectIntl(isAuthenticated(PilotAppSettings, 'PilotAppSettings'));
