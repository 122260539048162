import React, { FC, useState, useCallback, useEffect } from 'react';
import RobotGroupSetupDetails from './RobotGroupSetupDetails';

import isAuthenticated from '../../components/Authentication/Authenticated';
import { publish } from '../../actions/publish';
import { useTypedSelector } from '../../reducers';
import { equalityFnc } from '../../utils/conformState';
interface RobotGroupSetupProps {
	currentSubMenu: string;
	onSubMenuChange: (name: string) => void;
	subMenuCount: number;
	disableButton?: any;
	onUpdateMessageChange: (message: any) => void;
}

const RobotGroupSetup: FC<RobotGroupSetupProps> = (props: any) => {
	const {
		currentSubMenu,
		onSubMenuChange,
		subMenuCount,
		disableButton,
		onUpdateMessageChange,
	} = props;

	const [currentItem, setCurrentItem] = useState<any>(currentSubMenu);
	const [hideArrow, setHideArrow] = useState(false);
	const [robotGroup, setRobotGroup] = useState<any>();

	const selectedOrganization = useTypedSelector(
		state => state.selectedOrganizationState.organization,
		(left, right) => equalityFnc(left, right)
	);

	const deviceGroups = useTypedSelector(
		state => state.deviceGroupsState.deviceGroupsByOrganizationId[selectedOrganization.orgId]
	);

	const username = useTypedSelector(state => state.accountState.user.username);
	const encodedUser = window.btoa(username);

	useEffect(() => {
		if (
			!selectedOrganization ||
			!deviceGroups ||
			!robotGroup ||
			!(deviceGroups[currentItem]?.name !== robotGroup?.name)
		) {
			return;
		}

		const data = {
			deviceGroupId: robotGroup.deviceGroupId,
			name: robotGroup.name,
		};

		onUpdateMessageChange(
			`microservice/${selectedOrganization.orgId}/${encodedUser}/updateDeviceGroupInfo`,
			{ data, requestId: 'updateDeviceGroup' }
		);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [robotGroup]);

	useEffect(() => {
		setCurrentItem(currentSubMenu);
		let selectedItem: any = document.getElementById('row-' + currentItem);
		if (selectedItem) selectedItem.scrollIntoView();
	}, [currentItem, currentSubMenu]);

	useEffect(() => {
		if (subMenuCount < 10) {
			let selectedItem = document.getElementById('row-' + currentItem);
			let top = selectedItem ? selectedItem.offsetTop + 30 : 30;
			let arrow = document.getElementById('arrow');
			if (arrow) {
				arrow.style.top = top.toString() + 'px';
			}
			setHideArrow(false);
		} else {
			setHideArrow(true);
		}
	}, [currentItem, subMenuCount]);

	const goToItem = useCallback(
		(itemName: string) => {
			setCurrentItem(itemName);
			onSubMenuChange(itemName);
		},

		[onSubMenuChange]
	);

	const handleRobotGroupChange = useCallback(
		(label: any, value: any) => {
			let tempEditableRobotGroup = JSON.parse(JSON.stringify(robotGroup));

			switch (label) {
				case 'name':
					tempEditableRobotGroup[label] = value[label];
					break;
			}
			setRobotGroup(tempEditableRobotGroup);
		},

		[robotGroup]
	);

	const renderContent = useCallback(() => {
		const availableRobotGroup = deviceGroups;

		if (currentItem && currentItem !== '') {
			if (!robotGroup || robotGroup.deviceGroupId !== currentItem)
				if (availableRobotGroup && availableRobotGroup[currentItem])
					setRobotGroup(availableRobotGroup[currentItem]);
		}

		return (
			<RobotGroupSetupDetails
				robotGroup={
					currentItem !== ''
						? availableRobotGroup && availableRobotGroup[currentItem]
							? availableRobotGroup[currentItem]
							: null
						: null
				}
				goTo={goToItem}
				monitorizeFormChange={handleRobotGroupChange}
				disableButton={disableButton}
			/>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentItem, deviceGroups, goToItem, robotGroup]);

	return renderContent();
};

export default isAuthenticated(RobotGroupSetup, 'RobotGroupSetup');
