import React from 'react';
import { useHistory } from 'react-router-dom';

class PropsFromParent {
	onClick?: Function;
	online?: boolean;
	charging?: boolean;
	batteryPercent: string;
}

const Battery: React.FC<PropsFromParent> = ({
	onClick,
	batteryPercent = '40',
	online = false,
	charging = false,
}) => {
	const history = useHistory();

	const batteryColor = () => {
		if (online) {
			if (charging) {
				return '#56ae4d';
			} else {
				if (parseInt(batteryPercent) > 20) {
					return '#56ae4d';
				} else if (parseInt(batteryPercent) > 10) {
					return '#e1c406';
				} else {
					return '#d92e2e';
				}
			}
		} else {
			batteryPercent = '40';
			return '#a5a5a6';
		}
	};
	const styles = {
		batteryContainer: {
			position: 'relative' as 'relative',
			width: '28px',
			height: '14px',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		innerBattery: {
			width: '25px',
			height: '15px',
			borderColor: `${batteryColor()}`,
			border: `2px solid  ${batteryColor()}`,
			borderRadius: '4px',
			display: 'flex',
			justifyContent: 'flex-start',
			alignItems: 'center',
		},
		batteryPercent: {
			width: `${(parseInt(batteryPercent) / 100) * 22}px`,
			height: '9px',
			borderColor: `${batteryColor()}`,
			// border: `1px solid white`,
			margin: '1px',
			borderRadius: '2px',
			backgroundColor: `${batteryColor()}`,
		},
		headBattery: {
			backgroundColor: `${batteryColor()}`,
			width: '2px',
			height: '6px',
			marginLeft: '1px',
		},
		chargingWrapper: {
			position: 'absolute' as 'absolute',
			width: '15px',
			left: '5px',
			height: '24px',
		},
		displayNone: {
			display: 'none',
		},
	};
	return (
		<div style={styles.batteryContainer} onClick={() => (onClick ? onClick() : '')}>
			<div style={styles.innerBattery}>
				<div style={styles.batteryPercent} />
			</div>
			<div style={styles.headBattery} />
			<div style={charging ? styles.chargingWrapper : styles.displayNone}>
				<img
					alt=""
					src={`../../assets/images/${
						history.location.pathname === '/gobe/session'
							? 'green-flash2.svg'
							: online
							? 'green-flash.svg'
							: 'grey-flash.svg'
					}`}
				/>
			</div>
		</div>
	);
};

export default Battery;
