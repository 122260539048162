import { DeviceGroup } from '../types/types';

const getUserGroupNames = (
	groups: Array<string>,
	orgId: string,
	userGroups: Record<string, Record<string, any>>
) => {
	let userGroupNames: string[] = [];
	if (groups != null) {
		let userGroupNames: any = [];
		for (let userGroupId of groups) {
			if (
				userGroups.userGroupsByOrganizationId != null &&
				userGroups.userGroupsByOrganizationId[orgId] != null &&
				userGroups.userGroupsByOrganizationId[orgId][userGroupId] != null
			) {
				userGroupNames.push(userGroups.userGroupsByOrganizationId[orgId][userGroupId].name);
			}
		}
		return userGroupNames;
	} else return userGroupNames;
};

const getDeviceGroupNames = (groups: Array<string>, deviceGroup: Record<string, DeviceGroup>) => {
	let deviceGroupNames: string[] = [];
	if (groups != null) {
		let deviceGroupNames: any = [];
		for (let deviceGroupId of groups) {
			if (deviceGroup != null && deviceGroup[deviceGroupId] != null) {
				deviceGroupNames.push(deviceGroup[deviceGroupId].name);
			}
		}
		return deviceGroupNames;
	} else return deviceGroupNames;
};

export { getUserGroupNames, getDeviceGroupNames };
