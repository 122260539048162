import { useCallback, useLayoutEffect, useReducer, useRef } from 'react';

const _DOCKING_KEYS = ['P', 'p'] as const;
const AUTO_DOCKING_KEYS = new Set(_DOCKING_KEYS);

/**
 * User must keep DOCKING_KEY pressed for at least this much time,
 * before the docking controller is engaged
 */
const MIN_KEY_DOWN_TIME__MS = 2 * 1000;

type StateChartEvent =
	| 'dockingKeyDown' // when user presses docking key
	| 'dockingKeyUp' // when user release docking key
	| 'invalidKey' // when user presses a key other than a docking key
	| 'terminated' // when docking controller reports that the docking sequence has been terminated
	| 'initiated' // when docking controller reports that the docking sequence has been initiated
	| 'error' // when there is an error in the docking controller
	| 'timedOut'; // when an action/state timed out
type StateChartState =
	| 'notPressed' // docking key is not pressed at all
	| 'pressedButWaiting' // docking is pressed down, but not for long enough
	| 'pressedAndEngaged.withKeyDown' // continuously sending DOCK CONTINUE. docking key is pressed down
	| 'pressedAndEngaged.withKeyUp' // continuously sending DOCK CONTINUE. docking key has been released, but not for long enough to abort
	| 'ended' // catch-all for weird states and events
	| 'showingStatus' //Show the status of the current state
	| 'resetState'; // Hide the status dialog and resetting the state to IDLE
const StateChart: {
	states: Record<StateChartState, Partial<Record<StateChartEvent, StateChartState>>>;
	general: Partial<Record<StateChartEvent, StateChartState>>;
} = {
	states: {
		/** No docking key is pressed */
		notPressed: {
			dockingKeyDown: 'pressedButWaiting',
			invalidKey: 'notPressed', // remain in the same state
		},
		/** Docking key is pressed, but not for long enough */
		pressedButWaiting: {
			dockingKeyUp: 'notPressed',
			invalidKey: 'notPressed',
			initiated: 'pressedAndEngaged.withKeyDown',
		},
		'pressedAndEngaged.withKeyDown': {
			dockingKeyUp: 'pressedAndEngaged.withKeyUp',
			invalidKey: 'pressedAndEngaged.withKeyUp',
		},
		'pressedAndEngaged.withKeyUp': {
			dockingKeyDown: 'pressedAndEngaged.withKeyDown',
			timedOut: 'ended',
		},
		ended: {
			dockingKeyDown: 'showingStatus',
			dockingKeyUp: 'notPressed',
			error: 'notPressed',			
		},
		showingStatus: {
			dockingKeyDown: 'showingStatus',
			dockingKeyUp: 'resetState',			
		},
		resetState: {
			timedOut: 'notPressed',
		},
	},
	/**
	 * Transitions for events that may occur in any state.
	 * If the state itself doesn't handle the event, it will be handled using the definition here
	 */
	general: {
		invalidKey: 'ended',
		terminated: 'ended',
		error: 'ended',
	},
};

const StateReducer = (state: StateChartState, action: { type: StateChartEvent }) => {
	const nextState = StateChart.states[state][action.type] ?? StateChart.general[action.type];
	// console.debug(`AutoDockStateChart curr: ${state} -> e: ${action.type} -> next: ${nextState}`);
	return nextState ?? state;
};

type AutoDockingKeyPressArgs = {
	sendSTART: () => void;
	sendSTOP: () => void;
	sendCONTINUE: () => void;
	sendRESET: () => void;
};
export default function useDockingKeyPressStateChart({
	sendSTART,
	sendSTOP,
	sendCONTINUE,
	sendRESET,
}: AutoDockingKeyPressArgs) {
	const [state, dispatch] = useReducer(StateReducer, 'notPressed' as StateChartState);

	const onKeyDown = useCallback((e: KeyboardEvent) => {
		if (AUTO_DOCKING_KEYS.has(e.key as never)) dispatch({ type: 'dockingKeyDown' });
		else dispatch({ type: 'invalidKey' });
	}, []);

	const onKeyUp = useCallback((e: KeyboardEvent) => {
		if (AUTO_DOCKING_KEYS.has(e.key as never)) dispatch({ type: 'dockingKeyUp' });
		else dispatch({ type: 'invalidKey' });
	}, []);

	const onDockingInitiated = useCallback(() => dispatch({ type: 'initiated' }), []);
	const onDockingTerminated = useCallback(() => dispatch({ type: 'terminated' }), []);
	const onDockingError = useCallback(() => dispatch({ type: 'error' }), []);

	useLayoutEffect(() => {
		if (state === 'notPressed') {
			sendRESET();
		}
	}, [state, sendRESET]);

	useLayoutEffect(() => {
		if (state === 'pressedButWaiting') {
			// once we have waited long enough, fire INITIATE
			const timeoutID = setTimeout(sendSTART, MIN_KEY_DOWN_TIME__MS);
			return () => clearTimeout(timeoutID);
		}
	}, [state, sendSTART]);

	useLayoutEffect(() => {
		if (!(state === 'pressedAndEngaged.withKeyDown' || state === 'pressedAndEngaged.withKeyUp'))
			return;

		// repeatedly fire CONTINUE
		sendCONTINUE();
		const intervalID = setInterval(sendCONTINUE, 100);

		let timeoutID: ReturnType<typeof setTimeout> | undefined;
		if (state === 'pressedAndEngaged.withKeyUp') {
			// if docking key is up for too long, time out
			timeoutID = setTimeout(() => {
				dispatch({ type: 'timedOut' });
				dispatch({ type: 'error' });
			}, 1000);
		}

		return () => {
			if (timeoutID !== undefined) clearTimeout(timeoutID);
			clearInterval(intervalID);
		};
	}, [state, sendCONTINUE]);

	useLayoutEffect(() => {
		if (state === 'ended') {
			sendSTOP();

			/**
			 *  NOTE: This code is disabled so that user need to release the Docking Key (P) and
			 *  then press again to Start/Retry Docking
			 */
			/** Automatically get out of this state after a while */
			// const timeoutId = setTimeout(() => dispatch({ type: 'timedOut' }), 1000);
			// return () => clearTimeout(timeoutId);
		}
	}, [state, sendSTOP]);

	useLayoutEffect(() => {
		let timeoutID: ReturnType<typeof setTimeout> | undefined;
		if (state === 'resetState') {
			// registerShowStatusTimer(2000);
			timeoutID = setTimeout(() => {
				dispatch({ type: 'timedOut' });
			}, 2000);
		}
		return () => {
			// if (showStatusTimer.current) clearTimeout(showStatusTimer.current);
			if (timeoutID !== undefined) clearTimeout(timeoutID);
		};
	}, [state, sendRESET]);
	
	return {
		state,
		onKeyDown,
		onKeyUp,
		onDockingInitiated,
		onDockingTerminated,
		onDockingError,
	};
}
