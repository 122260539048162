import { defineMessages } from 'react-intl';

export default defineMessages({
	title: {
		id: 'UserMoveGroup.title',
		defaultMessage: 'Move To Group',
		description: 'Move To Group',
	},
	switch: {
		id: 'UserMoveGroup.switch',
		defaultMessage: 'Choose The User Group',
		description: 'Choose The User Group',
	},
	cancel: {
		id: 'UserMoveGroup.cancel',
		defaultMessage: 'Cancel',
		description: 'Cancel',
	},
	move: {
		id: 'UserMoveGroup.move',
		defaultMessage: 'Move',
		description: 'Move',
	},
	from: {
		id: 'UserMoveGroup.from',
		defaultMessage: 'Please choose new group for :',
		description: 'From :',
	},
	toGroup: {
		id: 'UserMoveGroup.toGroup',
		defaultMessage: 'To Group',
		description: 'To Group',
	},
	fromGroup: {
		id: 'UserMoveGroup.fromGroup',
		defaultMessage: 'From User Group :',
		description: 'From User Group',
	},
});
