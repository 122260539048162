import React, { FC } from 'react';
import classes from './Robots.module.css';
import { IonLabel } from '@ionic/react';
import { FormattedMessage } from 'react-intl';
import Messages from './Robots.messages';
import { getStateEntityByOrgId, equalityFnc } from '../../utils/conformState';
import { useTypedSelector } from '../../reducers';
import classNames from 'classnames';

interface RobotGroupProps {
	robotId: string;
	orgId: string;
	customClass?: any;
}

const RobotGroupName: FC<RobotGroupProps> = props => {
	const { robotId, orgId, customClass } = props;

	const robot = useTypedSelector(
		state =>
			getStateEntityByOrgId(state, 'deviceState', {
				entityId: robotId,
				orgId,
				propertyOptions: ['online', 'deviceGroupsIds'],
			}),
		(left, right) => equalityFnc(left, right)
	);

	const deviceGroups = useTypedSelector(
		state => state.deviceGroupsState.deviceGroupsByOrganizationId[orgId],
		(left, right) => equalityFnc(left, right)
	);

	return (
		<div className={classes.groupContainer}>
			{robot.deviceGroupsIds && deviceGroups ? (
				Object.values(deviceGroups).map((deviceGroup: any, index: any) => {
					return robot.deviceGroupsIds[0] === deviceGroup.deviceGroupId ? (
						<IonLabel
							className={
								robot.online
									? classes.groupNameLb
									: (classes.groupNameLb, classes.textGrey, customClass)
							}
							key={index}
						>
							{deviceGroup.name}
						</IonLabel>
					) : (
						''
					);
				})
			) : (
				<IonLabel
					className={
						robot.online
							? classes.groupNameLb
							: (classes.groupNameLb, classes.textGrey, customClass)
					}
				>
					<FormattedMessage {...Messages.unassigned} />
				</IonLabel>
			)}
		</div>
	);
};

export default RobotGroupName;
