import React, { FC, useState, useEffect } from 'react';
import { ErrorMessage, useForm } from 'react-hook-form';
import { injectIntl, FormattedMessage } from 'react-intl';
import {
	IonGrid,
	IonRow,
	IonList,
	IonListHeader,
	IonLabel,
	IonItem,
	IonInput,
	IonIcon,
	IonText,
} from '@ionic/react';
import { checkmarkCircle } from 'ionicons/icons';

import classes from './RobotForm.module.css';
import classNames from 'classnames';
import Messages from './RobotForm.messages';

import isAuthenticated from '../../components/Authentication/Authenticated';
import ReactSelect from '../ReactSelect/ReactSelect';
import BatteryDetails from '../BatteryDetails/BatteryDetails';
import Battery from '../Battery/Battery';
import batteryLevelConverter from '../../utils/batteryLevelConverter';
import { useTypedSelector, AppRootState } from '../../reducers';
import { equalityFnc } from '../../utils/conformState';
import { DeviceGroup } from '../../types/types';
import { AutoComplete } from '../AutoComplete/AutoComplete';
import { InputLimit, MiddleInputLimit } from '../../utils/validator';
const moment = require('moment-timezone');

interface RobotDetailsProps {
	control: any;
	device: any;
	classStyle: string;
	timeZone?: any;
}

const RobotDetailsForm: FC<RobotDetailsProps> = (props: any) => {
	const { intl, device, control, classStyle, timeZone } = props;

	const selectedOrganizationId = useTypedSelector(
		state => state.selectedOrganizationState.organization.orgId
	);
	const userGroups = useTypedSelector(
		(state: AppRootState) =>
			state.userGroupsState.userGroupsByOrganizationId[selectedOrganizationId],
		(left, right) => equalityFnc(left, right, false)
	);
	const deviceGroups = useTypedSelector(
		(state: AppRootState) =>
			(state.deviceGroupsState.deviceGroupsByOrganizationId as Record<
				string,
				Record<string, DeviceGroup>
			>)[selectedOrganizationId],
		(left, right) => equalityFnc(left, right, false)
	);
	const devices = useTypedSelector(state => state.deviceState.devicesByOrganizationId);
	const [hoverBatt, setHoverBatt] = useState(false);
	const [timeZoneOptions] = useState<any>(
		moment.tz.names().map((name: string) => ({ label: name, value: name }))
	);

	/**
	 * @param {string} entity - The entity type to get current values for React-Select element
	 * @param {string} labelProperty - The property that contains the name of the entity
	 * @param {string} valueProperty - The property that contains the identifying value of the entity
	 */
	const selectOptions = (entity: string, labelProperty: string, valueProperty: string) => {
		let label;
		let value;

		const options = [];
		let currentEntities: Record<string, any> | null;
		if (entity === 'userGroup') {
			currentEntities = userGroups;
		} else if (entity === 'deviceGroup') {
			currentEntities = deviceGroups;
		} else currentEntities = null;

		if (currentEntities === null) currentEntities = [];
		else {
			for (let i in currentEntities) {
				label = currentEntities[i][labelProperty];
				value = currentEntities[i][valueProperty];

				options.push({
					label,
					value,
				});
			}
		}

		return options;
	};

	/**
	 * @param {string} entity - The entity type to get current values for React-Select element
	 */
	const currentEntities = (
		entity: string,
		labelProperty: string,
		valueProperty: string,
		type: string
	) => {
		let selectedEntities = [];
		let allEntities: any;
		let parameter: any;
		if (entity === 'userGroup') {
			allEntities = userGroups;
			parameter = 'userGroupsIds';
		} else if (entity === 'deviceGroup') {
			allEntities = deviceGroups;
			parameter = 'deviceGroupsIds';
		}

		const selectedRobotClone: any = device;
		for (let key in allEntities) {
			if (selectedRobotClone != null && selectedRobotClone[parameter] != null) {
				for (let i in selectedRobotClone[parameter]) {
					if (entity === 'userGroup' || entity === 'deviceGroup') {
						if (allEntities[key][valueProperty] === selectedRobotClone[parameter][i]) {
							selectedEntities.push(allEntities[key]);
						}
					}
				}
			}
		}
		const options: any = [];
		if (selectedEntities != null && selectedEntities.length > 0) {
			for (let key in selectedEntities) {
				options.push({
					label: selectedEntities[key][labelProperty],
					value: selectedEntities[key][valueProperty],
				});
			}
		}

		if (type === 'label') {
			let optionsLabel = [];

			for (let option of options) {
				optionsLabel.push(option.label);
			}

			return optionsLabel;
		}
		return options;
	};

	const ontimeZoneChange = (timezone: any) => {
		timeZone(timezone);
		control.setFieldValue('timeZone', timeZone);
	};

	return (
		<form className={classNames(classes.editForm, classStyle)}>
			<IonGrid className={classes.formGrid}>
				<IonRow>
					<IonList>
						<IonListHeader>
							<IonLabel class={classes.labelFont}>
								<FormattedMessage {...Messages.robotDetails} />
							</IonLabel>
						</IonListHeader>
						<IonItem>
							<IonLabel position="fixed" className={classes.formLb}>
								<FormattedMessage {...Messages.name} />
							</IonLabel>
							<IonInput
								className={classNames(classes.editable, classes.robotName)}
								name="name"
								placeholder={intl.formatMessage({
									id: 'RobotsPage.nameHint',
								})}
								value={control?.values?.name}
								onIonChange={e => {
									control.handleChange(e);
								}}
								maxlength={MiddleInputLimit}
								data-cy="robot_details_form_name"
							/>
							{control?.errors && control?.errors?.name && (
								<IonText color="danger" className="ion-padding-start">
									<small>
										<FormattedMessage
											id="RobotsPageForm.name"
											defaultMessage={control?.errors?.name}
										/>
									</small>
								</IonText>
							)}
						</IonItem>
						<IonItem>
							<IonLabel position="fixed" className={classes.formLb}>
								<FormattedMessage {...Messages.location} />
							</IonLabel>
							<IonInput
								className={classes.editable}
								name="location"
								placeholder={intl.formatMessage({
									id: 'RobotsPage.locationHint',
								})}
								value={control?.values?.location}
								onIonChange={e => {
									control.handleChange(e);
								}}
								maxlength={InputLimit}
								data-cy="robot_details_form_location"
							/>
						</IonItem>
						<IonItem>
							<IonLabel position="fixed" className={classes.formLb}>
								<FormattedMessage {...Messages.timeZone} />
							</IonLabel>
							<AutoComplete
								defaultValue={timeZoneOptions.find(
									(op: any) => op.value === control?.values?.timeZone
								)}
								options={timeZoneOptions}
								placeHolder={intl.formatMessage({
									id: 'RobotsPage.timeZoneHint',
								})}
								onChange={({ value }: any) => ontimeZoneChange(value)}
								variant={'outlined'}
								style={{ width: 300 }}
								dataCy="robot_details_form_timezone"
							/>
						</IonItem>
						<div
							className={
								hoverBatt
									? classes.batteryDetailsWrapperShow
									: classes.batteryDetailsWrapper
							}
						>
							<BatteryDetails
								batteryPercent={
									device.battery && device.battery.level != null
										? device.battery.level
										: 40
								}
								charging={
									device.battery && device.battery.charging
										? device.battery.charging
										: device.battery?.isCharging
										? device.battery.isCharging
										: false
								}
							/>
						</div>
						<IonItem>
							<IonLabel position="fixed" className={classes.formLb}>
								<FormattedMessage {...Messages.Battery} />:
							</IonLabel>
							<div className={classes.battTextContainer}>
								<div
									className={
										device.online
											? classes.batteryRoster
											: (classes.batteryRoster, classes.batteryRosterOff)
									}
									onMouseOver={e => setHoverBatt(true)}
									onMouseOut={e => setHoverBatt(false)}
								>
									<Battery
										batteryPercent={
											device.battery && device.battery.level != null
												? device.battery.level
												: 40
										}
										online={device.online}
										charging={
											device.battery && device.battery.charging
												? device.battery.charging
												: device.battery?.isCharging
												? device.battery.isCharging
												: false
										}
									/>
								</div>
								<div className={classes.batteryText}>
									{
										batteryLevelConverter(
											device.battery != null ? device.battery.level : 40,
											8
										).hour
									}
									h:
									{
										batteryLevelConverter(
											device.battery != null ? device.battery.level : 40,
											8
										).min
									}
									m
								</div>
								{(device.battery && device.battery.charging) ||
								(device.battery && device.battery.isCharging) ? (
									<div className={classes.chargingText}>
										{'- '} <FormattedMessage {...Messages.charging} />
									</div>
								) : (
									''
								)}
							</div>
						</IonItem>
						<IonItem>
							<IonLabel position="fixed" className={classes.formLb}>
								<FormattedMessage {...Messages.status} />:
							</IonLabel>
							{device.currentState != null ? (
								device.currentState === 'inSession' ? (
									<IonLabel className={classes.statusNameLb}>
										<FormattedMessage {...Messages.onCall} />
									</IonLabel>
								) : device.currentState === 'available' &&
								  device.online === true ? (
									<IonLabel className={classes.statusNameLb}>
										<IonIcon
											className={classes.availableIcon}
											icon={checkmarkCircle}
										/>{' '}
										<FormattedMessage {...Messages.online} />
									</IonLabel>
								) : device.currentState === 'reserved' ? (
									<IonLabel className={classes.statusNameLb}>
										<FormattedMessage {...Messages.reserved} />
									</IonLabel>
								) : device.currentState === 'onHold' ? (
									<IonLabel className={classes.statusNameLb}>
										<FormattedMessage {...Messages.onHold} />
									</IonLabel>
								) : device.currentState === 'configuring' ? (
									<IonLabel className={classes.statusNameLb}>
										<FormattedMessage {...Messages.configuring} />
									</IonLabel>
								) : device.currentState === 'unavailable' ? (
									<IonLabel className={classes.statusNameLb}>
										<FormattedMessage {...Messages.unavailable} />
									</IonLabel>
								) : device.currentState === 'updating' ? (
									<IonLabel className={classes.statusNameLb}>
										<FormattedMessage {...Messages.updating} />
									</IonLabel>
								) : (
									<IonLabel className={classes.statusNameLb}>
										<FormattedMessage {...Messages.offline} />
									</IonLabel>
								)
							) : (
								<>
									{device.online === true ? (
										<IonLabel className={classes.statusNameLb}>
											<FormattedMessage {...Messages.online} />
										</IonLabel>
									) : (
										<IonLabel className={classes.statusNameLb}>
											<FormattedMessage {...Messages.offline} />
										</IonLabel>
									)}
								</>
							)}
						</IonItem>
						<IonItem>
							<IonLabel position="fixed" className={classes.formLb}>
								<FormattedMessage {...Messages.type} />
							</IonLabel>

							<IonLabel className={classes.type}>{device.deviceType}</IonLabel>
						</IonItem>

						<IonItem disabled>
							<IonLabel position="fixed" className={classes.formLb}>
								<FormattedMessage {...Messages.gobeCare} />:
							</IonLabel>
						</IonItem>
						<IonItem hidden={true}>
							<IonLabel
								position="fixed"
								className={classNames(classes.subTitleLb, classes.formLb)}
							>
								<FormattedMessage {...Messages.linkedTo} />:
							</IonLabel>
						</IonItem>

						{/* <IonItem>
					<IonLabel
						position="fixed"
						className={
							!isEditable
								? classNames(classes.formLb, classes.ddlLb)
								: classes.hidden
						}
					>
						<FormattedMessage {...Messages.userGroups} />:
					</IonLabel>
					{currentEntities('userGroup', 'name', 'userGroupId', 'label').map(
						(groupName: any, i: number) => {
							return (
								<IonLabel
									position="fixed"
									className={classes.formLb}
									key={i}
								>
									{groupName}
								</IonLabel>
							);
						}
					)}
					<ReactSelect
						className={isEditable ? classes.groupSelect : classes.hidden}
						lbClass={classes.selectLb}
						label={
							intl.formatMessage({
								id: 'UsersPage.userGroups',
							}) + ':'
						}
						defaultValue={currentEntities(
							'userGroup',
							'name',
							'userGroupId',
							'ReactSelect'
						)}
						value={currentEntities(
							'userGroup',
							'name',
							'userGroupId',
							'ReactSelect'
						)}
						options={selectOptions('userGroup', 'name', 'userGroupId')}
						placeHolder={intl.formatMessage({
							id: 'UsersPage.userGroupsHint',
						})}
						name="userGroupsIds"
						control={control}
						isMulti
						isRequired={false}
					/>
				</IonItem> */}
					</IonList>
				</IonRow>
			</IonGrid>
		</form>
	);
};

export default injectIntl(isAuthenticated(RobotDetailsForm, 'RobotDetailsForm'));
