import React, { FC, useEffect, useState } from 'react';
import { IonList, IonItem, IonLabel, IonListHeader, IonButton, IonCol, IonRow } from '@ionic/react';
import isAuthenticated from '../../components/Authentication/Authenticated';
import { FormattedMessage, injectIntl } from 'react-intl';
import classes from './OrganizationForm.module.css';
import { AcceptedAgreementsPayload } from '../../reducers/accountReducers';

import Messages from './OrganizationForm.messages';
import BasicModal from '../BasicModal/BasicModal';

interface AgreementModalProps {
	selectedAgreement: AcceptedAgreementsPayload;
	onDismissModal: any;
	open: boolean;
}

const OrganizationAgreementModal: FC<AgreementModalProps> = (props: AgreementModalProps) => {
	const { selectedAgreement, onDismissModal, open } = props;

	const [isModalOpenState, setIsModalOpenState] = useState<boolean>(false);

	useEffect(() => {
		setIsModalOpenState(open || false);
	}, [open]);

	const formatText = (text: string) => {
		const textArr = text.split('-');
		for (let i = 0; i < textArr.length; i++) {
			textArr[i] =
				textArr[i].substr(0, 1).toUpperCase() + textArr[i].substr(1, textArr[i].length);
		}
		return textArr.join(' ');
	};

	return (
		<BasicModal open={isModalOpenState} onClose={onDismissModal}>
			<IonList className={classes.modalContent}>
				<IonListHeader className={classes.modalHeader} lines="none">
					<div className={classes.headerContainer}>
						<IonLabel className={classes.headerTitle} color="primary">
							{formatText(selectedAgreement?.type || '')}
						</IonLabel>
					</div>
				</IonListHeader>
				<IonItem style={{ width: '100%' }}>
					<iframe
						className={classes.agreementFrame}
						title={selectedAgreement?.type}
						id={selectedAgreement?.type}
						src={
							'data:text/html;charset=utf-8;http-equiv=content-type,' +
							escape(
								selectedAgreement?.content
									.replace('72pt 72pt 72pt 72pt', '0 10px 0 0')
									.replace(/href/g, ' target="_blank" href')
							)
						}
					/>
				</IonItem>
			</IonList>

			<IonRow className={classes.btnRow}>
				<IonCol className={classes.submitCol}>
					<IonButton
						expand="block"
						shape="round"
						type="submit"
						size="large"
						fill="outline"
						onClick={onDismissModal}
					>
						<FormattedMessage {...Messages.ok} />
					</IonButton>
				</IonCol>
			</IonRow>
		</BasicModal>
	);
};

export default injectIntl(
	isAuthenticated(OrganizationAgreementModal, 'OrganizationAgreementModal')
);
