import { defineMessages } from 'react-intl';

export default defineMessages({
	new: {
		id: 'Organizations.new',
		defaultMessage: 'New Organization',
		description: 'New organization',
	},
	details: {
		id: 'details',
		defaultMessage: 'Details',
		description: 'Details',
	},
	contact: {
		id: 'Organizations.contact',
		defaultMessage: 'Contact',
		description: 'Contact',
	},
	agreement: {
		id: 'Organizations.agreement',
		defaultMessage: 'Agreements',
		description: 'Agreements',
	},
	goBeCare: {
		id: 'Organizations.goBeCare',
		defaultMessage: 'GoBe Care',
		description: 'GoBe Care',
	},
	sso: {
		id: 'Organizations.sso',
		defaultMessage: 'SSO',
		description: 'SSO'
	},
	saved: {
		id: 'saved',
		defaultMessage: 'Saved',
		description: 'Saved',
	},
	robotName: {
		id: 'goBeCareInfo.robotName',
		defaultMessage: 'Robot name',
		description: 'Robot name',
	},
	serialNr: {
		id: 'goBeCareInfo.serialNr',
		defaultMessage: 'Serial number',
		description: 'Serial number',
	},
	activationDate: {
		id: 'goBeCareInfo.activationDate',
		defaultMessage: 'Activation date',
		description: 'Activation date',
	},
	timeLeft: {
		id: 'goBeCareInfo.timeLeft',
		defaultMessage: 'Time left',
		description: 'Time left',
	},
	actions: {
		id: 'goBeCareInfo.actions',
		defaultMessage: 'Actions',
		description: 'Actions',
	},
});
