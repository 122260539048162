import React, { useCallback, useEffect, useRef, useState } from 'react';
import './index.scss';

interface Props {
    text?: string
}

const Tooltip: React.FC<Props> = ({children, text, ...rest }) => {
    const [show, setShow] = React.useState(false);

    return (
        <div className="tooltip-container">
            <div className={text && show ? 'tooltip-box visible' : 'tooltip-box'}>
                {text}
                <span className="tooltip-arrow" />
            </div>
            <div
                onMouseEnter={() => setShow(true)}
                onMouseLeave={() => setShow(false)}
                {...rest}
            >
                {children}
            </div>
        </div>
    );
};

export default Tooltip