import React, { FC, useState, Fragment, useEffect, useCallback, useRef } from 'react';

import { injectIntl, FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';

import isAuthenticated from '../../components/Authentication/Authenticated';
import { sortedCollection } from '../../selectors';

import GuestReservationsGrid from '../../components/GuestReservationsGrid/GuestReservationsGrid';

import classes from './GuestReservations.module.css';

import Messages from './GuestReservations.messages';

import { useTypedSelector } from '../../reducers';
import { b64EncodeUnicode } from '../../utils/encoding';
import styled from 'styled-components';
import MainContent from '../../components/MainContent/MainContent';
import AddGuestReservationModal from '../../components/GuestReservationsModal/AddGuestReservationModal';
import { Box, Button, MenuItem, Typography } from '@material-ui/core';
import { useQuery, gql, useMutation } from '@apollo/client';
import { Cascader } from 'antd';
import _ from 'lodash';
import { store } from '../../store/store';
import { Organization } from '../../types/types';
import { publish } from '../../actions/publish';
import DeleteModal from '../../components/DeleteModal/DeleteModal';

const StyledNewGuestReservationButton = styled(Button)(({ theme }) => ({
	boxShadow: 'none',
	textTransform: 'none',
	color: 'white',
	paddingLeft: '15px',
	paddingRight: '15px',
	backgroundColor: 'var(--ion-color-primary)',
	':hover': {
		backgroundColor: 'var(--ion-color-primary)',
		color: 'white',
		boxShadow: 'none',
	},
}));

const StyledMenuItem = styled(MenuItem)(() => ({
	paddingLeft: 10,
	borderRadius: 5,
}));

const GuestReservations: FC = (props: any) => {
	// const { intl, users, userGroups, history, orgId } = props;
	const {
		intl,
		history,
		organization,
		selectedOrganizationName,
		selectedOrganizationId,
		setParameter,
	} = props;
	const [selectedOrgId, setSelectedOrgId] = useState<string>(selectedOrganizationId);
	const [selectedOrgName, setSelectedOrgName] = useState<string>(selectedOrganizationName);
	const [allOrganizations, setAllOrganizations] = useState<Array<any>>(
		Object.values(organization.organizations || {})
	);

	const selectedOrg: Organization = allOrganizations.find((o: any) => o.orgId === selectedOrgId);
	const defaultOrg = allOrganizations.find((o: any) => o.orgId === selectedOrganizationId);
	const loggedInUser = useTypedSelector(state => state.accountState.user.username);
	const spinoutType = useTypedSelector(state => state.versionState.spinoutType) as string;

	useEffect(() => {
		setSelectedOrgId(selectedOrganizationId);
		let orgId: any = getParentLink(selectedOrganizationId);
		setSelectedOrganizationPath(orgId);
	}, [selectedOrganizationId]);

	useEffect(() => {
		const selectedOrg: Organization = allOrganizations.find(
			(o: any) => o.orgId === selectedOrgId
		);
		//set selected organization state to the changed organization
		for (let key in selectedOrg) {
			setParameter(key, `UPDATE_SEL_ORG_${key.toUpperCase()}`, selectedOrg[key]);
		}
		if (!selectedOrg) return;

		setSelectedOrgName(selectedOrg.name);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allOrganizations, selectedOrgId, setParameter]);

	useEffect(() => {
		setAllOrganizations(Object.values(organization.organizations));
	}, [organization, organization.organizations]);

	const onOrganizationChange = useCallback(
		(selected: any) => {
			let orgId: any;
			if (!selected) {
				orgId = getParentLink(defaultOrg.orgId);
				setSelectedOrganizationPath(orgId);
			} else if (Array.isArray(selected)) {
				orgId = _.last(selected);
				setSelectedOrganizationPath(selected);
			} else {
				orgId = selected;
				setSelectedOrganizationPath([orgId]);
			}
			if (selectedOrgId && orgId !== selectedOrgId) {
				setSelectedOrgId(orgId);
				store.dispatch({
					type: 'UPDATE_SELECTEDORGANIZATIONID',
					payload: { selectedOrganizationId: orgId },
				});
			}
		},
		[defaultOrg]
	);

	const getParentOrgId = (selectedOrgId: string, orgStructure: any = '') => {
		const org = getParentOrg(selectedOrgId, orgStructure);
		if (org) {
			return org.id;
		}
		return '';
	};

	const getParentOrg = (selectedOrgId: string, orgStructureArray: any = []) => {
		if (!orgStructureArray || orgStructureArray.length === 0) {
			orgStructureArray = organization ? organization.organizationStructure : [];
		}

		if (orgStructureArray) {
			for (let orgStructure of orgStructureArray) {
				for (let child in orgStructure.children) {
					if (child === selectedOrgId) {
						return orgStructure;
					} else {
						const temp: any = getParentOrg(selectedOrgId, [
							orgStructure.children[child],
						]);
						if (temp) {
							return temp;
						}
					}
				}
			}
		}
		return '';
	};

	const getParentLink = (orgId: string) => {
		let parentLink = [orgId];
		let parent = getParentOrgId(orgId);
		while (parent) {
			parentLink = [parent, ...parentLink];
			parent = getParentOrgId(parent);
		}
		return parentLink;
	};

	const [selectedOrganizationPath, setSelectedOrganizationPath] = useState(
		defaultOrg && defaultOrg.orgId ? getParentLink(defaultOrg.orgId) : []
	);

	const getGuestReservationsQuery = gql`
		query getGuestReservationsQuery($orgId: String!) {
			getGuestReservations(orgId: $orgId) {
				orgId
				guest
				guestName
				serialNumber
				startingAt
				createdAt
				host
				hostName
				subject
				endingAt
				createdBy
			}
		}
	`;
	const {
		loading: getGuestReservationsLoading,
		error: getGuestReservationsError,
		data: getGuestReservationsData,
		refetch: refetchGuestReservationsData,
	} = useQuery(getGuestReservationsQuery, {
		variables: { orgId: selectedOrgId },
		fetchPolicy: 'no-cache',
	});

	const [guestReservations, setGuestReservations] = useState<any>([]);
	useEffect(() => {
		setGuestReservations(getGuestReservationsData?.getGuestReservations || []);
	}, [getGuestReservationsData, getGuestReservationsLoading]);

	const removeGuestReservationMutation = gql`
		mutation removeGuestReservationMutation(
			$orgId: String!
			$serialNumber: String!
			$guest: String!
			$startingAt: DateTime!
		) {
			removeGuestReservation(
				orgId: $orgId
				serialNumber: $serialNumber
				guest: $guest
				startingAt: $startingAt
			) {
				__typename
			}
		}
	`;
	const [removeGuestReservation] = useMutation(removeGuestReservationMutation, {
		onCompleted: data => {
			refetchGuestReservationsData();
		},
	});

	const [selectedUsers, updateSelectedUsers] = useState<any[]>([]);
	const [showAddGuestReservationModal, setShowAddGuestReservationModal] = useState(false);
	//state for editing reservation. Should use same modal, but call a different resolver in backend replacing reservation.
	const [showEditGuestReservationModal, setShowEditGuestReservationModal] = useState(false);
	const [showDeleteGuestReservationModal, setShowDeleteGuestReservationModal] = useState(false);

	const deleteReservationRef = useRef({});
	const editReservationRef = useRef<any>(null);

	const formatData = useCallback(
		(data: any) => {
			return data.map((d: any) => {
				return d;
			});
		},
		[intl]
	);

	const filter = (inputValue: any, path: any) => {
		return path.some(
			(option: any) =>
				option.label.toLowerCase().indexOf(inputValue ? inputValue.toLowerCase() : '') > -1
		);
	};

	const CascaderOrgOption = organization.organizationStructure.map((option: any) => ({
		...option,
		value: option.id,
		label: option.label,
		children: Object.values(option?.children || {}).map((child: any) => ({
			...child,
			value: child.id,
			label: child.label,
			children: Object.values(child?.children || {}).map((nextChild: any) => ({
				...nextChild,
				value: nextChild.id,
				label: nextChild.label,
				children: Object.values(nextChild?.children || {}).map((endChild: any) => ({
					...endChild,
					value: endChild.id,
					label: endChild.label,
					children: [], // Cascader is looking for children as an array not object. So, last mile should be an empty array.
				})),
			})),
		})),
	}));

	const resendCalendarInvitation = (guestReservation: any) => {
		publish(
			`microservice/${guestReservation?.orgId}/${b64EncodeUnicode(loggedInUser)}/inviteUser`,
			{
				requestId: 'someId',
				data: {
					firstName: guestReservation?.guestName,
					lastName: '',
					username: guestReservation?.guest,
					orgId: guestReservation?.orgId,
					spinoutType,
					domainUrl: window.location.hostname,
					invitationType: 'calendarInvitation',
					guestReservation: {
						host: guestReservation?.host,
						hostName: guestReservation?.hostName,
						subject: guestReservation?.subject,
						startTime: guestReservation?.startingAt,
						endTime: guestReservation?.endingAt,
					},
				},
			}
		);
	};
	return (
		<Fragment>
			<MainContent
				// title="Reservations"
				headerChildren={
					<>
						<div>
							<Cascader
								size="large"
								options={CascaderOrgOption}
								showSearch={{ filter }}
								changeOnSelect
								expandTrigger="hover"
								allowClear={false}
								defaultValue={[selectedOrgName]}
								value={selectedOrganizationPath}
								onChange={onOrganizationChange}
								displayRender={(label: any) => {
									return label.join('/');
								}}
								data-cy="change_organization_select"
							/>
						</div>
					</>
				}
				renderContent={history => {
					return (
						<div className={classes.main}>
							<p className={classes.reservationTitle}>Reservations</p>
							<Box mt={2} mb={4}>
								<StyledNewGuestReservationButton
									data-cy="guest_reservations_add_reservation_button"
									onClick={() => {
										setShowAddGuestReservationModal(true);
									}}
								>
									New reservation
								</StyledNewGuestReservationButton>
							</Box>

							<GuestReservationsGrid
								data={guestReservations}
								getGuestReservationsLoading={getGuestReservationsLoading}
								selectUser={false}
								selectedUsers={selectedUsers}
								moreContent={(item: any, onClosePopover: any) => {
									return (
										<>
											<StyledMenuItem
												onClick={() => {
													editReservationRef.current = {
														orgId: item.orgId,
														serialNumber: item.serialNumber,
														startingAt: item.startingAt,
														endingAt: item.endingAt,
														subject: item.subject,
														guest: item.guest,
														guestName: item.guestName,
														host: item.host,
														hostName: item.hostName,
													} as any;
													setShowEditGuestReservationModal(true);
													onClosePopover();
												}}
												data-cy={
													item?.serialNumber +
													'_' +
													item?.guest +
													'_reservation_popover_menu_edit'
												}
											>
												<FormattedMessage {...Messages.edit} />
											</StyledMenuItem>
											<StyledMenuItem
												onClick={() => {
													resendCalendarInvitation(item);
													onClosePopover();
												}}
												data-cy={
													item?.serialNumber +
													'_' +
													item?.guest +
													'_reservation_popover_menu_resend'
												}
											>
												<FormattedMessage {...Messages.resend} />
											</StyledMenuItem>
											<StyledMenuItem
												onClick={() => {
													deleteReservationRef.current = {
														orgId: item.orgId,
														serialNumber: item.serialNumber,
														guest: item.guest,
														startingAt: item.startingAt,
													};
													setShowDeleteGuestReservationModal(true);
													onClosePopover();
												}}
												data-cy={
													item?.serialNumber +
													'_' +
													item?.guest +
													'_reservation_popover_menu_delete'
												}
											>
												<FormattedMessage {...Messages.delete} />
											</StyledMenuItem>
										</>
									);
								}}
								history={history}
							/>
							<AddGuestReservationModal
								isOpen={
									showAddGuestReservationModal || showEditGuestReservationModal
								}
								isEdit={showEditGuestReservationModal}
								onDismiss={() => {
									editReservationRef.current = null;
									setShowAddGuestReservationModal(false);
									setShowEditGuestReservationModal(false);
								}}
								refetchGuestReservationsData={refetchGuestReservationsData}
								originalReservationProps={editReservationRef.current}
							/>
							<DeleteModal
								isOpen={showDeleteGuestReservationModal}
								title={<FormattedMessage {...Messages.deleteTitle} />}
								onConfirm={() => {
									removeGuestReservation({
										variables: { ...deleteReservationRef.current },
									});
									deleteReservationRef.current = {};
									setShowDeleteGuestReservationModal(false);
								}}
								onDismiss={() => {
									deleteReservationRef.current = {};
									setShowDeleteGuestReservationModal(false);
								}}
								itemName="Reservation"
							/>
						</div>
					);
				}}
			/>
		</Fragment>
	);
};

const mapStateToProps = (state: any) => ({
	selectedOrganizationId: state.selectedOrganizationState.organization.orgId,
	selectedOrganizationName: state.selectedOrganizationState.organization.name,
	organization: state.organizationState,
	// userGroups: state.userGroupsState,
});

const enhance = compose(connect(mapStateToProps, { setParameter }));

export default injectIntl(isAuthenticated(enhance(GuestReservations), 'GuestReservations'));
