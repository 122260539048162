import React, { FC, useState, useEffect, Fragment, useCallback } from 'react';
import { IonGrid, IonRow, IonCol, IonIcon, IonCheckbox, IonProgressBar, IonLabel } from '@ionic/react';

import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';

import classes from './ProgressBar.module.css';

interface ProgressBarProps {
	leftText: string;
    rightText: string;
    value: number;
    maxValue: number;
}

const ProgressBar: FC<ProgressBarProps> = (props: any) => {
	const {
		leftText,
        rightText,
        value,
        maxValue,
	} = props;

	const [normalizedProgress, setNormalizedProgress] = useState(0.0);

	useEffect(() => {
        setNormalizedProgress(value/maxValue);
	}, [value, maxValue]);

	return (
		<Fragment>
			<IonGrid className={classes.progressBarGrid}>
				<IonRow>
                    <IonCol sizeSm="6" >
                        <IonLabel>{leftText}</IonLabel>
                    </IonCol>
                    <IonCol sizeSm="6" className={classes.alignRight}>
                        <IonLabel>{rightText}</IonLabel>
                    </IonCol>
				</IonRow>
                <IonRow>
                    <IonProgressBar color="primary" value={normalizedProgress}></IonProgressBar>
				</IonRow>
			</IonGrid>
		</Fragment>
	);
};

export default connect(null, { setParameter })(ProgressBar);
