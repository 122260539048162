export const getSessionTimeDescription = (startTime: Date | undefined, endTime: Date) => {
	if (!startTime) return 'never';

	const seconds = (endTime.getTime() - startTime.getTime()) / 1000;
	const y = Math.floor(seconds / (3600 * 24 * 365));
	const M = Math.floor(seconds / (3600 * 24 * (365 / 12)));
	const w = Math.floor(seconds / (3600 * 24 * 7));
	const d = Math.floor(seconds / (3600 * 24));
	const h = Math.floor((seconds % (3600 * 24)) / 3600);
	const m = Math.floor((seconds % 3600) / 60);
	const yDisplay = y > 0 ? y + (y === 1 ? ' year, ' : ' years, ') : '';
	const MDisplay = M > 0 ? M + (M === 1 ? ' month, ' : ' months, ') : '';
	const wDisplay = w > 0 ? w + (w === 1 ? ' week, ' : ' weeks, ') : '';
	const dDisplay = d > 0 ? d + (d === 1 ? ' day, ' : ' days, ') : '';
	const hDisplay = h > 0 ? h + (h === 1 ? ' hour, ' : ' hours, ') : '';
	const mDisplay = m > 0 ? m + (m === 1 ? ' minute ' : ' minutes ') : '';
	const sDisplay = m === 0 ? 'Less than a minute' : '';
	console.log(y);
	if (y >= 1) return `${y} ${yDisplay} ago`;
	else if (M >= 1) return `${M} ${MDisplay} ago`;
	else if (w >= 1) return `${w} ${wDisplay} ago`;
	else if (d >= 1) return `${d} ${dDisplay} ago`;
	else if (h >= 1) return `${h} ${hDisplay} ago`;
	else if (m >= 1) return `${m} ${mDisplay} ago`;
	else return `${sDisplay} ago`;
};
