import { MqttRouter } from 'mqtt-sub-router';
import { store } from '../store/store';

const DevicesRouter = new MqttRouter()
	.listen('update', ({ data, params, topic, originalTopic, mqttClient }) => {
		const deviceId = originalTopic.split('/')[0];
		const state = store.getState().deviceState;
		const userRole = store.getState().accountState.user.roles;
		let organizationId;

		if (!data) return;
		const index = state.items.findIndex(d => d.deviceId === deviceId);

		if (index > -1) organizationId = state.items[index].orgId;

		const payload = { deviceId, props: data, organizationId, userRole };
		if (shouldUpdateDeviceState(payload, state)) {
			store.dispatch({
				type: `UPDATE_DEVICE_PROPS`,
				payload: payload,
			});
		}
	})
	.listen('online', ({ data, params, topic, originalTopic, mqttClient }) => {
		const deviceId = originalTopic.split('/')[0];
		const state = store.getState().deviceState;
		const userRole = store.getState().accountState.user.roles;
		let organizationId;

		if (!data) return;
		const index = state.items.findIndex(d => d.deviceId === deviceId);

		if (index > -1) organizationId = state.items[index].orgId;

		const payload = { deviceId, props: data, organizationId, userRole };
		if (shouldUpdateDeviceState(payload, state)) {
			store.dispatch({
				type: `UPDATE_DEVICE_PROPS`,
				payload: payload,
			});
		}
	});

const shouldUpdateDeviceState = (payload, state) => {
	if (!payload.userRole) return state;
	const props = payload.props;
	const deviceId = payload.deviceId;
	const position = state.items.findIndex(d => d.deviceId === deviceId);
	const device = state.items[position];

	const orgId = payload.organizationId || device?.orgId;

	if (!orgId) return state;
	const deviceInOrgId = state.devicesByOrganizationId[orgId][deviceId];
	let isUpdated = false;
	const deviceInAccountState = store.getState().accountState.user.devices[deviceId];
	for (const key in props) {
		if (key === 'currentState' && props[key] === 'available') {
			device.online = true;
			deviceInAccountState.online = true;
			deviceInOrgId.online = true;
		}

		if (key === 'battery') {
			if (
				!(
					device.battery &&
					device.battery.isCharging === props.battery.isCharging &&
					device.battery.available === props.battery.available &&
					deviceInOrgId.battery &&
					deviceInOrgId.battery.isCharging === props.battery.isCharging &&
					deviceInOrgId.battery.available === props.battery.available &&
					deviceInAccountState.battery &&
					deviceInAccountState.battery.isCharging === props.battery.isCharging &&
					deviceInAccountState.battery.available === props.battery.available
				)
			) {
				isUpdated = true;
			}
		} else if (key === 'network') {
			if (
				!(
					device.network &&
					device.network.ssid === props.network.ssid &&
					device.network.quality === props.network.quality &&
					deviceInOrgId.network &&
					deviceInOrgId.network.ssid === props.network.ssid &&
					deviceInOrgId.network.quality === props.network.quality &&
					deviceInAccountState.network &&
					deviceInAccountState.network.ssid === props.network.ssid &&
					deviceInAccountState.network.quality === props.network.quality
				)
			) {
				isUpdated = true;
			}
		} else if (
			device[key] !== props[key] ||
			deviceInOrgId[key] !== props[key] ||
			deviceInAccountState[key] !== props[key]
		) {
			isUpdated = true;
		}
	}
	return isUpdated;
};
export default DevicesRouter;
