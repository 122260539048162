import { defineMessages } from 'react-intl';

export default defineMessages({
	admin: {
		id: 'admin',
		defaultMessage: 'Admin',
		description: 'Admin user',
	},
	edit: {
		id: 'Users.edit',
		defaultMessage: 'Edit user',
		description: 'Edit user',
	},
	addGroup: {
		id: 'User.addGroup',
		defaultMessage: 'Add to user group',
		description: 'Add to user group',
	},
	addToGroupsHint: {
		id: 'User.addToGroupsHint',
		defaultMessage: 'Please choose groups to add to {user}',
		description: 'Please choose groups for the selected user',
	},
	addUsersToGroups: {
		id: 'User.addUsersToGroups',
		defaultMessage: '{number} Users have been selected:',
		description: '{number} Users have been selected:',
	},
	addToUserGroup: {
		id: 'User.addToUserGroup',
		defaultMessage: 'Add to user group',
		description: 'Add to user group',
	},
	availableGroups: {
		id: 'Users.availableGroups',
		defaultMessage: 'Available groups',
		description: 'Available groups',
	},
	selectedGroups: {
		id: 'Users.selectedGroups',
		defaultMessage: 'Selected groups',
		description: 'Selected groups',
	},
	groupsSelected: {
		id: 'Users.groupsSelected',
		defaultMessage:
			'{count, number} {count, plural, one {user group} other {user groups}} has been selected',
		description: 'Number of selected groups',
	},
	noSelection: {
		id: 'Users.noSelection',
		defaultMessage: 'No groups selected',
		description: 'No groups selected',
	},
	invited: {
		id: 'InviteModal.invited',
		defaultMessage: '{name} has been invited',
		description: 'A new user has been invited',
	},
	selectUsers: {
		id: 'InviteModal.selectUsers',
		defaultMessage: 'Select Users',
		description: 'Select Users',
	},
});
