import React, { FC } from 'react';
import classes from './Robots.module.css';

import Battery from '../Battery/Battery';
import BatteryDetails from '../BatteryDetails/BatteryDetails';
import batteryLevelConverter from '../../utils/batteryLevelConverter';
import { useTypedSelector } from '../../reducers';
import { getStateEntityByOrgId, equalityFnc } from '../../utils/conformState';

interface RobotNameProps {
	level?: any;
	battery?: any;
	online?: boolean;
}

const RobotBattLevel: FC<RobotNameProps> = props => {
	const { level, battery, online } = props;
	if (battery) {
		return (
			<div className={classes.battTextContainer}>
				<div
					className={
						online
							? classes.batteryRoster
							: (classes.batteryRoster, classes.batteryRosterOff)
					}
				>
					<Battery
						batteryPercent={battery && battery.level != null ? battery.level : null}
						online={online || false}
						charging={
							battery && battery.isCharging
								? battery.isCharging
								: battery.charging
								? battery.charging
								: false
						}
					/>
					<div className={classes.batteryDetailsWrapper}>
						<BatteryDetails
							batteryPercent={battery && battery.level != null ? battery.level : null}
							charging={
								battery && battery.isCharging
									? battery.isCharging
									: battery.charging
									? battery.charging
									: false
							}
						/>
					</div>
				</div>
				<div className={online ? classes.batteryText : classes.batteryTextGrey}>
					{battery && battery?.level}%
				</div>
			</div>
		);
	} else {
		return <div className={classes.chargeContainer} />;
	}
};

export default RobotBattLevel;
