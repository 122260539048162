import React, { FC, ReactNode, Fragment, useEffect, useState } from 'react';
import { IonLabel, IonIcon, IonSegment, IonSegmentButton } from '@ionic/react';
import { FormattedMessage } from 'react-intl';
import { checkmarkOutline } from 'ionicons/icons';
import classes from './Tabs.module.css';
import classNames from 'classnames';
interface TabsProps {
	data: {
		title: { id: string; defaultMessage: string };
		path: string;
		icon?: string;
		disabled?: boolean;
		dataCy?: string;
	}[];
	onChange?: any;
	onClick?: any;
	currentTab?: string | null | undefined;
	content?: ReactNode;
	tabBtnClass?: any;
	numberOfCurrentTab?: any;
	page?: any;
}

const Tabs: FC<TabsProps> = ({
	data,
	onChange,
	onClick,
	currentTab,
	content,
	tabBtnClass,
	numberOfCurrentTab,
	page,
}) => {
	const [tabs, setTabs] = useState<any>(null);

	useEffect(() => {
		setTabs(
			data.map(({ title, path, icon, disabled, dataCy }, index) => {
				return (
					<IonSegmentButton
						id={`fm-tabs-${path}`}
						type="button"
						value={path}
						key={index}
						layout="icon-start"
						className={tabBtnClass ? tabBtnClass : classes.tabBtn}
						disabled={disabled}
						data-cy={dataCy}
					>
						{!page &&
							(icon ? (
								<IonIcon className={classes.tabIcon} src={icon} size="medium" />
							) : null)}

						{!page && (
							<IonLabel className={classes.tabTitle}>
								<FormattedMessage {...title} />
							</IonLabel>
						)}

						{page && numberOfCurrentTab > index && (
							<IonIcon
								className={classes.tabIconOrganizations}
								src={checkmarkOutline}
								size="medium"
							/>
						)}

						{page && index === numberOfCurrentTab && (
							<IonLabel className={classes.selectedItem}>
								<FormattedMessage {...title} />
							</IonLabel>
						)}

						{page && index !== numberOfCurrentTab && (
							<IonLabel
								className={classNames(
									index < numberOfCurrentTab
										? classes.passedItem
										: classes.tabTitleOrganizations
								)}
							>
								<FormattedMessage {...title} />
							</IonLabel>
						)}
					</IonSegmentButton>
				);
			})
		);
	}, [data, numberOfCurrentTab, page, tabBtnClass]);

	const onTabChecked = () => {
		let currentInd = data.findIndex((tab: any) => tab.path === currentTab);
		if (currentInd > 0) {
			data[currentInd].disabled = false;
		}
	};

	return (
		<Fragment>
			<IonSegment
				mode="ios"
				onIonChange={e => {
					onTabChecked();
					if (onChange) onChange(e.detail.value);
				}}
				onClick={(e: any) => {
					onTabChecked();
					if (onClick) {
						onClick(e.target.value);
					}
				}}
				value={currentTab}
				className={classes.tabContainer}
			>
				{tabs}
			</IonSegment>
			<div className={classes.tabContent}>{content}</div>
		</Fragment>
	);
};

export default Tabs;
