import React from 'react';

interface FMBrandedSplitPaneImageProps {
	url: string;
}

export const FMBrandedSplitPaneImage: React.FC<FMBrandedSplitPaneImageProps> = ({ url }) => {
	const style = {
		width: '100%',
		height: '100%',
		backgroundSize: 'cover',
		backgroundImage: `url(${url})`,
	};
	return <div style={style} />;
};
