import { AppRootState } from '../../../../reducers';

export const deviceStateConverter = (state: AppRootState) => {
	let newItems: any = [];
	if (!state.accountState.user.devices) {
		return newItems;
	} else {
		Object.values(state.accountState.user.devices).map((item: any) => {
			newItems.push({ deviceId: item.deviceId, orgId: item.orgId });
		});
		return newItems;
	}
};

export const equalityFnc = (prevState: any, nextState: any, shouldUpdate = true) => {
	if (!shouldUpdate) return true;
	return JSON.stringify(prevState) === JSON.stringify(nextState);
};

export const itemConverter = (state: any, deviceId: any) => {
	return state.deviceState.items[
		state.deviceState.items.findIndex((deviceItem: any) => deviceItem.deviceId === deviceId)
	];
};
