import { SessionInfo } from '../useSessionInitiator';

export const SIGNALING_CLIENT_KEEPALIVE_INTERVAL = 5000;

export type ICEPayload = { candidate: string | null; sdpMLineIndex: number };
export type SDPPayload = { id: string } & Omit<RTCSessionDescription, 'toJSON'>;

export interface ISignalingClient {
	readonly sessionInfo: SessionInfo;

	/** Callback for when sdp is received from peer from peer
	 * @param {string} id - Identifier (from the sender's perspective) for this sdp.
	 * 	When replying back to this SDP, the same identifier must be used
	 */
	onRemoteSDP: ((data: SDPPayload) => void) | null;

	/** Callback for when ice candidate is received from peer  */
	onRemoteICECandidate: ((payload: ICEPayload) => void) | null;

	/** Callback for when peer notifies of hanging up */
	onRemoteHangUp: (() => void) | null;

	/** Callback for when the remote peer stops sending us keepalive events */
	onKeepaliveTimeout: (() => void) | null;

	/** Callback for when this instance reconnects to the underlying messaging client */
	onReconnect: (() => void) | null;

	/** Callback for when the remote peer is ending but, will retry in a bit */
	onRemoteWillRetry: (() => void) | null;

	/** Callback for when the remote peer is ready for a session retry */
	onRemoteReadyToRetry: (() => void) | null;

	/**
	 * Ensure that the signaling client is ready for signaling,
	 * and notify the remote peer that we are ready for the initial SDP offer
	 */
	readonly start: () => Promise<void>;

	readonly sendSDPToPeer: (data: SDPPayload) => Promise<void>;

	readonly sendICECandidateToPeer: (data: ICEPayload) => Promise<void>;

	/**
	 * Prompt the remote peer to trigger an ICE restart sequence.
	 * The remote peer is then expected to send an new offer, in response to this prompt.
	 */
	readonly sendICERestartRequest: () => Promise<void>;

	readonly sendHangup: () => Promise<void>;

	/** Send to the remote peer that we are ready to retry session */
	readonly sendReadyForRetry: () => Promise<void>;

	readonly close: () => Promise<void>;
}
