import React from 'react';
import { FormattedMessage, FormattedDate, FormattedTime } from 'react-intl';
import ListMessages from '../components/ListGrid/ListGrid.messages';

export const formatTimeAgo = (date: Date, now: number) => {
	const diffSeconds = date.getTime() - now;
	const time = Math.abs(Math.round(diffSeconds / 1000));

	if (time < 60) {
		return (
			<FormattedMessage
				{...ListMessages.timeAgo.timeSeconds}
				values={{ number: Math.max(time, 0) }}
			/>
		);
	} else if (time < 3600) {
		return (
			<FormattedMessage
				{...ListMessages.timeAgo.timeMinutes}
				values={{ number: Math.floor(time / 60) }}
			/>
		);
	} else if (time < 86400) {
		return (
			<FormattedMessage
				{...ListMessages.timeAgo.timeHours}
				values={{ number: Math.floor(time / 3600) }}
			/>
		);
	} else return <FormattedDate value={date} year="numeric" month="2-digit" day="2-digit" />;
};

export const mmmDDYYYYFormat = (timestamp: number) => {
	const date = new Date(timestamp);

	return <FormattedDate value={date} year="numeric" month="short" day="2-digit" />;
};

export const mmddYYYYFormat = (timestamp: number) => {
	return <FormattedDate value={new Date(timestamp)} />;
};

export const timeFormat = (timestamp: number) => {
	return <FormattedTime value={new Date(timestamp)} />;
};

export const getDifferenceInDays = (date: Date) => {
	var today = new Date().getTime();
	var diffInMs = Math.abs(today - date.getTime());
	return Math.floor(diffInMs / (1000 * 60 * 60 * 24));
};
