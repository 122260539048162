/* eslint-disable camelcase */
import React, { useEffect, useState, useCallback, useLayoutEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import LocalVideo from './videos/localVideo';
import RemotePrimaryCamVideo from './videos/remoteVideo';
import './index.scss';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';
import { SET_DATA_CHANNEL, SET_FULL_SCREEN_STATUS, SET_SESSION_INFO } from '../../actions/types';
import {
	SET_IS_EXPANDED,
	SET_SHOW_ABSOLUTE_MENU,
	SET_SHOW_MENU,
} from '../../../../../actions/types';
import PauseOrEndSessionOverlay from './overlays/sessionEndPause';
import { ConnectedProps } from 'react-redux';
import { AppRootState } from '../../../../../reducers';
import { openFullscreen, closeFullScreen } from '../../utils/fullScreen';
import { useIonViewDidEnter } from '@ionic/react';
import RobotUnavailableOverlay from './overlays/failedInitPeerConnection';
import SessionNetworkFailureOverlay from './overlays/failedSessionPeerConnection';
import useCallerPeerConnection from './peerConnection/useCallerPeerConnection';
import { publish } from 'redux-mqtt';
import { PeerConnectionEndReasonCode } from './peerConnection/useCallerPeerConnection/peerConnection';
import ImpairedDrivingIndicator from './indicators/drivingImpairment';
import useNavController from './navigation/useNavController';
import KeyboardNavInput from './navigation/keyboard';
import MediaAccessDeniedOverlay from './overlays/mediaDevicesAccessDenied';
import AutoDockingInput from './navigation/autoDocking';
import NavViewWithSessionOptions from './NavViewWithSessionOptions';
import { IActiveNavInput, RtpReceiverID } from '../../types';
import ActiveNavigationInputIndicator from './indicators/activeNavigationInput';
import useSessionOverlay from './overlays/useSessionOverlay';
import RobotName from '../../components/robotName';
import useSignalingClient from './peerConnection/signaling';

const NO_REMOTE_VIDEO_TIMEOUT = 60 * 1000;

const reduxConnector = connect(
	(state: AppRootState) => ({
		sessionInfo: state.goBeState.sessionState.sessionInfo!,
		// TODO: NEW-TAB-PROJECT
		// Read sessionInfo from url params.
		// Perhaps, create a wrapper component that handles this reading-of-sessionInfo,
		// 		and pass it on as props to Session component.

		navSpeed: state.goBeState.sessionState.navSpeed,
		showAbsoluteMenu: state.menuState.showAbsoluteMenu,
		isMenuExpanded: state.menuState.isExpanded,
		localVoiceVolume: state.goBeState.sessionState.localVoiceVolume,
		isDataFetchLoaderShowing: state.fetchDataState.showLoader,
		drivingMode: state.goBeState.sessionState.drivingMode,
	}),
	{ setParameter, publish }
);

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;

const Session: React.FC<PropsFromRedux> = ({
	navSpeed,
	setParameter,
	showAbsoluteMenu,
	isMenuExpanded,
	localVoiceVolume,
	isDataFetchLoaderShowing,
	drivingMode,
	sessionInfo,
}) => {
	const history = useHistory();
	const navigateToRosterPage = useCallback(() => {
		history.replace('/gobe');
		setParameter('sessionInfo', SET_SESSION_INFO, null);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history]);

	// Starting : Navigation Input management logic
	const [currNavInput, setCurrNavInput] = useState<IActiveNavInput | null>(null);
	const buildOnNavInputFocusChanged = (
		forNavInput: IActiveNavInput,
		setState: (callback: (curr: IActiveNavInput | null) => IActiveNavInput | null) => void
	) => {
		return (isNavInputFocused: boolean) => {
			setState(currState => {
				if (isNavInputFocused) return forNavInput;
				else {
					// If the nav-input identified by `forNavInput` is no longer focused,
					// 	 then we clear/reset state only if it was the one previously focused
					if (currState === forNavInput) return null;
					return currState;
				}
			});
		};
	};
	const onKeyboardNavInputFocusChanged = buildOnNavInputFocusChanged('keyboard', setCurrNavInput);
	const onJoystickActivationChanged = buildOnNavInputFocusChanged('joystick', setCurrNavInput);
	const onAutoDockingActiveChanged = buildOnNavInputFocusChanged('auto-docking', setCurrNavInput);

	// End : Navigation Input management logic

	const [hasPrimaryVideoStartedPlaying, setHasPrimaryVideoStartedPlaying] = useState(false);

	const { currentOverlay, showOverlay, hideOverlay } = useSessionOverlay();

	const [navDataChannel, setNavDataChannel] = useState<RTCDataChannel | undefined>();

	const onDataChannel = useCallback(
		(dataChannel: RTCDataChannel) => {
			if (dataChannel.label === 'nav-datachannel') {
				(window as any).datachannel = dataChannel;
				setNavDataChannel(dataChannel);
			} else {
				console.log('OTHER-DATACHANNEL created');
				setParameter('dataChannel1', SET_DATA_CHANNEL, dataChannel);
				dataChannel.send(`VOL ${localVoiceVolume}`);
			}
		},
		[localVoiceVolume, setParameter]
	);

	const [localStream, setLocalMediaStream] = useState<MediaStream | null>(null);

	const onPeerConnectionStarted = useCallback(() => {
		// TODO: Add some logic for this...
		console.debug('session.Component PeerConnection started');
	}, []);

	const onPeerConnectionEnded = useCallback(
		(reason: PeerConnectionEndReasonCode) => {
			function assertUnreachable(x: never): never {
				throw new Error("Didn't expect to get here");
			}

			// todo: Show a different overlays for the retry failure cases

			switch (reason) {
				case 'LOCAL_HANGUP':
				case 'PAUSED_STATE_TIMED_OUT':
					navigateToRosterPage();
					return;
				case 'PEER_HANGUP': // yeah, our peer is a robot! - this might be an error
				case 'FAILED_STATE_TIMED_OUT':
				case 'RETRY_FAILED':
				case 'RETRY_TIMEOUT':
				case 'ERROR':
					showOverlay('SessionNetworkFailure');
					return;
				case 'CLEANUP':
					return;
				default:
					// this is a type-safe way to ensure that all cases
					//  	of the PeerConnectionEndReasonCode are handled in this switch
					return assertUnreachable(reason);
			}
		},
		[navigateToRosterPage, showOverlay]
	);

	const signalingClient = useSignalingClient(sessionInfo);

	// setup the controls for the peer connection
	const {
		endPeerConnection,
		startPeerConnection,
		pausePeerConnection,
		unpausePeerConnection,
		togglePrimaryCamera,
		primaryCameraState,
		isPeerConnectionPaused: isSessionPaused,
		primaryMediaStream,
		primaryRTPTransceiver,
		navMediaStream,
		navRTPTransceiver,
	} = useCallerPeerConnection({
		signalingClient,
		onDataChannel,
		onStarted: onPeerConnectionStarted,
		onEnded: onPeerConnectionEnded,
	});

	// Now start the peer connection
	useEffect(() => {
		if (!localStream) return;

		startPeerConnection(localStream);
	}, [startPeerConnection, localStream]);

	/** True if the user can see at least a frame of the video,
	 * and the video is not obscured by any fullscreen-overlays */
	const isVideoVisible = hasPrimaryVideoStartedPlaying && currentOverlay === null;

	const videoRtpReceivers = useMemo(() => {
		return {
			primaryCam: primaryRTPTransceiver?.receiver,
			navCam: navRTPTransceiver?.receiver,
		} as Partial<Record<RtpReceiverID, RTCRtpReceiver>>;
	}, [navRTPTransceiver, primaryRTPTransceiver]);
	const { navController, isNavigationInProgress, isDrivingImpaired } = useNavController({
		speed: navSpeed,
		isPeerConnectionPaused: isSessionPaused,
		rtpReceivers: videoRtpReceivers,
		datachannel: navDataChannel,
		isVideoVisible,
	});

	useLayoutEffect(() => {
		let link = document.getElementById('jsd-widget');
		if (link && (link as any).style.display !== 'none') {
			(link as any).style.display = 'none';
		}
	}, []);

	useIonViewDidEnter(() => {
		window.addEventListener('hashchange', () => {
			if (window.location.pathname !== '/gobe/session') {
				closeFullScreen();
			}
		});
	});

	const onClickUnpauseSession = () => {
		unpausePeerConnection();
		hideOverlay();
	};

	// Starting : Fullscreen status management logic
	useEffect(() => {
		// add the fullscreen event handler
		const fullScreenChangeHandler = () => {
			if (document.fullscreenElement) {
				setParameter('showMenu', SET_SHOW_MENU, false);
				setParameter('showAbsoluteMenu', SET_SHOW_ABSOLUTE_MENU, false);
				setParameter('isExpanded', SET_IS_EXPANDED, false);
				setParameter('fullScreenStatus', SET_FULL_SCREEN_STATUS, true);
			} else {
				setParameter('showMenu', SET_SHOW_MENU, false);
				setParameter('showAbsoluteMenu', SET_SHOW_ABSOLUTE_MENU, true);
				setParameter('isExpanded', SET_IS_EXPANDED, false);
				setParameter('fullScreenStatus', SET_FULL_SCREEN_STATUS, false);
			}
		};
		document.addEventListener('fullscreenchange', fullScreenChangeHandler);
		// open the fullscreen mode and hide the menu
		openFullscreen();
		setParameter('showMenu', SET_SHOW_MENU, false);
		setParameter('showAbsoluteMenu', SET_SHOW_ABSOLUTE_MENU, false);

		return () => {
			// delete the fullscreen event handler
			document.removeEventListener('fullscreenchange', fullScreenChangeHandler);

			// close the fullscreen mode and show the menu
			closeFullScreen();
			setParameter('showMenu', SET_SHOW_MENU, true);
			setParameter('showAbsoluteMenu', SET_SHOW_ABSOLUTE_MENU, false);
			setParameter('isExpanded', SET_IS_EXPANDED, true);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// End : Fullscreen status management logic

	useLayoutEffect(() => {
		if (hasPrimaryVideoStartedPlaying) return;
		const primaryCamVideoTimeoutID = setTimeout(
			() => showOverlay('NoRemoteVideo'),
			NO_REMOTE_VIDEO_TIMEOUT
		);
		return () => clearTimeout(primaryCamVideoTimeoutID);
	}, [hasPrimaryVideoStartedPlaying, showOverlay]);
	const onPrimaryVideoPlaybackBegan = () => setHasPrimaryVideoStartedPlaying(true);

	const isKeyboardNavInputEnabled =
		currentOverlay === null &&
		!isDataFetchLoaderShowing &&
		(currNavInput === 'keyboard' || currNavInput == null) &&
		primaryCameraState.currentPrimaryCamera === 'wide_cam';

	// IMPORTANT : we disable the right click to avoid the ghost movements when the user use the joystick and click the right button
	document.addEventListener('contextmenu', event => {
		event.preventDefault();
	});

	const isJoystickControlSupportedByRobot = !!sessionInfo.capabilities
		?.mouse_control_with_joystick;

	return (
		<div
			className="Session"
			id="Session"
			data-session-id={signalingClient.sessionInfo.uuid}
			// FIXME: We must remove any sensitive data from sessionInfo, before adding it as a data-attribute
			// data-session-info={signalingClient.sessionInfo}
		>
			<PauseOrEndSessionOverlay
				isVisible={currentOverlay === 'EndOrPauseSessionConfirmation'}
				isSessionPaused={isSessionPaused}
				onClickResumeSession={onClickUnpauseSession}
				onClickEndSession={endPeerConnection}
				onClickPauseSession={pausePeerConnection} // TODO: Prevent pausing until peer connection is actually started!!
				onClickCancel={hideOverlay}
			/>

			<RobotUnavailableOverlay
				isVisible={
					currentOverlay === 'UnavailableRobot' || currentOverlay === 'NoRemoteVideo'
				}
				onClickBackToRoster={navigateToRosterPage}
				onClickTryAgain={navigateToRosterPage} // TODO: Implement this, and allow user to call robot again
			/>
			<SessionNetworkFailureOverlay
				isVisible={currentOverlay === 'SessionNetworkFailure'}
				onClickBackToRoster={navigateToRosterPage}
				robotName={sessionInfo.robot.name}
			/>

			<MediaAccessDeniedOverlay
				isVisible={currentOverlay === 'MediaAccessDenied'}
				onClickBackToRoster={navigateToRosterPage}
				robotName={sessionInfo.robot.name}
			/>

			<LocalVideo
				startWideCameraStats={() => undefined}
				stopWideCameraStats={() => undefined}
				wideCameraStats=""
				isGreyedOut={false}
				isPaused={false}
				shouldShowLoadingIndicator={!hasPrimaryVideoStartedPlaying}
				onGotMediaStream={setLocalMediaStream}
				onMediaDevicesAccessError={() => showOverlay('MediaAccessDenied')}
			/>

			<AutoDockingInput
				onActivenessChanged={onAutoDockingActiveChanged}
				navDataChannel={navDataChannel}
				isPeerConnectionPaused={isSessionPaused}
				isVideoVisible={isVideoVisible}
			/>

			<KeyboardNavInput
				navController={navController}
				disabled={!isKeyboardNavInputEnabled}
				onFocusChanged={onKeyboardNavInputFocusChanged}
				// nonFocusableChild={sessionOptionsDivElement}
			>
				<RemotePrimaryCamVideo
					robotId={sessionInfo.robot.id}
					primaryCameraState={primaryCameraState}
					isGreyedOut={false}
					isPaused={false}
					onPlaybackBegan={onPrimaryVideoPlaybackBegan}
					mediaStream={primaryMediaStream}
				/>
				{isJoystickControlSupportedByRobot ? null : (
					/* If the robot does not support joystick control, we render the nav-view within the keyboard input.
					This makes it possible to the pilot to be able to control the robot with keyboard
					even when the mouse cursor is on the nav-view. */
					<NavViewWithSessionOptions
						// * SessionOptions props
						onClickHangUp={() => showOverlay('EndOrPauseSessionConfirmation')}
						togglePrimaryCamera={togglePrimaryCamera}
						primaryCameraState={primaryCameraState}
						isSuperZoom1Enabled={!!sessionInfo.capabilities?.super_zoom_1}
						localStream={localStream}
						hasPrimaryVideoStartedPlaying={hasPrimaryVideoStartedPlaying}
						// * NavigationVideo props
						isGreyedOut={false}
						isPaused={false}
						mediaStream={navMediaStream}
						navController={navController}
						handleJoystickEnabled={onJoystickActivationChanged}
						isJoystickMounted={false}
						// * extra props
						isNavigating={isNavigationInProgress}
						isDrivingAllowed={
							currNavInput === 'joystick' || currNavInput === 'keyboard'
						}
					/>
				)}
			</KeyboardNavInput>

			<RobotName
				isMenuExpanded={isMenuExpanded}
				isMenuAbsolute={showAbsoluteMenu}
				drivingMode={drivingMode}
				robotName={sessionInfo.robot.name}
			/>

			<ActiveNavigationInputIndicator
				activeNavInput={currNavInput}
				isMenuExpanded={isMenuExpanded}
				isMenuAbsolute={showAbsoluteMenu}
			/>

			<ImpairedDrivingIndicator
				isMenuExpanded={isMenuExpanded}
				isAbsoluteMenuVisible={showAbsoluteMenu}
				isDrivingImpaired={isDrivingImpaired}
			/>

			{isJoystickControlSupportedByRobot ? (
				/* If the robot does not support joystick control, we render the nav-view within the keyboard input.
					This makes it possible to the pilot to be able to control the robot with keyboard
					even when the mouse cursor is on the nav-view. */
				<NavViewWithSessionOptions
					// * SessionOptions props
					onClickHangUp={() => showOverlay('EndOrPauseSessionConfirmation')}
					togglePrimaryCamera={togglePrimaryCamera}
					primaryCameraState={primaryCameraState}
					isSuperZoom1Enabled={!!sessionInfo.capabilities?.super_zoom_1}
					localStream={localStream}
					hasPrimaryVideoStartedPlaying={hasPrimaryVideoStartedPlaying}
					// * NavigationVideo props
					isGreyedOut={false}
					isPaused={false}
					mediaStream={navMediaStream}
					navController={navController}
					handleJoystickEnabled={onJoystickActivationChanged}
					isJoystickMounted={
						primaryCameraState.currentPrimaryCamera === 'wide_cam' &&
						currNavInput !== 'auto-docking'
					}
					isDrivingAllowed={currNavInput === 'joystick' || currNavInput === 'keyboard'}
					// * extra props
					isNavigating={isNavigationInProgress}
				/>
			) : null}
		</div>
	);
};

export default React.memo(reduxConnector(Session));
