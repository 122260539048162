import React from 'react';
import styled from 'styled-components';
import { IonIcon, IonLabel } from '@ionic/react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@material-ui/core';

const Wrapper = styled.div`
	height: 37px;
	display: flex;
	flex-direction: row;
	width: fit-content;
	border: 2px solid #dddddd;
	border-radius: 100px;
	&:hover {
		.Button {
			display: none;
		}
	}
`;

type SegmentType = {
	disable?: any;
};
const Segment = styled.div<SegmentType>`
	height: 100%;
	border-right: 1px solid #dddddd;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px 16px;
	cursor: ${p => (p.disable ? 'not-allowed' : 'pointer')};
	&:last-child {
		border: none;
		border-top-right-radius: 100px;
		border-bottom-right-radius: 100px;
	}
	&:first-child {
		border-top-left-radius: 100px;
		border-bottom-left-radius: 100px;
	}
	&:hover {
		background-color: #eeeeee;
	}
`;

const StyledIcon = styled(IonIcon)`
	margin-right: 8px;
	color: ${(props: { selected: boolean; spinoutPrimaryColor: string; disable: string }) =>
		props.disable ? props.disable : props.selected ? props.spinoutPrimaryColor : 'auto'};
`;

const Label = styled(Typography)`
	font-size: 15px;
	color: ${(props: { selected: boolean; spinoutPrimaryColor: string; disable: string }) =>
		props.disable ? props.disable : props.selected ? props.spinoutPrimaryColor : 'auto'};
`;

type SegmentControllerProps = {
	selectedSegment: string;
	segments: any;
	onChange: any;
	spinoutPrimaryColor: string;
};

const SegmentController = (props: SegmentControllerProps) => {
	const selectedSegment = props.selectedSegment;
	const disableColor = '#afafa6';

	return (
		<Wrapper>
			{props.segments.map((segment: any) => {
				if (segment.hidden) return null;
				return (
					<Segment
						disable={segment.disable}
						onClick={() => !segment.disable && props.onChange(segment.id)}
					>
						<StyledIcon
							size="small"
							icon={segment.icon}
							selected={selectedSegment === segment.id}
							spinoutPrimaryColor={props.spinoutPrimaryColor}
							disable={segment.disable && disableColor}
						/>
						<Label
							selected={selectedSegment === segment.id}
							disable={segment.disable && disableColor}
							spinoutPrimaryColor={
								segment.disable ? disableColor : props.spinoutPrimaryColor
							}
						>
							<FormattedMessage id={segment.id} defaultMessage={segment.id} />
						</Label>
					</Segment>
				);
			})}
		</Wrapper>
	);
};

export { SegmentController };
