import React from 'react';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { store, persistor } from './store/store';
import { RecreateMqttClient } from './providers/mqtt';

// strip logs from production builds
// n.b.: production builds run on all deployed envs, e.g. nightly, staging, production
function noop() {}
if (process.env.NODE_ENV === 'production') {
	console.log = noop;
	console.warn = noop;
	console.error = noop;
}

RecreateMqttClient();

ReactDOM.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<App />
		</PersistGate>
	</Provider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
// serviceWorker.register();
