import React from 'react';
import { IonButton } from '@ionic/react';
import classes from './FMButtons.module.css';

interface FMButtonPrimaryProps {
	onClick: () => void;
}

export const FMButtonPrimary: React.FC<FMButtonPrimaryProps> = props => {
	return (
		<IonButton {...props} shape="round" size="large" className={classes.buttonPrimary}>
			{props.children}
		</IonButton>
	);
};
