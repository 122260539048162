import React, { FC } from 'react';
import Moment from 'moment';
import { TableDataCell } from './TableDataCell';

type Rowprops = {
	column: any;
	row: any;
};
const TableRowCell: FC<Rowprops> = (props: any) => {
	if (props.column.renderCell) {
		return props.column.renderCell(props.row);
	} else if (props.column.type) {
		return (
			<TableDataCell
				value={Moment(props.row[props.column.dataField]).format('DD/MM/YYYY') || ''}
			/>
		);
	} else {
		return <TableDataCell value={props.row[props.column.dataField]} />;
	}
};

export { TableRowCell };
