import _ from 'lodash';
import moment from 'moment';
import { useTypedSelector } from '../../../../reducers';
import { Device } from '../../../../types/types';

export type IDeviceCurrentState =
	| 'inSession'
	| 'available'
	| 'onHold'
	| 'configuring'
	| 'unavailable'
	| 'updating'
	| 'offline';

export type ReservationUIStates =
	| 'beforeReservationDay'
	| 'reservationDay'
	| 'reservationTime'
	| 'inSession'
	| 'reservationEnded';

export type Reservation = {
	status: ReservationUIStates | null;
	startDate: string;
	startDateOffsetInMs?: number;
	endDate: string;
	endDateOffsetInMs?: number;
	durationInMs?: number;
	isGuest: boolean;
};

export type GuestReservation = {
	startingAt: string;
	endingAt: string;
	guest: string;
	[key: string]: any;
};

export const getDeviceCurrentState = (
	device: Partial<Device>,
	isCalling?: boolean,
	accountState?: any
): {
	deviceStatus: IDeviceCurrentState;
	canCall: boolean;
	isPermanentDevice: boolean | undefined;
	othersNextReservation: any;
	myNextReservation: any;
	nextReservation: any;
	nextReservationInProgress: boolean;
} => {
	const robotStatus =
		device.currentState ?? (device.status && JSON.parse(device.status)?.currentState);
	const robotReadyToCall = !isCalling && device.online && robotStatus === 'available';

	const getDeviceStatus = () => {
		const stateFromDevice =
			device.currentState ?? (device.status && JSON.parse(device.status)?.currentState);

		if (!stateFromDevice) {
			return 'unavailable';
		} else if (device.online === false) {
			return 'offline';
		} else {
			return stateFromDevice;
		}
	};

	const getReservationStatus = (reservation: GuestReservation) => {
		let reservationStatus = null;

		const start = reservation.startingAt;
		const end = reservation.endingAt;
		const isGuest = accountState?.user?.username === reservation?.guest;

		const reservationInAction = moment().isBetween(start, end);
		const isReservationDate = moment().isSame(start, 'date');
		const hasReservationEnded = moment().isAfter(end, 'milliseconds');
		const isBeforeReservationDay = moment().isBefore(start, 'date');

		if (reservationInAction && !hasReservationEnded && robotStatus === 'inSession') {
			reservationStatus = 'inSession';
		} else if (isReservationDate && reservationInAction && robotStatus !== 'inSession') {
			reservationStatus = 'reservationTime';
		} else if (isReservationDate) {
			reservationStatus = 'reservationDay';
		} else if (hasReservationEnded) {
			reservationStatus = 'reservationEnded';
		} else if (isBeforeReservationDay) {
			reservationStatus = 'beforeReservationDay';
		} else {
			return null;
		}

		return {
			status: reservationStatus,
			isGuest,
			startDate: start,
			endDate: end,
		};
	};

	const reservations = device?.guestReservation
		?.map((item: GuestReservation) => getReservationStatus(item))
		.filter((item: Reservation) => !moment(item.startDate).isBefore(new Date(), 'day'));

	const myNextReservation: Reservation = _.chain(reservations)
		.filter((item: Reservation) => item.isGuest === true)
		.sortBy([
			function(o) {
				return o.startDate;
			},
		])
		.first()
		.value();

	const othersNextReservation: Reservation = _.chain(reservations)
		.filter((item: Reservation) => item.isGuest === false)
		.sortBy([
			function(o) {
				return o.startDate;
			},
		])
		.first()
		.value();

	const nextReservation = () => {
		if (!myNextReservation && othersNextReservation) {
			return moment().isSameOrAfter(
				moment(othersNextReservation?.startDate).subtract(7, 'days'),
				'day'
			)
				? othersNextReservation
				: null;
		} else if (!othersNextReservation && myNextReservation) {
			return myNextReservation;
		} else if (
			moment(myNextReservation?.startDate).isBefore(othersNextReservation?.startDate)
		) {
			return myNextReservation;
		} else if (moment(myNextReservation?.startDate).isAfter(othersNextReservation?.startDate)) {
			return moment().isSameOrAfter(
				moment(othersNextReservation?.startDate).subtract(7, 'days'),
				'day'
			)
				? othersNextReservation
				: null;
		} else {
			return null;
		}
	};

	return {
		deviceStatus: getDeviceStatus(),
		isPermanentDevice: device?.isPermanent,
		canCall: robotReadyToCall,
		myNextReservation,
		othersNextReservation,
		nextReservation: nextReservation(),
		nextReservationInProgress: nextReservation()
			? nextReservation()?.status === 'inSession'
			: false,
	};
};

export function useDeviceCurrentState(device: Partial<Device>, isCalling?: boolean) {
	const accountState = useTypedSelector(state => state.accountState);
	return getDeviceCurrentState(device, isCalling, accountState);
}

export const statusIconConverter = (usedStatus: any) => {
	switch (usedStatus) {
		case 'available':
			return 'available';
		case 'reserved':
			return 'grey-today.svg';
		case 'onHold':
			return 'grey-pause.svg';
		case 'configuring':
			return 'grey-config.svg';
		case 'inSession':
			return 'grey-play.svg';
		case 'unavailable':
			return 'grey-minimize.svg';
		case 'updating':
			return 'grey-cloud-download.svg';
	}
};

export const statusTextConverter = (usedStatus: any) => {
	switch (usedStatus) {
		case 'available':
			return 'Available';
		case 'reserved':
			return 'Reserved';
		case 'onHold':
			return 'On Hold';
		case 'configuring':
			return 'Configuring';
		case 'inSession':
			return 'In Session';
		case 'unavailable':
			return 'Unavailable';
		case 'updating':
			return 'Updating';
	}
};
