import React from 'react';

export const InSessionPilotIcon = () => {
	return (
		<svg
			width="53"
			height="57"
			viewBox="0 0 53 57"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M26.5109 8.29639C15.4971 8.29639 6.54395 17.3592 6.54395 28.5694C6.54395 39.7796 15.4971 48.8424 26.5109 48.8424C37.5248 48.8424 46.4779 39.7796 46.4779 28.5694C46.4779 17.3592 37.5248 8.29639 26.5109 8.29639ZM4.54395 28.5694C4.54395 16.2821 14.3653 6.29639 26.5109 6.29639C38.6566 6.29639 48.4779 16.2821 48.4779 28.5694C48.4779 40.8567 38.6566 50.8424 26.5109 50.8424C14.3653 50.8424 4.54395 40.8567 4.54395 28.5694Z"
				fill="#AAAAAA"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M37.6468 0L39.7325 0.928774L37.76 5.35848C46.3938 9.54361 52.347 18.3918 52.347 28.6304C52.347 42.9053 40.7749 54.4774 26.5 54.4774C22.9939 54.4774 19.6509 53.7794 16.6024 52.5146L14.6852 56.8201L12.5994 55.8913L14.5341 51.5468C6.28336 47.2298 0.652954 38.5876 0.652954 28.6304C0.652954 14.3555 12.225 2.78343 26.5 2.78343C29.7266 2.78343 32.8151 3.37467 35.6631 4.45472L37.6468 0ZM26.5 4.78343C13.3296 4.78343 2.65295 15.4601 2.65295 28.6304C2.65295 41.8008 13.3296 52.4774 26.5 52.4774C39.6703 52.4774 50.347 41.8008 50.347 28.6304C50.347 15.4601 39.6703 4.78343 26.5 4.78343Z"
				fill="white"
			/>
			<path
				d="M21.8873 37.6256C21.5995 37.625 21.3168 37.5496 21.067 37.4067C20.5045 37.0879 20.1548 36.4692 20.1548 35.7974V22.2037C20.1548 21.5301 20.5045 20.9132 21.067 20.5945C21.3227 20.4475 21.6133 20.3719 21.9082 20.3756C22.2032 20.3793 22.4917 20.4622 22.7437 20.6156L34.3617 27.5699C34.6038 27.7218 34.8034 27.9326 34.9418 28.1827C35.0801 28.4327 35.1527 28.7138 35.1527 28.9996C35.1527 29.2854 35.0801 29.5665 34.9418 29.8166C34.8034 30.0667 34.6038 30.2775 34.3617 30.4293L22.7418 37.3856C22.484 37.5415 22.1886 37.6244 21.8873 37.6256Z"
				fill="#AAAAAA"
			/>
		</svg>
	);
};
