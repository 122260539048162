import React from 'react';
import classes from './FMHeaderGoBe.module.css';

interface FMHeaderGoBe {}

export const FMHeaderGoBe: React.FC<FMHeaderGoBe> = ({ children }) => (
	<div className={classes.outerContainer}>
		<div className={classes.innerImgContainer}>
			<img src="./assets/img/gobe-robots-logo.svg" alt="GoBe Robots" />
		</div>
		<div className={classes.innerMsgContainer}>{children}</div>
	</div>
);
