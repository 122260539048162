import React, { FC, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import LanguageOptions from '../../../config/LanguageOptions';

import classes from '../CustomFormComponents.module.css';
import classNames from 'classnames';

interface LanguageSelectProps {
	control: any;
	intl: any;
	errors: any;
	name: string;
	disabled?: boolean;
	initializedValue?: string;
	menuPlacement?: 'auto' | 'bottom' | 'top' | undefined;
	marginLess?: boolean;
	required?: boolean;
	standardInput?: boolean;
}

const LanguageSelect: FC<LanguageSelectProps> = LanguageSelectProps => {
	const {
		control,
		intl,
		errors,
		name,
		disabled,
		initializedValue,
		menuPlacement,
		marginLess,
		required,
		standardInput,
	} = LanguageSelectProps;

	const [selectedCountry, setSelectedCountry] = useState(initializedValue);

	const styles = {
		control: () => ({
			display: 'flex',
		}),
		indicatorSeparator: () => ({ display: 'none' }),
		option: (provided: any, state: any) => ({
			...provided,
			fontSize: '15px',
			color: 'var(--ion-text-color)',
			backgroundColor: state.isFocused ? 'var(--form-field-hover-color)' : null,
		}),
		singleValue: (provided: any, state: any) => {
			const opacity = state.isDisabled ? 0.5 : 1;
			const transition = 'opacity 300ms';

			return { ...provided, opacity, transition, fontSize: '15px', marginLeft: 0 };
		},
		valueContainer: (provided: any, state: any) => ({
			...provided,
			padding: '0',
		}),
	};

	const options: { label: string; value: string }[] = LanguageOptions.map(option => {
		const label = intl.formatMessage({ id: option.labelId });
		return { label, value: option.value };
	});

	const defaultValue = initializedValue
		? options.find(option => option.value === initializedValue)
		: null;

	let errorMessage = null;
	if (errors && errors[name] && errors[name].type === 'required') {
		errorMessage = (
			<p className={classes.errorMessage}>
				{intl.formatMessage({
					id: 'LanguageOptionsMeta.required',
				})}
			</p>
		);
	}

	const requiredIndicator = required ? (
		<span className={classes.requiredIndicator}>*</span>
	) : null;

	const containerClasses = classNames({
		[classes.container]: true,
		[classes.containerStandardInput]: Boolean(standardInput),
		[classes.containerMarginLess]: Boolean(marginLess),
		[classes.containerError]: Boolean(errorMessage),
		[classes.containerSelected]: Boolean(selectedCountry),
		[classes.containerDisabled]: Boolean(disabled),
	});

	const label = standardInput ? null : (
		<div className={classes.label}>
			{intl.formatMessage({
				id: 'LanguageOptionsMeta.language',
			})}
			{requiredIndicator}
		</div>
	);

	return (
		<div className={containerClasses}>
			{label}
			<Controller
				className={classes.controller}
				as={Select}
				control={control}
				onChange={([selectedOption]) => {
					setSelectedCountry(selectedOption.value);
					return selectedOption;
				}}
				rules={required ? { required: true } : {}}
				styles={styles}
				options={options}
				menuPlacement={menuPlacement ? menuPlacement : 'auto'}
				name={name}
				placeholder=""
				isDisabled={disabled}
				isClearable={false}
				isSearchable={false}
				defaultValue={defaultValue}
			/>
			{errorMessage}
		</div>
	);
};

export default injectIntl(LanguageSelect);
