import { MONGODB_CONFIG } from '../actions/types';

const Initial_State = {
	mongoDbConfig: {
		realmAppId: '',
		database: '',
		disinfectionCollection: '',
		mongoClient: '',
		mongoChartUrl: '',
		collections: {
			disinfectionCollection: '',
			robotCollection: '',
			mapCollection: '',
			fileCollection: '',
		},
		charts: {
			numberOfRoomsDisinfectedByDay: '',
			successfulDisinfectionRate: '',
			disinfectionsCompleted: '',
			numberOfLocationsDisinfected: '',
			distanceCovered_meters: '',
			disinfectionSuccessRateDonut: '',
			successfulDisinfectionsNumberOfInterruptions: '',
			failedDisinfectionNumberOfInterruptions: '',
			failedDisinfectionsByLocation: '',
			robotLocations: '',
			oldestDisinfectionsCompleted: '',
			disinfectionsByOperator: '',
		},
	},
};

export default (state = Initial_State, action: any) => {
	switch (action.type) {
		case MONGODB_CONFIG:
			return {
				...state,
				mongoDbConfig: action.payload,
			};
		default:
			return state;
	}
};
