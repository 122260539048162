import React from 'react';
import { IDeviceCurrentState, Reservation } from '../../../../../../utils/statusConverter';
import '../../index.scss';
import { DefaultStatusLabel } from './DefaultStatusLabel';

interface Props {
	reservation: Reservation | null | undefined;
	deviceStatus: IDeviceCurrentState;
	isPermanentDevice: boolean | undefined;
	isCalling: boolean;
	nextReservationInProgress?: boolean;
}

export const ReservationStatusLabel = ({
	reservation,
	deviceStatus,
	isPermanentDevice,
	nextReservationInProgress,
}: Props) => {
	const isGuestSession = reservation?.isGuest;
	const guestSessionInProgress = reservation?.isGuest && nextReservationInProgress;

	if (reservation?.isGuest && !isPermanentDevice) {
		switch (reservation?.status) {
			case 'beforeReservationDay':
			case 'reservationDay':
				return <span className="bodyText">Unavailable</span>;
			case 'reservationTime':
				return <DefaultStatusLabel status={deviceStatus} isGuest={reservation?.isGuest} />;
			case 'inSession':
				return (
					<span className="bodyText">
						{isGuestSession && guestSessionInProgress ? 'My Session' : 'In Session'}
					</span>
				);
			default:
				return <span className="bodyText"></span>;
		}
	} else if (reservation?.isGuest && isPermanentDevice) {
		switch (reservation?.status) {
			case 'beforeReservationDay':
			case 'reservationDay':
			case 'reservationTime':
				return <DefaultStatusLabel status={deviceStatus} isGuest={reservation?.isGuest} />;
			case 'inSession':
				return (
					<span className="bodyText">
						{isGuestSession && guestSessionInProgress ? 'My Session' : 'In Session'}
					</span>
				);
			default:
				return <span className="bodyText"></span>;
		}
	} else {
		switch (reservation?.status) {
			case 'beforeReservationDay':
			case 'reservationDay':
				return <DefaultStatusLabel status={deviceStatus} isGuest={reservation?.isGuest} />;
			case 'reservationTime':
				return <span className="bodyText">Unavailable</span>;
			case 'inSession':
				return (
					<span className="bodyText">
						{isGuestSession && guestSessionInProgress ? 'My Session' : 'In Session'}
					</span>
				);
			default:
				return <span className="bodyText"></span>;
		}
	}
};
