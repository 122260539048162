import { defineMessages } from 'react-intl';

export default defineMessages({
	details: {
		id: 'details',
		defaultMessage: 'Details',
		description: 'Details',
	},
	orgDetails: {
		id: 'Organization.details',
		defaultMessage: 'Organization Details',
		description: 'Organization details',
	},
	billingDetails: {
		id: 'Organization.billingDetails',
		defaultMessage: 'Billing Details',
		description: 'Billing details',
	},
	shippingDetails: {
		id: 'Organization.shippingDetails',
		defaultMessage: 'Shipping Details',
		description: 'Shipping details',
	},
	companyName: {
		id: 'Organization.companyName',
		defaultMessage: 'Company Name',
		description: 'Company Name',
	},
	createdOn: {
		id: 'Organization.createdOn',
		defaultMessage: 'Created On',
		description: 'Created On',
	},
	language: {
		id: 'Organization.language',
		defaultMessage: 'Language',
		description: 'Language',
	},
	street: {
		id: 'Address.dateAdded',
		defaultMessage: 'Street',
		description: 'Street',
	},
	addressLine1: {
		id: 'Address.addressLine1',
		defaultMessage: 'Address Line 1',
		description: 'Address Line 1',
	},
	addressLine2: {
		id: 'Address.addressLine2',
		defaultMessage: 'Address Line 2',
		description: 'Address Line 2',
	},
	street2: {
		id: 'Address.lastLogin',
		defaultMessage: 'Street2',
		description: 'Street2',
	},
	zip: {
		id: 'Address.zip',
		defaultMessage: 'Zip',
		description: 'Zip',
	},
	city: {
		id: 'Address.city',
		defaultMessage: 'City',
		description: 'City',
	},
	state: {
		id: 'Address.state',
		defaultMessage: 'State',
		description: 'State',
	},
	country: {
		id: 'Address.country',
		defaultMessage: 'Country',
		description: 'Country',
	},
	linkedTo: {
		id: 'Users.linkedTo',
		defaultMessage: 'Linked to',
		description: 'Linked to',
	},
	no: {
		id: 'no',
		defaultMessage: 'No',
		description: 'No',
	},
	yes: {
		id: 'yes',
		defaultMessage: 'Yes',
		description: 'Yes',
	},
	contactPerson: {
		id: 'Organizations.contact',
		defaultMessage: 'Contact Person',
		description: 'Contact Person',
	},
	firstName: {
		id: 'ContactPerson.firstName',
		defaultMessage: 'First Name',
		description: 'First Name',
	},
	lastName: {
		id: 'ContactPerson.lastName',
		defaultMessage: 'Last Name',
		description: 'Last Name',
	},
	jobTitle: {
		id: 'ContactPerson.jobTitle',
		defaultMessage: 'Job Title',
		description: 'Job Title',
	},
	email: {
		id: 'ContactPerson.email',
		defaultMessage: 'Email address',
		description: 'Email address',
	},
	phoneNumber: {
		id: 'ContactPerson.phoneNumber',
		defaultMessage: 'Phone Number',
		description: 'Phone Number',
	},
	agreements: {
		id: 'Organizations.agreement',
		defaultMessage: 'Agreements',
		description: 'Accepted Agreements',
	},
	acceptedOn: {
		id: 'Organizations.acceptedOn',
		defaultMessage: 'Accepted on:',
		description: 'Accepted on date',
	},
	version: {
		id: 'Organizations.version',
		defaultMessage: 'Version',
		description: 'Version',
	},
	acceptedDate: {
		id: 'Organizations.acceptedDate',
		defaultMessage: 'Accepted Date',
		description: 'Accepted Date',
	},
	acceptedTime: {
		id: 'Organizations.acceptedTime',
		defaultMessage: 'Accepted Time',
		description: 'Accepted Time',
	},
	acceptedBy: {
		id: 'Organizations.acceptedBy',
		defaultMessage: 'Accepted By',
		description: 'Accepted By',
	},

	ok: {
		id: 'Organization.ok',
		defaultMessage: 'OK',
		description: 'OK',
	},
	sso: {
		id: 'Organizations.sso',
		defaultMessage: 'Single Sign On',
		description: 'Single Sign On',
	},
	configuration: {
		id: 'Organizations.configuration',
		defaultMessage: 'Configuration',
		description: 'Configuration',
	},
	callbackURL: {
		id: 'Organizations.callbackURL',
		defaultMessage: 'Callback URL',
		description: 'Callback URL',
	},
	orgDomains: {
		id: 'Organizations.orgDomains',
		defaultMessage: 'Organization domain(s)',
		description: 'Organization domain(s)',
	},
	ssoType: {
		id: 'Organizations.ssoType',
		defaultMessage: 'SSO Type',
		description: 'SSO Type',
	},
	samlConfig: {
		idpEntryPoint: {
			id: 'Organizations.samlConfig.idpEntryPoint',
			defaultMessage: 'IDP Entry Point',
			description: 'IDP Entry Point',
		},
		idpCertificate: {
			id: 'Organizations.samlConfig.idpCertificate',
			defaultMessage: 'IDP Certificate',
			description: 'IDP Certificate',
		},
		idpIssuer: {
			id: 'Organizations.samlConfig.idpIssuer',
			defaultMessage: 'IDP Issuer',
			description: 'IDP Issuer',
		},
	},
	oidcConfig: {
		idpIssuer: {
			id: 'Organizations.oidcConfig.idpIssuer',
			defaultMessage: 'IDP Issuer',
			description: 'IDP Issuer',
		},
		idpClient: {
			id: 'Organizations.oidcConfig.idpClient',
			defaultMessage: 'IDP Client',
			description: 'IDP Client',
		},
		idpClientSecret: {
			id: 'Organizations.oidcConfig.idpClientSecret',
			defaultMessage: 'IDP Client Secret',
			description: 'IDP Client Secret',
		},
	},
	azureAdOidcConfig: {
		idpMetadataEndpoint: {
			id: 'Organizations.azureAdOIDCConfig.idpMetadataEndpoint',
			defaultMessage: 'Metadata Endpoint',
			description: 'Metadata Endpoint',
		},
		idpClient: {
			id: 'Organizations.azureAdOIDCConfig.idpClient',
			defaultMessage: 'Client ID',
			description: 'Client ID',
		},
		idpClientSecret: {
			id: 'Organizations.azureAdOIDCConfig.idpClientSecret',
			defaultMessage: 'Client Secret',
			description: 'Client Secret',
		},
	},
	googleConfig: {
		clientId: {
			id: 'Organizations.googleConfig.idpClientId',
			defaultMessage: 'Client ID',
			description: 'Client ID',
		},
		clientSecret: {
			id: 'Organizations.googleConfig.clientSecret',
			defaultMessage: 'Client Secret',
			description: 'Client Secret',
		},
	},
	testSSOConfig: {
		id: 'Organizations.testSSOConfig',
		defaultMessage: 'Test',
		description: 'Test',
	},
	applySSOConfigFromSandboxToLive: {
		id: 'Organizations.applySSOConfigFromSandboxToLive',
		defaultMessage: 'Apply',
		description: 'Apply',
	},
	verifyDomain: {
		id: 'Organizations.verifyDomain',
		defaultMessage: 'Verify Domain',
		description: 'Verify Domain',
	},
});
