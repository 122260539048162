import React, { FC, useEffect } from 'react';
import { IonList, IonItem, IonButton, IonRow } from '@ionic/react';
import { injectIntl, FormattedMessage } from 'react-intl';
import FormInputListItem from '../FormInputListItem/FormInputListItem';
import { PhoneNumberInput } from '../CustomFormComponents';
import isAuthenticated from '../../components/Authentication/Authenticated';
import { useForm } from 'react-hook-form';
import { get } from 'lodash';

import classes from './AddOrganizationModal.module.css';
import classNames from 'classnames';

import Messages from '../AddOrganizationModal/AddOrganizationModal.messages';
import { EmailInputLimit, InputLimit, ShortInputLimit } from '../../utils/validator';

interface AddContactFormProps {
	onSubmit: (data: any) => void;
	onCancel: () => void;
	onValid: () => void;
	country: string;
}

const AddContactForm: FC<AddContactFormProps> = (props: any) => {
	const { intl, isLastStep } = props;
	const {
		handleSubmit: contactSubmit,
		control: contactControl,
		errors: contactErrors,
		formState,
	} = useForm();

	useEffect(() => {
		props.onValid(formState.isValid, formState.isSubmitted, formState.dirty);
	}, [formState, props]);

	const onContactSubmit = contactSubmit((data: any) => {
		props.onSubmit(data);
	});

	return (
		<form onSubmit={onContactSubmit} className={classes.addForm}>
			<IonList className={classNames(classes.formList, 'ion-padding')}>
				<IonRow className={classes.modalContentContainer}>
					<IonRow className={classes.rowContainer}>
						<FormInputListItem
							type="text"
							name="contactPerson.firstName"
							label={intl.formatMessage({
								id: 'ContactPerson.firstName',
							})}
							placeholderText={intl.formatMessage({
								id: 'ContactPerson.firstNameHint',
							})}
							control={contactControl}
							rules={{
								required: {
									value: true,
									message: intl.formatMessage({
										id: 'ContactPerson.firstNameError',
									}),
								},
							}}
							required
							maxLength={ShortInputLimit}
							errorMsg={get(contactErrors.contactPerson, 'firstName.message')}
							dataCy="contactPerson.firstName"
						/>
					</IonRow>
					<IonRow className={classes.rowContainer}>
						<FormInputListItem
							type="text"
							name="contactPerson.lastName"
							label={intl.formatMessage({
								id: 'ContactPerson.lastName',
							})}
							placeholderText={intl.formatMessage({
								id: 'ContactPerson.lastNameHint',
							})}
							control={contactControl}
							rules={{
								required: {
									value: true,
									message: intl.formatMessage({
										id: 'ContactPerson.lastNameError',
									}),
								},
							}}
							required
							maxLength={ShortInputLimit}
							errorMsg={get(contactErrors.contactPerson, 'lastName.message')}
							dataCy="contactPerson.lastName"
						/>
					</IonRow>
					<IonRow className={classes.rowContainer}>
						<FormInputListItem
							type="text"
							name="contactPerson.companyName"
							label={intl.formatMessage({
								id: 'ContactPerson.companyName',
							})}
							placeholderText={intl.formatMessage({
								id: 'ContactPerson.companyNameHint',
							})}
							control={contactControl}
							rules={{ required: false }}
							maxLength={InputLimit}
							dataCy="contactPerson.companyName"
						/>
					</IonRow>
					<IonRow className={classes.rowContainer}>
						<FormInputListItem
							type="text"
							name="contactPerson.jobTitle"
							label={intl.formatMessage({
								id: 'ContactPerson.jobTitle',
							})}
							placeholderText={intl.formatMessage({
								id: 'ContactPerson.jobTitleHint',
							})}
							control={contactControl}
							rules={{ required: false }}
							maxLength={InputLimit}
							dataCy="contactPerson.jobTitle"
						/>
					</IonRow>
					<IonRow className={classes.rowContainer}>
						<FormInputListItem
							type="email"
							name="contactPerson.email"
							label={intl.formatMessage({
								id: 'ContactPerson.email',
							})}
							placeholderText={intl.formatMessage({
								id: 'ContactPerson.emailHint',
							})}
							control={contactControl}
							rules={{
								required: {
									value: true,
									message: intl.formatMessage({
										id: 'ContactPerson.emailError',
									}),
								},
							}}
							required
							maxLength={EmailInputLimit}
							errorMsg={get(contactErrors.contactPerson, 'email.message')}
							dataCy="contactPerson.email"
						/>
					</IonRow>
					<IonRow className={classes.rowContainer}>
						<PhoneNumberInput
							control={contactControl}
							errors={contactErrors}
							initializedCountry={props.country}
							name="contactPerson.phoneNumber"
							dataCy="contactPerson.phoneNumber"
						/>
					</IonRow>
				</IonRow>
				<IonRow className={classNames(classes.rowContainer, classes.btnContainer)}>
					<IonItem lines="none" className={classes.btnRow}>
						<IonButton
							className={classes.cancelBtn}
							expand="block"
							shape="round"
							size="large"
							fill="outline"
							onClick={props.onCancel}
						>
							<FormattedMessage {...Messages.cancel} />
						</IonButton>
						<IonButton
							id="add-org-form-contact-person-next-btn"
							className={classes.submitBtn}
							expand="block"
							shape="round"
							type="submit"
							size="large"
							fill="outline"
							data-cy="create_organization_submit"
						>
							{isLastStep ? (
								<FormattedMessage {...Messages.create} />
							) : (
								<FormattedMessage {...Messages.next} />
							)}
						</IonButton>
					</IonItem>
				</IonRow>
			</IonList>
		</form>
	);
};

export default injectIntl(isAuthenticated(AddContactForm, 'AddContactForm'));
