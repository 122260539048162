import * as Realm from 'realm-web';
import { store } from '../store/store';

// Config for Alex's Org MongoDB project
// constants: {
// 	REALM_APP_ID: 'uvdr-front-app-yqrjy',
// 	DATABASE: 'uvdr',
// 	DISINFECTION_COLLECTION: 'Disinfections',
// 	MONGO_CLIENT: 'mongodb-atlas',
// 	MONGO_CHART_URL: 'https://charts.mongodb.com/charts-uvd_robot_test-vqman',
// 	collections: {
// 		DISINFECTION_COLLECTION: 'Disinfections',
// 		ROBOT_COLLECTION: 'Robots',
// 		MAP_COLLECTION: 'Maps',
// 		FILE_COLLECTION: 'Files',
// 	},
// },

export let mongodbClient = {
	user: null,
	app: null,
	credentials: null,
	getApp: function() {
		if (this.app) {
			return this.app;
		}

		this.app = new Realm.App({
			id: store.getState().mongoDbConfigState.mongoDbConfig.realmAppId,
		});
		return this.app;
	},
	getCredentials: function(jwt) {
		if (this.credentials) {
			return this.credentials;
		}

		this.credentials = Realm.Credentials.jwt(jwt);
		return this.credentials;
	},
	readCollection: function(filters, collectionName) {
		return new Promise((resolve, reject) => {
			if (!this.user) {
				reject('you should connect to mongo before read data');
				return;
			}

			const mongo = this.user.mongoClient(
				store.getState().mongoDbConfigState.mongoDbConfig.mongoClient
			);
			const db = mongo.db(store.getState().mongoDbConfigState.mongoDbConfig.database);
			const dbCollection = db.collection(collectionName);

			const query = filters;
			const projection = {};
			dbCollection
				.find(query, projection)
				.then(result => {
					resolve(result);
				})
				.catch(err => {
					console.error(`Failed to find document: ${err}`);
					reject(err);
				});
		});
	},
	countCollection: function(filters, collectionName) {
		return new Promise((resolve, reject) => {
			if (!this.user) {
				reject('you should connect to mongo before read data');
				return;
			}

			const mongo = this.user.mongoClient(
				store.getState().mongoDbConfigState.mongoDbConfig.mongoClient
			);
			const db = mongo.db(store.getState().mongoDbConfigState.mongoDbConfig.database);
			const dbCollection = db.collection(collectionName);

			const query = filters;
			const projection = {};
			dbCollection
				.count(query, projection)
				.then(result => {
					resolve(result);
				})
				.catch(err => {
					console.error(`Failed to find document: ${err}`);
					reject(err);
				});
		});
	},
	getUser: function(jwt) {
		//console.log('this.user', this.user);
		const user = this.user;
		let that = this;
		return new Promise(function(resolve, reject) {
			//console.log('user', user);
			if (user) {
				resolve(user);
				return;
			}

			const app = that.getApp();
			const credentials = that.getCredentials(jwt);

			try {
				app.logIn(credentials)
					.then(res => {
						//console.log('Successfully logged in!', res);
						if (res) {
							that.user = res;
							resolve(that.user);
						}
					})
					.catch(err => {
						console.log('err app.logIn', err);
						reject(err);
					});
			} catch (err) {
				console.error('Failed to log in', err.message);
				reject(err);
			}
		});
	},
};
