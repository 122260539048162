import React, { FC } from 'react';
import { Table as MUITable, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { TableContainer } from '@material-ui/core';
import { TableHeaderCell } from './TableHeaderCell';
import { styled } from '@material-ui/styles';
import { TableRowCell } from './TableRowCell';

const StyledTable = styled(MUITable)(() => ({
	width: '100%',
	padding: 0,
	borderRadius: 5,
}));
const StyledTableHeader = styled(TableHead)(() => ({
	'& .MuiTableCell-head': {
		fontFamily: 'Industry-Demi',
		background: 'var(--ion-main-background-color)',
		textTransform: 'uppercase',
		borderBottom: 'none',
		fontSize: 15,
		height: 45,
	},
}));

type TableProps = {
	columns?: any;
	rows?: any;
	hasHeader?: any;
};

const Table: FC<TableProps> = (props: any) => {
	return (
		<TableContainer>
			<StyledTable>
				{props.hasHeader ? (
					<StyledTableHeader>
						{props.columns.map((header: any, index: any) => {
							const isFirst = index === 0;
							const isLast = props.columns.length === index + 1;
							return (
								<TableHeaderCell
									key={header + index}
									header={header}
									isFirst={isFirst}
									isLast={isLast}
									index={index}
								/>
							);
						})}
					</StyledTableHeader>
				) : null}
				<TableBody>
					{props.rows && props.rows.length > 0
						? props.rows.map((row: any, index: any) => {
								return (
									<TableRow>
										{props.columns.map((column: any) => {
											return <TableRowCell column={column} row={row} />;
										})}
									</TableRow>
								);
						  })
						: null}
				</TableBody>
			</StyledTable>
		</TableContainer>
	);
};

export default Table;
