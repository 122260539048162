import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { styled } from '@material-ui/styles';
import TableCell from '@material-ui/core/TableCell';

const useStyles = makeStyles({
	rowContent: {
		height: '100%',
		fontSize: '15px',
	},
});

const StyledBorderBottom = styled(TableCell)(() => ({
	borderColor: '#f1f1f3',
}));

export const TableDataCell = ({ value, index }: any) => {
	const classes = useStyles();
	return (
		<StyledBorderBottom>
			<div className={classes.rowContent} key={'column-' + index}>
				{value}
			</div>
		</StyledBorderBottom>
	);
};
