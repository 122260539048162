import _ from 'lodash';
import { PrimaryCameraState, RobotPrimaryCamera } from '../../../../types';
import { ICEPayload, ISignalingClient, SDPPayload } from '../signaling/types';
import { generateUUID } from '../utils';
import watchRTC from '@testrtc/watchrtc-sdk';
const WATCH_RTC_API_KEY = '';

export type PeerConnectionEndReasonCode =
	| 'PEER_HANGUP'
	| 'LOCAL_HANGUP'
	| 'CLEANUP'
	| 'FAILED_STATE_TIMED_OUT'
	| 'PAUSED_STATE_TIMED_OUT'
	| 'RETRY_TIMEOUT'
	| 'RETRY_FAILED'
	| 'ERROR';

type LocalTrackKey = 'pilotVideo' | 'pilotAudio';
export type RemoteTrackKey = 'wide_cam' | 'zoom_cam' | 'nav_cam' | 'audio';

export const NAV_DATACHANNEL_LABEL = 'nav-datachannel';
export const NON_NAV_DATACHANNEL_LABEL = 'non-nav-datachannel';
export const NON_NAV_DATACHANNEL_LABEL__LEGACY = 'other-datachannel';

type IPeerConnectionEvent = 'pause' | 'unpause' | 'retrying';

const PAUSED_CONNECTION_TIMEOUT__MS = 15 * (60 * 1000);
const FAILED_CONNECTION_TIMEOUT__MS = 1 * 60 * 1000;
/** If the remote peer does not get back to us (within this time), that is ready to retry, we stop waiting */
const RETRYING_SESSION_TIMEOUT__MS = 20 * 1000;

/**
 * Internal representation of the state of the session.
 * NB: Should not be used outside this module/class,
 * as it is subject to change, and we dont want to have to worry about breaking stuff outside this class
 */
type SessionState = 'NotInitialized' | 'InProgress' | 'Paused' | 'Retrying' | 'Ended';

// const STATS_SAMPLING_PERIOD_MS = 200;
export default class PeerConnectionWithSignalling {
	private sessionStateMachine = new SessionStateMachine();
	/**
	 * The state of the 'session'.
	 * NB: For internal use only. Should not be directly exposed outside this class.
	 * Changes to the session state, should be listened to, via event handlers/callbacks
	 */
	private get sessionState() {
		return this.sessionStateMachine.state;
	}

	// todo: Refactor all callbacks to use this EventEmitter API
	private eventTarget = new EventTarget();
	public addEventListener = (event: IPeerConnectionEvent, listener: (...args: []) => void) => {
		this.eventTarget.addEventListener(event, listener);
	};
	public removeEventListener = (event: IPeerConnectionEvent, listener: (...args: []) => void) => {
		this.eventTarget.removeEventListener(event, listener);
	};
	private dispatchEvent = (event: IPeerConnectionEvent) => {
		this.eventTarget.dispatchEvent(new Event(event));
	};

	// private rtpStreamStatsSender: RTCRtpStreamStatsSender<LocalTrackKey | RemoteTrackKey>;

	/** A promise that resolves when we are done resetting state,
	 * in order to prepare for a session retry.
	 * Resolves TRUE, if the we were able to reset state for retry, else FALSE.
	 * NB: This promise never rejects */
	private resetForRetryPromise = Promise.resolve(false);

	private pc: RTCPeerConnection | undefined;

	private nonNavDatachannel: RTCDataChannel | undefined;

	/** A mapping of RemoteTrackKey to corresponding MediaStreamTrack & transceiver */
	private remoteMediaKeyMap: Partial<
		Record<RemoteTrackKey, { track: MediaStreamTrack; transceiver: RTCRtpTransceiver }>
	> = {};

	private get remoteMediaTracks(): MediaStreamTrack[] {
		return Object.values(this.remoteMediaKeyMap).reduce((otherTracks, val) => {
			if (val === undefined) return otherTracks;
			return [...otherTracks, val.track];
		}, [] as MediaStreamTrack[]);
	}

	private localMediaStream: MediaStream | undefined;

	/** A mapping of the mid in RTCSessionDescription, to a named media track key */
	private remoteTracksMidsMap: Record<string, RemoteTrackKey> = {
		video0: 'wide_cam',
		video1: 'nav_cam',
		audio2: 'audio',
		video3: 'zoom_cam',
	};
	private _primaryCameraState: PrimaryCameraState = {
		// todo: fixme: Get the initial value from robot in the HELLO request
		currentPrimaryCamera: RobotPrimaryCamera.WIDE_CAM,
		isChangingPrimaryCameraTo: null,
	};
	public get primaryCameraState() {
		return this._primaryCameraState;
	}
	private _onPrimaryCameraStateChanged = (newState: PrimaryCameraState) => {
		this._primaryCameraState = newState;
		if (this.onPrimaryCameraStateChange !== null) {
			this.onPrimaryCameraStateChange(this.primaryCameraState);
		}
	};

	private _primaryMediaStream: MediaStream = new MediaStream();
	public get primaryMediaStream() {
		return this._primaryMediaStream;
	}

	// public onTrack:
	// 	| ((track: MediaStreamTrack, key: RemoteTrackKey, transceiver: RTCRtpTransceiver) => void)
	// 	| null;
	public onStarted: (() => void) | null;
	public onEnded: ((reason: PeerConnectionEndReasonCode) => void) | null;
	public onDataChanel: ((datachannel: RTCDataChannel) => void) | null;
	public onConnectionStateChange: ((connectionState: RTCPeerConnectionState) => void) | null;
	public onPrimaryCameraStateChange: ((newState: PrimaryCameraState) => void) | null;
	public onPrimaryMediaStreamChanged:
		| ((stream: MediaStream, transceiver: RTCRtpTransceiver) => void)
		| null;
	public onNavMediaStreamChanged:
		| ((stream: MediaStream, transceiver: RTCRtpTransceiver) => void)
		| null;
	public onRetrying: (() => void) | null;

	constructor(private signallingClient: ISignalingClient) {
		watchRTC.init();

		// this.onTrack = (tr, k, trx) => console.warn('Unhandled callback onTrack', tr, k, trx);
		this.onStarted = () => console.warn('Unhandled callback onStarted');
		this.onEnded = r => console.warn('Unhandled callback onEnded', r);
		this.onDataChanel = d => console.warn('Unhandled callback onDataChanel', d);
		this.onConnectionStateChange = s =>
			console.warn('Unhandled callback onConnectionStateChange', s);

		this.signallingClient.onRemoteSDP = this.onPeerSDP.bind(this);
		this.signallingClient.onRemoteICECandidate = this.onPeerICE.bind(this);
		this.signallingClient.onRemoteHangUp = this.onRemoteHangUp.bind(this);
		this.signallingClient.onRemoteWillRetry = this.onRemotePeerWillRetry.bind(this);
		this.signallingClient.onRemoteReadyToRetry = this.onRemotePeerReadyToRetry.bind(this);

		this.start = this.start.bind(this);
		this.end = this.end.bind(this);
		this.pause = this.pause.bind(this);
		this.unpause = this.unpause.bind(this);
		this.promptIceRestart = this.promptIceRestart.bind(this);
		this.togglePrimaryCamera = this.togglePrimaryCamera.bind(this);
		// this.setVolume = this.setVolume.bind(this);
		// this.setStatusMessage = this.setStatusMessage.bind(this);

		watchRTC.setConfig({
			rtcApiKey: WATCH_RTC_API_KEY,
			rtcRoomId: this.signallingClient.sessionInfo.uuid,
			rtcPeerId: this.signallingClient.sessionInfo.robot.id,
			keys: {
				...this.signallingClient.sessionInfo.capabilities,
				signalingServerUrl: this.signallingClient.sessionInfo.signaling?.url ?? null,
				robotId: this.signallingClient.sessionInfo.robot.id,
				pilotId: this.signallingClient.sessionInfo.pilot.id,
			},
		});
	}

	/** Initialize the peer connection, add tracks, and attach callbacks  */
	private async setupPeerConnection(stream: MediaStream) {
		const peerConnection = new RTCPeerConnection({
			bundlePolicy: 'max-bundle',
			iceServers: [this.signallingClient.sessionInfo.iceServers],
			iceTransportPolicy: 'relay',
		});
		this.pc = peerConnection;

		const videoSenders: Array<RTCRtpSender> = [];
		for (const track of stream.getTracks()) {
			const sender = peerConnection.addTrack(track, stream);
			if (track.kind === 'video') {
				videoSenders.push(sender);
				// this.rtpStreamStatsSender.setStatsSource('pilotVideo', track);
			} else if (track.kind === 'audio') {
				// this.rtpStreamStatsSender.setStatsSource('pilotAudio', track);
			}
		}

		// Set preferred params on RTCRtpSender for video
		for (let i = 0; i < videoSenders.length; i++) {
			const sender = videoSenders[i];
			try {
				const params = await sender.getParameters();
				const updatedParams = {
					...params,
					encodings: params.encodings.map(encoding => ({
						...encoding,
						maxBitrate: 0.5 * 10 ** 6, // in bits per second
						priority: 'high',
					})),
					degradationPreference: 'maintain-resolution',
				};
				await sender.setParameters(updatedParams as any);
			} catch (error) {
				console.warn(`Error -> peerConnection.transceiver.sender.setParameters`, error);
			}
		}

		const supportsSetCodecPreferences =
			window.RTCRtpTransceiver && 'setCodecPreferences' in window.RTCRtpTransceiver.prototype;
		if (supportsSetCodecPreferences) {
			const { codecs } = RTCRtpSender.getCapabilities('video') as RTCRtpCapabilities;
			console.log('Supported Codecs ', codecs);
			const rearrangedCodecs = [
				...codecs.filter(codec => codec.mimeType === 'video/VP8'),
				...codecs.filter(codec => codec.mimeType === 'video/H264'),
				...codecs.filter(codec => !['video/H264', 'video/VP8'].includes(codec.mimeType)),
			];
			const transceiver = peerConnection
				.getTransceivers()
				.find(t => t.sender && t.sender.track === stream?.getVideoTracks()[0]);
			if (transceiver) {
				// @ts-ignore
				transceiver.setCodecPreferences(rearrangedCodecs);
				console.log('Codec preferences has been set on transceiver');
			} else {
				console.log('transceiver has not been set up on peer connection yet');
			}
		} else {
			console.warn('Unfortunately, specifying preferred codec is not supported');
		}

		peerConnection.ontrack = this._onTrack.bind(this);
		peerConnection.onicecandidate = this.onLocalICE.bind(this);
		peerConnection.onconnectionstatechange = this._onConnectionStateChange.bind(this);
		peerConnection.oniceconnectionstatechange = this._onICEConnectionStateChange.bind(this);
		peerConnection.onicegatheringstatechange = this._onICEGatheringStateChange.bind(this);
		peerConnection.ondatachannel = this._onDataChannel.bind(this);

		watchRTC.mapStream(stream.id, 'pilotVideo');
	}

	public get capabilities() {
		return this.signallingClient.sessionInfo.capabilities;
	}

	public get connectionState() {
		return this.pc?.connectionState || 'new';
	}

	public get uuid() {
		return this.signallingClient.sessionInfo.uuid;
	}

	public get localId() {
		return this.signallingClient.sessionInfo.pilot.id;
	}

	public get peerId() {
		return this.signallingClient.sessionInfo.robot.id;
	}

	public start = async (localStream: MediaStream) => {
		console.debug('PeerConnection.start()');

		const transitionSucceeded = this.sessionStateMachine.goToState({
			from: 'NotInitialized',
			to: 'InProgress',
		});
		if (!transitionSucceeded) {
			throw new Error(`abort PeerConnection.start()`);
		}

		try {
			this.muteMediaTracksBasedOnPauseState();
			// console.log('ICE_SERVERS', iceServers);
			await this.setupPeerConnection(localStream);
			// let the peer know that we are ready for initial offer
			await this.signallingClient.start().then(this.onStarted);
			// this.rtpStreamStatsSender.start(this.pc!);
		} catch (error) {
			// the remote peer did not respond with an OK :(
			console.error('peerConnection.start', error);
			// there was an error with initial signalling stage
			let reason: PeerConnectionEndReasonCode = 'ERROR';
			this.end(reason);
		}
	};

	public end = (reason: PeerConnectionEndReasonCode = 'LOCAL_HANGUP') => {
		console.debug('peerConnection.end', reason);
		watchRTC.addEvent({ type: 'global', name: 'sessionEnded', parameters: { reason } });

		const transitionSucceeded = this.sessionStateMachine.goToState({
			from: ['NotInitialized', 'InProgress', 'Paused', 'Retrying'],
			to: 'Ended',
		});
		if (!transitionSucceeded) return;

		// this.rtpStreamStatsSender.stop();
		// notify the remote peer that we are hanging up
		this.signallingClient.sendHangup().catch(console.error);

		this.cleanup();

		this.onEnded && this.onEnded(reason);
	};

	/** Cleanup resources used in this class.
	 * @Returns The local MediaStream if any
	 */
	private cleanup = () => {
		this.resetForRetryPromise = Promise.resolve(false);

		// clear any pending timeouts
		if (this.failedConnStateTimeoutID !== undefined)
			clearTimeout(this.failedConnStateTimeoutID);
		if (this.pausedConnTimeoutID !== undefined) clearTimeout(this.pausedConnTimeoutID);
		if (this.retryTimeoutID) {
			clearTimeout(this.retryTimeoutID);
			this.retryTimeoutID = undefined;
		}

		this.pc?.close();
		this.pc = undefined;

		this.remoteMediaTracks.forEach(track => track.stop());
		this.remoteMediaKeyMap = {};

		const copyOfLocalMediaStream = this.localMediaStream;

		// NB: We don't end/stop the local tracks in this module.
		// We leave it to the creator of the tracks to end/stop when it deems it necessary.
		this.localMediaStream = undefined;

		return copyOfLocalMediaStream;
	};

	private retryTimeoutID: ReturnType<typeof setTimeout> | undefined;
	private onRemotePeerWillRetry = () => {
		const transitionSucceeded = this.sessionStateMachine.goToState({
			from: ['InProgress', 'Paused'],
			to: 'Retrying',
		});
		if (!transitionSucceeded) return;

		this.dispatchEvent('retrying');
		watchRTC.addEvent({ type: 'global', name: 'retrying', parameters: {} });

		// NB: Make sure the promise never rejects - rather returns true/false
		this.resetForRetryPromise = new Promise<boolean>(resolve => {
			const localMediaStream = this.cleanup();
			if (!localMediaStream) {
				resolve(false); // no, we cannot retry session, as no local media stream is available yet
			} else {
				this.setupPeerConnection(localMediaStream)
					.then(() => resolve(true))
					.catch((error: unknown) => {
						console.error('Failed to setupPeerConnection for retry', error);
						resolve(false);
						this.end('RETRY_FAILED');
					});
			}
		});

		// if the remote peer does not send us (in time enough),
		//  that it is ready for a retry, we will end the session
		this.retryTimeoutID = setTimeout(() => {
			this.end('RETRY_TIMEOUT');
		}, RETRYING_SESSION_TIMEOUT__MS);
	};

	private onRemotePeerReadyToRetry = () => {
		watchRTC.addEvent({ type: 'global', name: 'remotePeerReadyForRetry', parameters: {} });

		if (this.retryTimeoutID) {
			clearTimeout(this.retryTimeoutID);
			this.retryTimeoutID = undefined;
		}

		// wait for us to completely have been setup for retry
		Promise.resolve(this.resetForRetryPromise)
			.then(didSetupForRetry => {
				this.resetForRetryPromise = Promise.resolve(false);

				if (didSetupForRetry) {
					this.signallingClient.sendReadyForRetry().catch(console.error);
				} else {
					this.end('RETRY_FAILED');
				}
			})
			.catch(console.error);
	};

	private onRemoteHangUp = () => {
		this.end('PEER_HANGUP');
	};

	public get isPaused() {
		return this.sessionState === 'Paused';
	}
	/** Pause the peer connection.
	 * The remote is notified of the pause, and no media is sent or played-from the remote peer
	 */
	public pause = () => {
		const transitionSucceeded = this.sessionStateMachine.goToState({
			from: 'InProgress',
			to: 'Paused',
		});
		if (!transitionSucceeded) return;

		watchRTC.addEvent({ type: 'global', name: 'sessionPaused', parameters: {} });

		try {
			this.nonNavDatachannel?.send('SESSION PAUSE');
		} catch (error) {
			console.error("Unable to send 'SESSION PAUSE' message via datachannel", error);
		}

		this.muteMediaTracksBasedOnPauseState();

		this.dispatchEvent('pause');

		// explicitly end the session if paused for too long
		if (this.pausedConnTimeoutID !== undefined) clearTimeout(this.pausedConnTimeoutID);
		this.pausedConnTimeoutID = setTimeout(
			() => this.end('PAUSED_STATE_TIMED_OUT'),
			PAUSED_CONNECTION_TIMEOUT__MS
		);
	};

	/** Resume the peer connection from a prior paused state.
	 * The remote is notified of the resumption, and media sent or played-from the remote peer
	 */
	public unpause = () => {
		const transitionSucceeded = this.sessionStateMachine.goToState({
			from: 'Paused',
			to: 'InProgress',
		});
		if (!transitionSucceeded) return;

		watchRTC.addEvent({ type: 'global', name: 'sessionUnpaused', parameters: {} });

		try {
			this.nonNavDatachannel?.send('SESSION UNPAUSE');
		} catch (error) {
			console.error("Unable to send 'SESSION UNPAUSE' message via datachannel", error);
		}
		this.muteMediaTracksBasedOnPauseState();

		this.dispatchEvent('unpause');

		if (this.pausedConnTimeoutID !== undefined) clearTimeout(this.pausedConnTimeoutID);
	};

	private muteMediaTracksBasedOnPauseState = (): void => {
		// mute local media
		this.localMediaStream?.getTracks().forEach(track => {
			track.enabled = !this.isPaused;
		});

		// mute remote media
		this.remoteMediaTracks.forEach(track => {
			track.enabled = !this.isPaused;
		});
	};

	// NB: For now, these functions will not be exposed.
	// Rather, the caller component, will directly call datachannel.send in the appropriate places
	// The ideal implementation will be to have all of such functions exposed from this class.

	// /** Set the perceived volume of our audio on the remote peer's end  */
	// public setVolume(value: Number) {
	// 	try {
	// 		this.nonNavDatachannel?.send(`VOL ${value}`);
	// 	} catch (error) {
	// 		console.error(`Unable to send 'VOL ${value}' message via datachannel`, error);
	// 	}
	// }

	// /** Send status message to the remote peer */
	// public setStatusMessage(message: String) {
	// 	try {
	// 		this.nonNavDatachannel?.send(`MSG ${message}`);
	// 	} catch (error) {
	// 		console.error(`Unable to send 'MSG ${message}' message via datachannel`, error);
	// 	}
	// }

	private _onTrack = (e: RTCTrackEvent) => {
		console.debug('peerConnection.pc.onTrack', e);
		const remoteTrackKey = this.remoteTracksMidsMap[e.transceiver.mid!];
		if (remoteTrackKey === undefined) {
			console.error(
				'Invalid mid',
				`mid '${e.transceiver.mid}' does not correspond to any RemoteTrackKey`
			);
			return;
		}

		this.remoteMediaKeyMap[remoteTrackKey] = { track: e.track, transceiver: e.transceiver };

		if (e.track.kind === 'video') {
			watchRTC.mapStream(e.streams[0].id, remoteTrackKey);
		}

		if (e.track.kind === 'video') {
			const isEventForCurrentPrimaryCamera =
				remoteTrackKey === this.primaryCameraState.currentPrimaryCamera;
			if (isEventForCurrentPrimaryCamera) {
				this._primaryMediaStream = new MediaStream([
					...this.primaryMediaStream.getAudioTracks(),
					e.track,
				]);
				this.onPrimaryMediaStreamChanged &&
					this.onPrimaryMediaStreamChanged(this.primaryMediaStream, e.transceiver);
			} else if (remoteTrackKey === 'nav_cam') {
				const navMediaStream = new MediaStream([e.track]);
				this.onNavMediaStreamChanged &&
					this.onNavMediaStreamChanged(navMediaStream, e.transceiver);
			}
		} else {
			// audio
			this._primaryMediaStream = new MediaStream([
				...this.primaryMediaStream.getVideoTracks(),
				e.track,
			]);
		}

		this.muteMediaTracksBasedOnPauseState();
		// TODO: notify the statistics sender that a remote media track is available
		// this.rtpStreamStatsSender.setStatsSource(key, e.track);
	};

	private _onDataChannel = (ev: RTCDataChannelEvent) => {
		console.info('ondatachannel', ev);
		/** Labels of the other datachannel, which is not used for navigation-related stuff */
		const nonNavLabels = [NON_NAV_DATACHANNEL_LABEL, NON_NAV_DATACHANNEL_LABEL__LEGACY];
		if (nonNavLabels.includes(ev.channel.label)) {
			this.nonNavDatachannel = ev.channel;
		}
		this.onDataChanel && this.onDataChanel(ev.channel);
	};

	private _onICEGatheringStateChange = (ev: Event) => {
		console.info('ice-gathering-state ', this.pc?.iceGatheringState);
	};

	/** Setup a timeout to end peer connection if `failed` for some time */
	private handleFailedConnectionState = () => {
		if (this.connectionState === 'failed') {
			// a timeout has already been scheduled, abort
			if (this.failedConnStateTimeoutID !== undefined) return;

			const isAllSendersFailed = (this.pc?.getSenders() || []).every(
				sender =>
					sender.transport?.state === 'failed' || sender.transport?.state === 'closed'
			);
			const isAllReceiversFailed = (this.pc?.getReceivers() || []).every(
				receiver =>
					receiver.transport?.state === 'failed' || receiver.transport?.state === 'closed'
			);

			console.log({ isAllReceiversFailed, isAllSendersFailed }, 'FAILED_TRANSPORTS');

			this.failedConnStateTimeoutID = setTimeout(
				() => this.end('FAILED_STATE_TIMED_OUT'),
				FAILED_CONNECTION_TIMEOUT__MS
			);
		} else {
			if (this.failedConnStateTimeoutID !== undefined) {
				clearTimeout(this.failedConnStateTimeoutID);
				this.failedConnStateTimeoutID = undefined;
			}
		}
	};

	private _onConnectionStateChange = () => {
		console.debug('peerConnection._onConnectionStateChange ', this.connectionState);
		this.handleFailedConnectionState();
		this.onConnectionStateChange && this.onConnectionStateChange(this.connectionState);
	};

	private _onICEConnectionStateChange = () => {
		const iceConnectionState = this.pc?.iceConnectionState;
		console.debug('peerConnection._onICEConnectionStateChange ', this.connectionState);
		if (iceConnectionState === 'failed' || iceConnectionState === 'disconnected') {
			this.signallingClient
				.sendICERestartRequest()
				.catch(error => console.error('Error prompting iceRestart', error));
		}
	};

	/** Callback to handle ICE candidates generated from this local */
	private onLocalICE = async (e: RTCPeerConnectionIceEvent) => {
		if (!e.candidate) {
			console.debug('peerConnection.onLocalICE NULL');
			return;
		}

		return this.signallingClient
			.sendICECandidateToPeer({
				sdpMLineIndex: e.candidate.sdpMLineIndex!,
				candidate: e.candidate?.candidate || null,
			})
			.catch(error => {
				console.error('peerConnection.onLocalICE', error);
			});
	};

	/** Callback to handle sdp from peer */
	private onPeerSDP = async (data: SDPPayload) => {
		console.log('peerConnection.onPeerSDP');

		const { id: key, ...offer } = data;
		if (offer.type !== 'offer') {
			console.error('peerConnection.onPeerSDP Invalid remote SDP type', offer);
			return;
		} else if (this.pc === undefined) {
			console.error('this.pc is not defined. Call this.setupPeerConnection() first');
			return;
		}

		console.debug('onPeerSDP\n', offer.sdp);
		try {
			// set received offer from peer
			await this.pc.setRemoteDescription(offer);
			// set corresponding answer for the received offer
			await this.pc.setLocalDescription(await this.pc.createAnswer());
			// send answer to peer, via signalling channel
			// We use the same key as what the remote peer sent, to indicate that this answer is for that specific offer
			await this.signallingClient.sendSDPToPeer({
				id: key,
				// RTCSessionDescription doesn't seem to support spread operator.
				// So we have to manually copy the properties
				type: this.pc.localDescription!.type,
				sdp: this.pc.localDescription!.sdp,
			});
		} catch (error) {
			// catch any errors and log them only.
			// We really don't want to be throwing here in this callback
			console.error('peerConnection.onPeerSDP', error);
		}
	};

	/** Callback to handle ice from peer */
	private onPeerICE = async (data: ICEPayload) => {
		if (this.pc === undefined) {
			console.error('this.pc is not defined. Call this.setupPeerConnection() first');
			return;
		}
		console.debug('peerConnection.onPeerICE', data.candidate, data.sdpMLineIndex);
		try {
			await this.pc.addIceCandidate({
				candidate: data.candidate!, // TODO: Check that the incoming candidate is never null
				sdpMLineIndex: data.sdpMLineIndex,
			});
		} catch (err) {
			console.error('peerConnection.onPeerICE error: ', err);
		}
	};

	/** Used to time out and end a session when it remains in the failed state for too long */
	private failedConnStateTimeoutID: ReturnType<typeof setTimeout> | undefined;
	/** Used to time out and end session when it is paused for too long */
	private pausedConnTimeoutID: ReturnType<typeof setTimeout> | undefined;

	public promptIceRestart = () => {
		watchRTC.addEvent({ type: 'global', name: 'promptForIceRestart', parameters: {} });

		this.signallingClient.sendICERestartRequest().catch((error: Error) => {
			watchRTC.addEvent({
				type: 'global',
				name: 'promptForIceRestartError',
				parameters: { error },
			});
			console.error('Error prompting iceRestart', error);
		});
	};

	public togglePrimaryCamera = async (): Promise<RobotPrimaryCamera> => {
		const isSwitchingPrimaryCamera = this.primaryCameraState.isChangingPrimaryCameraTo !== null;
		if (isSwitchingPrimaryCamera) {
			throw new Error(`Cannot switch camera. Already switching`);
		}

		const toCameraType: RobotPrimaryCamera =
			this.primaryCameraState.currentPrimaryCamera === RobotPrimaryCamera.WIDE_CAM
				? RobotPrimaryCamera.ZOOM_CAM
				: RobotPrimaryCamera.WIDE_CAM;

		watchRTC.addEvent({
			type: 'global',
			name: 'switchingCamera',
			parameters: {
				toCameraType,
				currentCameraType: this.primaryCameraState.currentPrimaryCamera,
			},
		});

		this._onPrimaryCameraStateChanged({
			...this.primaryCameraState,
			isChangingPrimaryCameraTo: toCameraType,
		});
		const _switch = async () => {
			// eslint-disable-next-line camelcase
			if (!this.capabilities?.super_zoom_1)
				throw new Error('GoBeSuperZoom1 is not enabled for this peer connection');

			type ICommand = 'REQUEST_CAMERA_SWITCH' | 'SHOULD_SWITCH_CAMERA';
			type IEvent =
				| 'CAN_SWITCH_CAMERA'
				| 'CANNOT_SWITCH_CAMERA'
				| 'DID_SWITCH_CAMERA'
				| 'FAILED_TO_SWITCH_CAMERA'
				| 'INVALID_CAMERA_SWITCH_MESSAGE';
			function isEventType(value: string): value is IEvent {
				const possibleValues: Record<IEvent, any> = {
					CAN_SWITCH_CAMERA: true,
					CANNOT_SWITCH_CAMERA: true,
					DID_SWITCH_CAMERA: true,
					FAILED_TO_SWITCH_CAMERA: true,
					INVALID_CAMERA_SWITCH_MESSAGE: true,
				};
				return Object.keys(possibleValues).includes(value);
			}
			/** Send a message to the remote peer over datachannel and wait for a response */
			const makeRequest = (
				dataChannel: RTCDataChannel,
				request: { type: ICommand; value: string; id: string },
				timeoutMs: number = REQUEST_TIMEOUT_MS
			) => {
				return new Promise<{ type: IEvent; value: string }>((resolve, reject) => {
					const onDataChannelMessage = (e: MessageEvent) => {
						const [type, value, forRequestId] = (e.data as string).split(' ');
						if (forRequestId !== request.id) return;

						clearTimeout(timeoutId);
						dataChannel.removeEventListener('message', onDataChannelMessage);

						if (isEventType(type)) resolve({ type: type as IEvent, value });
						else
							reject(
								new Error(`INVALID_RESPONSE ${JSON.stringify({ type, value })}`)
							);
					};
					dataChannel.addEventListener('message', onDataChannelMessage);
					const timeoutId = setTimeout(() => {
						dataChannel.removeEventListener('message', onDataChannelMessage);
						reject(new Error('NO_RESPONSE'));
					}, timeoutMs);
					dataChannel.send(`${request.type} ${request.value} ${request.id}`);
				});
			};

			if (!this.nonNavDatachannel) {
				throw new Error('Datachannel has not been initialized');
			}

			const requestId = generateUUID();
			const REQUEST_TIMEOUT_MS = 10 * 1000;

			await makeRequest(this.nonNavDatachannel, {
				type: 'REQUEST_CAMERA_SWITCH',
				value: toCameraType,
				id: requestId,
			}).then(response => {
				if (response.type === 'CAN_SWITCH_CAMERA') {
					// const mid = Number.parseInt(response.value);
					// const expectedMediaTrackMidKey = `video${mid}`;
					// this.remoteTracksMidsMap[expectedMediaTrackMidKey] = toCameraType;
				} else if (response.type === 'CANNOT_SWITCH_CAMERA')
					throw new Error(`Cannot switch camera. Reason: ${response.value}`);
				else throw Error(`INVALID_RESPONSE: ${JSON.stringify(response)}`);
			});
			return makeRequest(this.nonNavDatachannel!, {
				type: 'SHOULD_SWITCH_CAMERA',
				value: toCameraType,
				id: requestId,
			}).then(response => {
				if (response.type === 'DID_SWITCH_CAMERA') {
					return toCameraType;
				} else if (response.type === 'FAILED_TO_SWITCH_CAMERA')
					throw new Error(`Failed to switch camera. Reason: ${response.value}`);
				else throw new Error(`INVALID_RESPONSE: ${JSON.stringify(response)}`);
			});
		};
		return _switch()
			.then(newCameraType => {
				const mediaTrack = this.remoteMediaKeyMap[newCameraType]?.track;
				if (mediaTrack) {
					this._primaryMediaStream = new MediaStream([
						...this.primaryMediaStream.getAudioTracks(),
						mediaTrack,
					]);
					const transceiver = this.remoteMediaKeyMap[newCameraType]!.transceiver;
					this.onPrimaryMediaStreamChanged &&
						this.onPrimaryMediaStreamChanged(this.primaryMediaStream, transceiver);
				}

				watchRTC.addEvent({
					type: 'global',
					name: 'switchCameraSuccess',
					parameters: {
						toCameraType,
						currentCameraType: this.primaryCameraState.currentPrimaryCamera,
					},
				});

				this._onPrimaryCameraStateChanged({
					currentPrimaryCamera: newCameraType,
					isChangingPrimaryCameraTo: null,
				});
				return newCameraType;
			})
			.catch(error => {
				watchRTC.addEvent({
					type: 'global',
					name: 'switchCameraError',
					parameters: {
						toCameraType,
						currentCameraType: this.primaryCameraState.currentPrimaryCamera,
						error,
					},
				});
				this._onPrimaryCameraStateChanged({
					...this.primaryCameraState,
					isChangingPrimaryCameraTo: null,
				});
				throw error;
			});
	};
}

class SessionStateMachine {
	protected validStateTransitions: Record<SessionState, SessionState[]> = {
		NotInitialized: ['InProgress', 'Ended'],
		InProgress: ['Paused', 'Retrying', 'Ended'],
		Paused: ['InProgress', 'Retrying', 'Ended'],
		Retrying: ['InProgress', 'Ended'],
		Ended: [], // <-- cannot go to any other state from here
	};

	private _state: SessionState = 'NotInitialized';

	public get state(): SessionState {
		return this._state;
	}

	public goToState(transition: {
		from: SessionState | SessionState[];
		to: SessionState;
	}): boolean {
		const isExpectedCurrentState = (Array.isArray(transition.from)
			? transition.from
			: [transition.from]
		).includes(this._state);
		const isValidTransition = this.validStateTransitions[this._state].includes(transition.to);

		if (isExpectedCurrentState && isValidTransition) {
			this._state = transition.to;
			return true;
		} else {
			console.warn(
				'Invalid state transition aborted.\n' +
					`Requested transition: ${transition}.\n` +
					`Valid transitions: ${JSON.stringify({
						curr: this._state,
						next: this.validStateTransitions[this._state],
					})}`
			);
			return false;
		}
	}
}
