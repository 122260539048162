import React, { FC } from 'react';
import classes from '../PasswordRequirements/PasswordRequirements.module.css';
import { Box, Popper, styled } from '@material-ui/core';

const StyledPopper = styled(Popper)(() => ({
	marginTop: 6,
	height: 146,
	width: 266,
	'& .MuiPopover-paper': {
		height: 146,
	},
}));
const StyledUnorderedList = styled('ul')(() => ({
	margin: 0,
	padding: '17px 16px 16px 16px',
	display: 'block',
}));

const Styledlist = styled('li')(() => ({
	paddingBottom: 8,
	fontSize: 15,
	fontFamily: 'Roboto',
}));
const StyledBox = styled(Box)(() => ({
	background: '#fff',
	boxShadow: '0px 2px 10px #00000027',
	paddingLeft: 17,
	borderRadius: 10,
}));

interface PasswordRequirementsProps {
	anchorEl: any;
	open: any;
	valueList: any;
}

const PasswordRequirementPopper: FC<PasswordRequirementsProps> = ({
	anchorEl,
	open,
	valueList,
}) => {
	return (
		<StyledPopper id="popover" open={open} anchorEl={anchorEl} placement="bottom-start">
			<StyledBox>
				<StyledUnorderedList>
					{valueList &&
						valueList.map((item: any) => {
							return (
								<Styledlist
									key="length"
									className={item.valid ? classes.valid : classes.invalid}
								>
									{item.value}
									<br />
								</Styledlist>
							);
						})}
				</StyledUnorderedList>
			</StyledBox>
		</StyledPopper>
	);
};
export default PasswordRequirementPopper;
