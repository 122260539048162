import { ComponentProps } from 'react';
import NavigationVideo from './videos/navigationVideo';
import SessionOptions from '../../components/sessionOptions';
import React from 'react';
import useDelayedShowOrHide from '../../utils/useDelayedShowOrHide';

type NavVideoProps = ComponentProps<typeof NavigationVideo>;
type SessionOptionsProps = ComponentProps<typeof SessionOptions>;
type Props = {
	isNavigating: boolean;
} & NavVideoProps &
	SessionOptionsProps;

export default function NavViewWithSessionOptions(props: Props) {
	const isSessionOptionsVisible = useDelayedShowOrHide(!props.isNavigating, {
		showDelayMs: 1000,
		hideDelayMs: 0,
	});

	return (
		<div>
			{isSessionOptionsVisible ? (
				<SessionOptions {...(props as SessionOptionsProps)} />
			) : null}
			<NavigationVideo {...(props as NavVideoProps)} />
		</div>
	);
}
