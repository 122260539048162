import { MqttRouter } from 'mqtt-sub-router';
import { store } from '../store/store';

const SetPasswordRouter = new MqttRouter()
	.listen(
		'accountConfirmation/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			store.dispatch({
				type: `CONFIRM_ACCOUNT_RESPONSE`,
				payload: { requestId, status, data },
			});
		}
	)
	.listen(
		'acceptInvite/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			store.dispatch({
				type: `ACCEPT_INVITE_RESPONSE`,
				payload: { requestId, status, data },
			});
		}
	)
	.listen(
		'generatePasswordResetToken/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			store.dispatch({
				type: `PASSWORD_RESET`,
				payload: { requestId, status },
			});
		}
	);

export default SetPasswordRouter;
