import { b64DecodeUnicode } from '../utils/encoding';
const camelCaseKeys = require('camelcase-keys');
const {
	middlewares: { errorHandlingMiddlewareBuilder },
} = require('mqtt-sub-router');
const uuidRegex = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;
/** @param {string} message
 * @returns {string}
 */
const prepareMessageForPublish = message => {
	let preparedMessage;
	// convert UUIDs to regular string
	try {
		preparedMessage = JSON.parse(JSON.stringify(message));
	} catch (error) {
		console.debug(
			'prepareMessageForPublish: Message cannot be json-manipulated.\nThis log is only for debugging purposes',
			error
		);
	}
	try {
		preparedMessage = camelCaseKeys(preparedMessage, { deep: true, exclude: [uuidRegex] });
	} catch (error) {
		console.debug(
			`prepareMessageForPublish: message is not of type {object | object[]}.\nThis log is only for debugging purposes`,
			error
		);
	}
	try {
		preparedMessage = JSON.stringify(preparedMessage);
	} catch (error) {
		console.debug(
			'prepareMessageForPublish: message cannot be json stringified.\nThis log is only for debugging purposes',
			error
		);
	}
	return preparedMessage;
};
const utils = { uuidRegex, prepareMessageForPublish };
/** @type {import('mqtt-sub-router').MqttMiddleware<{publish: import('./types').publish}>} */
const injectedPublisherMiddleware = next => args => {
	/** @type {import('./types').publish} */
	const publish = (topic, message, options, callback) => {
		// using 'prepareMessageForPublish' from utils, is necessary to spyOn 'prepareMessageForPublish'
		let finalMessage = utils.prepareMessageForPublish(message);
		args.mqttClient.publish(topic, finalMessage, options, err => {
			if (err) {
				console.error(`An error occurred when PUBLISHing to topic: '${topic}'`);
				console.error(err);
			} else console.debug(`Successfully PUBLISHed to '${topic}'`);
			if (callback) callback(err);
		});
	};
	// add the publish function as an argument
	next({ ...args, publish });
};
/** @type {import('mqtt-sub-router').MqttMiddleware<{
 * 	publish: import('./types').publish,
 * 	emitEvent: import('./types').emitEvent
 * }>} */
const injectedEventEmitterMiddleware = next => args => {
	const { publish } = args;
	if (publish === undefined)
		throw Error(
			'`injectedEventEmitterMiddleware` requires `injectedPublisherMiddleware` in the right order'
		);
	const emitEvent = (event, data, microserviceName, callback) => {
		publish(
			`microservice/events/${event}`,
			{ data, microservice: microserviceName },
			undefined,
			callback
		);
	};
	next({ ...args, emitEvent });
};
const sendErrorToRemoteLoggingService = error => {
	// add logic to report errors by sending to a remote service of some sorts
	// 	or saving to cassandra
	console.error('Error has been logged to remote service');
};
/** Catches errors; publishes error to sender and reports to remote service
 * @type {import('mqtt-sub-router').MqttMiddleware<any>}
 */
const errorMiddleware = errorHandlingMiddlewareBuilder(
	// 'publish' Function expected to be passed from injectedPublisherMiddleware
	({ error, message, params, topic, mqttClient, publish }) => {
		console.error(
			`An error occurred when message: '${message}' was received on topic: '${topic}' with params: '${params}'`
		);
		console.error(error);
		const senderId = params.userId || params.deviceId;
		if (senderId)
			publish(`${senderId}/errors`, {
				type: 'SYSTEM_ERROR',
				topic,
				message,
				params,
			});
		sendErrorToRemoteLoggingService(error, mqttClient);
	}
);
/** @type {import('mqtt-sub-router').MqttMiddleware<{decodedEmail: string}>}
 * MqttMiddleware that decodes encodedEmail in params and makes it available in args as decodedEmail.
 *
 * To use it, ensure there is an :encodedEmail param in your routes
 */
const decodeEmailMiddleware = next => args => {
	let decodedEmail = undefined;
	try {
		decodedEmail =
			'encodedEmail' in args.params ? b64DecodeUnicode(args.params.encodedEmail) : undefined;
	} catch (_) {
		console.debug('decodeEmailMiddleware: `encodedEmail` param not found');
	}
	next({ ...args, decodedEmail });
};
/** @type {import('mqtt-sub-router').MqttMiddleware<{
 * 	data: {[x: string]: any} | {[x: string]: any}[],
 * 	requestId: string
 * }>} */
const messagePreProcessorMiddleware = next => args => {
	let { data, requestId, status } = args.message || {};
	next({ ...args, data, requestId, status });
};
/**
 * A middleware that parses incoming message as JSON string.
 * The resulting javascript object is passed on to the next `{@link MessageHandlerFunc}` as the message.
 * @type {import('mqtt-sub-router').MqttMiddleware<{}>}
 */
const jsonMessageMiddleware = next => {
	return args => {
		const { message } = args;
		let messageParsedAsJSON = args.message;
		if (typeof args.message === 'string') {
			try {
				messageParsedAsJSON = JSON.parse(message);
			} catch (err) {
				console.debug(
					'jsonMessageMiddleware: Unable to parse message as JSON.\nThis log is only for debugging purposes',
					err
				);
			}
		}
		next({ ...args, message: messageParsedAsJSON });
	};
};
/**
 * Default middlewares that will be registered on all routes.
 *
 * 1. Attempt to parse message as a json string
 * 2. Convert incoming message keys from camelCase to snake_case
 * 3. Add a publish function to `args` in  handler funcs
 * 4. Add an emitEvent function to `args` in handler funcs
 * 5. Trap uncaught exceptions and log them
 *
 * NB: The order of middlewares matters a lot !
 */
const defaultMiddlewares = [
	errorMiddleware,
	jsonMessageMiddleware,
	messagePreProcessorMiddleware,
	injectedPublisherMiddleware,
	injectedEventEmitterMiddleware,
];
export {
	errorMiddleware,
	injectedEventEmitterMiddleware,
	injectedPublisherMiddleware,
	decodeEmailMiddleware,
	messagePreProcessorMiddleware,
	jsonMessageMiddleware,
	utils,
	defaultMiddlewares,
};
