import {
	SET_USER_GROUP,
	UPDATE_USER_GROUP,
	UPDATE_USER_GROUP_USERS,
	UPDATE_USER_GROUP_DEVICE_GROUPS,
	DELETE_USER_GROUP,
	DELETE_DEVICE_GROUP,
	FETCH_USER_GROUPS_BEGIN,
	FETCH_USER_GROUPS_SUCCESS,
	FETCH_USER_GROUPS_FAILURE,
	SET_USER_GROUPS_SORT_PARAMS,
	RESET_APP,
	INSERT_NEW_USER_GROUP,
	CHANGE_ADD_USERS_TO_GROUP_STATE,
	USERS_ADDED_TO_GROUP_STATE,
	CREATED_NEW_USER_GROUP,
} from '../actions/types';
import { UserGroup } from '../types/types';

export interface initialUserGroupState {
	userGroupsByOrganizationId: Record<string, Record<string, UserGroup>>;
	items: Array<UserGroup>;
	loading: boolean;
	error: string | null;
	sortParams: Record<string, string>;
	addUsersOpen: boolean;
	addUsersCount: number;
	createdGroup: any;
}

const initialState: initialUserGroupState = {
	userGroupsByOrganizationId: {},
	items: [],
	loading: false,
	error: null,
	sortParams: { key: 'userGroupName', order: 'asc' },
	addUsersOpen: false,
	addUsersCount: 0,
	createdGroup: null,
};

export default function UserGroupsReducer(
	state = initialState,
	action: { type: string; payload: Record<string, any> }
) {
	let organizationId: string;
	let userGroup: UserGroup;
	let index;
	switch (action.type) {
		case FETCH_USER_GROUPS_BEGIN:
			return {
				...state,
				loading: true,
				error: null,
			};

		case FETCH_USER_GROUPS_SUCCESS:
			const groups: Record<string, UserGroup> = {};
			let items = state.items;
			organizationId = '';
			action.payload.forEach((element: UserGroup) => {
				organizationId = element.orgId;
				groups[element.userGroupId] = element;

				if (!items.find(item => item.userGroupId === element.userGroupId)) {
					items.push(element);
				}
			});
			return {
				...state,
				loading: false,
				items: items,
				userGroupsByOrganizationId: {
					...state.userGroupsByOrganizationId,
					[organizationId]: groups,
				},
			};

		case FETCH_USER_GROUPS_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload.error,
			};

		case SET_USER_GROUP:
			organizationId = action.payload.orgId;
			state.items.push(action.payload as UserGroup);
			return {
				...state,
				loading: false,
				items: [...state.items],
				userGroupsByOrganizationId: {
					...state.userGroupsByOrganizationId,
					[organizationId]: {
						...state.userGroupsByOrganizationId[organizationId],
						[action.payload.userGroupId]: action.payload,
					},
				},
			};

		case INSERT_NEW_USER_GROUP:
			organizationId = action.payload.orgId;
			state.items.push(action.payload as UserGroup);

			return {
				...state,
				loading: false,
				items: [...state.items],
				userGroupsByOrganizationId: {
					...state.userGroupsByOrganizationId,
					[organizationId]: {
						...state.userGroupsByOrganizationId[organizationId],
						[action.payload.userGroupId]: action.payload,
					},
				},
			};
		case UPDATE_USER_GROUP:
			userGroup = action.payload as UserGroup;
			organizationId = userGroup.orgId;
			index = state.items.findIndex(u => u.userGroupId === userGroup.userGroupId);
			state.items[index] = userGroup;
			state.userGroupsByOrganizationId[organizationId][userGroup.userGroupId] = userGroup;

			return {
				...state,
				loading: false,
				items: [...state.items],
				userGroupsByOrganizationId: {
					...state.userGroupsByOrganizationId,
				},
			};
		case UPDATE_USER_GROUP_USERS:
			let userGroupUsers = action.payload;
			organizationId = userGroupUsers.orgId;
			index = state.items.findIndex(u => u.userGroupId === userGroupUsers.userGroupId);
			if (index < 0) return state;

			userGroup = state.items[index];
			for (const key in userGroupUsers) {
				if (key === 'addedUsersIds') {
					userGroup.usersIds
						? (userGroup.usersIds = userGroup.usersIds.concat(userGroupUsers[key]))
						: (userGroup.usersIds = userGroupUsers[key]);
				} else if (key === 'removedUsersIds') {
					for (let i = 0; i < userGroupUsers[key].length; i++) {
						const ind = userGroup.usersIds.indexOf(userGroupUsers[key][i]);
						if (ind > -1) {
							userGroup.usersIds.splice(ind, 1);
						}
					}
				}
			}
			state.items[index] = userGroup;
			state.userGroupsByOrganizationId[organizationId][userGroup.userGroupId] = userGroup;

			return {
				...state,
				loading: false,
				items: [...state.items],
				userGroupsByOrganizationId: {
					...state.userGroupsByOrganizationId,
					[organizationId]: state.userGroupsByOrganizationId[organizationId],
				},
			};
		case UPDATE_USER_GROUP_DEVICE_GROUPS:
			let userGroupDeviceGroups = action.payload;
			organizationId = userGroupDeviceGroups.orgId;
			index = state.items.findIndex(u => u.userGroupId === userGroupDeviceGroups.userGroupId);
			if (index < 0) return state;

			userGroup = state.items[index];

			if (
				userGroupDeviceGroups.addedDeviceGroupsIds &&
				userGroupDeviceGroups.addedDeviceGroupsIds.length > 0
			) {
				userGroup.deviceGroupsIds = Array.isArray(userGroup.deviceGroupsIds)
					? userGroup.deviceGroupsIds
					: [];
				userGroup.deviceGroupsIds.push(...userGroupDeviceGroups.addedDeviceGroupsIds);
			}

			if (
				userGroupDeviceGroups.removedDeviceGroupsIds &&
				userGroupDeviceGroups.removedDeviceGroupsIds.length > 0
			) {
				for (let i = 0; i < userGroupDeviceGroups.removedDeviceGroupsIds.length; i++) {
					const index = userGroup.deviceGroupsIds?.indexOf(
						userGroupDeviceGroups.removedDeviceGroupsIds[i]
					);
					if (index > -1) {
						userGroup.deviceGroupsIds.splice(index, 1);
					}
				}
			}

			state.items[index] = userGroup;
			state.userGroupsByOrganizationId[organizationId][userGroup.userGroupId] = userGroup;

			return {
				...state,
				loading: false,
				items: [...state.items],
				userGroupsByOrganizationId: {
					...state.userGroupsByOrganizationId,
				},
			};
		case DELETE_USER_GROUP:
			const deletedGroup = action.payload;
			organizationId = deletedGroup.orgId;
			index = state.items.findIndex(u => u.userGroupId === deletedGroup.userGroupId);
			state.items.splice(index, 1);

			delete state.userGroupsByOrganizationId[organizationId][deletedGroup.userGroupId];
			return {
				...state,
				loading: false,
				items: [...state.items],
				userGroupsByOrganizationId: {
					...state.userGroupsByOrganizationId,
				},
			};
		case DELETE_DEVICE_GROUP:
			const deletedDeviceGroup = action.payload;
			organizationId = deletedDeviceGroup.orgId;
			const deviceGroupUserGroups = deletedDeviceGroup.userGroupsIds || [];
			for (let i = 0; i < deviceGroupUserGroups.length; i++) {
				const userGroupIndex = state.items.findIndex(
					u => u.userGroupId === deviceGroupUserGroups[i]
				);
				if (userGroupIndex === -1) continue;
				const userGroup = state.items[userGroupIndex];

				const index = userGroup.deviceGroupsIds.indexOf(deletedDeviceGroup.deviceGroupId);
				if (index > -1) userGroup.deviceGroupsIds.splice(index, 1);

				state.items[userGroupIndex] = userGroup;
				state.userGroupsByOrganizationId[organizationId][
					deviceGroupUserGroups[i]
				] = userGroup;
			}

			return {
				...state,
				loading: false,
				items: [...state.items],
				userGroupsByOrganizationId: {
					...state.userGroupsByOrganizationId,
				},
			};
		case SET_USER_GROUPS_SORT_PARAMS:
			return {
				...state,
				sortParams: action.payload.sortParams,
			};

		case RESET_APP:
			return {
				...state,
				userGroupsByOrganizationId: {},
				items: [],
				loading: false,
				error: null,
			};
		case CHANGE_ADD_USERS_TO_GROUP_STATE:
			return {
				...state,
				addUsersOpen: action.payload.userGroups,
			};
		case USERS_ADDED_TO_GROUP_STATE:
			return {
				...state,
				addUsersCount: action.payload.addUsersCount,
			};
		case CREATED_NEW_USER_GROUP:
			return {
				...state,
				createdGroup: action.payload.createdGroup,
			};
		default:
			// ALWAYS have a default case in a reducer
			return state;
	}
}
