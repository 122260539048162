import classNames from 'classnames';
import React from 'react';
import {
	IoCloudDownload,
	IoCloudOffline,
	IoCog,
	IoPause,
	IoPlay,
	IoRemoveCircle,
} from 'react-icons/io5';
import { IDeviceCurrentState } from '../../../../../../utils/statusConverter';
import '../../index.scss';
import { ReadyForCallStatusIcon } from '../ReadyForCall/ReadyForCallStatusIcon';

interface Props {
	status: IDeviceCurrentState;
	isGuest: boolean;
	isCalling: boolean;
}

export const DefaultStatusIcon = ({ status, isGuest, isCalling }: Props) => {
	switch (status) {
		case 'inSession':
			return isCalling ? (
				<ReadyForCallStatusIcon isCalling={isCalling} />
			) : (
				<IoPlay
					className={classNames('statusIcon', {
						goBePrimaryColor: isGuest,
					})}
				/>
			);
		case 'available':
			return <ReadyForCallStatusIcon isCalling={isCalling} />;
		case 'configuring':
			return <IoCog className="statusIcon" />;
		case 'onHold':
			return <IoPause className="statusIcon" />;
		case 'unavailable':
			return <IoRemoveCircle className="statusIcon" />;
		case 'updating':
			return <IoCloudDownload className="statusIcon" />;
		case 'offline':
			return <IoCloudOffline className="statusIcon" />;
		default:
			return null;
	}
};
