import React, { FC, useEffect, useState } from 'react';
import { IonGrid, IonRow, IonList, IonListHeader, IonLabel, IonItem } from '@ionic/react';
import { FormattedMessage, injectIntl } from 'react-intl';

import isAuthenticated from '../../components/Authentication/Authenticated';
import { publish } from '../../actions/publish';
import classes from './UserForm.module.css';
import classNames from 'classnames';
import Messages from './UserForm.messages';
import { User } from '../../types/types';
import { getSessionTimeDescription } from '../../utils/time';
import { useTypedSelector } from '../../reducers';
import { b64EncodeUnicode } from '../../utils/encoding';
import Sync from '../../assets/images/sync+.svg';
import SyncHover from '../../assets/images/sync+hover.svg';
import { styled, TableCell, Typography } from '@material-ui/core';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { url } from 'inspector';
import { images } from 'ionicons/icons';
import moment from 'moment';
import Table from '../Table/Table';
interface UserFormProps {
	user: User;
	orgId: string;
}
const StyledUserDetails = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
}));
const StyledSync = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'row',
	marginRight: 16,
	'&:hover': {
		cursor: 'pointer',
		'& div': {
			backgroundImage: `url(${SyncHover})`,
			backgroundRepeat: 'no-repeat',
		},
		'& p': {
			color: '#1e1f22',
		},
	},
}));
const StyledSuccessMessage = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'row',
	marginRight: 16,
}));
const StyledImage = styled('div')(() => ({
	backgroundImage: `url(${Sync})`,
	backgroundRepeat: 'no-repeat',
	height: 23,
	width: 27,
}));
const StyledTypography = styled(Typography)(() => ({
	fontFamily: 'Roboto',
	fontSize: 15,
	color: '#78787a',
	paddingLeft: 4,
	textTransform: 'none',
}));
const Styledlabel = styled(Typography)(() => ({
	color: '#56ae4d',
	fontSize: 15,
	textTransform: 'none',
	fontFamily: 'Roboto',
	paddingLeft: 8,
}));
const StyledCheckMarkOutline = styled(IoCheckmarkCircleOutline)(() => ({
	color: '#56ae4d',
	height: 18,
	width: 18,
}));
const StyledUserDetailsLabel = styled(Typography)(() => ({
	fontWeight: 'bold',
	fontSize: 15,
}));
const StyledSubtitle = styled(Typography)(() => ({
	fontFamily: 'Industry',
	fontWeight: 'bold',
	color: 'var(--ion-color-gray)',
}));
const StyledGroup = styled(Typography)(() => ({
	marginRight: 8,
	backgroundColor: '#eeeeee',
	borderRadius: 5,
	padding: '5px 8px',
	display: 'inline',
	marginBottom: 3,
}));
const StyledTableCell = styled(TableCell)(() => ({
	borderColor: '#f1f1f3',
}));
const UserFormNonEditable: FC<UserFormProps> = (props: any) => {
	const { orgId, user } = props;
	const [userAdded] = useState(new Date(user.dateJoined || 1));
	const [isAdmin, setIsAdmin] = useState(false);
	const [lastLogin, setLastLogin] = useState('never');
	const [timeAgo, setTimeAgo] = useState('never');
	const [userInfo, setUserInfo] = useState(JSON.parse(JSON.stringify(user)));
	const [resendInvitationStatus, setResendInvitationStatus] = useState(false);
	const { username, firstName, lastName } = user;
	const encodedUser = b64EncodeUnicode(username);
	const loggedInUser = useTypedSelector(state => state.accountState.user.username);

	const userGroups = useTypedSelector(
		state => state.userGroupsState.userGroupsByOrganizationId[orgId]
	);
	const deviceGroups = useTypedSelector(
		state => state.deviceGroupsState.deviceGroupsByOrganizationId[orgId]
	);
	const users = useTypedSelector(state => state.usersState.usersByOrganizationId);
	const spinoutType = useTypedSelector(state => state.versionState.spinoutType) as string;

	useEffect(() => {
		if (users) {
			const u = users[orgId][username];
			setIsAdmin(u.role && u.role?.match(/admin|Admin/g) ? true : false);
			setUserInfo(JSON.parse(JSON.stringify(u)));
		}
	}, [orgId, username, users, firstName, lastName]);

	useEffect(() => {
		setTimeAgo(
			getSessionTimeDescription(
				user.lastLogin ? new Date(user.lastLogin) : undefined,
				new Date()
			)
		);
		if (user.lastLogin) {
			setLastLogin(moment(user.lastLogin).format('MMMM DD, YYYY, h:mm a'));
		}
	}, [user.lastLogin]);

	/**
	 * @param {string} entity - The entity type to get current values for React-Select element
	 */
	const currentEntities = (
		entity: string,
		labelProperty: string,
		valueProperty: string,
		type?: string
	) => {
		let selectedEntities = [];
		let allEntities: any;
		let parameter: any;
		if (entity === 'userGroup') {
			allEntities = userGroups;
			parameter = 'userGroupsIds';
		}
		if (entity === 'deviceGroup') {
			allEntities = deviceGroups;
			parameter = 'deviceGroupsIds';
		}

		for (let key in allEntities) {
			if (userInfo != null && userInfo[parameter] != null) {
				for (let i in userInfo[parameter]) {
					if (entity === 'userGroup' || entity === 'deviceGroup') {
						if (allEntities[key][valueProperty] === userInfo[parameter][i]) {
							selectedEntities.push(allEntities[key]);
						}
					}
				}
			}
		}

		const options: any = [];
		if (selectedEntities != null && selectedEntities.length > 0) {
			const keyObj: any = {};
			for (let key in selectedEntities) {
				if (keyObj[key] === undefined) {
					keyObj[key] = key;
					options.push({
						label: selectedEntities[key][labelProperty],
						value: selectedEntities[key][valueProperty],
					});
				}
			}
		}

		if (type === 'label') {
			let optionsLabel: Array<any> = [];
			for (let option of options) {
				if (!optionsLabel.includes(option.label)) optionsLabel.push(option.label);
			}

			return optionsLabel;
		}
		return options;
	};

	const resendIncitationClick = () => {
		if (username === loggedInUser) {
			return;
		}
		const domainUrl = window.location.hostname;
		setResendInvitationStatus(true);
		publish(`microservice/${userInfo.orgId}/${b64EncodeUnicode(loggedInUser)}/inviteUser`, {
			requestId: 'someId',
			data: {
				firstName: userInfo.firstName,
				lastName: userInfo.lastName,
				username: userInfo.username,
				orgId: userInfo.orgId,
				spinoutType,
				domainUrl,
			},
		});
	};

	const renderLinkCell = (rowData: any) => {
		return (
			<>
				{rowData.hide ? null : (
					<>
						<StyledTableCell style={{ width: 200 }}>
							{rowData.type ? (
								<StyledUserDetailsLabel>{rowData.label}:</StyledUserDetailsLabel>
							) : (
								<StyledUserDetailsLabel>{rowData.label}</StyledUserDetailsLabel>
							)}
						</StyledTableCell>
						<StyledTableCell>{rowData.value}</StyledTableCell>{' '}
					</>
				)}
			</>
		);
	};
	const rows = [
		{
			label: <FormattedMessage {...Messages.name} />,
			value: userInfo.firstName + ' ' + userInfo?.lastName,
		},
		{
			label: <FormattedMessage {...Messages.email} />,
			value: username,
		},
		{
			label: <FormattedMessage {...Messages.administrator} />,
			value: isAdmin ? (
				<FormattedMessage {...Messages.yes} />
			) : (
				<FormattedMessage {...Messages.no} />
			),
		},
		{
			label: <FormattedMessage {...Messages.dateAdded} />,
			value: `${userAdded.getDate()}/${userAdded.getMonth() + 1}/${userAdded.getFullYear()}`,
		},

		// {
		// 	label: <FormattedMessage {...Messages.lastLogin} />,
		// 	value: lastLogin,
		// 	type: 'subheader',
		// 	hide: spinoutType !== 'beam' ? false : true,
		// },
		// {
		// 	label: <FormattedMessage {...Messages.softwareVersion} />,
		// 	value: '1.0.0',
		// 	type: 'subheader',
		// 	hide: spinoutType !== 'beam' ? false : true,
		// },
		{
			label: <FormattedMessage {...Messages.robotGroup} />,
			value: (
				<div style={{ display: 'flex', flexWrap: 'wrap' }}>
					{currentEntities('deviceGroup', 'name', 'deviceGroupId', 'label').map(
						(groupName: any, i: number) => {
							return <StyledGroup>{groupName}</StyledGroup>;
						}
					)}
				</div>
			),
			type: 'subheader',
			hide: spinoutType !== 'beam' ? false : true,
		},
		{
			label: <FormattedMessage {...Messages.userGroups} />,
			value: (
				<div style={{ display: 'flex', flexWrap: 'wrap' }}>
					{currentEntities('userGroup', 'name', 'userGroupId', 'label').map(
						(groupName: any, i: number) => {
							return <StyledGroup>{groupName}</StyledGroup>;
						}
					)}
				</div>
			),
			type: 'subheader',
			hide: spinoutType !== 'beam' ? false : true,
		},
	];
	const column = [
		{
			renderCell: (rowData: any) => renderLinkCell(rowData),
			dataField: 'value',
		},
	];
	return (
		<form className={classes.editForm} id="editUserForm">
			<IonGrid className={classes.formGrid}>
				<IonRow>
					<IonList>
						<IonListHeader>
							<IonLabel className={classes.labelFont}>
								<StyledUserDetails>
									<FormattedMessage {...Messages.userDetails} />
									{userInfo.status === 'pending' &&
									!resendInvitationStatus &&
									username !== loggedInUser ? (
										<StyledSync onClick={resendIncitationClick}>
											<StyledImage />
											<StyledTypography>Resend invitation</StyledTypography>
										</StyledSync>
									) : null}
									{resendInvitationStatus ? (
										<StyledSuccessMessage>
											<StyledCheckMarkOutline />
											<Styledlabel>User has been invited</Styledlabel>
										</StyledSuccessMessage>
									) : null}
								</StyledUserDetails>
							</IonLabel>
						</IonListHeader>
						<Table columns={column} rows={rows} hasHeader={false} />
					</IonList>
				</IonRow>
			</IonGrid>
		</form>
	);
};

export default injectIntl(isAuthenticated(UserFormNonEditable, 'UserFormNonEditable'));
