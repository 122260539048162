import React, { FC } from 'react';

interface RobotDateProps {
	robotId: string;
	orgId: string;
}

const RobotJoinedDate: FC<RobotDateProps> = props => {
	// const { robotId, orgId } = props;

	return <div>null</div>;
};

export default RobotJoinedDate;
