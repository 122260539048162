import React, { FC, useState, useEffect, Fragment, useCallback, useRef } from 'react';
import { IonGrid, IonRow, IonCol, IonIcon, IonCheckbox } from '@ionic/react';
import ReactPaginate from 'react-paginate';
import {
	ellipsisHorizontal,
	chevronForward,
	chevronBack,
	close,
	chevronUpOutline,
	chevronDownOutline,
} from 'ionicons/icons';

import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';

import { FormattedMessage } from 'react-intl';
import Messages from './ListGrid.messages';

import classes from './ListGrid.module.css';
import commonStyles from '../../theme/grid.module.css';
import classNames from 'classnames';
import { Popover, Typography, styled, Menu } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Checkbox from '../Checkbox/Checkbox';
interface ListGridProps {
	headers: {
		title: { id: string; defaultMessage: string; description: string };
		property: string;
		centered?: boolean;
		style?: any;
	}[];
	data: any | undefined;
	pageSize: number;
	noDataTemplate?: any;
	checkItem?: any;
	checkAll?: any;
	unCheckAll?: any;
	itemTemplate: (item: any, property: any, index?: any) => any;
	moreContent?: (item: any, onClosePopover: any) => React.ReactNode;
	onRowClick: (item: any) => void;
	handleOnCheck?: any;
	createdIndex?: number;
	sortType?: string;
	id?: string;
	listType: string;
	dataCy?: string;
}
const useStyles = makeStyles(theme => ({
	popover: {
		pointerEvents: 'none',
	},
	popoverContent: {
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		padding: 0,
		boxShadow: '0px 2px 10px #00000029',
		borderRadius: 5,
	},
}));
const StyledMenus = styled(Menu)(() => ({
	'& .MuiPopover-paper': {
		boxShadow: '0px 2px 10px #00000027',
		borderRadius: 5,
		paddingLeft: 6,
		paddingRight: 6,
	},
}));
const ListGrid: FC<ListGridProps> = (props: any) => {
	const {
		id,
		setParameter,
		headers,
		data,
		pageSize,
		noDataTemplate,
		itemTemplate,
		moreContent,
		onRowClick,
		handleOnCheck,
		createdIndex,
		sortType,
		checkAll,
		unCheckAll,
		checkItem,
		listType,
		dataCy,
	} = props;

	const [dataList, setDataList] = useState([]);
	const [currentPage, setCurrentPage] = useState(0);
	const [pageCount, setPageCount] = useState(0); // number of pages dependent of pageSize
	const [order, setOrder] = useState<'desc' | 'asc'>('asc');
	const [selectedSortKey, setSelectedSortKey] = useState(headers[0].property);
	const [anchorEl, setAnchorEl] = useState(null);
	const [openPopover, setPopoverOpen] = useState(false);
	const [popoverText, setPopoverText] = useState('');
	const [menuAnchorEl, setMenuAnchorEl] = useState(null);
	const [openMenuPopover, setOpenMenuPopover] = useState(false);
	const [popoverRow, setPopoverRow] = useState(null);
	const [isAllDataChecked, setIsAllDataChecked] = useState(false);
	const [isCheckedDataIndeterminate, setIsCheckedDataIndeterminate] = useState(false);

	const onSortChange = useCallback(
		(selected: any, sortOrder: 'desc' | 'asc') => {
			setParameter('sortParams', sortType, {
				key: selected,
				order: sortOrder,
			});
			setOrder(sortOrder);
			setSelectedSortKey(selected);
		},
		[setParameter, sortType]
	);

	useEffect(() => {
		setDataList(u => {
			const indexOfLastItem = (currentPage + 1) * pageSize;
			const indexOfFirstItem = indexOfLastItem - pageSize;
			const currentData = data.slice(indexOfFirstItem, indexOfLastItem);
			return currentData;
		});

		setPageCount(Math.ceil(data.length / pageSize));
		if (currentPage + 1 > pageCount) setCurrentPage(0);
	}, [currentPage, pageSize, data, pageCount]);
	useEffect(() => {
		if (createdIndex && createdIndex > -1) {
			setCurrentPage(Math.floor(createdIndex / pageSize));
		}
	}, [createdIndex, pageSize]);

	const Classes = useStyles();
	const onPagination = (data: any) => {
		setCurrentPage(data.selected);
	};
	const handleMenuOpen = (event: any, row: any) => {
		setOpenMenuPopover(true);
		setMenuAnchorEl(event.currentTarget);
		setPopoverRow(row);
	};
	const handleMenuClose = () => {
		setOpenMenuPopover(false);
		setMenuAnchorEl(null);
		setPopoverRow(null);
	};
	const handlePopoverClose = () => {
		setAnchorEl(null);
		setPopoverOpen(false);
	};

	useEffect(() => {
		switch(headers[0]?.selectedNumber) {
			case 0:
				setIsAllDataChecked(false);
				setIsCheckedDataIndeterminate(false);
				break;
			case data.length:
				setIsAllDataChecked(true);
				setIsCheckedDataIndeterminate(false);
				break;
			default:
				setIsAllDataChecked(false);
				setIsCheckedDataIndeterminate(true);
		}
	}, [data, headers]);

	const open = Boolean(anchorEl);
	return (
		<Fragment>
			<IonGrid className={classes.listGrid} id={id} data-cy={dataCy}>
				<IonRow className={classes.headerRow}>
					{headers && headers.length > 0 ? (
						<>
							{headers.map((header: any, i: number) => {
								return (
									<IonCol
										key={i}
										className={
											header.checkbox && sortType
												? header.centered
													? classNames(
														classes.centeredCol,
														classes.activeCol,
														header.hideMd && commonStyles.hideMd
													)
													: classNames(
														classes.activeCol,
														header.hideMd && commonStyles.hideMd
													)
												: classNames(header.hideMd && commonStyles.hideMd)
										}
										style={header.style}
									>
										{/* When transferring robot a checkbox is needed instead of column name */}
										{header.checkbox ? (
											<div className={classNames(classes.columnHeader)}>
												<Checkbox
													checked={isAllDataChecked}
													indeterminate={isCheckedDataIndeterminate}
													onChange={(e: any) => {
														e.target.checked ? checkAll() : unCheckAll();
													}}
												/>
												<div onClick={header.onUnCheck}>
													<IonIcon
														className={classNames(classes.closeIcon)}
														icon={close}
													/>
												</div>
												<span>
													<FormattedMessage
														{...Messages.selected}
														values={{
															selected: header.selectedNumber,
														}}
													/>
												</span>
											</div>
										) : (
											<div className={classes.columnHeader}>
												<div
													onClick={() =>
														sortType
															? onSortChange(
																header.property,
																order === 'asc' ? 'desc' : 'asc'
															)
															: null
													}
												>
													<FormattedMessage {...header.title} />
												</div>
												{sortType ? (
													<div className={classes.sortContainer}>
														<IonIcon
															className={
																header.property ===
																	selectedSortKey &&
																	order === 'asc'
																	? classNames(
																		classes.sortIcon,
																		classes.activeSort
																	)
																	: classes.sortIcon
															}
															size="small"
															icon={chevronUpOutline}
															onClick={() =>
																onSortChange(header.property, 'asc')
															}
														/>
														<IonIcon
															className={
																header.property ===
																	selectedSortKey &&
																	order === 'desc'
																	? classNames(
																		classes.sortIcon,
																		classes.activeSort
																	)
																	: classes.sortIcon
															}
															size="small"
															icon={chevronDownOutline}
															onClick={() => {
																console.log('icon click');

																onSortChange(
																	header.property,
																	'desc'
																);
															}}
														/>
													</div>
												) : null}
											</div>
										)}
									</IonCol>
								);
							})}
							<IonCol className={classes.moreCol} />
						</>
					) : (
						<IonCol sizeSm="12" />
					)}
				</IonRow>
				{dataList && dataList.length > 0 ? (
					dataList.map((item: any, i: number) => {
						return (
							<IonRow
								className={
									createdIndex && createdIndex === i + currentPage * pageSize
										? classNames(classes.dataRow, classes.createdRow)
										: classes.dataRow
								}
								key={i * headers.length}
								onClick={() => onRowClick(item)}
								data-cy={(item.username ?? item.name) + '_profile_button'}
							>
								{headers.map((header: any, j: number) => {
									return (
										<IonCol
											key={i + j}
											style={header.style}
											className={classNames(
												header.hideMd && commonStyles.hideMd
											)}
											aria-owns={open ? 'mouse-over-popover' : undefined}
											aria-haspopup="true"
											onMouseLeave={handlePopoverClose}
										>
											{header.checkbox ? (
												<div
													className={classNames(classes.elementContainer)}
												>
													<Checkbox
														checked={checkItem(item)}
														onChange={(e: any) => {
															handleOnCheck(item, e.target.checked);
														}}
														dataCy={item.name + '_checkbox'}
													/>
													{itemTemplate(
														item,
														header.property,
														i + currentPage * pageSize
													)}
												</div>
											) : (
												itemTemplate(
													item,
													header.property,
													i + currentPage * pageSize
												)
											)}
										</IonCol>
									);
								})}

								{moreContent ? (
									<IonCol
										className={classes.moreCol}
										onClick={(event: any) => {
											event.stopPropagation();
										}}
									>
										<IonIcon
											className={classes.moreIcon}
											size="small"
											icon={ellipsisHorizontal}
											onClick={e => handleMenuOpen(e, item)}
											data-cy={`${item.username ?? item.name}_popover_menu`}
										/>
									</IonCol>
								) : null}
							</IonRow>
						);
					})
				) : (
					<IonRow className={classes.noData}>{noDataTemplate}</IonRow>
				)}
			</IonGrid>
			<div className={classes.paginationContainer}>
				<div className={classes.pagingInfo}>
					<FormattedMessage
						{...Messages.listInfo}
						values={{
							current: pageSize > dataList.length ? dataList.length : pageSize,
							total: data.length,
							listType: listType,
						}}
					/>
				</div>
				<div>
					<FormattedMessage
						{...Messages.pageInfo}
						values={{
							current: pageCount !== 0 ? currentPage + 1 : currentPage,
							total: pageCount,
						}}
					/>
				</div>
				<ReactPaginate
					previousLabel={
						<IonIcon
							className={classes.icon}
							slot="icon-only"
							size="small"
							icon={chevronBack}
						/>
					}
					nextLabel={
						<IonIcon
							className={classes.icon}
							slot="icon-only"
							size="small"
							icon={chevronForward}
						/>
					}
					breakLabel="..."
					breakClassName="break-me"
					pageCount={pageCount}
					marginPagesDisplayed={2}
					pageRangeDisplayed={5}
					onPageChange={e => onPagination(e)}
					initialPage={0}
					forcePage={currentPage}
					containerClassName={classes.pagination}
					activeLinkClassName={classes.activePage}
					disabledClassName={classes.disabledPage}
				/>
			</div>
			<StyledMenus
				open={openMenuPopover}
				anchorEl={menuAnchorEl}
				onClose={handleMenuClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				{moreContent(popoverRow, handleMenuClose)}
			</StyledMenus>
			<Popover
				open={openPopover}
				anchorEl={anchorEl}
				className={Classes.popover}
				classes={{
					paper: Classes.popoverContent,
				}}
				onClose={handlePopoverClose}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				disableRestoreFocus
			>
				<Typography>{popoverText}</Typography>
			</Popover>
		</Fragment>
	);
};

export default connect(null, { setParameter })(ListGrid);
