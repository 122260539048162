import { defineMessages } from 'react-intl';

export default defineMessages({
	domainName: {
		id: 'Organization.domainName',
		defaultMessage: 'Domain Name',
		description: 'Domain Name',
	},
	domainStatus: {
		id: 'Organization.domainStatus',
		defaultMessage: 'Status',
		description: 'Status',
	},
	domainToken: {
		id: 'Organization.domainToken',
		defaultMessage: 'Token',
		description: 'Token',
	},
	noDomains: {
		id: 'Organization.noDomains',
		defaultMessage: 'No Domains here!',
		description: 'No Domains here!',
	},
	noDomainsHint: {
		id: 'Organization.addDomain',
		defaultMessage: "You haven't added any Domains yet!",
		description: "You haven't added any Domains yet!",
	},
	verify: {
		id: 'Organization.verify',
		defaultMessage: 'Verify',
		description: 'Verify',
	},
	add: {
		id: 'Organization.add',
		defaultMessage: 'Add',
		description: 'Add',
	},
	valid: {
		id:'Organization.validDomain',
		defaultMessage:'Domain is invalid',
		description:'Domain is invalid'
	}
});

