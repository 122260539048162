import { IonButtons, IonHeader, IonMenuButton, IonToolbar } from '@ionic/react';
import classNames from 'classnames';
import React, { FC, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import classes from './Header.module.css';
import HeaderList from './HeaderList';

import styled from 'styled-components';
import { publish } from '../../actions/publish';
import { RecreateMqttClient, signOut, subscribe, subscribedDevices } from '../../providers/mqtt';
import { store } from '../../store/store';
import { b64EncodeUnicode } from '../../utils/encoding';
import StayLoginModal from '../StayLoginModal/StayLoginModal';

const AlphaOverlay = styled.div`
	color: black;
	opacity: 0.5;
	font-weight: 100;
	width: 130px;
	margin-right: 16px;
	font-size: 16px;
	font-family: 'Industry';
`;

interface HeaderProps extends RouteComponentProps {}

const Header: FC<HeaderProps> = (props: any) => {
	const [showHeader, setShowHeader] = useState(true);
	const { location } = props;
	const [searchText, setSearchText] = useState('');
	const [isSearchHidden, setIsSearchHidden] = useState(true);
	const [searchBarValue, setSearchBarValue] = useState('');
	const [hashEventExists, setHashEventExists] = useState(false);
	const [showStayLoginModal, setShowStayLoginModal] = useState(false);
	const [stayLoginDetails, setStayLoginDetails]: any = useState();

	useEffect(() => {
		if (!hashEventExists && location.pathname.includes('/gobe')) {
			setHashEventExists(true);
			window.addEventListener('hashchange', () => {
				// if we are in the session page (either legacy or new one with hardware acceleration)
				if (window.location.pathname.includes('/gobe/session')) {
					setShowHeader(false);
				} else {
					setShowHeader(true);
				}
			});
		}
	}, [hashEventExists, location.pathname]);

	const handleStayLoginSessionEvent = (event: any) => {
		setShowStayLoginModal(true);
		if (event.detail) {
			setStayLoginDetails(event.detail);
		}
	};

	useEffect(() => {
		window.addEventListener('stayLoginSession', handleStayLoginSessionEvent, false);
		return () => {
			document.removeEventListener('stayLoginSession', handleStayLoginSessionEvent, false);
		};
	}, []);

	const onConfirmStayLogin = () => {
		if (stayLoginDetails && showStayLoginModal) {
			RecreateMqttClient();
			subscribe(stayLoginDetails.client, stayLoginDetails?.userName.toLowerCase());
			// Flag subscribed devices to false for re subscription on refresh end
			Object.keys(subscribedDevices).forEach(deviceId => {
				(subscribedDevices as { [id: string]: boolean })[deviceId] = false;
			});
			const accountState = store.getState().accountState;
			publish(
				`microservice/${b64EncodeUnicode(accountState.user.username)}/getOwnAccountInfo`,
				JSON.stringify({ requestId: 'someId' })
			);
			setShowStayLoginModal(false);
		}
	};

	const onDismissStayLogin = () => {
		if (stayLoginDetails && showStayLoginModal) {
			signOut(stayLoginDetails?.client);
			store.dispatch({
				type: 'SIGN_OUT_USER',
				payload: {},
			});
			setShowStayLoginModal(false);
			window.location.reload();
			console.debug(`Client ${stayLoginDetails?.clientID} has been DISCONNECTED from broker`);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, false);
		return () => {
			document.removeEventListener('click', handleClickOutside, false);
		};
	}, []);

	const handleClickOutside = (event: any) => {
		if (
			event != null &&
			event.toElement != null &&
			event.toElement.className != null &&
			event.toElement.className !== 'searchbar-input sc-ion-searchbar-md'
		) {
			setIsSearchHidden(true);
			setSearchBarValue('');
		}
	};

	const searchBarOnChange = (e: any) => {
		console.log(e.detail?.value);
		setSearchText(e.detail.value!);

		if (e.detail.value.length >= 3) {
			console.log('show searchbar');
			setIsSearchHidden(false);
			setSearchBarValue(e.detail.value.toLowerCase());
		} else {
			console.log('hide searchbar');
			setIsSearchHidden(true);
		}
	};

	const hide = () => {
		setIsSearchHidden(true);
	};

	const clearText = () => {
		console.log('clearText');
		setSearchBarValue('');
		setSearchText('');
	};

	if (!showHeader) {
		return <div />;
	} else
		return (
			<IonHeader>
				<StayLoginModal
					isOpen={showStayLoginModal}
					onDismiss={onDismissStayLogin}
					onConfirm={onConfirmStayLogin}
				/>
				<IonToolbar>
					<IonButtons>
						<IonMenuButton />
						{/* <IonSearchbar
							className={classes.searchBar}
							value={searchText}
							placeholder={props.intl.formatMessage({ id: 'Header.search' })}
							onIonChange={searchBarOnChange}
							// onFocus={e => setIsSearchHidden(false)}
							autocomplete="on"
						/> */}
						<div className={classes.searchBar} />
						{/* <IonButton
							className={classNames(classes.headerBtn, classes.notifHeaderBtn)}
						>
							<IonIcon
								className={classes.headerIcon}
								slot="icon-only"
								size="small"
								icon={notifications}
							/>
							<div className={classes.badge} />
						</IonButton> */}
						<div className={classNames(classes.headerBtn, classes.notifHeaderBtn)} />
						<div className={classes.logo} />
					</IonButtons>
				</IonToolbar>
				{isSearchHidden ? null : (
					<HeaderList
						hideList={isSearchHidden}
						setHideList={hide}
						inputValue={searchBarValue}
						clearText={clearText}
					/>
				)}
			</IonHeader>
		);
};

export default injectIntl(withRouter(Header));
