import { throttle } from 'lodash';
import { useLayoutEffect } from 'react';

/**
 * Hook to track changes to focus of a generic nav-input, as the user interacts with document/window.
 * @param disabled True if the implementing nav-input is currently disabled
 * @param onFocus Callback to prompt the implementing nav-input to gain focus
 * @param onBlur Callback to prompt the implementing nav-input to lose focus
 */
export default function useNavInputWindowFocus(args: {
	disabled: boolean;
	onFocus: () => void;
	onBlur: () => void;
	onWindowFocus: () => void;
	onWindowBlur: () => void;
}) {
	const { disabled, onFocus, onBlur, onWindowBlur, onWindowFocus } = args;
	useLayoutEffect(() => {
		if (disabled) return;

		const blurInput = throttle(onBlur, 500, { leading: true });

		// register the event-handlers in 'capture' mode, so that we can get the events immediately
		const options = { capture: true };

		document.addEventListener('contextmenu', blurInput, options);
		window.addEventListener('menuEvent', blurInput, options);
		window.addEventListener('beforeunload', blurInput, options);

		return () => {
			blurInput.cancel();
			document.removeEventListener('contextmenu', blurInput, options);
			window.removeEventListener('menuEvent', blurInput, options);
			window.removeEventListener('beforeunload', blurInput, options);
		};
	}, [onBlur, disabled]);

	// change focus, when user navigate in-to our out-of page/tab/window
	useLayoutEffect(() => {
		if (disabled) return;

		const options = { capture: true };
		const visibilityChanged = () => {
			if (document.visibilityState === 'visible' && !disabled) onFocus();
			else if (document.visibilityState === 'hidden') onBlur();
		};
		document.addEventListener('visibilitychange', visibilityChanged, options);
		return () => {
			document.removeEventListener('visibilitychange', visibilityChanged, options);
		};
	}, [disabled, onFocus, onBlur]);

	// remove focus from the input, when we switch fullscreen states
	useLayoutEffect(() => {
		if (disabled) return;

		const options = { capture: true };
		document.addEventListener('fullscreenchange', onBlur, options);
		return () => {
			document.removeEventListener('fullscreenchange', onBlur, options);
		};
	}, [disabled, onBlur]);

	useLayoutEffect(() => {
		window.addEventListener('focus', onWindowFocus);
		window.addEventListener('blur', onWindowBlur);
		return () => {
			window.removeEventListener('focus', onWindowFocus);
			window.removeEventListener('blur', onWindowBlur);
		};
	}, [onWindowBlur, onWindowFocus]);
}
