import { defineMessages } from 'react-intl';

export default defineMessages({
	robotDetails: {
		id: 'RobotSetupDetail.details',
		defaultMessage: 'Robot details',
		description: 'Robot details',
	},

	robotName: {
		id: 'RobotSetupDetails.name',
		defaultMessage: 'Robot Name',
		description: 'Robot Name',
	},

	robotLocation: {
		id: 'RobotSetupDetails.location',
		defaultMessage: 'Robot Location ',
		description: 'Robot Location',
	},

	robotTimeZone: {
		id: 'RobotSetupDetails.timeZone',
		defaultMessage: 'Robot time zone',
		description: 'Robot time zone',
	},

	createdOn: {
		id: 'RobotSetupDetails.createdOn',
		defaultMessage: 'Created on',
		description: 'Created on',
	},
	upload: {
		id: 'AccountManagementPage.upload',
		defaultMessage: 'Upload Picture',
		description: 'Upload Picture',
	},
	web: {
		id: 'AccountManagementPage.web',
		defaultMessage: 'From The Web',
		description: 'From The Web',
	},
});
