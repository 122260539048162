import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import TableCell from '@material-ui/core/TableCell';
import { styled } from '@material-ui/styles';

const StyledTableCellLeft = styled(TableCell)(() => ({
	borderTopLeftRadius: 5,
	borderBottomLeftRadius: 5,
	padding: '0px 16px',
}));
const StyledTableCellRight = styled(TableCell)(() => ({
	borderTopRightRadius: 5,
	borderBottomRightRadius: 5,
	padding: '0px 16px',
}));
const ColumnHeaderCell = ({ header, isFirst, isLast }: any) => {
	if (isFirst) {
		return <StyledTableCellLeft>{header.formattedMessage}</StyledTableCellLeft>;
	} else if (isLast) {
		return <StyledTableCellRight>{header.formattedMessage}</StyledTableCellRight>;
	}

	return <TableCell style={{ padding: '0px 16px' }}>{header.formattedMessage}</TableCell>;
};

type Headerprops = {
	header: any;
	isFirst: boolean;
	isLast: boolean;
	index: number;
};

const TableHeaderCell: FC<Headerprops> = (props: any) => {
	return (
		<ColumnHeaderCell
			key={props.header + props.index}
			header={props.header}
			isFirst={props.isFirst}
			isLast={props.isLast}
		/>
	);
};
export { TableHeaderCell };
