import React, { FC, useState, Fragment, useEffect, useCallback } from 'react';
import { IonButton, IonIcon, IonLabel, IonListHeader, IonRow } from '@ionic/react';
import {
	add,
	personAdd,
	checkmarkCircleOutline,
	arrowForward,
	closeOutline,
	closeCircle,
} from 'ionicons/icons';
import { injectIntl, FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';
import { publish } from '../../actions/publish';
import isAuthenticated from '../../components/Authentication/Authenticated';
import { sortedCollection } from '../../selectors';

import UserEdit from '../../components/UserEdit/UserEdit';
import UsersGrid from '../../components/UsersGrid/UsersGrid';
import SearchBar from '../SearchBar/SearchBar';
import FilterList from '../../components/FilterList/FilterList';
import AddItemsModal from '../CreateAddItemsModal/CreateAddItemsModal';

import classes from './Users.module.css';

import Messages from './Users.messages';
import classNames from 'classnames';
import { useTypedSelector } from '../../reducers';
import { b64EncodeUnicode } from '../../utils/encoding';
import { MenuItem, MenuList } from '@material-ui/core';
import { styled } from '@material-ui/styles';

interface UsersProps {
	filterValues: any;
	orgId: string;
}
const StyledMenuItem = styled(MenuItem)(() => ({
	paddingLeft: 10,
	borderRadius: 5,
}));

const Users: FC<UsersProps> = (props: any) => {
	// const { intl, users, userGroups, history, orgId } = props;
	const { intl, users, history, orgId } = props;
	const username = useTypedSelector(state => state.accountState.user.username);
	const encodedUser = b64EncodeUnicode(username);
	// const { addToGroupOpen, invitedUser, sortParams } = users;
	const { invitedUser, sortParams } = users;
	// const { userGroupsByOrganizationId } = userGroups;
	const [isEditing, setIsEditing] = useState(false);
	const [selectedUser, setSelectedUser] = useState<any>(null);
	const [selectedFilter, setSelectedFilter] = useState<any>('all');
	// const [showAddToGroupModal, setAddToGroupModal] = useState(false);
	// const [availableGroups, setAvailableGroups] = useState<any[]>([]);
	const [usersByOrg, setUsersByOrg] = useState<any>();
	const [searchText, setSearchText] = useState('');
	// const [selectUser, setSelectUser] = useState(false);
	const [selectedUsers, updateSelectedUsers] = useState<any[]>([]);

	// const usersGroup = useTypedSelector(
	// 	state => state.userGroupsState.userGroupsByOrganizationId[orgId]
	// );
	const spinoutType = useTypedSelector(state => state.versionState.spinoutType) as string;

	// useEffect(() => {
	// 	if (addToGroupOpen) {
	// 		if (!selectedUser) return;
	// 		setAvailableGroups(
	// 			userGroupsByOrganizationId[orgId]
	// 				? !selectedUser.userGroupsIds
	// 					? Object.values(userGroupsByOrganizationId[orgId])
	// 					: Object.values(userGroupsByOrganizationId[orgId]).filter(
	// 							(gr: any) =>
	// 								selectedUser.userGroupsIds.findIndex(
	// 									(id: string) => id === gr.userGroupId
	// 								) < 0
	// 					  )
	// 				: []
	// 		);
	// 		setAddToGroupModal(true);
	// 	} else {
	// 		setAddToGroupModal(false);
	// 	}
	// }, [addToGroupOpen, orgId, selectedUser, userGroupsByOrganizationId]);

	useEffect(() => {
		if (history.location.data?.editableUser) {
			const user = history.location.data.editableUser;
			setIsEditing(prev => !prev);
			setSelectedUser(user);
			history.location.data.editableUser = undefined;
		}
	}, [history.location.data]);

	useEffect(() => {
		const noUsersInOrg = () => {
			const usersByOrgObject = users.usersByOrganizationId[orgId];

			if (
				!usersByOrgObject ||
				usersByOrgObject.constructor !== Object ||
				Object.keys(usersByOrgObject).length === 0
			) {
				return true;
			}
		};

		if (noUsersInOrg()) {
			setUsersByOrg({});
			setSelectedUser(null);
			return;
		}

		if (searchText) {
			const filterData = onFilterValues(selectedFilter);
			let searchedUsers = JSON.parse(JSON.stringify(filterData));
			for (const key in searchedUsers) {
				let name = searchedUsers[key].firstName + ' ' + searchedUsers[key].lastName;
				let userEmail = searchedUsers[key].username;
				if (
					name.toLowerCase().indexOf(searchText.toLowerCase()) < 0 &&
					userEmail.toLowerCase().indexOf(searchText.toLowerCase()) < 0
				)
					delete searchedUsers[key];
			}
			setUsersByOrg(searchedUsers);
		} else {
			onFilterChange(selectedFilter);
		}
		if (selectedUser) {
			setSelectedUser(users.usersByOrganizationId[orgId][selectedUser.username]);
		}
	}, [orgId, users.usersByOrganizationId, users, selectedUser, searchText]);

	useEffect(() => {
		if (invitedUser) {
			setTimeout(() => {
				props.setParameter('users', 'USER_INVITED', null);
			}, 5000);
		}
	}, [invitedUser, props]);

	const onFilterChange = (data: any) => {
		setSelectedFilter(data);
		if (searchText) {
			setSearchText('');
		}
		const filterData = onFilterValues(data);
		if (filterData) {
			setUsersByOrg(filterData);
		}
	};

	const onFilterValues = (data: any) => {
		let filterValue;
		switch (data) {
			case 'all':
				filterValue = users.usersByOrganizationId[orgId];
				break;
			case 'admins':
				let admins = JSON.parse(JSON.stringify(users.usersByOrganizationId[orgId]));
				for (const key in admins) {
					if (!admins[key].role) delete admins[key];
				}
				filterValue = admins;
				break;
			case 'notAdmins':
				let notAdmins = JSON.parse(JSON.stringify(users.usersByOrganizationId[orgId]));
				for (const key in notAdmins) {
					if (notAdmins[key].role?.match(/admin|Admin/g)) delete notAdmins[key];
				}
				filterValue = notAdmins;
				break;
			case 'registrationIncomplete':
				let incomplete = JSON.parse(JSON.stringify(users.usersByOrganizationId[orgId]));

				for (const key in incomplete) {
					if (
						incomplete[key].type !== 'inviteUser' &&
						incomplete[key].status !== 'pending'
					) {
						delete incomplete[key];
					}
				}
				filterValue = incomplete;

				break;
			default:
				break;
		}
		return filterValue;
	};
	const onEditUser = (user: any) => {
		setIsEditing(prev => !prev);
		user = usersByOrg[user.username];
		setSelectedUser(user);
	};

	const openInviteUser = async () => {
		props.setParameter('users', 'CHANGE_USER_INVITE_STATE', true);
	};

	// const openAddToGroup = (user: any) => {
	// 	props.setParameter('users', 'CHANGE_ADD_TO_GROUP_STATE', true);
	// 	setSelectedUser(user);
	// };
	// const onAddToGroup = (selectedItems: any) => {
	// 	selectedItems.forEach((item: any) => {
	// 		const filterUsers = selectedUsers.filter(
	// 			user => !user.userGroupsIds?.includes(item.userGroupId)
	// 		);
	// 		const usersIds = filterUsers.map(user => user.username);
	// 		publish(`microservice/${orgId}/${encodedUser}/updateUserGroupUsers`, {
	// 			data: {
	// 				userGroupId: item.userGroupId,
	// 				add: usersIds,
	// 			},
	// 			requestId: 'updateUserGroup',
	// 		});
	// 	});
	// 	if (selectedItems.length > 0) {
	// 		props.setParameter('addUsersCount', 'USERS_ADDED_TO_GROUP_STATE', selectedItems.length);
	// 	}
	// 	props.setParameter('users', 'CHANGE_ADD_TO_GROUP_STATE', false);
	// 	unCheckAll();
	// 	setSelectUser(false);
	// };

	const formatData = useCallback(
		(data: any) => {
			return data.map((d: any) => {
				// name
				d.name = ((d.firstName || '') + ' ' + (d.lastName || '')).trim();

				// // userGroups & userGroupName
				// let groups: any[] = [];
				// if (d.userGroupsIds) {
				// 	for (let userGroupId of d.userGroupsIds) {
				// 		if (
				// 			userGroupsByOrganizationId != null &&
				// 			userGroupsByOrganizationId[orgId] != null &&
				// 			userGroupsByOrganizationId[orgId][userGroupId] != null
				// 		) {
				// 			groups.push(userGroupsByOrganizationId[orgId][userGroupId]);
				// 		}
				// 	}
				// }
				// d.userGroups = groups;
				// d.userGroupName =
				// 	groups.length > 1
				// 		? intl.formatMessage(
				// 				{
				// 					id: 'nrGroups',
				// 				},
				// 				{ number: d.userGroups.length }
				// 		  )
				// 		: groups.length > 0
				// 		? groups[0].name
				// 		: '';
				return d;
			});
		},
		[intl, orgId]
	);

	const data: any = sortedCollection({
		items: usersByOrg ? formatData(Object.values(usersByOrg)) : [],
		sortParams: sortParams,
	});

	// const handleOnCheck = (user: any, bool: boolean) => {
	// 	if (bool) {
	// 		let obj = selectedUsers.find(o => o.username === user.username);
	// 		if (!obj) updateSelectedUsers(selectedUsers.concat(user));
	// 	} else {
	// 		updateSelectedUsers(
	// 			selectedUsers.filter((item: any) => item.username !== user.username)
	// 		);
	// 	}
	// };
	// const checkAll = () => {
	// 	updateSelectedUsers(data);
	// };
	// const unCheckAll = () => {
	// 	updateSelectedUsers([]);
	// };

	// const renderHeader = () => {
	// 	const users = selectedUsers.map((user, index) => {
	// 		return (
	// 			<IonLabel key={index} className={classes.userContainer}>
	// 				{user.name}
	// 				<IonButton
	// 					className={classes.deleteUserBtn}
	// 					shape="round"
	// 					color="transparent"
	// 					onClick={() => handleOnCheck(user, false)}
	// 				>
	// 					<IonIcon size="35px" slot="icon-only" icon={closeCircle} />
	// 				</IonButton>
	// 			</IonLabel>
	// 		);
	// 	});

	// 	return (
	// 		<IonListHeader className={classes.modalHeader} lines="none">
	// 			<IonLabel color="primary" className={classes.headerTitle}>
	// 				<FormattedMessage {...Messages.addGroup} />
	// 			</IonLabel>
	// 			<IonRow className={classes.selectedUsersContainer}>
	// 				<FormattedMessage
	// 					{...Messages.addUsersToGroups}
	// 					values={{
	// 						number: selectedUsers.length,
	// 					}}
	// 				/>
	// 				{users}
	// 			</IonRow>
	// 		</IonListHeader>
	// 	);
	// };

	return !isEditing ? (
		<Fragment>
			<div className={classes.leftSide}>
				<div className={classes.btnContainer}>
					<IonIcon
						slot="icon-only"
						size="small"
						icon={personAdd}
						color="primary"
						className={classes.inviteIcon}
					/>
					<IonLabel color="primary" className={classes.inviteLb}>
						<FormattedMessage id="Menu.InviteUser" />
					</IonLabel>
					<IonButton className={classes.inviteBtn} shape="round" onClick={openInviteUser}>
						<IonIcon
							className={classes.addIcon}
							slot="icon-only"
							size="small"
							icon={add}
						/>
					</IonButton>
				</div>
				{invitedUser ? (
					<IonLabel className={classes.invitedUser}>
						<IonIcon
							slot="end"
							size="small"
							color="primary"
							icon={checkmarkCircleOutline}
						/>
						<FormattedMessage
							{...Messages.invited}
							values={{ name: <span>{invitedUser}</span> }}
						/>
					</IonLabel>
				) : null}
				{/* {spinoutType === 'beam' ? (
					<div />
				) : selectUser ? (
					<div className={classNames(classes.selectUsersContainer)}>
						<IonButton
							className={
								selectedUsers.length > 0
									? classes.selectUsersBtn
									: classes.disabledSelectUserBtn
							}
							onClick={selectedUsers.length > 0 ? openAddToGroup : () => {}}
						>
							<FormattedMessage {...Messages.selectUsers} />
						</IonButton>
					</div>
				) : (
					<div
						className={classNames(classes.btnContainer, classes.addToGroupBtnContainer)}
					>
						<IonLabel color="primary" className={classes.inviteLb}>
							<FormattedMessage {...Messages.addToUserGroup} />
						</IonLabel>
						<IonButton
							className={classes.addToGroupBtn}
							shape="round"
							onClick={() => setSelectUser(true)}
						>
							<IonIcon
								className={classes.addIcon}
								slot="icon-only"
								size="small"
								icon={arrowForward}
							/>
						</IonButton>
					</div>
				)} */}
				<SearchBar
					searchText={searchText}
					placeholder={props.intl.formatMessage({
						id: 'UserGroups.createModal.searchUsers',
					})}
					onChange={(value: any) => setSearchText(value)}
				/>
				<FilterList filterValues={props.filterValues} onChange={onFilterChange} />
			</div>
			<div className={classes.rightSide}>
				<UsersGrid
					data={sortedCollection({
						items: usersByOrg ? formatData(Object.values(usersByOrg)) : [],
						sortParams: sortParams,
					})}
					selectUser={false}
					selectedUsers={selectedUsers}
					// onUnCheck={() => {
					// 	setSelectUser(false);
					// 	unCheckAll();
					// }}
					// checkAll={checkAll}
					// unCheckAll={unCheckAll}
					moreContent={(item: any) => {
						return (
							<>
								<StyledMenuItem onClick={() => onEditUser(item)}>
									<FormattedMessage {...Messages.edit} />
								</StyledMenuItem>
							</>
						);
					}}
					onRowClick={onEditUser}
					history={history}
				/>
			</div>
			{/* <AddItemsModal
				isOpen={showAddToGroupModal}
				header={renderHeader()}
				title={<FormattedMessage {...Messages.addGroup} />}
				titleHint={
					<FormattedMessage
						{...Messages.addToGroupsHint}
						values={{
							user: selectedUser ? (
								<span className={classes.groupName}>{selectedUser.username}</span>
							) : (
								''
							),
						}}
					/>
				}
				availableTitle={<FormattedMessage {...Messages.availableGroups} />}
				selectedTitle={<FormattedMessage {...Messages.selectedGroups} />}
				selectedTxt={Messages.groupsSelected}
				noSelection={Messages.noSelection}
				searchMessageId="Users.searchGroup"
				orgId={orgId}
				initialData={selectedUser}
				nameProperty="name"
				availableItems={Object.values(usersGroup || {})}
				onAdd={onAddToGroup}
				onDismiss={() => props.setParameter('users', 'CHANGE_ADD_TO_GROUP_STATE', false)}
			/> */}
		</Fragment>
	) : (
		<UserEdit editableUser={selectedUser} onClose={() => setIsEditing(false)} />
	);
};

const mapStateToProps = (state: any) => ({
	selectedOrganization: state.selectedOrganizationState.organization,
	users: state.usersState,
	// userGroups: state.userGroupsState,
});

const enhance = compose(connect(mapStateToProps, { setParameter }));

export default injectIntl(isAuthenticated(enhance(Users), 'Users'));
