import React, { FC } from 'react';
import classes from './Robots.module.css';
import { IonAvatar, IonLabel, IonIcon } from '@ionic/react';
import classNames from 'classnames';
import { useTypedSelector } from '../../reducers';
import { equalityFnc, getStateEntityByOrgId } from '../../utils/conformState';
import robotAvatar from '../../assets/images/robotAvatar.svg';
import robotOfflineAvatar from '../../assets/images/robotOfflineAvatar.svg';
import EllipsisText from '../EllipsisText/EllipsisText';
import styled from 'styled-components';

const deviceIcons: Record<string, string> = {
	beam: '../../../assets/img/beam-logo-white.svg',
	gobe: '../../../assets/icons/GOBE-ROBOTS-logo-neg.svg',
	uvd: '../../../assets/img/uvd-logo.svg',
};

const Wrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	flexGrow: 1,
}));

interface RobotNameProps {
	robotId: string;
	orgId: string;
	selectRobots: boolean;
	dataCy?: string;
}

const RobotName: FC<RobotNameProps> = props => {
	const { robotId, orgId, selectRobots, dataCy } = props;

	const robot = useTypedSelector(
		state =>
			getStateEntityByOrgId(state, 'deviceState', {
				entityId: robotId,
				orgId,
				propertyOptions: ['online', 'pictureLink', 'name'],
			}),
		(left, right) => equalityFnc(left, right)
	);

	const spinoutType = useTypedSelector(state => state.versionState.spinoutType) as string;
	return (
		<div className={classes.nameContainer} data-cy={dataCy}>
			{!selectRobots && (
				<IonAvatar className={classes.robotAvatarOffline}>
					{robot.pictureLink ? (
						<img
							className={classes.profilePic}
							src={robot.online ? robot.pictureLink : robotOfflineAvatar}
							alt="Avatar"
						/>
					) : (
						<IonIcon
							className={classes.profilePic}
							size="large"
							icon={robot.online ? robotAvatar : robotOfflineAvatar}
						/>
					)}
				</IonAvatar>
			)}
			<IonLabel className={classes.nameLb}>
				<EllipsisText text={robot.name} />
			</IonLabel>
		</div>
	);
};

export default RobotName;
