import {
	IonRow,
	IonCol,
	IonButton,
	IonIcon,
	IonLabel,
	IonGrid,
	IonList,
	IonListHeader,
} from '@ionic/react';
import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { setParameter } from '../../actions/setParam';
// Styling
import classes from './UserMoveGroup.module.css';
// custom components
import ReactSelect from '../ReactSelect/ReactSelect';
import isAuthenticated from '../Authentication/Authenticated';
import Messages from './UserMoveGroup.messages';
import { useForm } from 'react-hook-form';
import { useTypedSelector, AppRootState } from '../../reducers';
import { equalityFnc, getStateEntityByOrgId } from '../../utils/conformState';
import { b64EncodeUnicode } from '../../utils/encoding';
import { publish } from '../../actions/publish';
import { AutoComplete } from '../AutoComplete/AutoComplete';
import BasicModal from '../BasicModal/BasicModal';

const UserMoveGroup: FC = (props: any) => {
	const { user, onDismiss, group } = props;
	const { handleSubmit, control } = useForm();
	const [selectedRobotGroupId, setSelectedRobotGroupId] = useState('');

	const selectedOrganizationId = useTypedSelector(
		state => state.selectedOrganizationState.organization.orgId
	);
	const username = useTypedSelector(state => state.accountState.user.username);
	const encodedUser = b64EncodeUnicode(username);

	const usersGroups = useTypedSelector(
		(state: AppRootState) =>
			state.userGroupsState.userGroupsByOrganizationId[selectedOrganizationId],
		(left, right) => equalityFnc(left, right, false)
	);

	const onTransfer = handleSubmit(() => {
		// add the robot to the new robotGroup
		publish(`microservice/${selectedOrganizationId}/${encodedUser}/updateUserGroupUsers`, {
			requestId: 'updateUserGroup',
			data: {
				userGroupId: selectedRobotGroupId,
				add: [user.username],
			},
		});
		// remove the user from the old userGroup
		publish(`microservice/${selectedOrganizationId}/${encodedUser}/updateUserGroupUsers`, {
			data: {
				userGroupId: group.userGroupId,
				remove: [user.username],
			},
			requestId: 'updateUserGroup',
		});

		onDismiss();
	});

	const selectOptions = () => {
		const options: any = [];
		for (let key in usersGroups) {
			let isExistUserForGroup =
				usersGroups[key]?.usersIds?.length > 0
					? usersGroups[key]?.usersIds.includes(user.username)
					: false;
			if (usersGroups[key].userGroupId !== group.userGroupId && !isExistUserForGroup)
				options.push({
					label: usersGroups[key].name,
					value: usersGroups[key].userGroupId,
				});
		}
		return options;
	};
	const onRobotGroupChange = (robotGroupId: string) => {
		setSelectedRobotGroupId(robotGroupId);
	};
	return (
		<BasicModal open={props.isOpen} onClose={props.onDismiss}>
			<form onSubmit={onTransfer} className={classes.addForm}>
				<IonList>
					<IonListHeader className={classes.modalHeader} lines="none">
						<div className={classes.headerContainer}>
							<IonLabel color="primary" className={classes.headerTitle}>
								<FormattedMessage {...Messages.title} />
							</IonLabel>
						</div>
						<IonLabel color="gray" className={classes.from}>
							<FormattedMessage {...Messages.from} />
							<b>{user?.firstName + ' ' + user?.lastName}</b>
						</IonLabel>
					</IonListHeader>
				</IonList>
				<IonLabel>
					<FormattedMessage {...Messages.fromGroup} />
				</IonLabel>
				<IonLabel className={classes.groupContainer}>{group?.name}</IonLabel>
				<br />
				<IonLabel>
					<FormattedMessage {...Messages.toGroup} />{' '}
					<span className={classes.required}>*</span>
				</IonLabel>
				<AutoComplete
					options={selectOptions()}
					placeHolder={Messages.switch.defaultMessage}
					required={false}
					onChange={({ value, label }: any) => onRobotGroupChange(value)}
				/>
				<IonGrid className={classes.modalContent}>
					<IonRow className={classes.btnRow}>
						<IonCol>
							<IonButton
								className={classes.cancelBtn}
								expand="block"
								shape="round"
								size="large"
								fill="outline"
								onClick={props.onDismiss}
							>
								<FormattedMessage {...Messages.cancel} />
							</IonButton>
						</IonCol>
						<IonCol className={classes.submitCol}>
							<IonButton
								expand="block"
								shape="round"
								type="submit"
								size="large"
								fill="outline"
							>
								<FormattedMessage {...Messages.move} />
							</IonButton>
						</IonCol>
					</IonRow>
				</IonGrid>
			</form>
		</BasicModal>
	);
};

const mapStateToProps = (state: any) => ({
	organization: state.organizationState,
	selectedOrganization: state.selectedOrganizationState.organization,
	devices: state.deviceState,
});

export default injectIntl(
	isAuthenticated(connect(mapStateToProps, { setParameter })(UserMoveGroup), 'UserMoveGroup')
);
