import React, { FC, useState, useCallback, useEffect } from 'react';
import { IonIcon, IonLabel, IonAvatar, IonImg, IonGrid, IonRow, IonCol } from '@ionic/react';

import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';
import isAuthenticated from '../Authentication/Authenticated';

import classes from './GuestReservations.module.css';
import classNames from 'classnames';
import { Popover, Typography, styled, Menu, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../theme/grid.module.css';

import { FormattedDate, FormattedMessage } from 'react-intl';

import Messages from './GuestReservations.messages';
import { formatTimeAgo } from '../../utils/formatTime';

import { useTypedSelector } from '../../reducers';
import EllipsisText from '../EllipsisText/EllipsisText';

import EmptyGuestReservationLogo from '../../assets/Empty-guest-reservation-logo.svg';
import { Avatar, Box } from '@material-ui/core';
import { chevronDownOutline, chevronUpOutline, ellipsisHorizontal } from 'ionicons/icons';
import moment from 'moment';
import _ from 'lodash';
import robotAvatar from '../../assets/images/robotAvatar.svg';

interface GuestReservationsGridProps {
	data: any;
	moreContent: React.ReactNode;
	onRowClick: any;
	checkItem: any;
	handleOnCheck: any;
	getGuestReservationsLoading: boolean;
}

const useStyles = makeStyles(theme => ({
	popover: {
		pointerEvents: 'none',
	},
	popoverContent: {
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		padding: 0,
		boxShadow: '0px 2px 10px #00000029',
		borderRadius: 5,
	},
}));
const StyledMenus = styled(Menu)(() => ({
	'& .MuiPopover-paper': {
		boxShadow: '0px 2px 10px #00000027',
		borderRadius: 5,
		paddingLeft: 6,
		paddingRight: 6,
	},
}));

const GuestReservationsGrid: FC<GuestReservationsGridProps> = (props: any) => {
	const { selectedOrganization, history, selectUser, moreContent, selectedDeviceId } = props;
	const [anchorEl, setAnchorEl] = useState(null);
	const [openPopover, setPopoverOpen] = useState(false);
	const [popoverText, setPopoverText] = useState('');
	const [menuAnchorEl, setMenuAnchorEl] = useState(null);
	const [openMenuPopover, setOpenMenuPopover] = useState(false);
	const [popoverRow, setPopoverRow] = useState(null);
	const spinoutType = useTypedSelector(state => state.versionState.spinoutType);
	const [guestReservationsData, setGuestReservationsData] = useState<any[]>([]);
	const devicesByOrganizationId = useTypedSelector(
		state => state.deviceState.devicesByOrganizationId
	);
	const usersByOrganizationId = useTypedSelector(state => state.usersState.usersByOrganizationId);
	const Classes = useStyles();
	const headers = [
		{
			title: Messages.date,
			property: 'reservationDate',
			style: { flex: 1 },
		},
		{ title: Messages.startTime, property: 'startingAt', style: { flex: 1 } },
		{ title: Messages.duration, property: 'endingAt', style: { flex: 1 } },
		{
			title: Messages.robot,
			property: 'serialNumber',
			style: { flex: 2.5 },
			hidden: !!selectedDeviceId,
		},
		{ title: Messages.guest, property: 'guest', style: { flex: 2 } },
		{ title: Messages.subject, property: 'subject', style: { flex: 2 } },
		{ title: Messages.organiser, property: 'host', style: { flex: 2 } },
		{ title: Messages.createdBy, property: 'createdBy', style: { flex: 2 } },
		{ title: Messages.invitedOn, property: 'createdAt', style: { flex: 1 }, centered: true },
	];
	const [sortKey, setSortKey] = useState(headers[0].property);
	const [sortOrder, setSortOrder] = useState<'desc' | 'asc'>('asc');
	const [pageSize, setPageSize] = useState(11);
	const organizationId = selectedOrganization.orgId;

	useEffect(() => {
		setGuestReservationsData(
			_.orderBy(props.data, sortKey === 'reservationDate' ? 'startingAt' : sortKey, sortOrder)
		);
	}, [props.data, sortKey, sortOrder]);

	const onSortChange = (key: string, order: 'desc' | 'asc') => {
		setSortKey(key);
		setSortOrder(order);
	};

	const handleMenuOpen = (event: any, row: any) => {
		setOpenMenuPopover(true);
		setMenuAnchorEl(event.currentTarget);
		setPopoverRow(row);
	};
	const handleMenuClose = () => {
		setOpenMenuPopover(false);
		setMenuAnchorEl(null);
		setPopoverRow(null);
	};
	const handlePopoverClose = () => {
		setAnchorEl(null);
		setPopoverOpen(false);
	};

	const formatTemplate = useCallback(
		(item: any, property: string) => {
			switch (property) {
				case 'guest':
					if (
						item[property] &&
						usersByOrganizationId[organizationId][item[property]] &&
						usersByOrganizationId[organizationId][item[property]]?.status !==
							'pending' &&
						usersByOrganizationId[organizationId][item[property]].firstName !== ''
					) {
						return (
							<Chip
								label={
									<div className={classes.ellipsisWrapper}>
										{`${
											usersByOrganizationId[organizationId][item[property]]
												.firstName
										} ${
											usersByOrganizationId[organizationId][item[property]]
												.lastName
										}`}
									</div>
								}
								variant="outlined"
							/>
						);
					} else if (
						item.guestName &&
						item.guestName !== '' &&
						item.guestName?.length > 0
					) {
						return (
							<Chip
								label={
									<div className={classes.ellipsisWrapper}>{item.guestName}</div>
								}
								variant="outlined"
							/>
						);
					} else {
						return <EllipsisText text={item[property]} />;
					}
				case 'createdBy':
					return item[property] &&
						usersByOrganizationId[organizationId][item[property]] &&
						usersByOrganizationId[organizationId][item[property]]?.status !==
							'pending' ? (
						<Chip
							label={
								<div className={classes.ellipsisWrapper}>
									{`${
										usersByOrganizationId[organizationId][item[property]]
											.firstName
									} ${
										usersByOrganizationId[organizationId][item[property]]
											.lastName
									}`}
								</div>
							}
							variant="outlined"
						/>
					) : (
						<EllipsisText text={item[property]} />
					);
				case 'host':
					return (
						<EllipsisText
							text={
								usersByOrganizationId[organizationId][item[property]] &&
								usersByOrganizationId[organizationId][item[property]]?.status !==
									'pending'
									? `${
											usersByOrganizationId[organizationId][item[property]]
												.firstName
									  } ${
											usersByOrganizationId[organizationId][item[property]]
												.lastName
									  } <${item[property]}>`
									: `<${item[property]}>`
							}
						/>
					);
				case 'reservationDate':
					return <EllipsisText text={moment(item.startingAt).format('YYYY-MM-DD')} />;
				case 'startingAt':
					return <EllipsisText text={moment(item.startingAt).format('LT')} />;
				case 'endingAt':
					return (
						<EllipsisText
							text={
								moment
									.duration(moment(item.endingAt).diff(moment(item.startingAt)))
									.asMinutes() + ' min'
							}
						/>
					);
				case 'createdAt':
					return <EllipsisText text={moment(item.createdAt).format('YYYY-MM-DD')} />;
				case 'serialNumber':
					return (
						<div className={classes.robotInfo}>
							<IonIcon
								className={classes.robotAvatar}
								size="large"
								icon={robotAvatar}
							/>
							<span className={classes.robotSerialNumber}>
								<EllipsisText
									text={
										devicesByOrganizationId[organizationId][item[property]]
											? `${
													devicesByOrganizationId[organizationId][
														item[property]
													].name
											  } (${
													devicesByOrganizationId[organizationId][
														item[property]
													].location
											  })`
											: item[property]
									}
								/>
							</span>
						</div>
					);
				default:
					return <EllipsisText text={item[property]} />;
			}
		},
		[selectUser]
	);

	return (
		<>
			<IonGrid className={classes.listGrid} id="guest-reservations-list-grid">
				<IonRow className={classes.headerRow}>
					{headers && headers.length > 0 ? (
						<>
							{headers.map((header: any, i: number) => {
								return (
									!header.hidden && (
										<IonCol
											key={i}
											className={
												header.centered
													? classNames(
															classes.centeredCol,
															classes.activeCol,
															header.hideMd && commonStyles.hideMd
													  )
													: classNames(
															classes.activeCol,
															header.hideMd && commonStyles.hideMd
													  )
											}
											style={header.style}
										>
											<div className={classes.columnHeader}>
												<div
													onClick={() =>
														onSortChange(
															header.property,
															sortOrder === 'asc' ? 'desc' : 'asc'
														)
													}
												>
													<FormattedMessage {...header.title} />
												</div>
												<div className={classes.sortContainer}>
													<IonIcon
														className={
															header.property === sortKey &&
															sortOrder === 'asc'
																? classNames(
																		classes.sortIcon,
																		classes.activeSort
																  )
																: classes.sortIcon
														}
														size="small"
														icon={chevronUpOutline}
														onClick={() => {
															onSortChange(header.property, 'asc');
														}}
													/>
													<IonIcon
														className={
															header.property === sortKey &&
															sortOrder === 'desc'
																? classNames(
																		classes.sortIcon,
																		classes.activeSort
																  )
																: classes.sortIcon
														}
														size="small"
														icon={chevronDownOutline}
														onClick={() => {
															onSortChange(header.property, 'desc');
														}}
													/>
												</div>
											</div>
										</IonCol>
									)
								);
							})}
							<IonCol className={classes.moreCol} />
						</>
					) : (
						<IonCol sizeSm="12" />
					)}
				</IonRow>
				{guestReservationsData.length > 0 ? (
					guestReservationsData.map((item: any, i: number) => {
						return (
							<IonRow
								className={classes.dataRow}
								key={i * headers.length}
								data-cy={
									(item.deviceId || item.serialNumber) +
									'_' +
									item.guest +
									'_reservation_row'
								}
							>
								{headers.map((header: any, j: number) => {
									return (
										!header.hidden && (
											<IonCol
												key={i + j}
												style={header.style}
												className={classNames(
													header.hideMd && commonStyles.hideMd
												)}
												aria-haspopup="true"
											>
												{formatTemplate(item, header.property)}
											</IonCol>
										)
									);
								})}

								{moreContent ? (
									<IonCol
										className={classes.moreCol}
										onClick={(event: any) => {
											event.stopPropagation();
										}}
									>
										<IonIcon
											className={classes.moreIcon}
											size="small"
											icon={ellipsisHorizontal}
											onClick={e => handleMenuOpen(e, item)}
											data-cy={
												(item.deviceId || item.serialNumber) +
												'_' +
												item.guest +
												'_reservation_popover_menu'
											}
										/>
									</IonCol>
								) : null}
							</IonRow>
						);
					})
				) : props.getGuestReservationsLoading ? null : (
					<IonRow className={classes.noData}>
						<Box
							display="flex"
							justifyContent="center"
							width="100%"
							className={classes.noDataWrapper}
						>
							<IonImg src={EmptyGuestReservationLogo} />
							<IonLabel className={classes.noDataHint}>
								<FormattedMessage {...Messages.noGuestReservationsHint} />
							</IonLabel>
						</Box>
					</IonRow>
				)}
			</IonGrid>

			<StyledMenus
				open={openMenuPopover}
				anchorEl={menuAnchorEl}
				onClose={handleMenuClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				{moreContent(popoverRow, handleMenuClose)}
			</StyledMenus>
			<Popover
				open={openPopover}
				anchorEl={anchorEl}
				className={Classes.popover}
				classes={{
					paper: Classes.popoverContent,
				}}
				onClose={handlePopoverClose}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				disableRestoreFocus
			>
				<Typography>{popoverText}</Typography>
			</Popover>
		</>
	);
};

const mapStateToProps = (state: any) => ({
	selectedOrganization: state.selectedOrganizationState.organization,
});

export default isAuthenticated(
	connect(mapStateToProps, { setParameter })(GuestReservationsGrid),
	'GuestReservationsGrid'
);
