import { Children } from 'react';
import { act } from 'react-dom/test-utils';
import {
	FETCH_ORGANIZATIONS,
	FETCH_SHOWING_ORGANIZATION,
	FETCH_ORGANIZATION_CHILDREN,
	INSERT_NEW_ORGANIZATION_SUCCESS,
	UPDATE_ORGANIZATION,
	DELETE_ORGANIZATION,
	CHANGE_ADD_NEW_ORGANIZATION_STATE,
	RESET_APP,
	INSERT_NEW_CHILD_ORG,
	UNLINK_DEVICE_FROM_ORG,
	FETCH_ORGANIZATION_SSO_CONFIGURATION,
	UPDATE_ORGANIZATION_SSO_LIVE_CONFIGURATION,
	UPDATE_ORGANIZATION_SSO_SANDBOX_CONFIGURATION,
	GET_ORGANIZATION_STRUCTURE,
	SUBSCRIBE_TO_ORGANIZATION,
	RESET_SUBSCRIBE_TO_ORGANIZATION,
} from '../actions/types';
import { Organization, OrganizationStructure } from '../types/types';
import _ from 'lodash';

export interface initialOrganizationState {
	organizations: Record<string, Organization>;
	organizationStructure: any;
	showingOrganization: any;
	orgTypesAllowed: Record<string, Array<string>>;
	newOrganizationOpen: boolean;
	subscribedOrgs: Array<string>;
}

const initialState: initialOrganizationState = {
	organizations: {},
	organizationStructure: [],
	showingOrganization: {} as Organization,
	orgTypesAllowed: {
		bor: ['spinout'],
		spinout: ['solutionProvider', 'customer'],
		solutionProvider: ['customer'],
		customer: [],
	},
	newOrganizationOpen: false,
	subscribedOrgs: [],
};

const removeOrgFromStructureArray = (selectedOrgId: string, orgStructureArray: any) => {
	let response: any[] = [];
	for (const orgStructure of orgStructureArray) {
		const res = removeOrgFromStructure(selectedOrgId, orgStructure);
		response = [...response, res || orgStructure];
	}
	return response;
};

const removeOrgFromStructure = (selectedOrgId: string, orgStructure: any) => {
	if (orgStructure && orgStructure.children) {
		for (let child in orgStructure.children) {
			if (child === selectedOrgId) {
				delete orgStructure.children[child];

				break;
			} else {
				const temp: any = removeOrgFromStructure(
					selectedOrgId,
					orgStructure.children[child]
				);
				if (temp) {
					orgStructure.children[child] = temp;
				}
			}
		}
	}

	return orgStructure;
};

const insertOrgInStructureArray = (newOrg: any, selectedOrgId: string, orgStructureArray: any) => {
	let response: any[] = [];
	for (const orgStructure of orgStructureArray) {
		const res = insertOrgInStructure(newOrg, selectedOrgId, orgStructure);
		response = [...response, res || orgStructure];
	}
	return response;
};

const insertOrgInStructure = (newOrg: any, selectedOrgId: string, orgStructure: any) => {
	if (orgStructure) {
		if (orgStructure.id === selectedOrgId) {
			orgStructure.children = {
				...orgStructure.children,
				[newOrg.id]: newOrg,
			};
			return orgStructure;
		} else {
			if (orgStructure.children) {
				for (let child in orgStructure.children) {
					const temp: any = insertOrgInStructure(
						newOrg,
						selectedOrgId,
						orgStructure.children[child]
					);
					if (temp) {
						orgStructure.children[temp.id] = temp;
						return orgStructure;
					}
				}
			} else {
				return '';
			}
		}
	}
	return '';
};

const updateOrgInStructureArray = (newOrg: any, selectedOrgId: string, orgStructureArray: any) => {
	let response: any[] = [];
	for (const orgStructure of orgStructureArray) {
		const res = updateOrgInStructure(
			newOrg,
			newOrg.id != orgStructure.id ? selectedOrgId : '',
			orgStructure
		);
		response = [...response, res || orgStructure];
	}
	return response;
};

const updateOrgInStructure = (newOrg: any, selectedOrgId: string, orgStructure: any) => {
	if (!selectedOrgId) {
		orgStructure.label = newOrg.label;
		orgStructure.orgType = newOrg.orgType;

		return orgStructure;
	}
	if (orgStructure && orgStructure.children) {
		for (let child in orgStructure.children) {
			if (child === newOrg.id) {
				orgStructure.children[child].label = newOrg.label;
				orgStructure.children[child].orgType = newOrg.orgType;
				return orgStructure;
			}
			const temp: any = updateOrgInStructure(
				newOrg,
				selectedOrgId,
				orgStructure.children[child]
			);
			if (temp) {
				orgStructure.children[temp.id] = temp;
				return orgStructure;
			}
		}
	} else {
		return '';
	}
};

export default function organizationsReducer(
	state = initialState,
	action: Record<string, any>
): initialOrganizationState {
	switch (action.type) {
		case FETCH_ORGANIZATIONS:
			action.payload.organizations.forEach((element: Organization) => {
				state.organizations[element.orgId] = { ...element, userTopOrganization: true };
			});
			return {
				...state,
				organizations: state.organizations,
			};
		case FETCH_ORGANIZATION_CHILDREN:
			if (action.payload.children) {
				action.payload.children.forEach((element: Organization) => {
					state.organizations[element.orgId] = { ...element, userTopOrganization: false };
				});
				return {
					...state,
					organizations: state.organizations,
				};
			} else {
				return state;
			}

		case FETCH_SHOWING_ORGANIZATION:
			return {
				...state,
				showingOrganization: action.payload.organization,
			};

		case INSERT_NEW_ORGANIZATION_SUCCESS: {
			// UPDATE Org structure

			const newOrganization = action.payload.newOrganization;
			const parentOrganizationId = newOrganization.parentOrganization;

			const newOrg = {
				id: newOrganization.orgId,
				label: newOrganization.name,
				children: newOrganization.childOrgs || {},
				orgType: newOrganization.orgType,
				devices: newOrganization.devices || [],
			};

			let newStructure = insertOrgInStructureArray(
				newOrg,
				parentOrganizationId,
				state.organizationStructure
			);

			return {
				...state,
				organizations: {
					...state.organizations,
					[newOrganization.orgId]: newOrganization,
				},
				organizationStructure: [...newStructure],
			};
		}

		case DELETE_ORGANIZATION:
			const deletedOrgs = action.payload;
			if (!deletedOrgs && !Array.isArray(deletedOrgs)) {
				return state;
			}

			let newStructure = state.organizationStructure;
			for (const org of deletedOrgs) {
				delete state.organizations[org.id];
				newStructure = removeOrgFromStructureArray(org.id, newStructure);
			}

			return {
				...state,
				organizations: {
					...state.organizations,
				},
				organizationStructure: [...newStructure],
			};

		case INSERT_NEW_CHILD_ORG:
			let organizationsList = state.organizations;
			if (organizationsList[action.payload.parentOrganization].childOrgs == null) {
				(organizationsList[action.payload.parentOrganization].childOrgs as any) = {
					[action.payload.newOrganization.orgId]: action.payload.newOrganization,
				};
			} else {
				organizationsList[action.payload.parentOrganization].childOrgs[
					action.payload.newOrganization.orgId
				] = action.payload.newOrganization;
			}

			return {
				...state,
				organizations: organizationsList,
			};

		case UPDATE_ORGANIZATION:
			const updatedOrganization = action.payload;

			const parentOrganizationId = updatedOrganization.parentOrganization;

			const updateOrg = {
				id: updatedOrganization.orgId,
				label: updatedOrganization.name,
				children: updatedOrganization.childOrgs || {},
				orgType: updatedOrganization.orgType,
				devices: updatedOrganization.devices || [],
			};

			let updateStructure = updateOrgInStructureArray(
				updateOrg,
				parentOrganizationId,
				state.organizationStructure
			);

			return {
				...state,
				organizations: {
					...state.organizations,
					[updatedOrganization.orgId]: updatedOrganization,
				},
				organizationStructure: [...updateStructure],
			};

		case RESET_APP:
			return {
				...state,
				organizations: {},
				showingOrganization: {},
			};

		case CHANGE_ADD_NEW_ORGANIZATION_STATE:
			return {
				...state,
				newOrganizationOpen: action.payload.organizations,
			};

		case UNLINK_DEVICE_FROM_ORG:
			let deletedDevice = action.payload;
			if (
				state.organizations[deletedDevice.orgId].devices &&
				state.organizations[deletedDevice.orgId].devices[deletedDevice.deviceId]
			) {
				delete state.organizations[deletedDevice.orgId].devices[deletedDevice.deviceId];
			}

			return {
				...state,
				organizations: state.organizations,
			};
		case FETCH_ORGANIZATION_SSO_CONFIGURATION:
			let organizationDetails = action.payload;
			if (organizationDetails.orgId && organizationDetails.data) {
				state.organizations[organizationDetails.orgId].ssoConfiguration =
					organizationDetails.data;
			} else {
				// added console for error checking
				console.error('error payload for fetch organization:', action.payload);
				console.error(
					'error state organization for fetch organization:',
					state.organizations
				);
			}
			return {
				...state,
				organizations: state.organizations,
			};

		case UPDATE_ORGANIZATION_SSO_LIVE_CONFIGURATION:
			state.organizations[action.payload.orgId].ssoConfiguration = {
				...state.organizations[action.payload.orgId].ssoConfiguration,
				live: {
					...action.payload,
				},
			};
			return {
				...state,
				organizations: state.organizations,
			};

		case UPDATE_ORGANIZATION_SSO_SANDBOX_CONFIGURATION:
			state.organizations[action.payload.orgId].ssoConfiguration = {
				...state.organizations[action.payload.orgId].ssoConfiguration,
				sandbox: {
					...action.payload,
				},
			};
			return {
				...state,
				organizations: state.organizations,
			};

		case GET_ORGANIZATION_STRUCTURE:
			return {
				...state,
				organizationStructure: action.payload,
			};

		case SUBSCRIBE_TO_ORGANIZATION:
			state.subscribedOrgs = [...state.subscribedOrgs, action.payload.orgId];
			return {
				...state,
				subscribedOrgs: state.subscribedOrgs,
			};

		case RESET_SUBSCRIBE_TO_ORGANIZATION:
			state.subscribedOrgs = [];
			return {
				...state,
				subscribedOrgs: state.subscribedOrgs,
			};
		default:
			return state;
	}
}
