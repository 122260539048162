import React, { useRef, useEffect as useLayoutEffect, useState, useEffect } from 'react';
import './index.scss';
import { connect } from 'react-redux';
import { setParameter } from '../../../../actions/setParam';
import LocalVideoHeader from '../../../../components/localVideoHeader';
import { AppRootState } from '../../../../../../../reducers';
import { ConnectedProps } from 'react-redux';

type PropsFromParent = {
	startWideCameraStats: Function;
	stopWideCameraStats: Function;
	wideCameraStats: any;
	isGreyedOut: boolean;
	isPaused: boolean;
	shouldShowLoadingIndicator: boolean;
	onGotMediaStream: (mediaStream: MediaStream) => void;
	onMediaDevicesAccessError: () => void;
};

const reduxConnector = connect(
	(state: AppRootState) => ({
		// messageText: state.goBeState.sessionState.messageText,
		statusMessageDataChannel: state.goBeState.sessionState.dataChannel1,
		localVoiceVolume: state.goBeState.sessionState.localVoiceVolume,
		accountState: state.accountState,
		settings: (state.hardwareState as any).settings,
	}),
	{ setParameter }
);

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
type ComponentProps = PropsFromRedux & PropsFromParent;

const LocalVideo: React.FC<ComponentProps> = ({
	statusMessageDataChannel,
	settings,
	isGreyedOut,
	isPaused,
	shouldShowLoadingIndicator,
	onGotMediaStream,
	onMediaDevicesAccessError,
}) => {
	const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
	const [isLocalVideoViewExpanded, setIsLocalVideoViewExpanded] = useState(true);
	const videoRef = useRef<HTMLVideoElement | null>(null);

	// const [height, changeHeight] = useState(11);

	const [CAPTURE_OPTIONS, changeCaptureOptions] = useState<MediaStreamConstraints>({
		audio: ({
			sampleSize: 16,
			sampleRate: 48000,
			channelCount: { min: 1, max: 2, ideal: 2 },
			echoCancellation: true,
			autoGainControl: false,
			noiseSuppression: false,
			deviceId: settings.microphone?.id ? { exact: settings.microphone.id } : undefined,
		} as unknown) as MediaStreamConstraints['audio'],
		// audio: true,
		video: {
			facingMode: 'user',
			width: 640,
			height: 480,
			frameRate: { max: 20 },
			deviceId: settings.camera?.id ? { exact: settings.camera.id } : undefined,
		},
	});

	const [voiceLevel, setVoiceLevel] = useState(11); // FIXME: What is this magic number? Originally added by Meisam
	useLayoutEffect(() => {
		if (!mediaStream) return;

		const audioContext = new AudioContext();

		const analyser = audioContext.createAnalyser();
		const microphone = audioContext.createMediaStreamSource(mediaStream);
		const scriptProcessorNode = audioContext.createScriptProcessor(2048, 1, 1);

		analyser.smoothingTimeConstant = 0.8;
		analyser.fftSize = 1024;

		microphone.connect(analyser);
		analyser.connect(scriptProcessorNode);
		scriptProcessorNode.connect(audioContext.destination);

		const onAudioProcess = () => {
			let array = new Uint8Array(analyser.frequencyBinCount);
			analyser.getByteFrequencyData(array);
			let values = 0;

			let length = array.length;
			for (let i = 0; i < length; i++) {
				values += array[i];
			}

			let average = values / length;

			if (average > 100) {
				average = 100;
			}
			setVoiceLevel((average / 100) * 12);
		};
		scriptProcessorNode.addEventListener('audioprocess', onAudioProcess);

		return () => {
			microphone.disconnect();
			analyser.disconnect();
			scriptProcessorNode.disconnect();
			scriptProcessorNode.removeEventListener('audioprocess', onAudioProcess);

			audioContext.close().catch(error => console.error('Error closing AudioContext', error));
		};
	}, [mediaStream]);

	// Connect to the user's media device, immediately the component mounts
	useEffect(() => {
		navigator.mediaDevices
			.getUserMedia(CAPTURE_OPTIONS)
			.then(mediaStream => {
				setMediaStream(mediaStream);
				onGotMediaStream(mediaStream);
			})
			.catch(error => {
				console.error('Error getting media from user device', error);
				onMediaDevicesAccessError();
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []); // NB: No deps - we want effect to run only and only once

	// Cleanup media tracks when component is un-mounting
	useEffect(() => {
		if (!mediaStream) return;
		videoRef.current!.srcObject = mediaStream;
		return () => {
			mediaStream.getTracks().forEach(track => track.stop());
			setMediaStream(null);
		};
	}, [mediaStream]);

	const onStatusMessageInputChange = (value: string) => {
		if (statusMessageDataChannel && statusMessageDataChannel.readyState === 'open') {
			statusMessageDataChannel.send(`MSG ${value}`);
		}
	};

	const onCanPlay = () => {
		if (!isPaused) {
			videoRef.current
				?.play()
				.catch(error => console.warn('Unable to play LocalVideo', error));
		} else videoRef.current?.pause();
	};

	useLayoutEffect(() => {
		if (isPaused === true) videoRef.current?.pause();
		else {
			videoRef.current
				?.play()
				.catch(error => console.warn('Unable to play LocalVideo', error));
		}
	}, [isPaused]);

	const renderVideoLoading = () => {
		return (
			<div className={isLocalVideoViewExpanded ? '' : 'displayNone'}>
				<div className={!shouldShowLoadingIndicator ? 'displayNone' : 'showLocalLoading '}>
					<div className="localLoading" />
					{/* <video
						ref={videoRef}
						onCanPlay={handleCanPlay}
						autoPlay
						playsInline
						loop
						muted
						className="displayNone"
					/> */}
				</div>
				<video
					ref={videoRef}
					onCanPlay={onCanPlay}
					playsInline
					loop
					muted
					className={
						!shouldShowLoadingIndicator
							? isGreyedOut
								? 'localVideo greyVideo'
								: 'localVideo'
							: 'displayNone'
					}
					id="localVideo"
				/>
				<div className={!shouldShowLoadingIndicator ? 'whiteLogoWrapper' : 'displayNone'}>
					<img src="../../assets/images/white-gobe-logo.svg" alt="" />
				</div>
				<div
					className={
						!shouldShowLoadingIndicator ? 'audioRecognizeContainer' : 'displayNone'
					}
				>
					<div className="audioStrength" style={{ height: `${4 + voiceLevel / 2}px` }} />
					<div className="audioStrength" style={{ height: `${4 + voiceLevel}px` }} />
					<div className="audioStrength" style={{ height: `${4 + voiceLevel / 2}px` }} />
				</div>
			</div>
		);
	};

	return (
		<div>
			<div
				className={
					isLocalVideoViewExpanded
						? ' localContainer'
						: 'miniLocalContainer localContainer'
				}
			>
				<div>
					<LocalVideoHeader
						onToggleLocalVideoExpansion={() =>
							setIsLocalVideoViewExpanded(state => !state)
						}
						isLocalVideoExpanded={isLocalVideoViewExpanded}
					/>
				</div>
				{renderVideoLoading()}
			</div>
			<div className={isLocalVideoViewExpanded ? 'localInputContainer' : 'displayNone'}>
				<input
					placeholder="Enter a status here"
					onChange={event => onStatusMessageInputChange(event.target.value)}
					id="localInputContainer"
				/>
			</div>
		</div>
	);
};

export default reduxConnector(LocalVideo);
