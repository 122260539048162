import React, { FC, useState, useCallback, useEffect } from 'react';
import UserGroupSetupDetails from './UserGroupSetupDetails';

import isAuthenticated from '../../components/Authentication/Authenticated';
import { useTypedSelector } from '../../reducers';
import { equalityFnc } from '../../utils/conformState';
interface UserGroupSetupProps {
	currentSubMenu: string;
	onSubMenuChange: (name: string) => void;
	subMenuCount: number;
	onUpdateMessageChange: (message: any) => void;
}

const UserGroupSetup: FC<UserGroupSetupProps> = (props: any) => {
	const { currentSubMenu, onSubMenuChange, subMenuCount, onUpdateMessageChange } = props;

	const [currentItem, setCurrentItem] = useState<any>(currentSubMenu);
	const [hideArrow, setHideArrow] = useState(false);
	const [userGroup, setUserGroup] = useState<any>();

	const selectedOrganization = useTypedSelector(
		state => state.selectedOrganizationState.organization,
		(left, right) => equalityFnc(left, right)
	);

	const userGroupReducerInfo = useTypedSelector(
		state => state.userGroupsState.userGroupsByOrganizationId[selectedOrganization.orgId]
	);

	const user = useTypedSelector(state => state.accountState.user);
	const userGroups = useTypedSelector(state => state.userGroupsState);
	const encodedUser = window.btoa(user.username);

	useEffect(() => {
		if (!selectedOrganization || !userGroupReducerInfo || !userGroup) {
			return;
		}

		const data = {
			userGroupId: userGroup.userGroupId,
			name: userGroup.name,
		};

		onUpdateMessageChange(
			`microservice/${selectedOrganization.orgId}/${encodedUser}/updateUserGroupInfo`,
			{ data, requestId: 'updateUserGroup' }
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userGroup]);

	useEffect(() => {
		setCurrentItem(currentSubMenu);
		let selectedItem: any = document.getElementById('row-' + currentItem);
		if (selectedItem) selectedItem.scrollIntoView();
	}, [currentItem, currentSubMenu]);

	useEffect(() => {
		if (subMenuCount < 10) {
			let selectedItem = document.getElementById('row-' + currentItem);
			let top = selectedItem ? selectedItem.offsetTop + 30 : 30;
			let arrow = document.getElementById('arrow');
			if (arrow) {
				arrow.style.top = top.toString() + 'px';
			}
			setHideArrow(false);
		} else {
			setHideArrow(true);
		}
	}, [currentItem, subMenuCount]);

	const goToItem = useCallback(
		(itemName: string) => {
			setCurrentItem(itemName);
			onSubMenuChange(itemName);
		},
		[onSubMenuChange]
	);

	const handleUserGroupChange = useCallback(
		(label: any, value: any) => {
			let tempEditableUserGroup = JSON.parse(JSON.stringify(userGroup));

			switch (label) {
				case 'name':
					tempEditableUserGroup[label] = value[label];
					break;
			}
			setUserGroup(tempEditableUserGroup);
		},

		[userGroup]
	);

	const renderContent = useCallback(() => {
		const availableUserGroups =
			userGroups.userGroupsByOrganizationId[selectedOrganization.orgId];

		if (currentItem && currentItem !== '') {
			if (!userGroup || userGroup.userGroupId !== currentItem)
				if (availableUserGroups && availableUserGroups[currentItem])
					setUserGroup(availableUserGroups[currentItem]);
		}

		return (
			<UserGroupSetupDetails
				userGroup={
					currentItem !== ''
						? availableUserGroups && availableUserGroups[currentItem]
							? availableUserGroups[currentItem]
							: null
						: null
				}
				goTo={goToItem}
				monitorizeFormChange={handleUserGroupChange}
			/>
		);
	}, [
		currentItem,
		goToItem,
		handleUserGroupChange,
		selectedOrganization.orgId,
		userGroup,
		userGroups.userGroupsByOrganizationId,
	]);

	return renderContent();
};

export default isAuthenticated(UserGroupSetup, 'UserGroupSetup');
