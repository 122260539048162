import React, { memo } from 'react';
import '../../index.scss';
import { LaunchCardFooter } from './components/LaunchCardFooter';
import { LaunchCardBody } from './components/LaunchCardBody';
import { Device } from '../../../../../../../types/types';
import { getStateEntityByOrgId } from '../../../../../../../utils/conformState';
import { equalityFnc } from '../../../../utils/deviceStateConverter';
import { useTypedSelector } from '../../../../../../../reducers';

interface Props {
	deviceId: number | string;
	orgId: number | string;
	deviceGroupsNames?: string | null;
	onClickStartSession: (device: Device) => void;
	publishFavouriteDevice: (device: Device) => void;
	isCalling: boolean;
}

export const LaunchCard = memo(function LaunchCard({
	deviceId,
	orgId,
	isCalling,
	deviceGroupsNames,
	onClickStartSession,
	publishFavouriteDevice,
}: Props) {
	const device = useTypedSelector(
		state =>
			getStateEntityByOrgId(state, 'accountState', {
				entityId: deviceId as string,
				orgId: orgId as string,
				propertyOptions: [
					'deviceId',
					'serialNumber',
					'online',
					'status',
					'battery',
					'currentState',
					'name',
					'battery',
					'location',
					'guestReservation',
					'isPermanent',
				],
			}),
		(left, right) => equalityFnc(left, right)
	);

	const accountState = useTypedSelector(state => state.accountState);
	const isFavourite = accountState.user.favouriteDevice?.includes(device.serialNumber) || false;

	return (
		<div className="bodyContainer">
			<LaunchCardBody
				device={device}
				isFavorite={isFavourite}
				onFavoriteClick={publishFavouriteDevice}
				isCalling={isCalling}
				onClickStartSession={onClickStartSession}
			/>
			<LaunchCardFooter robotName={device?.name} robotGroupName={deviceGroupsNames} />
		</div>
	);
});
