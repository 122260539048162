import {
	SET_SHOW_MENU,
	SET_SHOW_ABSOLUTE_MENU,
	SET_IS_EXPANDED,
	SET_ACCOUNT_INFORMATION,
} from '../actions/types';

type menuStateModel = {
	showMenu: boolean;
	showAbsoluteMenu: boolean;
	isExpanded: boolean;
	showAccountInformation: boolean;
};

type menuActionsModel = {
	type: string;
	payload: menuStateModel;
};

const Initial_State = {
	showMenu: true,
	showAbsoluteMenu: false,
	isExpanded: true,
	showAccountInformation: false,
};

export default (state = Initial_State, action: menuActionsModel) => {
	switch (action.type) {
		case SET_SHOW_MENU:
			return { ...state, showMenu: action.payload.showMenu };
		case SET_SHOW_ABSOLUTE_MENU:
			return { ...state, showAbsoluteMenu: action.payload.showAbsoluteMenu };
		case SET_IS_EXPANDED:
			return { ...state, isExpanded: action.payload.isExpanded };
		case SET_ACCOUNT_INFORMATION:
			return { ...state, showAccountInformation: action.payload.showAccountInformation };
		default:
			return state;
	}
};
