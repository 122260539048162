import React, { FC, useState, useEffect } from 'react';
import { IonLabel, IonNote } from '@ionic/react';
import { FormattedMessage } from 'react-intl';
import Tooltip from 'react-tooltip-lite';

import InfoCard from '../InfoCard/InfoCard';
import classes from './RobotEdit.module.css';
import Messages from './RobotEdit.messages';

import { getDeviceGroupNames } from '../../utils/formatData';
import { useTypedSelector } from '../../reducers';
import { equalityFnc, getStateEntityByOrgId } from '../../utils/conformState';
import BatteryDetails from '../BatteryDetails/BatteryDetails';
import Battery from '../Battery/Battery';
import { Device, UVDMongoDevice } from '../../types/types';
import { mongodbClient } from '../../providers/mongodbClient';
import { stringify } from 'querystring';
import robotAvatar from '../../assets/images/robotAvatar.svg';
import robotOfflineAvatar from '../../assets/images/robotOfflineAvatar.svg';

const deviceIcons: Record<string, string> = {
	beam: '../../../assets/img/beam-logo-white.svg',
	gobe: '../../../assets/icons/GOBE-ROBOTS-logo-neg.svg',
	uvd: '../../../assets/img/uvd-logo.svg',
};

interface RobotCardProps {
	robotId: string;
	orgId: string;
	onClose: () => void;
	setEditPicOpen: any;
	device: UVDMongoDevice;
}

const RobotCard: FC<RobotCardProps> = props => {
	const { robotId, orgId, onClose, setEditPicOpen, device } = props;

	const deviceGroups = useTypedSelector(
		state => state.deviceGroupsState.deviceGroupsByOrganizationId[orgId],
		(left, right) => equalityFnc(left, right)
	);
	const spinoutType = useTypedSelector(state => state.versionState.spinoutType) as string;
	const mongoDbConfig = useTypedSelector(state => state.mongoDbConfigState.mongoDbConfig);

	const [disinfections, setDisinfections] = useState(0);

	const [groupNames, setGroupNames] = useState([]);

	const robot = useTypedSelector(
		state =>
			getStateEntityByOrgId(state, 'deviceState', {
				entityId: robotId,
				orgId,
				propertyOptions: ['online', 'pictureLink', 'name'],
			}),
		(left, right) => equalityFnc(left, right)
	);
	useEffect(() => {
		if (!device.deviceGroupsIds) return;
		setGroupNames(getDeviceGroupNames(device.deviceGroupsIds, deviceGroups));
	}, [device.deviceGroupsIds, deviceGroups]);

	useEffect(() => {
		if (!device.serialNumber) return;
		var filter = {
			robotId: device.serialNumber ? device.serialNumber : '',
		};
		mongodbClient
			.countCollection(filter, mongoDbConfig.collections.disinfectionCollection)
			.then((result: any) => {
				setDisinfections(result);
			})
			.catch((err: any) => {
				console.error(`Failed to find document: ${err}`);
			});
	}, [disinfections, device]);

	const displayGroupName = (name: string) => {
		let newString = name.length > 30 ? name.substring(0, 30) + '...' : name;
		return newString;
	};
	return (
		<InfoCard
			imgUrl={
				robot.online
					? robot.pictureLink
						? robot.pictureLink
						: robotAvatar
					: robotOfflineAvatar
			}
			title={device.name}
			subTitle={
				spinoutType !== 'uvd' ? (
					groupNames && groupNames.length > 0 ? (
						groupNames.length > 2 ? (
							<div data-cy="robot_card_group">
								<IonLabel className={classes.moreNames}>
									<FormattedMessage
										{...Messages.in}
										values={{
											group: groupNames[0],
										}}
									/>
									<Tooltip
										direction="up"
										content={
											<ul className={classes.groupNameItem}>
												{groupNames.slice(1).map((gr: any, i: number) => {
													return (
														<li key={i}>
															<IonLabel>{gr}</IonLabel>
															<br />
														</li>
													);
												})}
											</ul>
										}
									>
										<span className={classes.andLb}>
											<FormattedMessage {...Messages.and} />
										</span>
										<span className={classes.moreInLb}>
											<FormattedMessage
												{...Messages.moreIn}
												values={{
													nr: groupNames.length - 1,
												}}
											/>
										</span>
									</Tooltip>
								</IonLabel>
							</div>
						) : (
							<div data-cy="robot_card_group">
								<FormattedMessage
									{...Messages.in}
									values={{
										group:
											groupNames.length == 1
												? displayGroupName(groupNames[0])
												: null,
									}}
								/>
							</div>
						)
					) : null
				) : (
					<IonLabel className={classes.uvdStatus}>{device.uvdStatus?.status}</IonLabel>
				)
			}
			entityType="robot"
			noteContent={
				spinoutType !== 'uvd' ? (
					<>
						<IonNote className={classes.locationNote}>{device.location}</IonNote>
					</>
				) : (
					<>
						<Battery
							batteryPercent={
								device.uvdStatus && device.uvdStatus.battery_level != null
									? device.uvdStatus.battery_level
									: 40
							}
							online={true} //change this it will be always online
							charging={
								device.uvdStatus && device.uvdStatus.charging
									? device.uvdStatus.charging
									: false
							}
						/>
						<IonLabel className={classes.batteryPercentage}>
							{device.uvdStatus?.battery_level}%
						</IonLabel>
					</>
				)
			}
			onEditPic={() => setEditPicOpen(true)}
			onClose={onClose}
			avatarClass={classes.avatarClass}
			disinfections={disinfections}
			lastSeen={device.uvdStatus?.lastSeen}
			deviceId={device.serialNumber}
		/>
	);
};

export default RobotCard;
