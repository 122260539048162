import React, { useEffect, useRef, useState } from 'react';
import './sessionPopupBlockedModal.css';
import popupBlockedTutorialImage from './popup-blocked-tutorial.png';

interface SessionPopupBlockedModalProps {
	timeoutMs?: number;
	onOkay?: () => void;
	onCancel?: () => void;
	onTimeout?: () => void;
}

const DEFAULT_TIMEOUT_MS = 7 * 1000;

export default function SessionPopupBlockedModal({
	timeoutMs: _timeoutMs = DEFAULT_TIMEOUT_MS,
	onOkay,
	onCancel,
	onTimeout,
}: SessionPopupBlockedModalProps) {
	const timeoutMs = useRef(_timeoutMs).current;
	const [remainingSeconds, setRemainingSeconds] = useState<number>(timeoutMs);

	const [checkboxValue, setCheckboxValue] = useState(false);
	const onCheckboxValueChange = () => setCheckboxValue(value => !value);

	useEffect(() => {
		let x = timeoutMs;
		const interval = setInterval(() => {
			x -= 1000;
			if (x <= 0) {
				clearInterval(interval);
				onTimeout?.();
			} else {
				setRemainingSeconds(Math.round(x / 1000));
			}
		}, 1000);

		return () => clearInterval(interval);
	}, [onTimeout, timeoutMs]);

	return (
		<div className={'session-popup-blocked-modal'}>
			<div className="session-popup-blocked-modal-inner">
				<div className="message-container">
					<span className="main-message">
						GoBe needs to open a new tab/window to start the session
					</span>
					{!checkboxValue ? (
						<div className="dont-show-again-container">
							<input
								type="checkbox"
								onChange={onCheckboxValueChange}
								checked={checkboxValue}
							/>
							<span>Do not show this again</span>
						</div>
					) : (
						<img
							className="popup-blocked-tutorial"
							src={popupBlockedTutorialImage}
							alt="popup unblocking tutorial"
						/>
					)}
				</div>

				<span className="time-remaining">{`The session will be automatically aborted after ${remainingSeconds} seconds`}</span>

				<div className="session-popup-buttons-container">
					<div className="pill-button button-cancel" onClick={onCancel}>
						Cancel
					</div>
					<div className="pill-button button-okay" onClick={onOkay}>
						Okay
					</div>
				</div>
			</div>
		</div>
	);
}
