import React, { FC, useState, useEffect } from 'react';
import { IonIcon, IonList, IonItem, IonLabel } from '@ionic/react';
import { person, peopleOutline, bulbOutline } from 'ionicons/icons';
import classes from './Header.module.css';
import { getDevices, equalityFnc } from '../../utils/conformState';
import { useTypedSelector } from '../../reducers';
import { withRouter, RouteComponentProps } from 'react-router';

const deviceIcons = { beam: '', gobe: '/assets/img/GoBeDeviceOutline.svg' };
const deviceGroupIcons = { beam: '', gobe: '/assets/img/GoBeDeviceGroupsOutline.svg' };

//TODO: THIS FILE IS NOT USED BEFORE GLOBAL SEARCH IS IMPLEMENTED AND SHOWN AGAIN

interface HeaderProps extends RouteComponentProps {
	hideList?: boolean;
	setHideList: () => void;
	inputValue?: string;
	clearText?: () => void;
}

const HeaderList: FC<HeaderProps> = (props: any) => {
	const devices = useTypedSelector(
		state => getDevices(state, undefined),
		(left, right) => equalityFnc(left, right)
	);
	const users: any = useTypedSelector(state => state.usersState);
	const userGroups: any = useTypedSelector(state => state.userGroupsState);
	const deviceGroups: any = useTypedSelector(state => state.deviceGroupsState);
	const organizations: any = useTypedSelector(state => state.organizationState.organizations);
	const { history, hideList, setHideList, inputValue, clearText } = props;
	const [allValues, setAllValues] = useState(Array<object>());

	useEffect(() => {
		if (!hideList) {
			getItems();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hideList]);

	const getItems = () => {
		if (allValues.length === 0) {
			let values: Array<object> = [];
			if (users != null && users.items != null) {
				for (let user of users.items) {
					values.push({
						name: user.username,
						value: user.username,
						icon: person,
						path: 'users',
					});
				}
			}
			if (userGroups != null && userGroups.items != null) {
				for (let group of userGroups.items) {
					values.push({
						name: group.name,
						value: group.userGroupId,
						icon: peopleOutline,
						path: 'users/userGroups',
					});
				}
			}
			if (devices != null && devices != null) {
				for (let device of devices) {
					values.push({
						name: device.name,
						value: device.deviceId,
						icon: '/assets/img/GoBeDeviceOutline.svg',
						path: '/robots',
					});
				}
			}
			if (deviceGroups != null && deviceGroups.items != null) {
				for (let group of deviceGroups.items) {
					values.push({
						name: group.name,
						value: group.deviceGroupId,
						icon: '/assets/img/GoBeDeviceGroupsOutline.svg',
						path: 'robots/deviceGroups',
					});
				}
			}
			if (organizations != null) {
				for (let orgId in organizations) {
					values.push({
						name: organizations[orgId].name,
						value: orgId,
						icon: bulbOutline,
						path: 'organizations',
					});
				}
			}
			setAllValues(values);
		}
	};

	const navigateToPage = (item: any) => {
		setHideList();
		clearText();
		history.push('/fleetManagement');
	};

	return allValues.length > 0 ? (
		<IonList id="valueList" hidden={hideList} className={classes.searchResultsList}>
			{allValues.map((item: any, index) => (
				<IonItem
					onClick={() => navigateToPage(item)}
					key={index}
					className={
						item.name.toLowerCase().indexOf(inputValue) > -1 ? '' : classes.hidden
					}
				>
					<IonLabel onClick={e => navigateToPage(item)}>{item.name}</IonLabel>
					<IonIcon
						size="small"
						align-items="right"
						icon={item.icon}
						onClick={() => navigateToPage(item)}
					/>
				</IonItem>
			))}
		</IonList>
	) : null;
};

export default withRouter(HeaderList);
