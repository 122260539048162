import { MQTT_CONFIG, SET_MQTTCLIENT } from '../actions/types';

const Initial_State = {
	client: { connected: false },
	mqttConfig: {
		host: '172.25.0.4',
		port: '8080',
		path: 'mqtt',
		protocol: 'ws',
		protocolVersion: 5,
		keepalive: 30,
	},
};

export default (state = Initial_State, action) => {
	switch (action.type) {
		case SET_MQTTCLIENT:
			return {
				...state,
				client: action.payload.client,
			};
		case MQTT_CONFIG:
			return {
				...state,
				mqttConfig: action.payload,
			};
		default:
			return state;
	}
};
