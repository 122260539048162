import { defineMessages } from 'react-intl';

export default defineMessages({
	deleteMsg1: {
		id: 'confirmOrgDelete1',
		defaultMessage:
			'All users in <bold>{deletedOrg}</bold> will lose access to this organization. ',
		description:
			'All users in <bold>{deletedOrg}</bold> will lose access to this organization. ',
	},
	deleteMsg2: {
		id: 'confirmOrgDelete2',
		defaultMessage: 'The following devices will be transferred to <bold>{parentOrg}</bold>: ',
		description: 'The following devices will be transferred to <bold>{parentOrg}</bold>: ',
	},
	deleteMsg3: {
		id: 'confirmOrgDelete3',
		defaultMessage: 'Child organization <bold>{childOrgs}</bold> will be removed. ',
		description: 'Child organization <bold>{childOrgs}</bold> will be removed. ',
	},
	deleteMsg4: {
		id: 'confirmOrgDelete4',
		defaultMessage:
			'All users in <bold>{childOrgs}</bold> will lose access to this organization.',
		description: 'All users in <bold>{childOrgs}</bold> will lose access to this organization.',
	},
	deleteMsg5: {
		id: 'confirmOrgDelete5',
		defaultMessage:
			'<bold>{devices}</bold> in <bold>{childOrgs}</bold> will be moved to <bold>{parentOrg}</bold>. ',
		description:
			'<bold>{devices}</bold> in <bold>{deletingOrgsWithDevices}</bold> will be moved to <bold>{parentOrg}</bold>. ',
	},
	deleteMsg6: {
		id: 'confirmOrgDelete6',
		defaultMessage: 'For any issues, email support at <bold>{supportEmail}</bold>.',
		description: 'For any issues, email support at <bold>{supportEmail}</bold>.',
	},
	childOrgRemovalMsg: {
		id: 'childOrgRemovalMsg',
		defaultMessage: 'Additionally, all the child organizations will be removed:',
		description: 'Additionally, all the child organizations will be removed:',
	},
	delete: {
		id: 'delete',
		defaultMessage: 'Delete',
		description: 'Delete',
	},
	cancel: {
		id: 'cancel',
		defaultMessage: 'Cancel',
		description: 'Cancel',
	},
	errorMsg: {
		id: 'error',
		defaultMessage: 'Error, the organization it has linked devices',
		description: 'Error, the organization it has linked devices',
	},
});
