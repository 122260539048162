const url = window.location.href.toLowerCase();

// TODO make this come from an env var
let ssoBaseUrl = 'http://localhost:3000/';																// local test env
if (url.includes('nightly.gobe.blue')) {																	// nightly env
	ssoBaseUrl = 'https://nightly.gobe.blue-ocean-robotics-fleet.com/sso/';
} else if (url.includes('staging.gobe.blue')) {														// staging env
	ssoBaseUrl = 'https://staging.gobe.blue-ocean-robotics-fleet.com/sso/';
} else if (url.includes('gobe.blue-ocean-robotics-fleet')) {							// prod URL 1
	ssoBaseUrl = 'https://gobe.blue-ocean-robotics-fleet.com/sso/';
} else if (url.includes('app.gobe-robots')) {															// prod URL 2
	ssoBaseUrl = 'https://app.gobe-robots.com/sso/';
}

export default {
	defaultSupportKey: '73cb1fe5-1cde-477d-975c-88b265a26db8',
	publicPageComponentNames: [
		'AccountCreatePage',
		'LoginPage',
		'PrivacyPolicyModal',
		'LoginSSOPage',
		'LoginSSOSandboxResultPage',
	],
	ssoBaseUrl
};
