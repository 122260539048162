import React from 'react';

export const OfflineIcon = () => {
	return (
		<svg
			width="52"
			height="58"
			viewBox="0 0 52 58"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M25.858 8.93652C14.8441 8.93652 5.89099 17.9993 5.89099 29.2095C5.89099 40.4197 14.8441 49.4825 25.858 49.4825C36.8718 49.4825 45.825 40.4197 45.825 29.2095C45.825 17.9993 36.8718 8.93652 25.858 8.93652ZM3.89099 29.2095C3.89099 16.9222 13.7123 6.93652 25.858 6.93652C38.0036 6.93652 47.825 16.9222 47.825 29.2095C47.825 41.4969 38.0036 51.4825 25.858 51.4825C13.7123 51.4825 3.89099 41.4969 3.89099 29.2095Z"
				fill="#AAAAAA"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M36.9938 0.640137L39.0796 1.56891L37.1071 5.99861C45.7408 10.1837 51.694 19.0319 51.694 29.2706C51.694 43.5455 40.1219 55.1176 25.847 55.1176C22.341 55.1176 18.998 54.4195 15.9494 53.1548L14.0322 57.4603L11.9465 56.5315L13.8811 52.1869C5.6304 47.8699 0 39.2278 0 29.2706C0 14.9957 11.5721 3.42357 25.847 3.42357C29.0736 3.42357 32.1622 4.01481 35.0102 5.09486L36.9938 0.640137ZM25.847 5.42357C12.6767 5.42357 2 16.1002 2 29.2706C2 42.4409 12.6767 53.1176 25.847 53.1176C39.0173 53.1176 49.694 42.4409 49.694 29.2706C49.694 16.1002 39.0173 5.42357 25.847 5.42357Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M35 39.3906C34.9015 39.3908 34.8039 39.3714 34.7129 39.3337C34.6219 39.2959 34.5393 39.2406 34.4698 39.1708L16.4698 21.1708C16.3351 21.029 16.2611 20.8401 16.2636 20.6446C16.2661 20.449 16.3449 20.2621 16.4832 20.1238C16.6215 19.9855 16.8084 19.9067 17.004 19.9042C17.1995 19.9017 17.3883 19.9757 17.5301 20.1104L35.5301 38.1104C35.635 38.2153 35.7063 38.3489 35.7352 38.4944C35.7642 38.6398 35.7493 38.7906 35.6926 38.9276C35.6358 39.0646 35.5397 39.1817 35.4165 39.2641C35.2932 39.3465 35.1483 39.3905 35 39.3906ZM15.815 26.8643C14.6277 27.9472 14 29.4256 14 31.1403C14 32.8278 14.6741 34.369 15.898 35.4804C17.0769 36.551 18.6669 37.1403 20.375 37.1403H29.4125C29.4867 37.1403 29.5593 37.1184 29.621 37.0772C29.6827 37.036 29.7308 36.9774 29.7592 36.9088C29.7876 36.8403 29.7951 36.7648 29.7806 36.692C29.7661 36.6192 29.7303 36.5524 29.6778 36.5L18.7287 25.5509C18.6806 25.5027 18.6203 25.4685 18.5543 25.452C18.4882 25.4355 18.4189 25.4373 18.3537 25.4572C17.375 25.7567 16.5031 26.2362 15.815 26.8643ZM38 32.2653C38 33.8305 37.4262 35.1144 36.3402 35.9792C36.2689 36.0361 36.1953 36.0908 36.1194 36.1433C36.0472 36.1931 35.9598 36.216 35.8724 36.2081C35.7851 36.2001 35.7033 36.1618 35.6412 36.0997L21.98 22.4385C21.941 22.3995 21.9111 22.3524 21.8924 22.3006C21.8737 22.2488 21.8666 22.1934 21.8717 22.1386C21.8768 22.0837 21.894 22.0306 21.9219 21.9831C21.9499 21.9356 21.9879 21.8949 22.0334 21.8638C23.1818 21.0622 24.5412 20.6403 26 20.6403C27.8716 20.6317 29.6729 21.3523 31.0222 22.6494C32.1814 23.7674 32.9675 25.2425 33.3275 26.9619C33.3564 27.103 33.4254 27.2329 33.5261 27.3359C33.6269 27.4389 33.7551 27.5107 33.8956 27.5427C36.0312 28.0194 38 29.5667 38 32.2653Z"
				fill="#AAAAAA"
			/>
		</svg>
	);
};
