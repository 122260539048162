import React, { FC } from 'react';
import { IonLabel } from '@ionic/react';

import { useTypedSelector } from '../../reducers';
import { getStateEntityByOrgId, equalityFnc } from '../../utils/conformState';

import classes from './Robots.module.css';

interface RobotDefaultProps {
	robotId: string;
	orgId: string;
	property: string;
}

const RobotDefaultProps: FC<RobotDefaultProps> = props => {
	const { robotId, orgId, property } = props;

	const robot = useTypedSelector(
		state =>
			getStateEntityByOrgId(state, 'deviceState', {
				entityId: robotId,
				orgId,
				propertyOptions: ['online', property],
			}),
		(left, right) => equalityFnc(left, right)
	);

	if (!robot.online) {
		return <IonLabel className={classes.textGrey}>{robot[property]}</IonLabel>;
	}

	return <IonLabel>{robot[property]}</IonLabel>;
};

export default RobotDefaultProps;
