import React from 'react';
import { EmptyIcon } from './EmptyIcon';
import './index.scss';

export const EmptyListView = () => {
	return (
		<div className="emptyListWrapper">
			<EmptyIcon />
			<span className="emptyListText">You do not have access to any robots</span>
		</div>
	);
};
