import { defineMessages } from 'react-intl';

export default defineMessages({
	timeAgo: {
		timeSeconds: {
			id: 'timeAgo.seconds',
			defaultMessage: '{number}s ago',
			description: 'How many seconds ago',
		},
		timeMinutes: {
			id: 'timeAgo.minutes',
			defaultMessage: '{number}m ago',
			description: 'How many minutes ago',
		},
		timeHours: {
			id: 'timeAgo.hours',
			defaultMessage: '{number}h ago',
			description: 'How many hours ago',
		},
		timeYears: {
			id: 'timeAgo.years',
			defaultMessage: '{number}y ago',
			description: 'How many years ago',
		},
	},
	listInfo: {
		id: 'listInfo',
		defaultMessage: '{current} of {total} {listType}',
		description: 'Shows the info for number of data in list',
	},
	pageInfo: {
		id: 'pageInfo',
		defaultMessage: 'Page {current} of {total}',
		description: 'Shows the current page number',
	},
	selected: {
		id: 'selected',
		defaultMessage: '{selected} selected',
		description: 'Shows the current selected users',
	},
});
