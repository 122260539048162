import React from 'react';

export const UnavailableIcon = () => {
	return (
		<svg
			width="52"
			height="58"
			viewBox="0 0 52 58"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M26.0109 8.75659C14.9971 8.75659 6.04395 17.8194 6.04395 29.0296C6.04395 40.2398 14.9971 49.3026 26.0109 49.3026C37.0248 49.3026 45.9779 40.2398 45.9779 29.0296C45.9779 17.8194 37.0248 8.75659 26.0109 8.75659ZM4.04395 29.0296C4.04395 16.7423 13.8653 6.75659 26.0109 6.75659C38.1566 6.75659 47.9779 16.7423 47.9779 29.0296C47.9779 41.3169 38.1566 51.3026 26.0109 51.3026C13.8653 51.3026 4.04395 41.3169 4.04395 29.0296Z"
				fill="#AAAAAA"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M37.1468 0.460205L39.2325 1.38898L37.26 5.81868C45.8938 10.0038 51.847 18.852 51.847 29.0906C51.847 43.3655 40.2749 54.9376 26 54.9376C22.4939 54.9376 19.1509 54.2396 16.1024 52.9748L14.1852 57.2803L12.0994 56.3515L14.0341 52.007C5.78336 47.69 0.152954 39.0478 0.152954 29.0906C0.152954 14.8157 11.725 3.24364 26 3.24364C29.2266 3.24364 32.3151 3.83487 35.1631 4.91492L37.1468 0.460205ZM26 5.24364C12.8296 5.24364 2.15295 15.9203 2.15295 29.0906C2.15295 42.261 12.8296 52.9376 26 52.9376C39.1703 52.9376 49.847 42.261 49.847 29.0906C49.847 15.9203 39.1703 5.24364 26 5.24364Z"
				fill="white"
			/>
			<path
				d="M26.153 19.7106C20.7769 19.7106 16.403 24.0845 16.403 29.4606C16.403 34.8367 20.7769 39.2106 26.153 39.2106C31.529 39.2106 35.903 34.8367 35.903 29.4606C35.903 24.0845 31.529 19.7106 26.153 19.7106ZM29.903 30.2106H22.403C22.204 30.2106 22.0133 30.1316 21.8726 29.9909C21.732 29.8502 21.653 29.6595 21.653 29.4606C21.653 29.2617 21.732 29.0709 21.8726 28.9302C22.0133 28.7896 22.204 28.7106 22.403 28.7106H29.903C30.1019 28.7106 30.2926 28.7896 30.4333 28.9302C30.5739 29.0709 30.653 29.2617 30.653 29.4606C30.653 29.6595 30.5739 29.8502 30.4333 29.9909C30.2926 30.1316 30.1019 30.2106 29.903 30.2106Z"
				fill="#AAAAAA"
			/>
		</svg>
	);
};
