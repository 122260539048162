import React, { FC } from 'react';

import { injectIntl } from 'react-intl';
import isAuthenticated from '../Authentication/Authenticated';

interface ViewsSettingsProps {
	goTo?: () => void;
}

const ViewsSettings: FC<ViewsSettingsProps> = (props: any) => {
	return <h1>Views Details</h1>;
};

export default injectIntl(isAuthenticated(ViewsSettings, ' ViewsSettings'));
