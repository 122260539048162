import React from 'react';
import { IDeviceCurrentState, Reservation } from '../../../../../../../utils/statusConverter';
import '../../../index.scss';
import { InSessionStatus } from './components/InSessionStatus';
import { DefaultStatus } from './DefaultStatus';
import { UnavailableIcon } from './icons/UnavailableIcon';

interface Props {
	reservation: Reservation | null | undefined;
	deviceStatus: IDeviceCurrentState;
	isPermanentDevice: boolean | undefined;
	isCalling: boolean;
	nextReservationInProgress?: boolean;
}

export const ReservationStatus = ({
	reservation,
	deviceStatus,
	isPermanentDevice,
	isCalling,
	nextReservationInProgress,
}: Props) => {
	if (reservation?.isGuest && !isPermanentDevice) {
		switch (reservation?.status) {
			case 'beforeReservationDay':
			case 'reservationDay':
				return (
					<div className="currentStatusWrapper">
						<UnavailableIcon />
						<span className="statusLabel">Unavailable</span>
					</div>
				);
			case 'reservationTime':
				return (
					<DefaultStatus
						isCalling={isCalling}
						status={deviceStatus}
						isGuest={reservation?.isGuest}
					/>
				);
			case 'inSession':
				return isCalling ? (
					<DefaultStatus
						isCalling={isCalling}
						status={deviceStatus}
						isGuest={reservation?.isGuest}
					/>
				) : (
					<InSessionStatus
						isGuestSession={reservation?.isGuest}
						guestSessionInProgress={reservation?.isGuest && nextReservationInProgress}
					/>
				);
			default:
				return null;
		}
	} else if (reservation?.isGuest && isPermanentDevice) {
		switch (reservation?.status) {
			case 'beforeReservationDay':
			case 'reservationDay':
			case 'reservationTime':
				return (
					<DefaultStatus
						isCalling={isCalling}
						status={deviceStatus}
						isGuest={reservation?.isGuest}
					/>
				);
			case 'inSession':
				return isCalling ? (
					<DefaultStatus
						isCalling={isCalling}
						status={deviceStatus}
						isGuest={reservation?.isGuest}
					/>
				) : (
					<InSessionStatus
						isGuestSession={reservation?.isGuest}
						guestSessionInProgress={reservation?.isGuest && nextReservationInProgress}
					/>
				);
			default:
				return null;
		}
	} else {
		switch (reservation?.status) {
			case 'beforeReservationDay':
			case 'reservationDay':
				return (
					<DefaultStatus
						isCalling={isCalling}
						status={deviceStatus}
						isGuest={reservation?.isGuest || false}
					/>
				);
			case 'reservationTime':
				return (
					<div className="currentStatusWrapper">
						<UnavailableIcon />
						<span className="statusLabel">Unavailable</span>
					</div>
				);
			case 'inSession':
				return isCalling ? (
					<DefaultStatus
						isCalling={isCalling}
						status={deviceStatus}
						isGuest={reservation?.isGuest || false}
					/>
				) : (
					<InSessionStatus
						isGuestSession={reservation?.isGuest}
						guestSessionInProgress={reservation?.isGuest && nextReservationInProgress}
					/>
				);
			default:
				return null;
		}
	}
};
