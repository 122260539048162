import React, { FC } from 'react';
import { IonSearchbar } from '@ionic/react';

import classes from './SearchBar.module.css';

interface SearchBarProps {
	searchText: string;
	placeholder: any;
	onChange: (value: any) => void;
}

const SearchBar: FC<SearchBarProps> = (props: any) => {
	const { searchText, placeholder, onChange } = props;
	return (
		<IonSearchbar
			className={classes.searchBar}
			searchIcon="none"
			showCancelButton="never"
			value={searchText}
			placeholder={placeholder}
			// placeholder="&lt;span&gt;&lt;/span&gt;"
			onIonChange={e => onChange(e.detail.value!)}
			autocomplete="on"
			debounce={100}
		/>
	);
};

export default SearchBar;
