import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';
import isAuthenticated from '../Authentication/Authenticated';

import AddGuestReservationForm from './AddGuestReservationForm';

import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';

import { Box, Container, Typography } from '@material-ui/core';
import { useTypedSelector } from '../../reducers';
import { b64EncodeUnicode } from '../../utils/encoding';
import BasicModal from '../BasicModal/BasicModal';
import Messages from './AddGuestReservation.messages';
import classes from './AddGuestReservation.module.css';

const tabs = ['info', 'contact', 'allocate'];

interface AddGuestReservationProps extends RouteComponentProps {
	isOpen: boolean;
	onDismiss: any;
	refetchGuestReservationsData: any;
}

const AddGuestReservationModal: FC<AddGuestReservationProps> = (props: any) => {
	const {
		organization,
		selectedOrganization,
		refetchGuestReservationsData,
		selectedDeviceId,
	} = props;

	const username = useTypedSelector(state => state.accountState.user.username);
	const spinoutType = useTypedSelector(state => state.versionState.spinoutType);
	const encodedUser = b64EncodeUnicode(username);
	const organizationId = selectedOrganization.orgId;
	const { orgTypesAllowed } = organization;
	const [selectedRobots, setSelectedRobots] = useState<any[]>([]);

	const [organizationType, setOrganizationType] = useState(null);
	const [typesAllowed, setTypesAllowed] = useState([]);

	const [selectedSegment, setSelectedSegment] = useState<string>(tabs[0]);
	const [country, setCountry] = useState('');

	const [readyToCreateOrg, setReadyToCreateOrg] = useState(false);

	useEffect(() => {
		const selected: any = selectedOrganization;
		if (selected) {
			setTypesAllowed(orgTypesAllowed[selected.orgType]);
			if ((orgTypesAllowed[selected.orgType] || []).length === 1) {
				setOrganizationType(orgTypesAllowed[selected.orgType][0]);
			}
		}
	}, [orgTypesAllowed, selectedOrganization]);

	const onDismissModal = () => {
		props.onDismiss();
	};

	return (
		<BasicModal open={props.isOpen} onClose={onDismissModal}>
			<Box
				sx={{
					padding: 30,
				}}
			>
				<Typography variant="h3" className={classes.header} align="center">
					<FormattedMessage {...Messages.title} />
				</Typography>
				<Container>
					<AddGuestReservationForm
						onCancel={props.onDismiss}
						orgId={organizationId}
						refetchGuestReservationsData={refetchGuestReservationsData}
						selectedDeviceId={selectedDeviceId}
						originalReservationProps={props.originalReservationProps}
					/>
				</Container>
			</Box>
		</BasicModal>
	);
};

const mapStateToProps = (state: any) => ({
	client: state.mqttState.client,
	organization: state.organizationState,
	selectedOrganization: state.selectedOrganizationState.organization,
});

const enhance = compose(connect(mapStateToProps, { setParameter }));

export default injectIntl(
	isAuthenticated(enhance(AddGuestReservationModal), 'AddGuestReservationModal')
);
