import React from 'react';
import './privacy-and-cookie-policy_160374175000_en_gobe.css';

const PrivacyAndCookiePolicy160374175000EnGobe = () => {
	return (
		<div className="c4 c25">
			<p className="c4 c24">
				<span className="c10">Privacy and Cookie Policy</span>
			</p>
			<p className="c6 c4">
				<span className="c1">
					GoBe Robots ApS. (&ldquo;GoBe Robots&rdquo;, &ldquo;we&rdquo;, or
					&ldquo;us&rdquo;) takes the privacy and protection of personal information very
					seriously. By visiting and using the Sites and Services or by registering for
					the Services and creating an Account, you acknowledge to have read and
					understood the practices and policies outlined in this privacy policy
					(&ldquo;Privacy Policy&rdquo;). Unless otherwise defined herein, capitalized
					terms shall have the meanings assigned to such terms in the GoBe Robots Terms of
					Use that you can find on our terms of use page and which incorporate this
					Privacy Policy by reference.
				</span>
			</p>
			<p className="c20 c4">
				<span className="c0">Data Controller</span>
			</p>
			<p className="c3">
				<span className="c1">
					The legal entity responsible for the processing of your Personal Information is:
					<br />
					<br />
					GoBe Robots ApS
				</span>
			</p>
			<p className="c3">
				<span className="c1">Svendborgvej 226, Lindved</span>
			</p>
			<p className="c3">
				<span className="c1">5260 Odense S</span>
			</p>
			<p className="c3">
				<span className="c1">CVR-nr. 40664599</span>
			</p>
			<p className="c4 c18">
				<span className="c0">What This Privacy Policy Covers</span>
			</p>
			<p className="c3">
				<span className="c1">
					This Privacy Policy describes GoBe Robots practices with respect to Personal
					Information and Anonymous Information that we collect when you use the Services.
					This Privacy Policy does not apply to the use of your Personal Information that
					is collected by third parties that GoBe Robots does not own or control or that
					do not support GoBe Robots in providing the Services.
				</span>
			</p>
			<p className="c4 c21">
				<span className="c1">
					&ldquo;Personal Information&rdquo; is information that is personally
					identifiable like names, addresses, email addresses, or phone numbers, as well
					as other non-public information that is associated with the foregoing.
				</span>
			</p>
			<p className="c6 c4">
				<span className="c1">
					&ldquo;Anonymous Information&rdquo; means information that is not associated
					with or linked to your Personal Information; Anonymous Information does not
					permit the identification of individual persons.
				</span>
			</p>
			<p className="c6 c4">
				<span className="c1">
					We collect and use Personal Information and Anonymous Information as described
					in this Privacy Policy from Users.
				</span>
			</p>
			<p className="c6 c4">
				<span className="c15">
					YOUR PERSONAL INFORMATION MAY BE PROCESSED IN THE COUNTRY WHERE IT WAS COLLECTED
					AS WELL AS OTHER COUNTRIES (INCLUDING THE UNITED STATES) WHERE LAWS REGARDING
					PROCESSING OF PERSONAL INFORMATION MAY BE LESS STRINGENT THAN THE LAWS IN YOUR
					COUNTRY. GoBe Robots only transfers Personal Information from the EU/EAA to
					third countries or international organisations if the conditions laid down in
					Chapter V of the General Data Protection Regulations are fulfilled.
				</span>
				<span className="c15">&nbsp;</span>
			</p>
			<p className="c3">
				<span className="c0">Personal Information We Collect</span>
			</p>
			<p className="c6 c4">
				<span className="c1">
					We collect the following Personal Information that you submit to us voluntarily:
				</span>
			</p>
			<ul className="c11 lst-kix_esf4yiq3lmjd-0 start">
				<li className="c3 c7">
					<span className="c1">
						If you register for the Services and create an Account to become a Member,
						we collect your name, a username, your email address, and a password for
						your Account.
					</span>
				</li>
				<li className="c3 c7">
					<span className="c1">
						When you register for the Services we may also collect additional
						information on an optional basis, including, but not limited to, the city,
						state, and country where you live, and the company you work for.
					</span>
				</li>
				<li className="c6 c4 c7">
					<span className="c1">
						When you contact us by sending us an email, we will collect any information
						that is contained in (and/or attached to) your email.
					</span>
				</li>
			</ul>
			<p className="c6 c4">
				<span className="c1">
					We do not process any special categories of Personal Information (sensitive
					data).
				</span>
			</p>
			<p className="c3">
				<span className="c0">Personal Information Collected Via Technology</span>
			</p>
			<p className="c6 c4">
				<span className="c1">
					As you use the Services or Site, certain information may also be collected
					passively, including your Internet protocol (IP) address and the browser that
					you use to visit the Sites. GoBe Robots also stores a small text file called a
					&ldquo;Cookie&rdquo; on your computer to store your login information (if any)
					and your personal preferences for the Services. GoBe Robots may use both session
					Cookies (which expire once you close your web browser) and persistent Cookies
					(which stay on your computer until you delete them). While you will still be
					able to use the Services if you disable Cookies in your browser, certain parts
					of the Services may not function as effectively.
				</span>
			</p>
			<ol className="c11 lst-kix_78hcq49ygbyk-0 start" start="1">
				<li className="c3 c7">
					<span className="c14">How Do We Use Cookies?</span>
				</li>
			</ol>
			<ol className="c11 lst-kix_78hcq49ygbyk-1 start" start="1">
				<li className="c3 c5">
					<span className="c1">On our Website, we use cookies to:</span>
				</li>
			</ol>
			<ul className="c11 lst-kix_xzz4ue5r9r5k-0 start">
				<li className="c3 c5">
					<span className="c1">
						Remember your display preferences, such as contrast color settings or font
						size
					</span>
				</li>
				<li className="c3 c5">
					<span className="c1">
						Remember if you have already replied to a survey pop-up that asks you if the
						content was helpful or not (so you will not be asked again)
					</span>
				</li>
				<li className="c3 c5">
					<span className="c1">
						Remember if you have agreed (or not) to our use of cookies on the Website
					</span>
				</li>
				<li className="c3 c5">
					<span className="c1">Remember your login information</span>
				</li>
				<li className="c3 c5">
					<span className="c1">Help the performance of the Website</span>
				</li>
				<li className="c3 c5">
					<span className="c1">
						Anonymously provide us with analytics with respect to the overall usage and
						traffic on the Website
					</span>
				</li>
				<li className="c3 c5">
					<span className="c1">
						Track your first visit to the Website and the number of times you have
						visited the Website
					</span>
				</li>
				<li className="c3 c5">
					<span className="c1">Track how you reached the Website</span>
				</li>
				<li className="c3 c5">
					<span className="c1">
						Track the number of pages on the Website that you visited
					</span>
				</li>
				<li className="c9 c5 c4">
					<span className="c1">Personalize the content you see on the Website</span>
				</li>
			</ul>
			<ol className="c11 lst-kix_78hcq49ygbyk-1 start" start="1">
				<li className="c21 c5 c4">
					<span className="c1">
						[Also, some videos embedded in pages on the Website use a cookie to
						anonymously gather statistics on how you got there and what videos you
						visited.]
					</span>
				</li>
				<li className="c9 c5 c4">
					<span className="c1">
						Enabling these cookies is not strictly necessary in order for the Website to
						work but it will provide you with a better browsing experience. You can
						delete or block these cookies as allowed by your browser, but if you do,
						some features of this site may not work as intended.
					</span>
				</li>
			</ol>
			<ol className="c11 lst-kix_78hcq49ygbyk-0" start="2">
				<li className="c20 c7 c4">
					<span className="c14">Do We Use Other Cookies?</span>
				</li>
			</ol>
			<ol className="c11 lst-kix_78hcq49ygbyk-1 start" start="1">
				<li className="c6 c5 c4">
					<span className="c1">
						We may use tracking cookies to provide us with information needed for
						advertising.
					</span>
				</li>
				<li className="c5 c4 c9">
					<span className="c1">
						If we use such cookies, you will find a description of the individual
						cookies known to us at the end of this policy.
					</span>
				</li>
			</ol>
			<ol className="c11 lst-kix_78hcq49ygbyk-0" start="3">
				<li className="c21 c7 c4">
					<span className="c14">How to Control Cookies</span>
				</li>
			</ol>
			<ol className="c11 lst-kix_78hcq49ygbyk-1 start" start="1">
				<li className="c9 c5 c4">
					<span className="c1">
						You can control and/or delete cookies as allowed by your browser - for
						details, see http://www.aboutcookies.org/. You can delete cookies that are
						already on your computer and you can set most browsers to prevent them from
						being placed. If you do this, however, you may have to manually adjust some
						preferences every time you visit a website and some services and
						functionalities may not work.
					</span>
				</li>
			</ol>
			<p className="c6 c4 c19">
				<span className="c1">
					Since the foregoing information may be collected when you are signed into the
					Services, this information may be associated by us with Personal Information
					that we have collected from you.
				</span>
			</p>
			<p className="c3">
				<span className="c0">Personal Information From Other Sources</span>
			</p>
			<p className="c6 c4">
				<span className="c1">
					We may receive Personal Information about you from outside the Sites, such as
					telephone, fax, mail, or from third parties that provide services for us that
					are related to the Services. We may add this information to the information we
					have already collected from you via the Services.
				</span>
			</p>
			<p className="c3">
				<span className="c0">
					Use Of Information We Collect/Purpose of Collecting Information
				</span>
			</p>
			<p className="c6 c4">
				<span className="c1">
					In general, information (including Personal Information) you submit to us, or to
					third parties that support us in collecting information, is used by us to
					provide you and others with the Services, to respond to requests that you make,
					to improve our Services and to better tailor the features, performance and
					support of the Services.
				</span>
			</p>
			<p className="c6 c4">
				<span className="c1">
					We may also use your Personal Information to provide you with notifications
					related to your use of the Services, such as electronic newsletters or
					promotional emails, should you request to receive such communications from us.
				</span>
			</p>
			<p className="c6 c4">
				<span className="c1">
					We may create Anonymous Information records from Personal Information by
					excluding information (such as your name) that makes the information personally
					identifiable to you. We may also create Anonymous Information on how you use the
					Services. We may use this Anonymous Information for internal purposes, such as
					analyzing usage patterns so that we may enhance the Services, and we also
					reserve the right to use and disclose any Anonymous Information at our
					discretion.
				</span>
			</p>
			<p className="c6 c4">
				<span className="c1">
					We do not process your personal data for any other purpose than the above.
				</span>
			</p>
			<p className="c6 c4">
				<span className="c1">
					Our processing of your personal data collected in connection with your use of
					our Website and Services is based on our legitimate interest in furnishing you
					with information about our Services, products, events etc.
				</span>
			</p>
			<p className="c3">
				<span className="c0">Your rights</span>
			</p>
			<ul className="c11 lst-kix_u755olrqzope-0 start">
				<li className="c12 c16">
					<span className="c14 c4">Right to access, rectification etc.</span>
				</li>
			</ul>
			<p className="c12">
				<span className="c1 c4">
					You are entitled to be informed of the personal information which GoBeRobots is
					processing. You also have the right to have incorrect personal information
					corrected and request that GoBe Robots erase some or all of your personal
					information. However, GoBe Robots will not erase personal information if there
					are legal reasons for further processing, including if there is a need for
					retaining the information in accordance with relevant legislation on
					bookkeeping, if claims are not time barred etc.
				</span>
			</p>
			<p className="c8">
				<span className="c1 c4" />
			</p>
			<ul className="c11 lst-kix_uvla8mc0uget-0 start">
				<li className="c12 c16">
					<span className="c4 c14">Right to object </span>
				</li>
			</ul>
			<p className="c12">
				<span className="c1 c4">
					You are at any time entitled to object to the processing of your personal
					information. This is due to a balance between the interests of both parties. If
					there are no legitimate considerations that weigh heavier than your interest in
					ceasing the processing of your information, or legal reasons to continue
					processing, the processing will cease.
				</span>
			</p>
			<p className="c8">
				<span className="c1 c4" />
			</p>
			<p className="c8">
				<span className="c1 c4" />
			</p>
			<ul className="c11 lst-kix_8uromte14yar-0 start">
				<li className="c12 c16">
					<span className="c14 c4">Data portability</span>
				</li>
			</ul>
			<p className="c12">
				<span className="c1 c4">
					You are entitled to receive all personal data processed based on your access to
					the Site, Services and Account &nbsp; with GoBe Robots, transferred from
					GoBeRobots to another data controller (i.e., Data Portability Right). GoBe may
					send your personal information to the designated recipient of the information
					per. encrypted email and in many cases collected in excel files.
				</span>
			</p>
			<p className="c8">
				<span className="c1 c4" />
			</p>
			<ul className="c11 lst-kix_johatq9hcprj-0 start">
				<li className="c12 c16">
					<span className="c14 c4">Requests and complaints</span>
				</li>
			</ul>
			<p className="c9 c13">
				<span className="c1 c4">
					The request to exercise any of the rights listed above is addressed to [ &nbsp;]
					GoBe Robots. The request to GoBe Robots must be in writing and signed by you as
					an applicant and sent to the email address specified above.
				</span>
			</p>
			<p className="c12">
				<span className="c2">
					You are also entitled at any time to contact the Company or the Danish Data
					Protection Agency for reporting a complaint about GoBe Robots&rsquo;s processing
					of Personal Information. The Danish Data Protection Agency can be contacted on
					dt@remove-this.datatilsynet.dk.
				</span>
			</p>
			<ul className="c11 lst-kix_dkg1v55wbi2c-0 start">
				<li className="c7 c4 c20">
					<span className="c0">Feedback</span>
				</li>
			</ul>
			<p className="c6 c13 c4">
				<span className="c1 c4">
					If you provide feedback about the Services to us, we may use and disclose such
					feedback for any purpose, provided we do not associate such feedback with your
					Personal Information. We will collect any information contained in such feedback
					and will treat the Personal Information in it in accordance with this Privacy
					Policy.
				</span>
			</p>
			<p className="c3">
				<span className="c23">Disclosure of Personal Information</span>
			</p>
			<p className="c9">
				<span className="c1 c4">
					Except as otherwise stated in this Privacy Policy, we do not disclose to or
					share your Personal Information with third parties, unless you ask or authorize
					us to do so.
				</span>
			</p>
			<p className="c4 c6">
				<span className="c1">
					We may provide your Personal Information to third party service providers and
					suppliers who work on behalf of or with us to provide you with some of the
					services and features of the Services and to help us communicate with our Users.
					However, these service providers and suppliers do not have any independent right
					to use this information except to help us provide the Services.
				</span>
			</p>
			<p className="c6 c4">
				<span className="c15">
					We may &nbsp;share some or all of your Personal Information with{' '}
				</span>
				<span className="c15">our </span>
				<span className="c1">
					parent company, any subsidiaries, joint ventures, or other companies under a
					common control (collectively, &ldquo;Affiliates&rdquo;), in which case we will
					require our Affiliates to honour this Privacy Policy.
				</span>
			</p>
			<p className="c6 c4">
				<span className="c1">
					In the event GoBe Robots goes through a business transition such as a merger,
					acquisition by another company, or sale of all or a portion of its assets, your
					Personal Information will likely be among the assets transferred. You
					acknowledge that such transfers may occur, and that any acquirer of GoBe Robots
					or its assets may continue to use your Personal Information as set forth in this
					Privacy Policy. You hereby consent to GoBe Robots sharing your Personal
					Information under the above circumstances.
				</span>
			</p>
			<p className="c6 c4">
				<span className="c1">
					We may (and you authorize us to) disclose information we have collected from and
					about you (including Personal Information) if we believe in good faith that such
					disclosure is necessary to (a) comply with relevant laws or to respond to
					subpoenas or warrants served on us; (b) to enforce any agreement we may have
					entered into with you and to enforce the Privacy Policy; or (c) to protect and
					defend the rights or property of us, other Users, or third parties.
				</span>
			</p>
			<p className="c3">
				<span className="c0">Choices Regarding Personal Information</span>
			</p>
			<p className="c6 c4">
				<span className="c1">
					We will include unsubscribe instructions in each newsletter or promotional
					communication you receive from us.
				</span>
			</p>
			<p className="c6 c4">
				<span className="c1">
					We may send you service-related announcements (such as, but not limited to,
					notice that we have changed the Services, the Terms of Use, or the Privacy
					Policy) when we believe it is necessary to do so. You may not opt-out of these
					communications, which are not promotional in nature, but if you do not wish to
					receive these announcements, you have the option to terminate the Account you
					may have with us. You understand that any termination of your Account may
					involve deletion of your Account information (and associated Content) from our
					live databases and all the information and data stored for such Account in the
					Services. GoBe Robots will not have any liability whatsoever to you for any
					termination of your account or related deletion of your information.
				</span>
			</p>
			<p className="c3">
				<span className="c0">Retention of Personal Information</span>
			</p>
			<p className="c6 c4">
				<span className="c1">
					We will retain your Personal Information only as long as it is necessary to meet
					our business needs or to comply with applicable legal and regulatory
					requirements.
				</span>
			</p>
			<p className="c6 c4">
				<span className="c1">
					Your Personal information is primarily stored in digital media, and when we no
					longer need to retain your Personal Information, we will dispose of your
					Personal Information, whether stored in digital media or in hard copies, in a
					secure manner.
				</span>
			</p>
			<p className="c6 c4">
				<span className="c1">
					When we delete information that we have collected from or about you, it will be
					deleted from our active databases as well as our archives.
				</span>
			</p>
			<p className="c3">
				<span className="c0">Links to Other Websites</span>
			</p>
			<p className="c6 c4">
				<span className="c1">
					The Services may contain links to third-party websites (&ldquo;Third-Party
					Websites&rdquo;) (a) placed by us as a service to those interested in this
					information; or (b) posted by other Members. You understand that we do not
					monitor or have any control over and make no claim or representation regarding
					Third-Party Websites. To the extent such links are provided by us, they are
					provided only as a convenience, and such link to a Third-Party Website does not
					imply our endorsement, responsibility, adoption or sponsorship of, or
					affiliation with, such Third-Party Website. We have no control over, do not
					review and are not responsible for the privacy policies of or content displayed
					on such other websites. Please be aware that the terms of the Privacy Policy do
					not apply to any outside websites
				</span>
			</p>
			<p className="c3">
				<span className="c0">Privacy of Minors</span>
			</p>
			<p className="c6 c4">
				<span className="c1">
					We do not intentionally collect or maintain information from persons under the
					age of 13.
				</span>
			</p>
			<p className="c3">
				<span className="c0">Security and Confidentiality</span>
			</p>
			<p className="c6 c4">
				<span className="c1">
					Beam Robots has put in place commercially reasonable and appropriate physical,
					electronic, and managerial procedures to safeguard and secure the information we
					collect online. However, no company, including GoBe Robots, can fully eliminate
					security risks associated with Personal Information.
				</span>
			</p>
			<p className="c3">
				<span className="c0">Changes in this Privacy Policy</span>
			</p>
			<p className="c6 c4">
				<span className="c1">
					As part of the Terms of Use, this Privacy Policy is subject to occasional
					amendment, in accordance with the terms of the Terms of Use.
				</span>
			</p>
			<p className="c3">
				<span className="c1">The Privacy Policy was last modified on:</span>
			</p>
			<p className="c6 c4">
				<span className="c1">October 12th 2020</span>
			</p>
			<p className="c3">
				<span className="c0">Contact Us</span>
			</p>
			<p className="c6 c4">
				<span className="c15">
					If you have any questions or suggestions regarding our Privacy Policy please{' '}
				</span>
				<span className="c15 c17">
					<a className="c22" href="mailto:info@gobe-robots.com">
						contact us
					</a>
				</span>
				<span className="c15">.</span>
			</p>
		</div>
	);
};

export default PrivacyAndCookiePolicy160374175000EnGobe;
