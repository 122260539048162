import React, { FC, useState, useEffect } from 'react';
import { IonContent, IonPage, IonLabel, IonIcon } from '@ionic/react';
import PropTypes from 'prop-types';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import classes from './MainContent.module.css';
import Header from '../Header/Header';
import { FMFooterLogo } from '../../ui-elements/FMFooterLogo';
interface MainProps {
	title?: string;
	history: RouteComponentProps;
	headerChildren?: React.ReactNode;
	renderContent: (history: RouteComponentProps) => any;
}

type MainContentProps = RouteComponentProps & MainProps;

const MainContent: FC<MainContentProps> = ({ title, history, headerChildren, renderContent }) => {
	const [hideFooter, setHideFooter] = useState(false);

	useEffect(() => {
		if (window.location.pathname.includes('/gobe')) {
			setHideFooter(true);
		} else {
			setHideFooter(false);
		}
	}, []);

	return (
		<IonPage id="main">
			<Header />
			<IonContent className={classes.mainContent}>
				<div className={classes.pageContainer}>
					<div className={classes.pageHeader}>
						{title ? <IonLabel class={classes.pageTitle}>{title}</IonLabel> : null}
						{headerChildren}
					</div>
					<div className={classes.pageContent}>{renderContent(history)}</div>
					{
						hideFooter ? (
							<div />
						) :
							(<footer className={classes.footerContainer}>
								<FMFooterLogo />
							</footer>)
						
					}
				</div>
			</IonContent>
		</IonPage>
	);
};
MainContent.propTypes = {
	renderContent: PropTypes.func.isRequired,
};

export default withRouter(MainContent);
