/* eslint-disable camelcase */
import { ISignalingClient } from './types';
import { SessionInfo } from '../useSessionInitiator';
import MQTTSignalingClient from './mqttSignalling';
// TODO: NEW-TAB-PROJECT
// No need to import MQTTSignalingClient
import SocketIOSignalingClient from './socketIOSignaling';
import { useEffect, useMemo, useRef } from 'react';

/**
 * Signaling client factory.
 *
 * Returns a signaling client based on the robot's capabilities.
 */
export default function useSignalingClient(_sessionInfo: SessionInfo): ISignalingClient {
	const sessionInfo = useRef(_sessionInfo).current;

	const signalingClient = useMemo(() => {
		if (sessionInfo.capabilities?.signaling_via_socket_io && sessionInfo.signaling) {
			console.debug('Using socket.io signaling');
			return new SocketIOSignalingClient({
				...sessionInfo,
				signaling: sessionInfo.signaling,
			});
		} else {
			console.debug('Using MQTT signaling');
			return new MQTTSignalingClient(sessionInfo);
		}

		// TODO: NEW-TAB-PROJECT
		// Return only a SocketIOSignalingClient instance.
		// Remember that the 'signaling' prop in SessionInfo will be no-null
	}, [sessionInfo]);

	// close the signaling client when the component is unmounting
	useEffect(() => {
		return () => {
			signalingClient
				.close()
				.catch(error => console.error('Failed to close signaling client', error));
		};
	}, [signalingClient]);

	return signalingClient;
}
