import { IonFab, IonFabButton, IonIcon } from '@ionic/react';
import { add, business, people, person, refreshSharp, trash } from 'ionicons/icons';
import React, { FC, useCallback, useEffect, useState } from 'react';
import isAuthenticated from '../../components/Authentication/Authenticated';
import MainContent from '../../components/MainContent/MainContent';
import Tabs from '../../components/Tabs/Tabs';

import AddOrganizationModal from '../../components/AddOrganizationModal/AddOrganizationModal';
import DeleteModalOrgDeviceError from '../../components/DeleteModal/DeleteModalOrgDeviceError';
import DeleteModal from '../../components/DeleteModal/DeleteOrgModal';
import OrganizationInfo from '../../components/OrganizationInfo/OrganizationInfo';
import RobotGroups from '../../components/RobotGroups/RobotGroups';
import Robots from '../../components/Robots/Robots';
import UserGroups from '../../components/UserGroups/UserGroups';
import Users from '../../components/Users/Users';

import { Cascader } from 'antd';
import 'antd/dist/antd.css';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { publish } from '../../actions/publish';
import { setParameter } from '../../actions/setParam';

import Messages from './FleetManagement.messages';

import _ from 'lodash';
import { Redirect } from 'react-router-dom';
import { subscribeToOrganization } from '../../providers/mqtt';
import { useTypedSelector } from '../../reducers';
import { store } from '../../store/store';
import { Organization } from '../../types/types';
import { equalityFnc } from '../../utils/conformState';
import { b64EncodeUnicode } from '../../utils/encoding';
import classes from './FleetManagement.module.css';
import './styles.css';

const FleetManagement: FC = (props: any) => {
	const {
		selectedOrganizationId,
		selectedOrganizationName,
		organization,
		location,
		setParameter,
		history,
		userRoles,
	} = props;
	const [selectedOrgId, setSelectedOrgId] = useState<string>(selectedOrganizationId);
	const [selectedOrgName, setSelectedOrgName] = useState<string>(selectedOrganizationName);
	const [currentTab, setCurrentTab] = useState<string | undefined>('users');
	const [isAddHidden, setIsAddHidden] = useState(false);
	const [isDeleteHidden, setIsDeleteHidden] = useState(false);
	const [showAddOrganizationModal, setAddOrganizationModal] = useState(false);
	const [deleteOpen, setDeleteOpen] = useState(false);
	const [deleteErrorOpen, setDeleteErrorOpen] = useState(false);
	const [allOrganizations, setAllOrganizations] = useState<Array<any>>(
		Object.values(organization.organizations || {})
	);
	const { orgTypesAllowed, newOrganizationOpen } = organization;
	const wrappedClient = useTypedSelector(state => state.mqttState.client);
	const user = useTypedSelector(state => state.accountState.user);
	const username = useTypedSelector(state => state.accountState.user.username);
	const userOrganizationId = useTypedSelector(
		state => state.accountState.user.selectedOrganizationId
	);
	let encodedUser = b64EncodeUnicode(username);
	const spinoutType = useTypedSelector(state => state.versionState.spinoutType);
	const reloadOrg = useTypedSelector(state => state.selectedOrganizationState.reloadOrganization);

	const devices = useTypedSelector(
		state => state.deviceState.devicesByOrganizationId,
		(left, right) => equalityFnc(left, right, !isDeleteHidden)
	);

	const selectedOrg: Organization = allOrganizations.find((o: any) => o.orgId === selectedOrgId);
	const defaultOrg = allOrganizations.find((o: any) => o.orgId === selectedOrganizationId);

	const defaultOrgForCascader = defaultOrg
		? [
				{
					value: defaultOrg.orgId,
					label: defaultOrg.name,
					children: [],
				},
		  ]
		: [];

	const getParentOrgId = (selectedOrgId: string, orgStructure: any = '') => {
		const org = getParentOrg(selectedOrgId, orgStructure);
		if (org) {
			return org.id;
		}
		return '';
	};

	const getParentOrg = (selectedOrgId: string, orgStructureArray: any = []) => {
		if (!orgStructureArray || orgStructureArray.length === 0) {
			orgStructureArray = organization ? organization.organizationStructure : [];
		}

		if (orgStructureArray) {
			for (let orgStructure of orgStructureArray) {
				for (let child in orgStructure.children) {
					if (child === selectedOrgId) {
						return orgStructure;
					} else {
						const temp: any = getParentOrg(selectedOrgId, [
							orgStructure.children[child],
						]);
						if (temp) {
							return temp;
						}
					}
				}
			}
		}
		return '';
	};

	const getParentLink = (orgId: string) => {
		let parentLink = [orgId];
		let parent = getParentOrgId(orgId);
		while (parent) {
			parentLink = [parent, ...parentLink];
			parent = getParentOrgId(parent);
		}
		return parentLink;
	};

	const [selectedOrganizationPath, setSelectedOrganizationPath] = useState(
		defaultOrg && defaultOrg.orgId ? getParentLink(defaultOrg.orgId) : []
	);

	const constructTabsData = (skipUsers: boolean) => {
		return [
			...(skipUsers
				? []
				: [
						{
							title: Messages.users,
							path: 'users',
							icon: person,
							filter: [
								Messages.all,
								Messages.admins,
								Messages.notAdmins,
								Messages.registrationIncomplete,
							],
							sortIndex: spinoutType === 'uvd' ? 3 : 1,
							dataCy: 'users_tab',
						},
				  ]),
			...(spinoutType === 'gobe' || spinoutType === 'uvd'
				? [
						{
							title: Messages.userGroups,
							path: 'userGroups',
							icon: people,
							filter: [Messages.all, Messages.noMembers, Messages.noRobotGroups],
							sortIndex: 2,
							dataCy: 'userGroups_tab',
						},
						{
							title: Messages.robots,
							path: 'robots',
							icon:
								spinoutType === 'uvd'
									? './assets/icons/uvd-robot-single.svg'
									: './assets/icons/Robot-single.svg',
							filter: [
								Messages.all,
								Messages.available,
								Messages.unavailable,
								Messages.unassigned,
							],
							sortIndex: spinoutType === 'uvd' ? 1 : 3,
							dataCy: 'robots_tab',
						},
						{
							title: Messages.deviceGroups,
							path: 'robotGroups',
							icon:
								spinoutType === 'uvd'
									? './assets/icons/uvd-robot-group.svg'
									: './assets/icons/Robot-group.svg',
							filter: [Messages.all],
							sortIndex: spinoutType === 'uvd' ? 2 : 4,
							dataCy: 'robotGroups_tab',
						},
				  ]
				: []),
			{
				title: Messages.organizationInfo,
				path: 'organization-info',
				icon: business,
				sortIndex: 5,
				dataCy: 'organization-info_tab',
			},
		].sort((a, b) => a.sortIndex - b.sortIndex);
	};

	const [tabsData, setTabsData] = useState<Array<any>>(
		constructTabsData(spinoutType === 'beam' && selectedOrg.orgType === 'customer')
	);
	useEffect(() => {
		const currentTab =
			spinoutType === 'beam' && selectedOrg?.orgType === 'customer'
				? 'organization-info'
				: spinoutType === 'uvd'
				? 'robots'
				: 'users';
		setCurrentTab(currentTab);
	}, [selectedOrg?.orgType, spinoutType, tabsData]);

	useEffect(() => {
		setSelectedOrgId(selectedOrganizationId);
		let orgId: any = getParentLink(selectedOrganizationId);
		setSelectedOrganizationPath(orgId);
	}, [selectedOrganizationId]);

	useEffect(() => {
		setAllOrganizations(Object.values(organization.organizations));
	}, [organization, organization.organizations]);

	useEffect(() => {
		if (location.data) {
			setCurrentTab(location.data.tab);
			setSelectedOrgId(location.data.orgId);
		}
	}, [location.data]);

	useEffect(() => {
		const selectedOrg: Organization = allOrganizations.find(
			(o: any) => o.orgId === selectedOrgId
		);
		//set selected organization state to the changed organization
		for (let key in selectedOrg) {
			setParameter(key, `UPDATE_SEL_ORG_${key.toUpperCase()}`, selectedOrg[key]);
		}
		if (!selectedOrg) return;

		// Get agreements for organization
		setParameter('', 'UNLOAD_ORG_ACCEPTED_AGREEMENTS', {});
		if (selectedOrg) {
			publish(`microservice/${selectedOrg.orgId}/${encodedUser}/getOrgAcceptedAgreements`, {
				requestId: 'getOrgAcceptedAgreementsId',
				data: {
					spinoutType: spinoutType ? spinoutType : '',
				},
			});
		}

		setIsAddHidden(orgTypesAllowed[selectedOrg.orgType].length < 1 ? true : false);
		setIsDeleteHidden(
			selectedOrg.orgType === 'bor' /* || !getRole(user)?.match(/bor|spinout/g) */
				? true
				: false // 'false' changed to 'true' to hide the delete button until stability is reached
		);

		setSelectedOrgName(selectedOrg.name);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allOrganizations, orgTypesAllowed, selectedOrgId, setParameter]);

	useEffect(() => {
		if (reloadOrg) {
			setSelectedOrgId(userOrganizationId);
			setParameter('reload', 'SET_RELOAD_ORGANIZATION', false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reloadOrg]);

	useEffect(() => {
		if (newOrganizationOpen) {
			setAddOrganizationModal(true);
		} else {
			setAddOrganizationModal(false);
		}
	}, [newOrganizationOpen]);

	const onOrganizationChange = useCallback(
		(selected: any) => {
			let orgId: any;
			if (!selected) {
				orgId = getParentLink(defaultOrg.orgId);
				setSelectedOrganizationPath(orgId);
			} else if (Array.isArray(selected)) {
				orgId = _.last(selected);
				setSelectedOrganizationPath(selected);
			} else {
				orgId = selected;
				setSelectedOrganizationPath([orgId]);
			}
			if (selectedOrgId && orgId !== selectedOrgId) {
				setParameter('showLoader', 'CHANGE_LOADER', true);
				publish(`microservice/${orgId}/${b64EncodeUnicode(username)}/getOrgInfo`, {
					requestId: `getOrgInfoId-${orgId}`,
					data: {},
				});

				//subscribing to the organization here gives faster login than loading all on initial load.
				if (organization.subscribedOrgs.indexOf(orgId) === -1) {
					subscribeToOrganization(wrappedClient, orgId);
				}
				setCurrentTab('');
				setTabsData(
					constructTabsData(spinoutType === 'beam' && selectedOrg.orgType === 'customer')
				);
				setSelectedOrgId(orgId);
				store.dispatch({
					type: 'UPDATE_SELECTEDORGANIZATIONID',
					payload: { selectedOrganizationId: orgId },
				});
			}
		},
		[wrappedClient, defaultOrg]
	);

	const getParentOrgName = (selectedOrgId: string, orgStructure: any = '') => {
		const org = getParentOrg(selectedOrgId, orgStructure);
		if (org) {
			return org.label;
		}
		return '';
	};

	const getAllDevices = (orgStructure: any) => {
		let devices: any = [];
		if (orgStructure) {
			for (let child in orgStructure.children) {
				if (
					orgStructure.children[child].devices &&
					orgStructure.children[child].devices.length > 0
				) {
					for (let device of orgStructure.children[child].devices) {
						devices = [...devices, device.serialNumber];
					}
				}

				const temp: any = getAllDevices(orgStructure.children[child]);
				if (temp) {
					devices = [...devices, ...temp];
				}
			}
		}
		return devices;
	};

	const getDevicesInclusiveChild = (selectedOrgId: string, orgStructureArray: any = []) => {
		if (!orgStructureArray || orgStructureArray.length === 0) {
			orgStructureArray = organization ? organization.organizationStructure : [];
		}

		let devices: any = [];
		if (orgStructureArray) {
			for (let orgStructure of orgStructureArray) {
				for (let child in orgStructure.children) {
					if (child === selectedOrgId) {
						if (
							orgStructure.children[child].devices &&
							orgStructure.children[child].devices.length > 0
						) {
							devices = [
								...devices,
								...orgStructure.children[child].devices.map(
									(device: { serialNumber: any }) => device.serialNumber
								),
							];
						}

						const temp: any = getAllDevices(orgStructure.children[child]);
						if (temp) {
							devices = [...devices, ...temp];
						}
						return devices.join(', ');
					} else {
						const temp: any = getDevicesInclusiveChild(selectedOrgId, [
							orgStructure.children[child],
						]);
						if (temp) {
							return temp;
						}
					}
				}
			}
		}
	};

	const getAllChilds = (orgStructure: any) => {
		let childOrgs: any = [];
		if (orgStructure) {
			for (let child in orgStructure.children) {
				childOrgs = [...childOrgs, orgStructure.children[child].label];

				const temp: any = getAllChilds(orgStructure.children[child]);
				if (temp) {
					childOrgs = [...childOrgs, ...temp];
				}
			}
		}
		return childOrgs;
	};

	function getChildOrgNames(selectedOrgId: string, orgStructureArray: any = []): any {
		if (!orgStructureArray || orgStructureArray.length === 0) {
			orgStructureArray = organization ? organization.organizationStructure : [];
		}

		let childOrgs: any = [];
		if (orgStructureArray) {
			for (let orgStructure of orgStructureArray) {
				for (let child in orgStructure.children) {
					if (child === selectedOrgId) {
						//@TODO: This line needs to be removed as this is causing duplicity in org deletion modal message
						childOrgs = [...childOrgs, orgStructure.children[child].label];

						const temp: any = getAllChilds(orgStructure.children[child]);
						if (temp) {
							childOrgs = [...childOrgs, ...temp];
						}
						return childOrgs.join(', ');
					} else {
						const temp: any = getChildOrgNames(selectedOrgId, [
							orgStructure.children[child],
						]);
						if (temp) {
							return temp;
						}
					}
				}
			}
		}
	}

	function getOrgStructureForDeletingOrg(
		selectedOrgId: string,
		orgStructureArray: any = []
	): any {
		if (!orgStructureArray || orgStructureArray.length === 0) {
			orgStructureArray = organization ? organization.organizationStructure : [];
		}

		let childOrgs: any = {};
		if (orgStructureArray) {
			for (let orgStructure of orgStructureArray) {
				for (let child in orgStructure.children) {
					if (child === selectedOrgId) {
						return orgStructure.children[child];
					} else {
						const temp: any = getOrgStructureForDeletingOrg(selectedOrgId, [
							orgStructure.children[child],
						]);
						if (temp) {
							return temp;
						}
					}
				}
			}
		}
	}

	const getAllOrgsWithDevices = (orgStructure: any) => {
		let orgsWithDevice: any = [];
		if (orgStructure) {
			for (let child in orgStructure.children) {
				if (
					orgStructure.children[child].devices &&
					orgStructure.children[child].devices.length > 0
				) {
					orgsWithDevice = [...orgsWithDevice, orgStructure.children[child].label];
				}

				const temp: any = getAllOrgsWithDevices(orgStructure.children[child]);
				if (temp) {
					orgsWithDevice = [...orgsWithDevice, ...temp];
				}
			}
		}
		return orgsWithDevice;
	};

	function getDeletingOrgsWithDevices(selectedOrgId: string, orgStructureArray: any = []): any {
		if (!orgStructureArray || orgStructureArray.length === 0) {
			orgStructureArray = organization ? organization.organizationStructure : [];
		}

		let orgsWithDevice: any = [];
		if (orgStructureArray) {
			for (let orgStructure of orgStructureArray) {
				for (let child in orgStructure.children) {
					if (child === selectedOrgId) {
						if (
							orgStructure.children[child].devices &&
							orgStructure.children[child].devices.length > 0
						) {
							orgsWithDevice = [
								...orgsWithDevice,
								orgStructure.children[child].label,
							];
						}

						const temp: any = getAllOrgsWithDevices(orgStructure.children[child]);
						if (temp) {
							orgsWithDevice = [...orgsWithDevice, ...temp];
						}
						return orgsWithDevice.join(', ');
					} else {
						const temp: any = getDeletingOrgsWithDevices(selectedOrgId, [
							orgStructure.children[child],
						]);
						if (temp) {
							return temp;
						}
					}
				}
			}
		}
	}

	const onTabClick = (selected: string | undefined) => {
		setCurrentTab(selected);
	};
	const onTabChange = (selected: any) => {
		setCurrentTab(selected);
	};
	const renderTabContent = useCallback(() => {
		switch (currentTab) {
			case 'users':
				return (
					<Users
						filterValues={tabsData.find(td => td.path === currentTab)?.filter}
						orgId={selectedOrgId}
						history={history}
					/>
				);
			case 'userGroups':
				return (
					<UserGroups
						filterValues={tabsData.find(td => td.path === currentTab)?.filter}
						orgId={selectedOrgId}
						editableUserGroup={location.data ? location.data.editableUserGroup : null}
						history={history}
					/>
				);
			case 'robots':
				return (
					<Robots
						filterValues={tabsData.find(td => td.path === currentTab)?.filter}
						orgId={selectedOrgId}
						editableRobotId={
							location.data?.editableRobot
								? location.data.editableRobot.deviceId
								: null
						}
						history={history}
					/>
				);
			case 'robotGroups':
				return (
					<RobotGroups
						filterValues={tabsData.find(td => td.path === currentTab)?.filter}
						orgId={selectedOrgId}
						editableRobotGroup={location.data ? location.data.editableRobotGroup : null}
						history={history}
					/>
				);
			case 'organization-info':
				return (
					<OrganizationInfo
						filterValues={tabsData.find(td => td.path === currentTab)?.filter}
						orgId={selectedOrgId}
					/>
				);
			default:
				return null;
		}
	}, [currentTab, history, location.data, selectedOrgId, tabsData]);

	const openAddOrganization = async () => {
		props.setParameter('organizations', 'CHANGE_ADD_NEW_ORGANIZATION_STATE', true);
	};
	const openDeleteOrganization = async () => {
		if (selectedOrgId) {
			setDeleteOpen(true);
		}
	};
	const onDeleteConfirm = () => {
		const domainUrl = window.location.hostname;
		publish(`microservice/${selectedOrgId}/${encodedUser}/deleteOrg`, {
			data: { domainUrl },
			requestId: 'deletedOrganization',
		});

		setDeleteOpen(false);
		setSelectedOrgId(userOrganizationId);

		const parentOrg = getParentOrg(selectedOrgId);

		store.dispatch({
			type: 'UPDATE_SELECTEDORGANIZATIONID',
			payload: {
				selectedOrganizationId: parentOrg
					? parentOrg.id
					: organization.organizationStructure &&
					  organization.organizationStructure.length > 0
					? organization.organizationStructure[0].orgId
					: null,
			},
		});
	};
	const openEditOrganization = () => {
		setCurrentTab('organization-info');
	};

	const filter = (inputValue: any, path: any) => {
		return path.some(
			(option: any) =>
				option.label.toLowerCase().indexOf(inputValue ? inputValue.toLowerCase() : '') > -1
		);
	};

	const CascaderOrgOption = organization.organizationStructure.map((option: any) => ({
		...option,
		value: option.id,
		label: option.label,
		children: Object.values(option?.children || {}).map((child: any) => ({
			...child,
			value: child.id,
			label: child.label,
			children: Object.values(child?.children || {}).map((nextChild: any) => ({
				...nextChild,
				value: nextChild.id,
				label: nextChild.label,
				children: Object.values(nextChild?.children || {}).map((endChild: any) => ({
					...endChild,
					value: endChild.id,
					label: endChild.label,
					children: [], // Cascader is looking for children as an array not object. So, last mile should be an empty array.
				})),
			})),
		})),
	}));

	const accountState = useTypedSelector(state => state.accountState);

	return userRoles ? (
		<MainContent
			headerChildren={
				<>
					<Cascader
						size="large"
						options={CascaderOrgOption}
						showSearch={{ filter }}
						changeOnSelect
						expandTrigger="hover"
						allowClear={false}
						defaultValue={[selectedOrgName]}
						value={selectedOrganizationPath}
						onChange={onOrganizationChange}
						displayRender={(label: any) => {
							return label.join('/');
						}}
						data-cy="change_organization_select"
					/>

					<IonFab className={classes.fabBtnContainer}>
						<IonFabButton
							size="small"
							className={classes.deleteFabBtn}
							onClick={openDeleteOrganization}
							hidden={isDeleteHidden}
						>
							<IonIcon className={classes.fabIcon} icon={trash} />
						</IonFabButton>
						<IonFabButton
							size="small"
							className={classes.editFabBtn}
							onClick={openEditOrganization}
						>
							<IonIcon className={classes.fabIcon} src="./assets/icons/edit.svg" />
						</IonFabButton>
						<IonFabButton
							id="openAddOrganizationFab"
							size="small"
							className={classes.addFabBtn}
							onClick={openAddOrganization}
							hidden={isAddHidden}
							data-cy="create_organization_fab"
						>
							<IonIcon className={classes.fabIcon} icon={add} />
						</IonFabButton>
						<IonFabButton
							id="openAddOrganizationFab"
							size="small"
							className={classes.addFabBtn}
							onClick={() => {
								publish(
									`microservice/${b64EncodeUnicode(
										accountState.user.username
									)}/getOwnAccountInfo`,
									JSON.stringify({ requestId: 'someId' })
								);
							}}
							data-cy="create_organization_fab"
						>
							<IonIcon className={classes.fabIcon} icon={refreshSharp} />
						</IonFabButton>
					</IonFab>
				</>
			}
			renderContent={history => {
				return (
					<div className={classes.main}>
						<Tabs
							data={tabsData}
							currentTab={currentTab}
							content={renderTabContent()}
							onClick={onTabClick}
							onChange={onTabChange}
						/>
						<AddOrganizationModal
							isOpen={showAddOrganizationModal}
							onDismiss={() =>
								props.setParameter(
									'organizations',
									'CHANGE_ADD_NEW_ORGANIZATION_STATE',
									false
								)
							}
							orgId={selectedOrgId}
						/>
						<DeleteModal
							isOpen={deleteOpen}
							title={
								<FormattedMessage
									{...Messages.deleteOrgTitle}
									values={{ item: selectedOrgName }}
								/>
							}
							onConfirm={onDeleteConfirm}
							onDismiss={() => setDeleteOpen(false)}
							deletedOrg={selectedOrgName}
							//devices={getDevicesInclusiveChild(selectedOrgId)}
							parentOrg={getParentOrgName(selectedOrgId)}
							//childOrgs={getChildOrgNames(selectedOrgId)}
							//deletingOrgsWithDevices={getDeletingOrgsWithDevices(selectedOrgId)}
							supportEmail="support@gobe-robots.com"
							orgStructureForDeletingOrg={getOrgStructureForDeletingOrg(
								selectedOrgId
							)}
						/>
						<DeleteModalOrgDeviceError
							isOpen={deleteErrorOpen}
							title={
								<FormattedMessage
									{...Messages.deleteErrorTitle}
									values={{ item: 'Organization' }}
								/>
							}
							onConfirm={() => setDeleteErrorOpen(false)}
							onDismiss={() => setDeleteErrorOpen(false)}
							itemName={selectedOrgName + ' Organization Deletion Error'}
						/>
					</div>
				);
			}}
		/>
	) : (
		<Redirect to="/gobe" />
	);
};

const mapStateToProps = (state: any) => ({
	selectedOrganizationId: state.selectedOrganizationState.organization.orgId,
	selectedOrganizationName: state.selectedOrganizationState.organization.name,
	organization: state.organizationState,
	userRoles: state.accountState.user.roles,
});

const enhance = compose(connect(mapStateToProps, { setParameter }));

export default injectIntl(isAuthenticated(enhance(FleetManagement), 'FleetManagement'));
