import { defineMessages } from 'react-intl';

export default defineMessages({
	admin: {
		id: 'admin',
		defaultMessage: 'Admin',
		description: 'Admin user',
	},
	recentActivity: {
		id: 'Users.recentActivity',
		defaultMessage: 'Recent activity',
		description: 'Recent activity',
	},
	invited: {
		id: 'Users.invited',
		defaultMessage: 'Invited',
		description: 'Invited',
	},
	details: {
		id: 'details',
		defaultMessage: 'Details',
		description: 'Details',
	},
	saved: {
		id: 'saved',
		defaultMessage: 'Saved',
		description: 'Saved',
	},
	in: {
		id: 'Users.inGroup',
		defaultMessage: 'In: {group}',
		description: 'Part of user group',
	},
	moreIn: {
		id: 'Users.moreIn',
		defaultMessage: '{count, plural, one {# other} other {# others}}',
		description: 'Number of user group',
	},
	and: {
		id: 'and',
		defaultMessage: 'and',
		description: 'and',
	},
	editPicTitle: {
		id: 'editPicTitle',
		defaultMessage: 'Edit {item} Image',
		description: 'Edit image',
	},
});
