import React from 'react';
import './ReadyForCallStatus.scss';
import Lottie from 'react-lottie-player';
import preSessionLottieAnimationJSON from './pre-session-animation-card.json';
interface Props {
	isCalling: boolean;
}

export const ReadyForCallStatus = ({ isCalling }: Props) => {
	if (isCalling) {
		return (
			<Lottie
				loop
				animationData={preSessionLottieAnimationJSON}
				play
				speed={1.3}
				className="pre-session-animation"
			/>
		);
	}
	return (
		<div className="readyForCallContainer">
			<div className="goBePlayContainer">
				<div className="goBePlayWrapper">
					<img alt="" src="../../assets/images/goBe-play.svg" />
					<div className="greenPlayWrapperShadow" />
					<div className="greenPlayWrapper">
						<img alt="" src="../../assets/images/green-play.svg" />
					</div>
				</div>
			</div>
		</div>
	);
};
