import React, { FC, useEffect, useState } from 'react';
import {
	IonGrid,
	IonRow,
	IonList,
	IonListHeader,
	IonLabel,
	IonText,
	IonItem,
	IonInput,
	IonCheckbox,
} from '@ionic/react';
import { FormattedMessage, injectIntl } from 'react-intl';
import _ from 'lodash';
import isAuthenticated from '../../components/Authentication/Authenticated';
import classes from './UserForm.module.css';
import classNames from 'classnames';
import Messages from './UserForm.messages';
import { User } from '../../types/types';
import { getSessionTimeDescription } from '../../utils/time';
import { useTypedSelector } from '../../reducers';
import moment from 'moment';
import { AutoComplete } from '../AutoComplete/AutoComplete';
import { ShortInputLimit } from '../../utils/validator';
import { publish } from '../../actions/publish';
import { b64EncodeUnicode } from '../../utils/encoding';
interface UserFormProps {
	currentUser: User;
	errors: any;
	setAdmin: any;
	orgId: string;
	setValues: any;
}

const UserFormEditable: FC<UserFormProps> = (props: any) => {
	const { intl, currentUser, setAdmin, orgId, setValues, errors } = props;
	const [changingUser, setChangingUser] = useState(JSON.parse(JSON.stringify(currentUser)));
	const [isAdmin, setIsAdmin] = useState(false);
	const [userAdded] = useState(new Date(changingUser.dateJoined || 1));
	const [lastLogin, setLastLogin] = useState('never');
	const [timeAgo, setTimeAgo] = useState('never');
	const { username } = changingUser;
	const isSelf = useTypedSelector(state => state.accountState.user).username === username;
	const usernameForEncoding = useTypedSelector(state => state.accountState.user.username);
	const [encodedUser] = useState(b64EncodeUnicode(usernameForEncoding));

	const userGroups = useTypedSelector(
		state => state.userGroupsState.userGroupsByOrganizationId[orgId]
	);
	const deviceGroups = useTypedSelector(
		state => state.deviceGroupsState.deviceGroupsByOrganizationId[orgId]
	);
	const spinoutType = useTypedSelector(state => state.versionState.spinoutType) as string;

	useEffect(() => {
		setIsAdmin(currentUser.role && currentUser.role?.match(/admin|Admin/g) ? true : false);
	}, [currentUser.role]);

	useEffect(() => {
		setTimeAgo(
			getSessionTimeDescription(
				currentUser.lastLogin ? new Date(currentUser.lastLogin) : undefined,
				new Date()
			)
		);
		if (currentUser.lastLogin) {
			setLastLogin(moment(currentUser.lastLogin).format('MMMM DD, YYYY, h:mm a'));
		}
	}, [currentUser.lastLogin]);

	const updateLocalUserState = (e: CustomEvent, prop: string) => {
		const change = e.detail.value;
		const userToChange = changingUser;
		userToChange[prop] = change;
		setChangingUser(userToChange);
	};

	const addDeleteGroup = (e: any, prop: string) => {
		if (prop === 'userGroupsIds') {
			const userUserGroup = userGroupOptions(currentUser);

			const removedGroup = _.differenceWith(userUserGroup, e, _.isEqual);
			const removeGroupIds = removedGroup.map((o: any) => {
				return o.value;
			});

			const addGroup = _.differenceWith(e, userUserGroup, _.isEqual);

			const addGroupIds = addGroup.map((o: any) => {
				return o.value;
			});

			if (removeGroupIds && removeGroupIds.length > 0)
				removeGroupIds.forEach(id => {
					publish(`microservice/${orgId}/${encodedUser}/updateUserGroupUsers`, {
						requestId: 'updateUserGroupUsers',
						data: {
							userGroupId: id,
							remove: [username],
						},
					});
				});

			if (addGroupIds && addGroupIds.length > 0)
				addGroupIds.forEach(id => {
					publish(`microservice/${orgId}/${encodedUser}/updateUserGroupUsers`, {
						requestId: 'updateUserGroupUsers',
						data: {
							userGroupId: id,
							add: [username],
						},
					});
				});
		} else if (prop === 'deviceGroupsIds') {
			const userDeviceGroup = deviceGroupOptions(currentUser);

			const removedGroup = _.differenceWith(userDeviceGroup, e, _.isEqual);
			const removeGroupIds = removedGroup.map((o: any) => {
				return o.value;
			});

			const addGroup = _.differenceWith(e, userDeviceGroup, _.isEqual);
			const addGroupIds = addGroup.map((o: any) => {
				return o.value;
			});

			if ((addGroup && addGroup.length > 0) || (removedGroup && removedGroup.length > 0)) {
				publish(`microservice/${orgId}/${encodedUser}/updateUserDeviceGroups`, {
					requestId: 'updateUserDeviceGroups',
					data: {
						userId: username,
						remove: removeGroupIds || [],
						add: addGroupIds || [],
					},
				});
			}
		}
	};

	/**
	 * @param {string} entity - The entity type to get current values for React-Select element
	 * @param {string} labelProperty - The property that contains the name of the entity
	 * @param {string} valueProperty - The property that contains the identifying value of the entity
	 */
	const selectOptions = (entity: string, labelProperty: string, valueProperty: string) => {
		let label;
		let value;
		const options = [];
		let currentEntities: any;
		if (entity === 'userGroup') {
			currentEntities = userGroups;
		}
		if (entity === 'deviceGroup') {
			currentEntities = deviceGroups;
		}

		if (currentEntities === null) currentEntities = [];
		else {
			for (let i in currentEntities) {
				label = currentEntities[i][labelProperty];
				value = currentEntities[i][valueProperty];

				options.push({
					label,
					value,
				});
			}
		}

		return options;
	};

	const deviceGroupOptions = (user: User) => {
		const options: Array<{ label: string; value: string }> = [];

		const keyObj: any = {};
		user.deviceGroupsIds?.forEach(id => {
			if (deviceGroups && deviceGroups[id]) {
				if (keyObj[id] === undefined) {
					keyObj[id] = id;
					options.push({
						label: deviceGroups[id].name,
						value: id,
					});
				}
			}
		});

		return options;
	};

	const userGroupOptions = (user: User) => {
		const options: Array<{ label: string; value: string }> = [];

		const keyObj: any = {};
		user.userGroupsIds?.forEach(id => {
			if (userGroups && userGroups[id]) {
				if (keyObj[id] === undefined) {
					keyObj[id] = id;
					options.push({
						label: userGroups[id].name,
						value: id,
					});
				}
			}
		});
		return options;
	};

	return (
		<form className={classes.editForm} id="editUserForm">
			<IonGrid className={classes.formGrid}>
				<IonRow>
					<IonList>
						<IonListHeader>
							<IonLabel className={classes.labelFont}>
								<FormattedMessage {...Messages.userDetails} />
							</IonLabel>
						</IonListHeader>

						<IonItem>
							<IonLabel position="fixed" className={classes.formLb}>
								<FormattedMessage {...Messages.name} />
							</IonLabel>
							<div className={classes.inputDiv}>
								<IonInput
									className={classNames(classes.editable, classes.firstName)}
									name="firstName"
									type="text"
									placeholder={intl.formatMessage({
										id: 'Users.firstNameHint',
									})}
									defaultValue={changingUser.firstName || ''}
									value={changingUser.firstName || ''}
									onIonChange={e => {
										updateLocalUserState(e, 'firstName');
										setValues(e.detail.value, 'firstName');
									}}
									debounce={400}
									maxlength={ShortInputLimit}
								/>
								{errors?.firstName && (
									<IonText className={classes.errorColor}>
										<FormattedMessage
											id="inviteModal.firstname"
											defaultMessage={errors?.firstName}
										/>
									</IonText>
								)}
							</div>
							<div className={classes.inputDiv}>
								<IonInput
									className={classes.editable}
									name="lastName"
									type="text"
									placeholder={intl.formatMessage({
										id: 'Users.lastNameHint',
									})}
									defaultValue={changingUser.lastName || ''}
									value={changingUser.lastName || ''}
									onIonChange={e => {
										updateLocalUserState(e, 'lastName');
										setValues(setValues(e.detail.value, 'lastName'));
									}}
									debounce={400}
									maxlength={ShortInputLimit}
								/>
								{errors?.lastName && (
									<IonText className={classes.errorColor}>
										<FormattedMessage
											id="inviteModal.lastname"
											defaultMessage={errors?.lastName}
										/>
									</IonText>
								)}
							</div>
						</IonItem>

						<IonItem disabled>
							<IonLabel position="fixed" className={classes.formLb}>
								<FormattedMessage {...Messages.email} />
							</IonLabel>
							<IonLabel className={classes.inputLb}>{username}</IonLabel>
							{/* <IonInput
								// className={isEditable ? classes.editable : ''}
								className=""
								type="email"
								name="email"
								placeholder={intl.formatMessage({
									id: 'Users.emailHint',
								})}
								value={username}
								ref={register({ required: true })}
								readonly
								onIonChange={e => updateLocalUserState(e, 'username')}
							/> */}
						</IonItem>
						<IonItem disabled={isSelf}>
							<IonLabel position="fixed" className={classes.formLb}>
								<FormattedMessage {...Messages.administrator} />
							</IonLabel>
							<IonCheckbox
								hidden={isSelf}
								checked={isAdmin}
								onIonChange={e => {
									setIsAdmin(e.detail.checked);
									setAdmin(e.detail.checked);
								}}
								name="isAdmin"
							/>
							<IonLabel className={classes.chkLb}>
								{isAdmin
									? intl.formatMessage({
											id: 'Users.yes',
									  })
									: intl.formatMessage({
											id: 'Users.no',
									  })}
							</IonLabel>
						</IonItem>
						<IonItem disabled>
							<IonLabel position="fixed" className={classes.formLb}>
								<FormattedMessage {...Messages.dateAdded} />
							</IonLabel>
							<IonLabel className={classes.inputLb}>
								{`${userAdded.getDate()}/${userAdded.getMonth() +
									1}/${userAdded.getFullYear()}`}
							</IonLabel>
						</IonItem>
						{spinoutType !== 'beam' && (
							<div>
								{/* <IonItem disabled>
									<IonLabel position="fixed" className={classes.formLb}>
										<FormattedMessage {...Messages.lastLogin} />:
									</IonLabel>
									<IonLabel className={classes.inputLb}>{lastLogin}</IonLabel>
								</IonItem> */}
								{/* <IonItem disabled>
									<IonLabel position="fixed" className={classes.formLb}>
										<FormattedMessage {...Messages.timeAgo} />:
									</IonLabel>
									<IonLabel className={classes.inputLb}>{timeAgo}</IonLabel>
								</IonItem> */}
								{/* <IonItem disabled>
									<IonLabel position="fixed" className={classes.formLb}>
										<FormattedMessage {...Messages.softwareVersion} />:
									</IonLabel>
									<IonLabel className={classes.inputLb}>1.0.0</IonLabel>
								</IonItem> */}
								{/* <IonItem disabled>
									<IonLabel
										position="fixed"
										className={classNames(classes.subTitleLb, classes.formLb)}
									>
										<FormattedMessage {...Messages.linkedTo} />:
									</IonLabel>
									<IonLabel className={classes.inputLb} />
								</IonItem> */}
								<IonItem>
									<IonLabel position="fixed" className={classes.formLb}>
										<FormattedMessage {...Messages.robotGroup} />:
									</IonLabel>
									<AutoComplete
										options={selectOptions(
											'deviceGroup',
											'name',
											'deviceGroupId'
										)}
										required={false}
										onChange={(e: any) => {
											addDeleteGroup(e, 'deviceGroupsIds');
										}}
										defaultValue={deviceGroupOptions(currentUser)}
										style={{ width: 300 }}
										placeHolder={intl.formatMessage({
											id: 'UsersPage.deviceGroupHint',
										})}
										isMulti
										variant={'outlined'}
										filterSelectedOptions
										disableClearable
									/>
								</IonItem>
								<IonItem>
									<IonLabel position="fixed" className={classes.formLb}>
										<FormattedMessage {...Messages.userGroups} />:
									</IonLabel>
									<AutoComplete
										options={selectOptions('userGroup', 'name', 'userGroupId')}
										required={false}
										onChange={(e: any) => {
											addDeleteGroup(e, 'userGroupsIds');
										}}
										defaultValue={userGroupOptions(currentUser)}
										style={{ width: 300 }}
										placeHolder={intl.formatMessage({
											id: 'UsersPage.userGroupsHint',
										})}
										isMulti
										variant={'outlined'}
										filterSelectedOptions
										disableClearable
									/>
								</IonItem>
							</div>
						)}
					</IonList>
				</IonRow>
			</IonGrid>
		</form>
	);
};

export default injectIntl(isAuthenticated(UserFormEditable, 'UserFormEditable'));
