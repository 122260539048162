import {
	ACCOUNT_CREATE_DETAILS_UPDATED,
	ACCOUNT_CREATE_REQUEST_ID_CREATED,
	ACCOUNT_CREATE_STATUS_UPDATED,
} from '../actions/types';

export enum AccountCreatedStatus {
	WAITING_FOR_USER_INPUT = 'waitingForUserInput',
	PENDING = 'pending',
	SUCCESS = 'success',
	ERROR = 'error',
	FAIL = 'fail',
}

export interface AccountCreateDetails {
	companyName: string;
	firstName: string;
	lastName: string;
	email: string;
	address: string;
	address2?: string;
	zip: string;
	city: string;
	state: string;
	country: string;
}

export interface AccountCreatedState {
	details: AccountCreateDetails;
	requestId: string;
	status: AccountCreatedStatus;
}

const initialState: AccountCreatedState = {
	details: {
		companyName: '',
		firstName: '',
		lastName: '',
		email: '',
		address: '',
		address2: '',
		zip: '',
		city: '',
		state: '',
		country: '',
	},
	requestId: '',
	status: AccountCreatedStatus.WAITING_FOR_USER_INPUT,
};

export default (state = initialState, action: Record<string, any>) => {
	switch (action.type) {
		case ACCOUNT_CREATE_DETAILS_UPDATED:
			return {
				...state,
				details: action.payload.details,
			};
		case ACCOUNT_CREATE_REQUEST_ID_CREATED:
			return {
				...state,
				requestId: action.payload.requestId,
			};
		case ACCOUNT_CREATE_STATUS_UPDATED:
			return {
				...state,
				status: action.payload.status,
			};
		default:
			return state;
	}
};
