import React from 'react';
import './index.scss'


class PropsFromParent {
    isMenuExpanded: boolean;
    isMenuAbsolute: boolean;
    drivingMode: boolean;
    robotName: string
}

const RobotName: React.FC<PropsFromParent> = ({
    isMenuExpanded,
    isMenuAbsolute,
    drivingMode,
    robotName
}) => {

    return (
        <div
            className={
                isMenuAbsolute
                    ? isMenuExpanded
                        ? 'robotNameContainer containerMenuExpanded'
                        : 'robotNameContainer containerMenuCollapsed'
                    : 'robotNameContainer'
            }
        >
            <div className="robotHeadWrapper">
                {drivingMode ? (
                    <img alt="" src="../assets/images/robot-head.svg" />
                ) : (
                    <img alt="" src="../assets/images/black-robot-head.svg" />
                )}
            </div>
            <span className={!drivingMode ? 'black-text' : ''}>{robotName}</span>
        </div>
    )
}

export default RobotName