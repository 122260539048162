import React from 'react';
import MuiSnackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
	colorAndPosition: {
		backgroundColor: '#56AE4D',
		marginTop: '50px',
	},
	ErrorColorAndPosition: {
		backgroundColor: '#B5221B',
		marginTop: '50px',
	},
}));
export const Snackbar = ({ open, onClose, message, snackbarType = 'error' }) => {
	const classes = useStyles();
	return (
		<MuiSnackbar
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			open={open}
			autoHideDuration={6000}
			onClose={onClose}
		>
			<MuiAlert
				elevation={6}
				variant="filled"
				severity={snackbarType}
				onClose={onClose}
				className={
					snackbarType == 'error'
						? classes.ErrorColorAndPosition
						: classes.colorAndPosition
				}
			>
				<span id="message-id"> {message} </span>
			</MuiAlert>
		</MuiSnackbar>
	);
};
