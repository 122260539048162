import { defineMessages } from 'react-intl';

export default defineMessages({
	save: {
		id: 'save',
		defaultMessage: 'Save',
		description: 'Save',
	},
	cancel: {
		id: 'cancel',
		defaultMessage: 'Cancel',
		description: 'Cancel',
	},
	upload: {
		id: 'AccountManagementPage.upload',
		defaultMessage: 'Upload Picture',
		description: 'Upload Picture',
	},
	web: {
		id: 'AccountManagementPage.web',
		defaultMessage: 'From The Web',
		description: 'From The Web',
	},
});
