import { v1 as uuidv1 } from 'uuid';
export const timeUuid = () => {
	const time = new Date().getTime();
	let randomDigit = Math.floor(Math.random() * 10000);
	if (randomDigit === 10000) randomDigit -= 1;
	const v1options = {
		node: [0x01, 0x23, 0x45, 0x67, 0x89, 0xab],
		clockseq: 0x1234,
		msecs: time,
		nsecs: Math.random() * 1000,
	};
	return uuidv1(v1options);
};
