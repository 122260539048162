import {
	UPDATE_SEL_ORG_ORGID,
	UPDATE_SEL_ORG_CONTACTPERSON,
	UPDATE_SEL_ORG_NAME,
	UPDATE_SEL_ORG_LANGUAGE,
	UPDATE_SEL_ORG_SHIPPINGADDRESS,
	UPDATE_SEL_ORG_BILLINGADDRESS,
	UPDATE_SEL_ORG_CREATEDAT,
	UPDATE_SEL_ORG_CHILDORGS,
	UPDATE_SEL_ORG_ORGTYPE,
	UPDATE_SEL_ORG_SAVED_USER_ROLES,
	UPDATE_SEL_ORG_ADD_NEW_CHILD_ORG,
	UPDATE_ORG_ACCEPTED_AGREEMENTS,
	UNLOAD_ORG_ACCEPTED_AGREEMENTS,
	DELETE_ORGANIZATION,
	SET_RELOAD_ORGANIZATION,
} from '../actions/types';
import { Organization } from '../types/types';
import { AcceptedAgreementsPayload } from './accountReducers';

export interface initialSelectedOrganizationState {
	organization: Organization;
	reloadOrganization: boolean;
}

const initialState: initialSelectedOrganizationState = {
	organization: {
		orgId: '',
		contactPerson: null,
		name: '',
		childOrgs: {},
		savedUserRoles: [],
		orgType: '',
		acceptedAgreements: [],
		acceptedAgreementsLoaded: false,
		language: '',
		createdAt: '',
		shippingAddress: null,
		billingAddress: null,
		deviceGroups: [],
		devices: [],
		userGroups: [],
		users: [],
	},
	reloadOrganization: false,
};

export default (state = initialState, action: Record<string, any>) => {
	switch (action.type) {
		case UPDATE_SEL_ORG_ORGID:
			return {
				...state,
				organization: {
					...state.organization,
					orgId: action.payload.orgId,
				},
			};

		case UPDATE_SEL_ORG_CONTACTPERSON:
			return {
				...state,
				organization: {
					...state.organization,
					contactPerson: action.payload.contactPerson,
				},
			};

		case UPDATE_SEL_ORG_NAME:
			return {
				...state,
				organization: {
					...state.organization,
					name: action.payload.name,
				},
			};
		case UPDATE_SEL_ORG_LANGUAGE:
			return {
				...state,
				organization: {
					...state.organization,
					language: action.payload.language,
				},
			};
		case UPDATE_SEL_ORG_SHIPPINGADDRESS:
			return {
				...state,
				organization: {
					...state.organization,
					shippingAddress: action.payload.shippingAddress,
				},
			};
		case UPDATE_SEL_ORG_BILLINGADDRESS:
			return {
				...state,
				organization: {
					...state.organization,
					billingAddress: action.payload.billingAddress,
				},
			};
		case UPDATE_SEL_ORG_CREATEDAT:
			return {
				...state,
				organization: {
					...state.organization,
					createdAt: action.payload.createdAt,
				},
			};
		case UPDATE_SEL_ORG_CHILDORGS:
			return {
				...state,
				organization: {
					...state.organization,
					childOrgs: action.payload.childOrgs,
				},
			};
		case UPDATE_SEL_ORG_ORGTYPE:
			return {
				...state,
				organization: {
					...state.organization,
					orgType: action.payload.orgType,
				},
			};
		case UPDATE_SEL_ORG_SAVED_USER_ROLES:
			let savedUserRoles = [];
			if (action.payload != null && action.payload.length > 0) {
				for (let savedRole of action.payload) {
					// if (savedRole.roleName.search(action.orgType) > -1) {
					let ot = new RegExp(`organization_type.[a-zA-Z*]*`, 'gm');
					let ota = savedRole.enabledFeatures[0].match(ot);
					let role = ota[0].split('.')[1];

					savedUserRoles.push({
						[savedRole.roleName]: role,
					});
					// }
				}
			}

			return {
				...state,
				organization: {
					...state.organization,
					savedUserRoles: savedUserRoles,
				},
			};
		case UPDATE_SEL_ORG_ADD_NEW_CHILD_ORG:
			let childOrgList = state.organization.childOrgs;
			if (childOrgList != null) {
				childOrgList[action.payload.newOrganization.orgId] = action.payload.newOrganization;
			} else {
				childOrgList = {
					[action.payload.newOrganization.orgId]: action.payload.newOrganization,
				};
			}

			return {
				...state,
				organization: {
					...state.organization,
					childOrgs: childOrgList,
				},
			};
		case UPDATE_ORG_ACCEPTED_AGREEMENTS:
			const acceptedAgreements: AcceptedAgreementsPayload[] = action.payload;
			if (!acceptedAgreements || acceptedAgreements.length === 0) return state;

			for (let i = 0; i < acceptedAgreements.length; i++) {
				acceptedAgreements[i].content = acceptedAgreements[i].content?.replace(
					/#semicolon#/g,
					';'
				);
			}

			return {
				...state,
				organization: {
					...state.organization,
					acceptedAgreements: acceptedAgreements,
					acceptedAgreementsLoaded: true,
				},
			};
		case UNLOAD_ORG_ACCEPTED_AGREEMENTS:
			return {
				...state,
				organization: {
					...state.organization,
					acceptedAgreements: [],
					acceptedAgreementsLoaded: false,
				},
			};
		case DELETE_ORGANIZATION:
			return {
				...state,
				reloadOrganization: true,
			};
		case SET_RELOAD_ORGANIZATION:
			return {
				...state,
				reloadOrganization: action.payload.reload,
			};
		default:
			return state;
	}
};
