/* eslint-disable camelcase */

import { IClientOptions, IClientPublishOptions, IClientSubscribeOptions, MqttClient } from 'mqtt';
import { useCallback, useEffect, useState } from 'react';
import { Reservation } from '../../../utils/statusConverter';
import SessionMqttFactory from './mqttFactory';
import { generateUUID, SHA256Hash } from './utils';

export class SessionInitiationError extends Error {
	constructor(public message: AckResponse | 'INVALID_RESPONSE' | 'TIMEOUT') {
		super(message);
	}
}

/**
 * Removes characters that are not compliant with mqtt topics rules - plus(+), hash (#) and slash (/).
 * The resulting string will then be safe to use in an mqtt topic, without it changing the topic structure
 *
 * @param {string} id
 */
const getMqttTopicSafeId = (id: string) => {
	return id
		.replace('+', '.')
		.replace('#', '.')
		.replace('/', '.');
};

type AcknowledgementPayload = {
	response: 'OK' | 'NOT_ALLOWED_BUSY' | 'NOT_ALLOWED_ERROR' | 'NOT_ALLOWED_NOT_READY';
	iceServers: RTCIceServer;
	/** @deprecated Use `AckPayload.capabilities.super_zoom_1` flag instead */
	GoBeSuperZoom1Enabled?: boolean;
	capabilities?: {
		super_zoom_1?: boolean;
		keepalive_over_mqtt?: boolean;
		// TODO: NEW-TAB-PROJECT
		// 'keepalive_over_mqtt' is no longer required
		// NB: This is only true in the new project
		mouse_control_with_joystick?: boolean;
		mouse_control_with_slider?: boolean;
		signaling_via_socket_io?: boolean;
	} & Record<string, unknown>;
	signaling?: {
		token: string;
		url: string;
	};
	protocol?: string;
	// TODO: NEW-TAB-PROJECT
	// 'signaling' property will always be non-null.
	// NB: This is true only in the new project
};
export type SessionInfo = Omit<AcknowledgementPayload, 'response' | 'GoBeSuperZoom1Enabled'> & {
	uuid: string;
	robot: { id: string; name: string };
	pilot: { id: string; name: string; avatar?: string };
	/** @deprecated Prefer signaling over SocketIO instead */
	mqttConfig: IClientOptions;
	// TODO: NEW-TAB-PROJECT
	// 'mqttConfig' property is no longer required
};
type AckResponse = AcknowledgementPayload['response'];

async function mqttRequestReply(
	mqttClient: MqttClient,
	request: { topic: string; message: unknown; options: IClientPublishOptions },
	reply: { topic: string; options: IClientSubscribeOptions }
) {
	// execute subscribe(), and wait for the subscription to complete
	const subscriptionPromise = new Promise<void>((resolve, reject) => {
		if (reply.topic.includes('+') || reply.topic.includes('#')) {
			reject(new Error('Subscribing to wildcard topics is not supported'));
		} else {
			let isPromiseCompleted = false;

			mqttClient.subscribe(reply.topic, reply.options ?? { qos: 0 }, err => {
				if (isPromiseCompleted) {
					// mqttClient.unsubscribe(reply.topic);
					return;
				}
				isPromiseCompleted = true;

				clearTimeout(subscriptionTimeoutId);
				if (err) {
					console.error('Error subscribing to topic', reply.topic, err);
					reject(err);
				} else {
					resolve();
				}
			});

			const subscriptionTimeoutId = setTimeout(() => {
				reject(new Error(`Timed out while subscribing to topic: ${reply.topic}`));
				isPromiseCompleted = true;
			}, 3 * 1000);
		}
	});
	await subscriptionPromise;

	/** A promise that will be resolved when a reply is received on the expected reply topic
	The promise will be rejected on timeout */
	const messagePromise = new Promise<string>((resolve, reject) => {
		const onMessage = (topic: string, message: string) => {
			if (topic === reply.topic) {
				cleanup();
				resolve(message);
			}
		};

		const timeout = setTimeout(() => {
			cleanup();
			reject(new Error('Timed out waiting for mqtt reply'));
		}, 60 * 1000);

		const cleanup = () => {
			mqttClient.removeListener('message', onMessage);
			clearTimeout(timeout);
			mqttClient.unsubscribe(reply.topic);
		};

		mqttClient.on('message', onMessage);
	});

	mqttClient.publish(request.topic, JSON.stringify(request.message), request.options, err => {
		if (err) console.error('Error publishing to topic', request.topic, err);
	});
	return messagePromise;
}

type _PreSessionState = {
	robotId: string | null;
	loading: boolean;
	error: string | null;
};

type PreSessionState = Omit<_PreSessionState, 'robotId'> & { robotId: string };

type Args = {
	mqttConfig: Partial<IClientOptions>;
	credentials: {
		username: string;
		password: string;
	};
	pilot: {
		avatar?: string;
		name: string;
		id: string;
	};
	settings?: {
		initialVolume?: number;
	};
};
/**
 * React hook that returns a function for initiating sessions with robots.
 *
 * Ideally, this should just be a regular async function instead of a react hook.
 * But, we want to cache a pre-connected instance of MqttClient
 * 	(which is dependent on the credentials of the currently logged in user - as props),
 * so that we can save some time.
 * Without caching, we would have to wait for the mqtt client connection to be established before we can send a request.
 */
export default function useSessionInitiator({ mqttConfig, credentials, pilot, settings }: Args) {
	const [preSessionState, setPreSessionState] = useState<_PreSessionState>({
		robotId: null,
		loading: false,
		error: null,
	});

	const clearPreSessionState = useCallback(
		() => setPreSessionState({ robotId: null, loading: false, error: null }),
		[]
	);

	// clear preSessionState error, after some time
	useEffect(() => {
		if (preSessionState.error === null) return;

		const timeout = setTimeout(clearPreSessionState, 5 * 1000);

		return () => clearTimeout(timeout);
	}, [preSessionState, clearPreSessionState]);

	const initiateSession = useCallback(
		(
			robot: {
				serialNumber: string;
				name: string;
			},
			reservation?: {
				isMyPermanentDevice: boolean;
				currentReservation?: Reservation;
				othersNextReservation?: Reservation;
			}
		): Promise<{ sessionInfo: SessionInfo; abortSession: () => void }> => {
			const __initiateSession = async () => {
				const uuid = generateUUID();

				const consolidatedMqttConfig: IClientOptions = {
					...mqttConfig,
					path: `/${mqttConfig.path}`,
					...credentials,
				};
				const mqttClient = await SessionMqttFactory.getMqttClient(consolidatedMqttConfig);

				const safePilotInfo = { ...pilot, id: getMqttTopicSafeId(pilot.id) };
				const helloPayload = {
					...safePilotInfo,
					id: getMqttTopicSafeId(pilot.id),
					...settings,
					uuid,
					...(reservation?.currentReservation || reservation?.othersNextReservation
						? {
								reservation,
						  }
						: {}),
				};

				console.log('SessionInitiator: Sending hello');
				const acknowledgementPayload = await mqttRequestReply(
					mqttClient,
					{
						topic: `hello/${robot.serialNumber}`,
						options: { qos: 2 },
						message: helloPayload,
					},
					{
						topic: `ack/${robot.serialNumber}/${safePilotInfo.id}/${uuid}`,
						options: { qos: 0 },
					}
				).then(ackMessage => {
					console.log('SessionInitiator: Got Acknowledgement');

					const ackPayload: AcknowledgementPayload = JSON.parse(ackMessage);
					if (ackPayload.response === 'OK')
						return {
							...ackPayload,
							capabilities: {
								...(ackPayload.capabilities || {}),
								super_zoom_1:
									ackPayload.capabilities?.super_zoom_1 ||
									ackPayload.GoBeSuperZoom1Enabled,
							},
							pilotId: SHA256Hash(pilot.id),
						};
					else if (ackPayload.response) {
						throw new SessionInitiationError(ackPayload.response);
					} else {
						throw new SessionInitiationError('INVALID_RESPONSE');
					}
				});

				const sessionInfo: SessionInfo = {
					...acknowledgementPayload,
					uuid,
					robot: { ...robot, id: robot.serialNumber },
					pilot: safePilotInfo,
					mqttConfig: consolidatedMqttConfig,
				};

				const abortSession = () => {
					console.debug('Sending explicit hangup to robot, via MQTT');
					mqttClient.publish(
						`session/${safePilotInfo.id}/${robot.serialNumber}/${uuid}/hangup`,
						''
					);
				};

				return { sessionInfo, abortSession };
			};
			console.log('INITIATING SESSION', robot);
			setPreSessionState({ robotId: robot.serialNumber, loading: true, error: null });
			return __initiateSession()
				.then(sessionInfo => {
					clearPreSessionState();
					return sessionInfo;
				})
				.catch(error => {
					setPreSessionState({ robotId: robot.serialNumber, loading: false, error });
					throw error;
				});
		},
		[pilot, settings, clearPreSessionState, mqttConfig, credentials]
	);

	return {
		initiateSession,
		preSessionState:
			preSessionState.robotId === null ? null : (preSessionState as PreSessionState),
	};
}
