import { injectIntl } from 'react-intl';
import React, { FC, useEffect, useState } from 'react';
import isAuthenticated from '../Authentication/Authenticated';
import { IonList } from '@ionic/react';
import FormInputListItem from '../FormInputListItem/FormInputListItem';

import classes from './AddNewRobot.module.css';
import classNames from 'classnames';
import { useTypedSelector } from '../../reducers';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';

import { eyeSharp, eyeOffSharp } from 'ionicons/icons';
import { RobotPasswordRequirements } from '../PasswordRequirements/PasswordRequirements';
import { InputLimit } from '../../utils/validator';

interface AddNewRobotFromProps {
	control: any;
	errors: any;
}

const AddNewRobotForm: FC<AddNewRobotFromProps> = (props: any) => {
	const { intl, control, watch, errors } = props;
	const spinoutType = useTypedSelector(state => state.versionState.spinoutType);
	const [passwordType, setPasswordType] = useState(true);
	const password = watch('robotPassword');
	const displayPassword = () => {
		setPasswordType(prev => !prev);
	};

	return (
		<IonList className={classNames(classes.formList)}>
			<FormInputListItem
				type="text"
				name="name"
				label={intl.formatMessage({
					id: 'RobotsPage.name',
				})}
				placeholderText={intl.formatMessage({
					id: 'RobotsPage.nameHint',
				})}
				control={control}
				rules={{ required: true }}
				required
				errorMsg={
					(errors.name && intl.formatMessage({ id: 'RobotsPage.nameError' })) ||
					(errors.nameUnique && intl.formatMessage({ id: 'RobotsPage.nameNotUnique' }))
				}
				maxLength={InputLimit}
				dataCy="name"
			/>
			{spinoutType !== 'uvd' ? (
				<>
					<FormInputListItem
						type="text"
						name="saId"
						label={intl.formatMessage({
							id: 'RobotsPage.serviceAccessId',
						})}
						placeholderText={intl.formatMessage({
							id: 'RobotsPage.serviceAccessIdHint',
						})}
						control={control}
						rules={{ required: true }}
						required
						errorMsg={
							errors.saId &&
							intl.formatMessage({
								id: 'RobotsPage.serviceAccessIdError',
							})
						}
						pattern="[0-9a-zA-Z_.-]*"
						maxLength={InputLimit}
						dataCy="saId"
					/>
					<FormInputListItem
						type="text"
						name="authId"
						label={intl.formatMessage({
							id: 'RobotsPage.authenticationId',
						})}
						placeholderText={intl.formatMessage({
							id: 'RobotsPage.authenticationIdHint',
						})}
						control={control}
						rules={{ required: true }}
						required
						errorMsg={
							errors.authId &&
							intl.formatMessage({
								id: 'RobotsPage.authenticationIdError',
							})
						}
						pattern="[0-9a-zA-Z_.-]*"
						maxLength={InputLimit}
						dataCy="authId"
					/>
				</>
			) : null}
			<FormInputListItem
				type="text"
				name="serialNumber"
				label={intl.formatMessage({
					id: 'RobotsPage.serialNumber',
				})}
				placeholderText={intl.formatMessage({
					id: 'RobotsPage.serialNumberHint',
				})}
				control={control}
				rules={{ required: true }}
				required
				errorMsg={
					errors.serialNumber
						? intl.formatMessage({ id: 'RobotsPage.serialNumberError' })
						: errors.serialNumberUnique
						? intl.formatMessage({ id: 'RobotsPage.serialNumberUnique' })
						: ''
				}
				pattern="[0-9a-zA-Z]*"
				title={intl.formatMessage({
					id: 'RobotsPage.onlyLettersCharacters',
				})}
				maxLength={InputLimit}
				dataCy="serialNumber"
			/>

			{spinoutType !== 'uvd' ? (
				<>
					<div>
						{password && (
							<RobotPasswordRequirements
								customContainer={classes.passwordTooltip}
								checkMarkContainer={classes.checkMarkContainer}
								password={password}
							/>
						)}
						<FormInputListItem
							type={passwordType ? 'password' : 'text'}
							name="robotPassword"
							label={intl.formatMessage({
								id: 'RobotsPage.password',
							})}
							placeholderText={intl.formatMessage({
								id: 'RobotsPage.passwordHint',
							})}
							control={control}
							rules={{ required: true }}
							required
							autocomplete="new-password"
							errorMsg={
								errors.robotPassword &&
								intl.formatMessage({
									id: 'RobotsPage.passwordError',
								})
							}
							iconUrl={passwordType ? eyeOffSharp : eyeSharp}
							iconCallback={displayPassword}
							maxLength={InputLimit}
							dataCy="password"
						/>
					</div>
				</>
			) : null}

			<FormInputListItem
				type="text"
				name="location"
				label={intl.formatMessage({
					id: 'RobotsPage.location',
				})}
				placeholderText={intl.formatMessage({
					id: 'RobotsPage.locationHint',
				})}
				control={control}
				pattern="[0-9a-zA-Z]*"
				title={intl.formatMessage({
					id: 'RobotsPage.onlyLettersCharacters',
				})}
				maxLength={InputLimit}
				dataCy="location"
			/>
		</IonList>
	);
};

const mapStateToProps = (state: any) => ({
	client: state.mqttState.client,
	devices: state.deviceState,
});

const enhance = compose(connect(mapStateToProps, { setParameter }));

export default injectIntl(isAuthenticated(enhance(AddNewRobotForm), 'AddNewRobotForm'));
