export const SET_DATA_CHANNEL = 'SET_DATA_CHANNEL';
export const SET_BATTERY_STATUS = 'SET_BATTERY_STATUS';

export const SET_MORE_OPTIONS_STATUS = 'SET_MORE_OPTIONS_STATUS';
export const SET_FULL_SCREEN_STATUS = 'SET_FULL_SCREEN_STATUS';
export const SET_HIDE_NAV_OPTIONS_STATUS = 'SET_HIDE_NAV_OPTIONS_STATUS';
export const SET_DRIVING_MODE_STATUS = 'SET_DRIVING_MODE_STATUS';
export const SET_NAV_CAMERA_HEIGHT = 'SET_NAV_CAMERA_HEIGHT';

export const SET_LOCAL_VOICE_VOLUME = 'SET_LOCAL_VOICE_VOLUME';
export const SET_REMOTE_VOICE_VOLUME = 'SET_REMOTE_VOICE_VOLUME';
export const SET_NAV_SPEED = 'SET_NAV_SPEED';

export const SET_SESSION_INFO = 'SET_SESSION_INFO';

export const SET_ROASTER_LARGE_ICONS = 'SET_ROASTER_LARGE_ICONS';
export const SET_SORT_METHOD = 'SET_SORT_METHOD';
export const SET_SEARCH_TERM = 'SET_SEARCH_TERM';
export const TOGGLE_SORT_DIRECTION = 'TOGGLE_SORT_DIRECTION';
