import React, { FC, useState, useEffect, useCallback } from 'react';
import {
	IonGrid,
	IonRow,
	IonInput,
	IonList,
	IonItem,
	IonLabel,
	IonCol,
	IonText,
} from '@ionic/react';
import { countries } from 'countries-list';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';
import { publish } from '../../actions/publish';
import isAuthenticated from '../../components/Authentication/Authenticated';
import { injectIntl, FormattedMessage } from 'react-intl';
import Messages from './OrganizationForm.messages';
import { useFormik } from 'formik';
import classes from './OrganizationForm.module.css';
import { useTypedSelector } from '../../reducers';
import { b64EncodeUnicode } from '../../utils/encoding';
import { InputLimit } from '../../utils/validator';
import CountrySelectFormik from '../CustomFormComponents/CountrySelect/CountrySelectFormik';

interface OrganizationFormProps {
	organization: any;
	isEditable?: boolean;
	deviceSave?: boolean;
	onSubmit: (data: any) => void;
	saved: boolean;
	segment: string;
}

const OrganizationForm: FC<OrganizationFormProps> = (props: any) => {
	const { isEditable, organization, intl, saved, segment, deviceSave } = props;
	const username = useTypedSelector(state => state.accountState.user.username);
	const encodedUser = b64EncodeUnicode(username);
	const getCountryName = (countryCode: keyof Object): string => {
		if (countries[countryCode] !== undefined) {
			return countries[countryCode].name;
		} else {
			return countries['DK'].name;
		}
	};
	const parseOrganization = useCallback((organization: any) => {
		let org = JSON.parse(JSON.stringify(organization));
		if (!org) return null;
		org = {
			orgId: org ? org.orgId : '',
			name: org ? org.name : '',
			state: org?.billingAddress?.state || '',
			country: org?.billingAddress?.country || '',
			zip: org?.billingAddress?.zip || '',
			city: org?.billingAddress?.city || '',
			addressLine1: org?.billingAddress?.addressLine1 || '',
			addressLine2: org?.billingAddress?.addressLine2 || '',
			createdAt: org && org.createdAt ? new Date(org.createdAt) : new Date('01/01/1970'),
			language: org && org.language ? org.language : 'English',
		};

		return org;
	}, []);

	const [changingOrganization, setChangingOrganization] = useState(
		parseOrganization(organization)
	);
	let org = JSON.parse(JSON.stringify(organization));
	const formik = useFormik({
		initialValues: {
			orgId: org ? org.orgId : '',
			name: org ? org.name : '',
			state: org?.billingAddress?.state || '',
			country: org?.billingAddress?.country || 'DK',
			zip: org?.billingAddress?.zip || '',
			city: org?.billingAddress?.city || '',
			addressLine1: org?.billingAddress?.addressLine1 || '',
			addressLine2: org?.billingAddress?.addressLine2 || '',
			createdAt: org && org.createdAt ? new Date(org.createdAt) : new Date('01/01/1970'),
			language: org && org.language ? org.language : 'English',
		},
		validate: values => {
			let errors: any = {};
			if (!values.name && values.name == '') {
				errors.name = 'name is required';
			}
			if (!values.addressLine1 && values.addressLine1 == '') {
				errors.addressLine1 = 'address Line1 is required';
			}
			if (!values.zip && values.zip == '') {
				errors.zip = 'zip is required';
			}
			if (!values.city && values.city == '') {
				errors.city = 'city is required';
			}
			if (!values.country && values.country == '') {
				errors.country = 'country is required';
			}
			return errors;
		},
		onSubmit: (values: any) => {
			onEditSubmit(values);
		},
	});

	// when click on save if error
	useEffect(() => {
		if (deviceSave) {
			props.setDeviceSave(false);
		}
	}, [formik.values]);

	// on save button click from parent
	useEffect(() => {
		if (deviceSave) {
			formik.handleSubmit();
		}
	}, [deviceSave]);

	useEffect(() => {
		if (!isEditable) {
			setChangingOrganization(parseOrganization(organization));
			formik.resetForm();
		}
	}, [isEditable, organization, parseOrganization]);

	const updateLocalOrgState = (e: CustomEvent, prop: any) => {
		const change = e.detail.value;
		let orgToChange = Object.assign(changingOrganization);

		if (prop.includes('.')) {
			prop = prop.split('.');
			if (!orgToChange[prop[0]] && prop[1]) {
				orgToChange[prop[0]] = {};
			}
			orgToChange[prop[0]][prop[1]] = change;
		} else orgToChange[prop] = change;
		setChangingOrganization(orgToChange);
	};

	const onEditSubmit = (values: any) => {
		publish(`microservice/${organization.orgId}/${encodedUser}/updateOrgBasicInfo`, {
			data: {
				orgId: organization.orgId,
				name: values.name,
				billingAddress: {
					state: values.state,
					country: values.country,
					zip: values.zip,
					city: values.city,
					addressLine1: values.addressLine1,
					addressLine2: values.addressLine2,
				},
			},
			requestId: 'updateOrgBasicInfoId',
		});
		props.isSubmitForm(true);
	};

	return (
		<form onSubmit={formik.handleSubmit} className={classes.editForm}>
			<IonGrid className={classes.formGrid}>
				<IonRow className={classes.detailsHeader}>
					<IonLabel className={classes.labelFont}>
						<FormattedMessage {...Messages.details} />
					</IonLabel>
				</IonRow>
				<IonRow>
					<IonCol className={classes.detailsCol}>
						<div className={classes.subTitle}>
							<IonLabel position="fixed" className={classes.subTitleLb}>
								<FormattedMessage {...Messages.orgDetails} />:
							</IonLabel>
						</div>
						<IonList>
							<IonItem disabled={!isEditable}>
								<IonLabel position="fixed" className={classes.formLb}>
									<FormattedMessage {...Messages.companyName} />
								</IonLabel>

								<IonLabel
									className={!isEditable ? classes.inputLb : classes.hidden}
								>
									{changingOrganization.name}
								</IonLabel>

								<IonInput
									className={isEditable ? classes.editable : classes.hidden}
									name="name"
									placeholder={intl.formatMessage({
										id: 'Organizations.companyNameHint',
									})}
									value={formik.values.name}
									// ref={register({ required: true })}
									readonly={!isEditable}
									onIonChange={e => formik.handleChange(e)}
									maxlength={InputLimit}
								/>
								{formik?.errors && formik?.errors?.name && (
									<IonText color="danger" className="ion-padding-start">
										<FormattedMessage
											id="org.name"
											defaultMessage={`${formik?.errors?.name}`}
										/>
									</IonText>
								)}
							</IonItem>
							<IonItem disabled={!isEditable}>
								<IonLabel position="fixed" className={classes.formLb}>
									<FormattedMessage {...Messages.createdOn} />
								</IonLabel>
								<IonLabel className={classes.createdOnLb}>
									{`${changingOrganization.createdAt.getDate()}/${changingOrganization.createdAt.getMonth() +
										1}/${changingOrganization.createdAt.getFullYear()}`}
								</IonLabel>
							</IonItem>
							<IonItem disabled={!isEditable}>
								<IonLabel position="fixed" className={classes.formLb}>
									<FormattedMessage {...Messages.language} />
								</IonLabel>

								<IonLabel className={classes.inputLb}>
									{changingOrganization.language}
								</IonLabel>
							</IonItem>
							<IonItem disabled={!isEditable}>
								<IonLabel position="fixed" className={classes.formLb}>
									<FormattedMessage {...Messages.addressLine1} />
								</IonLabel>
								<IonLabel
									className={!isEditable ? classes.inputLb : classes.hidden}
								>
									{changingOrganization && changingOrganization.addressLine1
										? changingOrganization.addressLine1
										: ''}
								</IonLabel>

								<IonInput
									className={isEditable ? classes.editable : classes.hidden}
									name="addressLine1"
									placeholder={intl.formatMessage({
										id: 'Address.streetHint',
									})}
									value={formik.values.addressLine1}
									readonly={!isEditable}
									maxlength={InputLimit}
									onIonChange={e => formik.handleChange(e)}
								/>
								{formik?.errors && formik?.errors?.addressLine1 && (
									<IonText color="danger" className="ion-padding-start">
										<FormattedMessage
											id="org.addressLine1"
											defaultMessage={`${formik?.errors?.addressLine1}`}
										/>
									</IonText>
								)}
							</IonItem>
							<IonItem disabled={!isEditable}>
								<IonLabel position="fixed" className={classes.formLb}>
									<FormattedMessage {...Messages.addressLine2} />
								</IonLabel>
								<IonLabel
									className={!isEditable ? classes.inputLb : classes.hidden}
								>
									{changingOrganization && changingOrganization.addressLine2
										? changingOrganization.addressLine2
										: ''}
								</IonLabel>

								<IonInput
									className={isEditable ? classes.editable : classes.hidden}
									name="addressLine2"
									placeholder={intl.formatMessage({
										id: 'Address.streetHint',
									})}
									value={formik.values.addressLine2}
									readonly={!isEditable}
									onIonChange={e => formik.handleChange(e)}
									maxlength={InputLimit}
								/>
								{formik?.errors && formik?.errors?.addressLine2 && (
									<IonText color="danger" className="ion-padding-start">
										<FormattedMessage
											id="org.addressLine2"
											defaultMessage={`${formik?.errors?.addressLine2}`}
										/>
									</IonText>
								)}
							</IonItem>
							<IonItem disabled={!isEditable}>
								<IonLabel position="fixed" className={classes.formLb}>
									<FormattedMessage {...Messages.zip} />
								</IonLabel>
								<IonLabel
									className={!isEditable ? classes.inputLb : classes.hidden}
								>
									{changingOrganization && changingOrganization.zip
										? changingOrganization.zip
										: ''}
								</IonLabel>

								<IonInput
									className={isEditable ? classes.editable : classes.hidden}
									name="zip"
									placeholder={intl.formatMessage({
										id: 'Address.zipHint',
									})}
									value={formik.values?.zip}
									readonly={!isEditable}
									maxlength={InputLimit}
									onIonChange={e => formik.handleChange(e)}
								/>
								{formik?.errors && formik?.errors?.zip && (
									<IonText color="danger" className="ion-padding-start">
										<FormattedMessage
											id="org.zip"
											defaultMessage={`${formik?.errors?.zip}`}
										/>
									</IonText>
								)}
							</IonItem>
							<IonItem disabled={!isEditable}>
								<IonLabel position="fixed" className={classes.formLb}>
									<FormattedMessage {...Messages.city} />
								</IonLabel>
								<IonLabel
									className={!isEditable ? classes.inputLb : classes.hidden}
								>
									{changingOrganization && changingOrganization.city
										? changingOrganization.city
										: ''}
								</IonLabel>

								<IonInput
									className={isEditable ? classes.editable : classes.hidden}
									name="city"
									placeholder={intl.formatMessage({
										id: 'Address.cityHint',
									})}
									value={formik.values?.city}
									readonly={!isEditable}
									maxlength={InputLimit}
									onIonChange={e => formik.handleChange(e)}
								/>
								{formik?.errors && formik?.errors?.city && (
									<IonText color="danger" className="ion-padding-start">
										<FormattedMessage
											id="org.city"
											defaultMessage={`${formik?.errors?.city}`}
										/>
									</IonText>
								)}
							</IonItem>
							<IonItem disabled={!isEditable}>
								<IonLabel position="fixed" className={classes.formLb}>
									<FormattedMessage {...Messages.state} />
								</IonLabel>
								<IonLabel
									className={!isEditable ? classes.inputLb : classes.hidden}
								>
									{changingOrganization && changingOrganization.state
										? changingOrganization.state
										: ''}
								</IonLabel>

								<IonInput
									className={isEditable ? classes.editable : classes.hidden}
									name="state"
									placeholder={intl.formatMessage({
										id: 'Address.stateHint',
									})}
									value={formik.values?.state}
									readonly={!isEditable}
									onIonChange={e => formik.handleChange(e)}
									maxlength={InputLimit}
								/>
							</IonItem>
							<div className={classes.countrySelectItem}>
								<IonLabel position="fixed" className={classes.formLb}>
									<FormattedMessage {...Messages.country} />
								</IonLabel>
								<IonLabel
									// Hide country editing until stabilized
									// className={!isEditable ? classes.inputLb : classes.hidden}
									className={!isEditable ? classes.inputLb : classes.hidden}
								>
									{getCountryName(
										changingOrganization && changingOrganization.country
											? changingOrganization.country
											: 'DK'
									)}
								</IonLabel>
								{/* Hide country editing until stabilized */}
								<div className={isEditable ? '' : classes.hidden}>
									<CountrySelectFormik
										initializedValue={formik.values.country}
										setSelectedValue={(e: any) => {
											formik.setFieldValue('country', e);
										}}
										name="country"
										errors={formik.errors}
										menuPlacement="top"
										dataCy="country"
										standardInput
									/>
								</div>
							</div>
						</IonList>
					</IonCol>
				</IonRow>
			</IonGrid>
		</form>
	);
};
const mapStateToProps = (state: any) => ({
	client: state.mqttState.client,
	selectedOrganization: state.selectedOrganizationState.organization,
	organizations: state.organizationState.organizations,
});

const enhance = compose(connect(mapStateToProps, { setParameter }));

export default injectIntl(isAuthenticated(enhance(OrganizationForm), 'OrganizationForm'));
