import {
	SET_ROASTER_LARGE_ICONS,
	SET_SORT_METHOD,
	SET_SEARCH_TERM,
	TOGGLE_SORT_DIRECTION,
} from '../actions/types';

export type SortMethod = 'name' | 'deviceGroup' | 'availability' | 'lastUsage' | 'isFavourite';

type RoasterStateModel = {
	isGridView: boolean;
	searchTerm: string;
	isSortedAscending: boolean;
	agreementsStatus: boolean;
	goBesArray: [];
	sortMethod: SortMethod;
};

type RoasterActionsModel = {
	type: string;
	payload: RoasterStateModel;
};

// eslint-disable-next-line camelcase
const Initial_State: Partial<RoasterStateModel> = {
	isGridView: true,
	searchTerm: '',
	isSortedAscending: true,
	sortMethod: 'availability',
};

export default (state = Initial_State, action: RoasterActionsModel) => {
	switch (action.type) {
		case SET_ROASTER_LARGE_ICONS:
			return { ...state, isGridView: action.payload.isGridView };
		case SET_SORT_METHOD:
			return { ...state, sortMethod: action.payload.sortMethod };
		case SET_SEARCH_TERM:
			return { ...state, searchTerm: action.payload.searchTerm };
		case TOGGLE_SORT_DIRECTION:
			return { ...state, isSortedAscending: !state.isSortedAscending };
		default:
			return state;
	}
};
