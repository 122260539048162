import React from 'react';

export const EmptyIcon = () => {
	return (
		<svg
			width="49"
			height="52"
			viewBox="0 0 49 52"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M48.0959 26.0631C48.0959 39.4147 37.428 50.2309 24.2777 50.2309C11.1274 50.2309 0.459496 39.4147 0.459496 26.0631C0.459496 12.7114 11.1274 1.89529 24.2777 1.89529C37.428 1.89529 48.0959 12.7114 48.0959 26.0631Z"
				stroke="#242525"
				strokeWidth="0.608445"
			/>
			<path
				d="M24.2777 49.4863C37.0209 49.4863 47.3513 38.9994 47.3513 26.0631C47.3513 13.1268 37.0209 2.63989 24.2777 2.63989C11.5345 2.63989 1.2041 13.1268 1.2041 26.0631C1.2041 38.9994 11.5345 49.4863 24.2777 49.4863Z"
				fill="#EEEEEE"
				stroke="#242525"
				strokeWidth="1.82533"
			/>
			<path
				d="M24.2775 47.3575C35.8707 47.3575 45.2688 37.8231 45.2688 26.0619C45.2688 14.3007 35.8707 4.76636 24.2775 4.76636C12.6843 4.76636 3.28613 14.3007 3.28613 26.0619C3.28613 37.8231 12.6843 47.3575 24.2775 47.3575Z"
				fill="#EEEEEE"
			/>
			<path
				d="M24.2779 46.4448C35.367 46.4448 44.3566 37.319 44.3566 26.0619C44.3566 14.8047 35.367 5.67896 24.2779 5.67896C13.1888 5.67896 4.19922 14.8047 4.19922 26.0619C4.19922 37.319 13.1888 46.4448 24.2779 46.4448Z"
				fill="#EEEEEE"
				stroke="#58B255"
				strokeWidth="1.82533"
			/>
			<rect
				x="28.9551"
				y="23.8831"
				width="1.82533"
				height="4.86756"
				transform="rotate(-160 28.9551 23.8831)"
				fill="#242525"
			/>
			<rect
				x="14.9609"
				y="21.7683"
				width="1.82533"
				height="4.86756"
				transform="rotate(-160 14.9609 21.7683)"
				fill="#242525"
			/>
			<rect
				width="1.82533"
				height="7.30134"
				transform="matrix(-0.34202 0.939693 0.939693 0.34202 17.3994 32.6184)"
				fill="#242525"
			/>
			<circle cx="30.1471" cy="20.2118" r="7.60556" stroke="#242525" strokeWidth="1.82533" />
			<rect
				x="37.9658"
				y="25.8042"
				width="1.19883"
				height="4.86756"
				transform="rotate(-160 37.9658 25.8042)"
				fill="#242525"
			/>
			<rect
				x="38.0566"
				y="24.1833"
				width="1.82533"
				height="10.1972"
				transform="rotate(-57.4051 38.0566 24.1833)"
				fill="#242525"
			/>
		</svg>
	);
};
