/* eslint-disable no-unreachable */
import React, { FC, useState, useCallback, useEffect } from 'react';
import { IonLabel, IonList, IonItem, IonIcon } from '@ionic/react';
import { FormattedMessage } from 'react-intl';

import classNames from 'classnames';
import classes from './OrganizationSetupSubMenu.module.css';

import Messages from './OrganizationSetupSubMenu.messages';
import isAuthenticated from '../Authentication/Authenticated';
import { trash } from 'ionicons/icons';

import { useTypedSelector } from '../../reducers';
import { equalityFnc, getDevices } from '../../utils/conformState';

interface OrganizationSetupSubMenuProps {
	currentTabIndex: string;
	changeCurrentSubMenuHandler?: () => void;
	receivedCurrentSubMenu: string;
	setCurrentSubMenuItems?: any;
	setNextTabSubMenuItems?: any;
	setPrevTabSubMenuItems?: any;
	setDeleteGroupItem?: any;
}

const OrganizationSetupSubMenu: FC<OrganizationSetupSubMenuProps> = (props: any) => {
	const {
		currentTabIndex,
		changeCurrentSubMenuHandler,
		receivedCurrentSubMenu,
		setCurrentSubMenuItems,
		setNextTabSubMenuItems,
		setPrevTabSubMenuItems,
		setDeleteGroupItem,
	} = props;

	const selectedOrganization = useTypedSelector(
		state => state.selectedOrganizationState.organization,
		(left, right) => equalityFnc(left, right)
	);
	const userGroups = useTypedSelector(
		state => state.userGroupsState,
		(left, right) => equalityFnc(left, right)
	);
	const deviceGroup = useTypedSelector(
		state => state.deviceGroupsState,
		(left, right) => equalityFnc(left, right)
	);
	const devices = useTypedSelector(state => getDevices(state, selectedOrganization.orgId));

	const [availableRobotsSubMenusItem, setAvailableRobotsSubMenusItem] = useState<Array<any>>([]);
	const [availableRobotGroupSubMenusItem, setAvailableRobotGroupSubMenusItem] = useState<
		Array<any>
	>([]);
	const [availableUserGroupsSubMenusItem, setAvailableUserGroupsSubMenusItem] = useState<
		Array<any>
	>([]);

	const tabsData = [
		{
			path: 'account',
			subMenus: [
				{ message: Messages.profile, path: 'profile' },
				// { message: Messages.notifications, path: 'notifications' }, FIXME: Implement notification settings in the back end
			],
		},
		{
			path: 'organization',
			subMenus: [
				{ message: Messages.organizationDetails, path: 'organization' },
				{ message: Messages.billingShipping, path: 'billing' },
			],
		},
		{
			path: 'robots',
			subMenus: availableRobotsSubMenusItem,
		},
		{
			path: 'robotGroups',
			subMenus: availableRobotGroupSubMenusItem,
		},
		{
			path: 'userGroups',
			subMenus: availableUserGroupsSubMenusItem,
		},
	];

	useEffect(() => {
		setCurrentSubMenuItems(tabsData[currentTabIndex].subMenus);

		currentTabIndex > 0
			? setPrevTabSubMenuItems(tabsData[currentTabIndex - 1].subMenus)
			: setPrevTabSubMenuItems([]);
		currentTabIndex < tabsData.length - 1
			? setNextTabSubMenuItems(tabsData[currentTabIndex + 1].subMenus)
			: setNextTabSubMenuItems([]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentTabIndex]);

	useEffect(() => {
		if (devices) {
			const newItems = [];
			for (let i = 0; i < devices.length; i++) {
				newItems.push({
					message: devices[i].name,
					path: devices[i].deviceId,
				});
			}
			setAvailableRobotsSubMenusItem(newItems);
		}
	}, [devices]);

	useEffect(() => {
		if (deviceGroup.deviceGroupsByOrganizationId[selectedOrganization.orgId]) {
			const newItems = [];
			for (const key in deviceGroup.deviceGroupsByOrganizationId[
				selectedOrganization.orgId
			]) {
				newItems.push({
					message:
						deviceGroup.deviceGroupsByOrganizationId[selectedOrganization.orgId][key]
							.name,
					path:
						deviceGroup.deviceGroupsByOrganizationId[selectedOrganization.orgId][key]
							.deviceGroupId,
				});
			}

			let addedItem = newItems.find(newItem => {
				return !availableRobotGroupSubMenusItem.find(oldItem => {
					return newItem.path === oldItem.path;
				});
			});

			setAvailableRobotGroupSubMenusItem(newItems);

			if (!receivedCurrentSubMenu && newItems) {
				if (newItems && newItems.length > 0) {
					changeCurrentSubMenuHandler(newItems[0].path);
				} else if (!newItems || newItems.length < 1) {
					changeCurrentSubMenuHandler(null);
				}

				return;
			}

			const currentTabIsRobotGroups = currentTabIndex === 3;

			if (currentTabIsRobotGroups && addedItem) {
				changeCurrentSubMenuHandler(addedItem.path);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deviceGroup.deviceGroupsByOrganizationId, selectedOrganization.orgId]);

	useEffect(() => {
		if (userGroups.userGroupsByOrganizationId[selectedOrganization.orgId]) {
			const newItems = [];
			for (const key in userGroups.userGroupsByOrganizationId[selectedOrganization.orgId]) {
				newItems.push({
					message:
						userGroups.userGroupsByOrganizationId[selectedOrganization.orgId][key].name,
					path:
						userGroups.userGroupsByOrganizationId[selectedOrganization.orgId][key]
							.userGroupId,
				});
			}

			let addedItem = newItems.find(newItem => {
				return !availableUserGroupsSubMenusItem.find(oldItem => {
					return newItem.path === oldItem.path;
				});
			});

			setAvailableUserGroupsSubMenusItem(newItems);

			if (!receivedCurrentSubMenu && newItems) {
				if (newItems && newItems.length > 0) {
					changeCurrentSubMenuHandler(newItems[0].path);
				} else if (!newItems || newItems.length < 1) {
					changeCurrentSubMenuHandler(null);
				}

				return;
			}

			const currentTabIsUserGroups = currentTabIndex === 4;

			if (currentTabIsUserGroups && addedItem) {
				changeCurrentSubMenuHandler(addedItem.path);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userGroups.userGroupsByOrganizationId, selectedOrganization.orgId]);

	const renderTabContent = useCallback(() => {
		const renderDeleteIconForItem = (item: any) => {
			return null; // FIXME: this needs to be enabled when all issues related to deletion of robot/user groups has been resolved
			if (item.path !== receivedCurrentSubMenu) {
				return null;
			}

			const deleteIcon = (
				<IonIcon
					onClick={() => {
						setDeleteGroupItem(true);
					}}
					className={classes.trash}
					icon={trash}
				/>
			);

			if (
				tabsData[currentTabIndex].path === 'robotGroups' &&
				availableRobotGroupSubMenusItem.length > 1
			) {
				return deleteIcon;
			}

			if (
				tabsData[currentTabIndex].path === 'userGroups' &&
				availableUserGroupsSubMenusItem.length > 1
			) {
				return deleteIcon;
			}

			return null;
		};

		return (
			<IonList className={classes.list} id="container">
				{tabsData[currentTabIndex].subMenus.map((item: any, index: number) => {
					return (
						<IonItem
							id={'row-' + item.path}
							key={index}
							lines="none"
							className={
								receivedCurrentSubMenu === item.path
									? classNames(classes.selectedItem, 'selected')
									: ''
							}
							onClick={() => {
								changeCurrentSubMenuHandler(item.path);
							}}
						>
							{tabsData[currentTabIndex].path === 'robots' ||
							tabsData[currentTabIndex].path === 'robotGroups' ||
							tabsData[currentTabIndex].path === 'userGroups' ? (
								<IonLabel>{item.message}</IonLabel>
							) : (
								<IonLabel>
									<FormattedMessage {...item.message} />
								</IonLabel>
							)}
							{renderDeleteIconForItem(item)}
						</IonItem>
					);
				})}
			</IonList>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		availableRobotGroupSubMenusItem.length,
		changeCurrentSubMenuHandler,
		currentTabIndex,
		receivedCurrentSubMenu,
		tabsData,
	]);

	return renderTabContent();
};

export default isAuthenticated(OrganizationSetupSubMenu, 'OrganizationSetupSubMenu');
