import { useRef, useState, useEffect } from 'react';

export default function useDelayedShowOrHide(
	shouldShow: boolean,
	config: { showDelayMs: number; hideDelayMs: number }
) {
	const configRef = useRef(config);
	const [isVisible, setIsVisible] = useState(shouldShow);
	useEffect(() => {
		const { showDelayMs: _showDelayMs, hideDelayMs: _hideDelayMs } = configRef.current;
		const showDelayMs = Math.max(_showDelayMs, 0);
		const hideDelayMs = Math.max(_hideDelayMs, 0);

		let timeoutId: ReturnType<typeof setTimeout>;
		if (shouldShow) {
			timeoutId = setTimeout(() => setIsVisible(true), showDelayMs);
		} else {
			timeoutId = setTimeout(() => setIsVisible(false), hideDelayMs);
		}
		return () => clearTimeout(timeoutId);
	}, [shouldShow]);
	return isVisible;
}
