import React, { FC, useState, useCallback, useEffect } from 'react';
import OrganizationDetails from './OrganizationDetails';
import BillingShipping from './BillingShipping';

import isAuthenticated from '../../components/Authentication/Authenticated';
import { useTypedSelector } from '../../reducers';
import { equalityFnc } from '../../utils/conformState';

interface OrganizationSetupProps {
	currentSubMenu: string;
	disableButton?: any;
	onUpdateMessageChange: (message: any) => void;
}

const OrganizationSetup: FC<OrganizationSetupProps> = (props: any) => {
	const { currentSubMenu, disableButton, onUpdateMessageChange } = props;

	const username = useTypedSelector(state => state.accountState.user.username);
	const selectedOrganization = useTypedSelector(
		state => state.selectedOrganizationState.organization,
		(left, right) => equalityFnc(left, right)
	);

	const [newOrganizationDetails, setNewOrganizationDetails] = useState<any>(selectedOrganization);
	const [currentItem, setCurrentItem] = useState<string | undefined>(currentSubMenu);

	let encodedUser = window.btoa(username);

	const [editableOrganization, setEditableOrganization] = useState<any>(null);

	useEffect(() => {
		let selectedItem = document.getElementById('row-' + currentItem);
		let top = selectedItem ? selectedItem.offsetTop + 30 : 30;
		let arrow = document.getElementById('arrow');
		if (arrow) {
			arrow.style.top = top.toString() + 'px';
		}
	});

	useEffect(() => {
		if (!selectedOrganization) {
			return;
		}

		const initializedOrganization = JSON.parse(JSON.stringify(selectedOrganization));

		const hasUsableBillingAddress =
			initializedOrganization.billingAddress &&
			initializedOrganization.billingAddress.organizationName &&
			initializedOrganization.billingAddress.address &&
			initializedOrganization.billingAddress.city &&
			initializedOrganization.billingAddress.country;

		if (hasUsableBillingAddress) {
			setEditableOrganization(initializedOrganization);
			return;
		}

		if (!initializedOrganization.billingAddress) {
			initializedOrganization.billingAddress = {};
		}

		const hasUsableContactPersonAddress =
			initializedOrganization.contactPerson &&
			initializedOrganization.contactPerson.address &&
			initializedOrganization.contactPerson.address.address &&
			initializedOrganization.contactPerson.address.city &&
			initializedOrganization.contactPerson.address.country;

		if (hasUsableContactPersonAddress) {
			for (const [key, value] of Object.entries(
				initializedOrganization.contactPerson.address
			)) {
				if (value && !initializedOrganization.billingAddress[key]) {
					initializedOrganization.billingAddress[key] = value;
				}
			}
		}

		if (!initializedOrganization.billingAddress.organizationName) {
			initializedOrganization.billingAddress.organizationName = initializedOrganization.name;
		}

		if (!initializedOrganization.billingAddress.organizationName) {
			initializedOrganization.billingAddress.organizationName = initializedOrganization.name;
		}

		const hasUsableShippingAddress =
			initializedOrganization.shippingAddress &&
			initializedOrganization.shippingAddress.organizationName &&
			initializedOrganization.shippingAddress.address &&
			initializedOrganization.shippingAddress.city &&
			initializedOrganization.shippingAddress.country;

		if (hasUsableShippingAddress) {
			setEditableOrganization(initializedOrganization);
			return;
		}

		if (!initializedOrganization.shippingAddress) {
			initializedOrganization.shippingAddress = {};
		}

		for (const [key, value] of Object.entries(initializedOrganization.billingAddress)) {
			if (value && !initializedOrganization.shippingAddress[key]) {
				initializedOrganization.shippingAddress[key] = value;
			}
		}

		setEditableOrganization(initializedOrganization);
	}, [selectedOrganization]);

	useEffect(() => {
		if (!selectedOrganization) {
			return;
		}

		if (
			newOrganizationDetails &&
			(JSON.stringify(selectedOrganization.billingAddress) !==
				JSON.stringify(newOrganizationDetails.billingAddress) ||
				JSON.stringify(selectedOrganization.shippingAddress) !==
					JSON.stringify(newOrganizationDetails.shippingAddress))
		) {
			let topic = `microservice/${selectedOrganization.orgId}/${encodedUser}/updateOrgBasicInfo`;
			let data = {
				data: {
					orgId: selectedOrganization.orgId,
					shippingAddress: newOrganizationDetails.shippingAddress || null,
					billingAddress: newOrganizationDetails.billingAddress || null,
				},
				requestId: 'updateOrgBasicInfoId',
			};
			onUpdateMessageChange(topic, data);
		}

		if (!editableOrganization) {
			return;
		}

		const data: any = { orgId: selectedOrganization.orgId };
		let shouldUpdateOrgBasicInfo = false;

		if (editableOrganization.name && editableOrganization.name !== selectedOrganization.name) {
			shouldUpdateOrgBasicInfo = true;
			data.name = editableOrganization.name;
		}

		if (
			editableOrganization.language &&
			editableOrganization.language !== selectedOrganization.language
		) {
			shouldUpdateOrgBasicInfo = true;
			data.language = editableOrganization.language;
		}

		if (shouldUpdateOrgBasicInfo) {
			onUpdateMessageChange(
				`microservice/${selectedOrganization.orgId}/${encodedUser}/updateOrgBasicInfo`,
				{ data, requestId: 'updateOrgBasicInfoId' }
			);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [newOrganizationDetails]);

	useEffect(() => {
		if (currentItem !== currentSubMenu) {
			setCurrentItem(currentSubMenu);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSubMenu]);

	const handleProfileChange = useCallback(
		(label: any, value: any) => {
			disableButton(false);
			let tempEditableOrganization = JSON.parse(JSON.stringify(editableOrganization));

			switch (label) {
				case 'name':
					const { name } = value;
					if (!name) {
						break;
					}

					tempEditableOrganization.name = name;
					break;

				case 'language':
					// TODO: Implement
					break;
			}
			setEditableOrganization(tempEditableOrganization);
		},

		[disableButton, editableOrganization]
	);

	const handleBillingAndShippingChange = useCallback(
		(organizationAddressData: any) => {
			setNewOrganizationDetails(organizationAddressData);

			if (
				organizationAddressData?.billingAddress?.address === '' ||
				organizationAddressData?.billingAddress?.zip === '' ||
				organizationAddressData?.billingAddress?.city === '' ||
				organizationAddressData?.billingAddress?.country === '' ||
				organizationAddressData?.shippingAddress?.address === '' ||
				organizationAddressData?.shippingAddress?.zip === '' ||
				organizationAddressData?.shippingAddress?.city === '' ||
				organizationAddressData?.shippingAddress?.country === ''
			) {
				disableButton(true);
			} else {
				disableButton(false);
			}
		},

		[disableButton]
	);

	if (!selectedOrganization) {
		return null;
	}

	if (currentItem === 'organization') {
		return (
			<OrganizationDetails
				monitorizeFormChange={handleProfileChange}
				organization={editableOrganization}
			/>
		);
	}

	if (currentItem === 'billing') {
		return (
			<BillingShipping
				monitorizeFormChange={handleBillingAndShippingChange}
				organization={editableOrganization}
			/>
		);
	}

	return null;
};

export default isAuthenticated(OrganizationSetup, 'OrganizationSetup');
