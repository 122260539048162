import React from 'react';

export const UpdatingIcon = () => {
	return (
		<svg
			width="52"
			height="57"
			viewBox="0 0 52 57"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M26.0109 8.39673C14.9971 8.39673 6.04395 17.4596 6.04395 28.6697C6.04395 39.8799 14.9971 48.9427 26.0109 48.9427C37.0248 48.9427 45.9779 39.8799 45.9779 28.6697C45.9779 17.4596 37.0248 8.39673 26.0109 8.39673ZM4.04395 28.6697C4.04395 16.3824 13.8653 6.39673 26.0109 6.39673C38.1566 6.39673 47.9779 16.3824 47.9779 28.6697C47.9779 40.9571 38.1566 50.9427 26.0109 50.9427C13.8653 50.9427 4.04395 40.9571 4.04395 28.6697Z"
				fill="#AAAAAA"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M37.1468 0.100342L39.2325 1.02912L37.26 5.45882C45.8938 9.64395 51.847 18.4921 51.847 28.7308C51.847 43.0057 40.2749 54.5778 26 54.5778C22.4939 54.5778 19.1509 53.8797 16.1024 52.615L14.1852 56.9205L12.0994 55.9917L14.0341 51.6471C5.78336 47.3301 0.152954 38.688 0.152954 28.7308C0.152954 14.4559 11.725 2.88377 26 2.88377C29.2266 2.88377 32.3151 3.47501 35.1631 4.55506L37.1468 0.100342ZM26 4.88377C12.8296 4.88377 2.15295 15.5604 2.15295 28.7308C2.15295 41.9011 12.8296 52.5778 26 52.5778C39.1703 52.5778 49.847 41.9011 49.847 28.7308C49.847 15.5604 39.1703 4.88377 26 4.88377Z"
				fill="white"
			/>
			<g clip-path="url(#clip0_6485_5740)">
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M34.057 25.1431C34.9125 25.301 35.6892 25.5884 36.3108 25.9835C37.5159 26.749 38.153 27.8984 38.153 29.307C38.153 31.9957 36.1205 33.6007 32.7155 33.6007H26.903V37.0423L28.6233 35.3248C28.7648 35.1877 28.9545 35.1117 29.1515 35.1134C29.3485 35.115 29.537 35.1941 29.6761 35.3336C29.8153 35.4731 29.894 35.6617 29.8952 35.8587C29.8964 36.0557 29.8201 36.2453 29.6827 36.3865L26.6827 39.3813C26.5421 39.5216 26.3516 39.6004 26.153 39.6004C25.9544 39.6004 25.7639 39.5216 25.6233 39.3813L22.6233 36.3865C22.4859 36.2453 22.4095 36.0557 22.4107 35.8587C22.4119 35.6617 22.4906 35.4731 22.6298 35.3336C22.7689 35.1941 22.9574 35.115 23.1544 35.1134C23.3514 35.1117 23.5412 35.1877 23.6827 35.3248L25.403 37.0423V33.6007H26.903V27.6007C26.9029 27.4992 26.8823 27.3987 26.8423 27.3054C26.8024 27.2121 26.7438 27.1278 26.6703 27.0578C26.5968 26.9878 26.5099 26.9334 26.4147 26.898C26.3196 26.8625 26.2183 26.8468 26.1169 26.8516C25.7133 26.8718 25.403 27.2182 25.403 27.6218V33.6007H20.528C16.7747 33.6007 14.153 31.3976 14.153 28.2382C14.153 26.6426 14.7951 25.2959 16.0087 24.3429C16.8469 23.6857 17.9437 23.2273 19.2155 22.9976C19.3336 22.9763 19.445 22.927 19.5403 22.8539C19.6355 22.7807 19.7119 22.6858 19.763 22.5771C20.2221 21.6132 20.8945 20.7663 21.7294 20.1007C22.9655 19.1201 24.4959 18.6007 26.153 18.6007C28.0378 18.6007 29.8162 19.2781 31.1615 20.5085C32.3123 21.5609 33.097 22.9493 33.4655 24.567C33.4969 24.7103 33.5697 24.8413 33.6748 24.9437C33.78 25.0461 33.9129 25.1154 34.057 25.1431Z"
					fill="#AAAAAA"
				/>
			</g>
			<defs>
				<clipPath id="clip0_6485_5740">
					<rect
						width="24"
						height="24"
						fill="white"
						transform="translate(14.153 17.1007)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};
