import { defineMessages } from 'react-intl';

export default defineMessages({
	date: {
		id: 'GuestReservations.date',
		defaultMessage: 'Date',
		description: 'Date',
	},
	startTime: {
		id: 'GuestReservations.startTime',
		defaultMessage: 'Start time',
		description: 'Start time',
	},
	duration: {
		id: 'GuestReservations.duration',
		defaultMessage: 'Duration',
		description: 'Duration',
	},
	robot: {
		id: 'GuestReservations.robot',
		defaultMessage: 'Robot',
		description: 'Robot',
	},
	guest: {
		id: 'GuestReservations.guest',
		defaultMessage: 'Guest',
		description: 'Guest',
	},
	subject: {
		id: 'GuestReservations.subject',
		defaultMessage: 'Subject',
		description: 'Subject',
	},
	organiser: {
		id: 'GuestReservations.organiser',
		defaultMessage: 'Organiser',
		description: 'Organiser',
	},
	createdBy: {
		id: 'GuestReservations.createdBy',
		defaultMessage: 'Created by',
		description: 'Created by',
	},
	invitedOn: {
		id: 'GuestReservations.invitedOn',
		defaultMessage: 'Invited on',
		description: 'Invited on',
	},
	noGuestReservations: {
		id: 'GuestReservations.noGuestReservations',
		defaultMessage: 'No Reservations!',
		description: 'No Reservations!',
	},
	noGuestReservationsHint: {
		id: 'GuestReservations.noGuestReservationsHint',
		defaultMessage:
			'Create your first reservation to easily give limited access to a guest outside your organisation.',
		description:
			'Create your first reservation to easily give limited access to a guest outside your organisation.',
	},
});
