import { defineMessages } from 'react-intl';

export default defineMessages({
    logout:{
        id: 'Session.logout',
		defaultMessage: 'Log out',
		description: 'Log Out',
	},
    title:{
        id: 'Session.title',
		defaultMessage: 'Whoops!',
		description: 'Whoops',
	},
    SubTitle:{
        id: 'Session.subTitle',
		defaultMessage: 'Refresh to continue',
		description: 'Refresh to continue',
    },
    hint: {
		id: 'Session.hint',
		defaultMessage: 'You are still logged in.',
		description: 'You are still logged in.',
	},
    refresh:{
        id: 'Session.refresh',
		defaultMessage: 'Refresh',
		description: 'Refresh',
	},
});
