import React, { FC } from 'react';
import { IonList, IonListHeader, IonIcon, IonLabel, IonItem, IonRow, IonCol, IonRouterLink } from '@ionic/react';
import { FormattedMessage } from 'react-intl';

import { barcode, barcodeOutline, cloudDownload, cloudDownloadOutline, cog, cogOutline, cube, cubeOutline, informationCircleOutline, timeOutline } from 'ionicons/icons';

import classes from './RobotEdit.module.css';
import Messages from './RobotEdit.messages';
import { Device, UVDMongoDevice } from '../../types/types';

interface RobotServiceInfoProps {
	robotId: string;
	orgId: string;
	device: UVDMongoDevice;
}

const RobotActivity: FC<RobotServiceInfoProps> = props => {
	const { robotId, orgId, device } = props;

	function getLastServiceInDays(serviceDate: Date){
		var today = new Date().getTime();
		var diffInMs = Math.abs(today - serviceDate.getTime());
		return Math.floor(diffInMs / (1000 * 60 * 60 * 24));
	}

	return (
		<div className={classes.recentContainer}>
			<IonList lines="none">
				<IonListHeader>
					<IonIcon color="primary" size="small" icon={informationCircleOutline} />
					<IonLabel color="primary" className={classes.recentLb}>
						<FormattedMessage {...Messages.serviceInfo} />
					</IonLabel>
				</IonListHeader>
				<IonItem className={classes.serviceInfoItem}>
					<IonRow>
						<IonCol><IonIcon color="000000" icon={cog} className={classes.serviceInfoIcon}></IonIcon></IonCol>
						<IonCol>
							<IonRow><IonLabel><FormattedMessage {...Messages.lastService} /></IonLabel></IonRow>
							<IonRow>
								<IonLabel className={classes.greyLabel}>
									<IonIcon size="small" icon={timeOutline} className={classes.serviceInfoSubIcon} />{getLastServiceInDays(device.lastService ? device.lastService : new Date())} days ago
								</IonLabel>
							</IonRow>
						</IonCol>
					</IonRow>
				</IonItem>
				<IonItem className={classes.serviceInfoItem}>
					<IonRow>
						<IonCol><IonIcon color="000000" icon={cloudDownload} className={classes.serviceInfoIcon}></IonIcon></IonCol>
						<IonCol>
							<IonRow><IonLabel><FormattedMessage {...Messages.softwareVersion} /></IonLabel></IonRow>
							<IonRow>
								<IonLabel className={classes.greyLabel}>
									<IonRouterLink href="blank">{device.softwareVersion}</IonRouterLink>
									<IonIcon size="small" icon={timeOutline} className={classes.serviceInfoSubIcon} />
									{new Intl.DateTimeFormat("en-GB", {
										year: "numeric",
										month: "short",
										day: "2-digit"
										}).format(device.softwareVersionDate)}
								</IonLabel>
							</IonRow>
						</IonCol>
					</IonRow>
				</IonItem>
				<IonItem className={classes.serviceInfoItem}>
					<IonRow>
						<IonCol><IonIcon color="000000" icon={cube} className={classes.serviceInfoIcon}></IonIcon></IonCol>
						<IonCol>
							<IonRow><IonLabel><FormattedMessage {...Messages.servicePackage} /></IonLabel></IonRow>
							<IonRow><IonLabel className={classes.greyLabel}>{device.package}</IonLabel></IonRow>
						</IonCol>
					</IonRow>
				</IonItem>
				<IonItem className={classes.serviceInfoItem}>
					<IonRow>
						<IonCol><IonIcon color="000000" icon={barcodeOutline} className={classes.serviceInfoIcon}></IonIcon></IonCol>
						<IonCol>
							<IonRow><IonLabel><FormattedMessage {...Messages.serialNumber} /></IonLabel></IonRow>
							<IonRow><IonLabel className={classes.greyLabel}>{device.serialNumber}</IonLabel></IonRow>
						</IonCol>
					</IonRow>
				</IonItem>
			</IonList>
		</div>
	);
};

export default RobotActivity;
