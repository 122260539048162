import React, { FC, useState, useMemo } from 'react';
import { IonGrid, IonRow, IonList, IonLabel, IonCol } from '@ionic/react';
import isAuthenticated from '../../components/Authentication/Authenticated';
import { injectIntl, FormattedMessage } from 'react-intl';
import Messages from './OrganizationForm.messages';
import classes from './OrganizationForm.module.css';
import { useTypedSelector } from '../../reducers';
import { AcceptedAgreementsPayload } from '../../reducers/accountReducers';
import OrganizationAgreementModal from './OrganizationAgreementModal';
import classNames from 'classnames';
import { mmmDDYYYYFormat, timeFormat } from '../../utils/formatTime';
import Table from '../Table/Table';
import { TableCell } from '@material-ui/core';
import { styled } from '@material-ui/styles';
interface OrganizationAgreementProps {
	organization: any;
	isEditable?: boolean;
	saved: boolean;
	segment: string;
}
const StyledTableCell = styled(TableCell)(() => ({
	borderColor: '#f1f1f3',
}));

const OrganizationAgreementForm: FC<OrganizationAgreementProps> = props => {
	const { isEditable } = props;
	const [selectedAgreement, setSelectedAgreement] = useState<AcceptedAgreementsPayload | null>(
		null
	);

	const [modalOpen, setModalOpen] = useState<boolean>(false);

	const agreements: Array<any> = useTypedSelector(
		state => state.selectedOrganizationState.organization.acceptedAgreements
	);

	const openAgreement = (agreement: AcceptedAgreementsPayload) => {
		setSelectedAgreement(agreement);
		setModalOpen(true);
	};

	const rows = useMemo(() => {
		return agreements.filter(
			data =>
				data.type === 'business-terms-and-conditions' ||
				data.type === 'sub-processing-agreement' ||
				data.type === 'data-processing-agreement'
		);
	}, [agreements]);

	const renderLinkCell = (rowData: any) => {
		return (
			<StyledTableCell>
				<div
					className={classNames(classes.inputLbAgreement)}
					key={rowData.type}
					onClick={e => {
						openAgreement(rowData);
					}}
				>
					<FormattedMessage id={`Organizations.${rowData.type}`} />
				</div>
			</StyledTableCell>
		);
	};
	const columns = [
		{
			dataField: 'type',
			renderCell: (rowData: any) => renderLinkCell(rowData),
			formattedMessage: <FormattedMessage {...Messages.agreements} />,
		},
		{
			dataField: 'version',
			formattedMessage: <FormattedMessage {...Messages.version} />,
		},
		{
			dataField: 'dateOfAcceptance',
			formattedMessage: <FormattedMessage {...Messages.acceptedDate} />,
			type: 'date',
		},
		{
			dataField: 'acceptedTime',
			formattedMessage: <FormattedMessage {...Messages.acceptedTime} />,
		},
		{
			dataField: 'userId',
			formattedMessage: <FormattedMessage {...Messages.acceptedBy} />,
		},
	];
	return (
		<div className={classes.tableHeader}>
			<Table columns={columns} rows={rows} hasHeader={true} />
			<OrganizationAgreementModal
				open={modalOpen}
				selectedAgreement={selectedAgreement}
				onDismissModal={() => {
					setModalOpen(false);
				}}
			/>
		</div>
	);
};

export default injectIntl(isAuthenticated(OrganizationAgreementForm, 'OrganizationAgreementForm'));
