import React, { useEffect, useState, useRef } from 'react';

const useHover = <T extends Element>(): [React.MutableRefObject<T>, boolean] => {
	const [isHovered, setHovered] = useState(false);

	const ref = useRef<any>(null);

	const handleMouseover = () => setHovered(true);
	const handleMouseout = () => setHovered(false);

	useEffect(() => {
		const instance = ref.current;
		if (instance) {
			instance.addEventListener('mouseover', handleMouseover);
			instance.addEventListener('mouseout', handleMouseout);

			return () => {
				instance.removeEventListener('mouseover', handleMouseover);
				instance.removeEventListener('mouseout', handleMouseout);
			};
		}
	}, [ref.current]);

	return [ref, isHovered];
};

export default useHover;
