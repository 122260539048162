/* eslint-disable camelcase */
import { IClientPublishOptions, IClientSubscribeOptions, MqttClient } from 'mqtt';
import SessionMqttFactory from '../mqttFactory';
import { SessionInfo } from '../useSessionInitiator';
import {
	ICEPayload,
	ISignalingClient,
	SDPPayload,
	SIGNALING_CLIENT_KEEPALIVE_INTERVAL,
} from './types';

enum SignalingEvent {
	Ready = 'ready',
	Keepalive = 'keepalive',
	SDP = 'sdp',
	ICECandidate = 'ice',
	Hangup = 'hangUp',
}

/** A signaling client with MQTT as the mode of transport */
export default class MQTTSignalingClient implements ISignalingClient {
	private isClosed = false;

	private incomingKeepaliveTimeoutId: ReturnType<typeof setTimeout> | null = null;
	private outgoingKeepaliveLoopId: ReturnType<typeof setInterval> | null = null;

	onRemoteSDP: ((data: SDPPayload) => void) | null = null;
	onRemoteICECandidate: ((payload: ICEPayload) => void) | null = null;
	onRemoteHangUp: (() => void) | null = null;
	onKeepaliveTimeout: (() => void) | null;
	onReconnect: (() => void) | null;
	// ***************************************************************************************
	// Unsupported methods
	onRemoteWillRetry: (() => void) | null; // not supported when using MQTT for signaling
	onRemoteReadyToRetry: (() => void) | null; // not supported when using MQTT for signaling
	sendReadyForRetry = async () =>
		console.log('MQTTSignalingClient: sendReadyToRetry -- aborted -- not supported');
	// ***************************************************************************************

	private mqttClient: MqttClient | null = null;
	private baseTopics: {
		/** topics for incoming session-related messages, ie ice, sdp, hangup */
		sessionIn: string;
		/** topics for outgoing session-related messages, ie ice, sdp, hangup */
		sessionOut: string;
	};

	private stopAutoResubscription: (() => void) | null = null;

	constructor(public readonly sessionInfo: SessionInfo) {
		this.baseTopics = {
			sessionIn: `session/${sessionInfo.robot.id}/${sessionInfo.pilot.id}/${sessionInfo.uuid}`,
			sessionOut: `session/${sessionInfo.pilot.id}/${sessionInfo.robot.id}/${sessionInfo.uuid}`,
		};
	}

	start = async () => {
		this.mqttClient = await SessionMqttFactory.getMqttClient(this.sessionInfo.mqttConfig);
		await this.subscribeToSignallingTopics();
		await this.sendSignalingEvent(SignalingEvent.Ready);
		this.startOutgoingKeepaliveLoop();
	};

	sendSDPToPeer = (data: SDPPayload) => this.sendSignalingEvent(SignalingEvent.SDP, data);

	sendICECandidateToPeer = (data: ICEPayload) =>
		this.sendSignalingEvent(SignalingEvent.ICECandidate, data);

	sendICERestartRequest = () =>
		this.sendSignalingEvent(SignalingEvent.SDP, { type: 'iceRestart' });

	sendHangup = () => this.sendSignalingEvent(SignalingEvent.Hangup);

	close = async () => {
		if (!this.mqttClient) return Promise.reject(new Error('MQTT client is not initialized'));

		this.isClosed = true;
		await this.unsubscribeFromSignalingTopics();
		this.stopOutgoingKeepaliveLoop();
		this.mqttClient = null;
	};

	private sendSignalingEvent = async (
		event: SignalingEvent,
		data: unknown = null,
		skipIfNotConnected = false
	) => {
		if (!this.mqttClient) return Promise.reject(new Error('MQTT client is not initialized'));

		if (this.isClosed) {
			console.error('Will not send event. Signaling client is closed');
			return;
		} else if (skipIfNotConnected && !this.mqttClient.connected) {
			console.debug('Will not send event. MQTT client is not connected');
			return;
		} else {
			console.info('signaling.sendSignalingEvent', { event });
			this.publish(`${this.baseTopics.sessionOut}/${event}`, data ?? '', { qos: 1 });
		}
	};

	private publish = (topic: string, data: any, options?: IClientPublishOptions) => {
		if (!this.mqttClient) return Promise.reject(new Error('MQTT client is not initialized'));

		return new Promise<void>((resolve, reject) => {
			let parsedData = data;
			try {
				parsedData = JSON.stringify(data);
				// eslint-disable-next-line no-empty
			} catch (error) {}
			this.mqttClient!.publish(topic, parsedData, options || { qos: 0 }, (err?: Error) => {
				if (err) {
					reject(err);
					console.error(`signaling.publish ERROR ${topic}`, err);
				} else {
					console.debug(`signaling.published`, topic);
					resolve();
				}
			});
		});
	};

	/**
	 * Wrapper around mqtt client subscribe function to make it promise-like.
	 * Automatically resubscribes when client reconnects.
	 * @param topic
	 * @param options
	 * @returns A promise that resolves function to stop auto resubscribes
	 */
	private subscribe = (topic: string | string[], options?: IClientSubscribeOptions) => {
		if (!this.mqttClient) return Promise.reject(new Error('MQTT client is not initialized'));

		const client = this.mqttClient;

		const logSuccess = () => console.debug(`signaling.subscribe`, topic);
		// todo: Handle subscription timeout. ie, what if the subscription never goes through?
		return new Promise<() => void>((resolve, reject) => {
			client.subscribe(topic, options || { qos: 0 }, error => {
				if (error) {
					console.error('signaling.subscribe ERROR', topic, error);
					reject(error);
				} else {
					logSuccess();

					const reSubscribe = () => {
						logSuccess();
						client.subscribe(topic, options || { qos: 0 });
					};
					// Subscribe again when the client becomes connected again
					client.addListener('connect', reSubscribe);

					const stopAutoReSubcription = () => {
						console.debug('signaling.stopAutoReSubcription');
						client.removeListener('connect', reSubscribe);
					};

					resolve(stopAutoReSubcription);
				}
			});
		});
	};

	private subscribeToSignallingTopics = async () => {
		if (!this.mqttClient) return Promise.reject(new Error('MQTT client is not initialized'));

		this.mqttClient.on('message', this.onMQTTMessage);
		const stopAutoReSubscription = await this.subscribe([`${this.baseTopics.sessionIn}/#`], {
			qos: 1,
		});
		if (this.stopAutoResubscription) this.stopAutoResubscription();
		this.stopAutoResubscription = stopAutoReSubscription;
	};

	private unsubscribeFromSignalingTopics = async () => {
		if (!this.mqttClient) return Promise.reject(new Error('MQTT client is not initialized'));

		this.mqttClient.removeListener('message', this.onMQTTMessage);
		if (this.stopAutoResubscription) {
			this.stopAutoResubscription();
		}
		this.stopAutoResubscription = null;
		this.unsubscribe([`${this.baseTopics.sessionIn}/#`]);
	};

	private unsubscribe = (topic: string | string[]) => {
		if (!this.mqttClient) return Promise.reject(new Error('MQTT client is not initialized'));

		return new Promise<void>((resolve, reject) => {
			this.mqttClient!.unsubscribe(topic, {}, error => {
				if (error) {
					console.error('signaling.unsubscribe ERROR', topic, error);
					reject(error);
				} else resolve();
			});
		});
	};

	private messageAsJSON = (message: string) => {
		let parsedMessage: unknown = message;
		try {
			parsedMessage = JSON.parse(message);
		} catch (error) {
			console.warn('Unable to parse message as JSON', error);
		}
		return parsedMessage;
	};

	/** Handler for messages received from mqtt broker */
	private onMQTTMessage = (_topic: string, message: string) => {
		if (this.isClosed) {
			console.error('Will not process incoming message. Signaling client is closed');
			return;
		}
		const signalingEvent = _topic.replace(`${this.baseTopics.sessionIn}/`, '');
		console.debug('signaling.onMessage', { event: signalingEvent });

		let parsedMessage = this.messageAsJSON(message);
		switch (signalingEvent) {
			case SignalingEvent.Keepalive:
				return this.resetIncomingKeepaliveTimeout();
			case SignalingEvent.SDP:
				return this.onRemoteSDP?.(parsedMessage as SDPPayload);
			case SignalingEvent.ICECandidate:
				return this.onRemoteICECandidate?.(parsedMessage as ICEPayload);
			case SignalingEvent.Hangup:
				return this.onRemoteHangUp?.();
			default:
				console.warn('Unexpected signaling event received', signalingEvent);
				return;
		}
	};

	private startOutgoingKeepaliveLoop = () => {
		this.stopOutgoingKeepaliveLoop();

		this.outgoingKeepaliveLoopId = setInterval(
			() =>
				this.sendSignalingEvent(SignalingEvent.Keepalive, { timestamp: Date.now() }, true),
			SIGNALING_CLIENT_KEEPALIVE_INTERVAL
		);
	};

	private stopOutgoingKeepaliveLoop = () => {
		if (this.outgoingKeepaliveLoopId !== null) {
			clearInterval(this.outgoingKeepaliveLoopId);
		}
		this.outgoingKeepaliveLoopId = null;
	};

	private resetIncomingKeepaliveTimeout = () => {
		this.stopIncomingKeepaliveTimeout();

		this.incomingKeepaliveTimeoutId = setTimeout(() => {
			this.onKeepaliveTimeout?.();
		}, SIGNALING_CLIENT_KEEPALIVE_INTERVAL);
	};

	private stopIncomingKeepaliveTimeout = () => {
		if (this.incomingKeepaliveTimeoutId !== null) {
			clearTimeout(this.incomingKeepaliveTimeoutId);
		}
		this.incomingKeepaliveTimeoutId = null;
	};
}
