import React, { FC, useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { IonIcon, IonLabel, IonButton, IonRow, IonGrid } from '@ionic/react';
import { add, repeat, closeCircle } from 'ionicons/icons';

import classes from './Robots.module.css';
import classNames from 'classnames';
import Messages from './Robots.messages';

import isAuthenticated from '../../components/Authentication/Authenticated';
import AddNewRobotModal from '../AddNewRobot/AddNewRobotModal';
import RobotTransferModal from '../RobotTransferModal/RobotTransferModal';
import SearchBar from '../SearchBar/SearchBar';
import FilterList from '../FilterList/FilterList';
import { Device } from '../../types/types';
import { useTypedSelector } from '../../reducers';
import { equalityFnc } from '../../utils/conformState';
import { getRole } from '../../actions/accountActions';
import { Snackbar } from '../Snackbar';

interface RobotsProps {
	isAddNewRobotShow: boolean;
	showTransferRobot: boolean;
	deviceToShow: Array<Device>;
	searchText: string;
	setSearchText: any;
	selectedFilter: string;
	filterValues: Array<string>;
	setFilterValue: any;
	setRobotHeaders: any;
	headers: Array<Record<string, string>>;
}

const RobotsOptions: FC<RobotsProps> = (props: any) => {
	const {
		isAddNewRobotShow,
		intl,
		showTransferRobot,
		searchText,
		setSearchText,
		filterValues,
		selectedFilter,
		setFilterValue,
		organizationName,
		selectRobots,
		setSelectRobots,
		selectedRobots,
		unCheckAll,
		handleOnCheck,
	} = props;
	const [showAddNewRobot, setShowAddNewRobot] = useState(false);
	const [showTransfer, setShowTransfer] = useState(false);
	const [duplicateSerialNoSnackBarOpen, setDuplicateSerialNoSnackbarOpen] = useState(false);

	const account = useTypedSelector(
		state => state.accountState.user,
		(left, right) => equalityFnc(left, right)
	);
	const selectedOrganizationType = useTypedSelector(
		state => state.selectedOrganizationState.organization.orgType
	);

	const onErrorDuplicateSerialNo = () => {
		setDuplicateSerialNoSnackbarOpen(true);
	};

	const renderSelectedRobots = () => {
		const robots = selectedRobots.map((robot: any, index: number) => {
			return (
				<IonLabel key={index} className={classes.robotContainer}>
					{robot.name}
					<IonButton
						className={classes.deleteRobotBtn}
						shape="round"
						color="transparent"
						onClick={() => handleOnCheck(robot, false)}
					>
						<IonIcon size="35px" slot="icon-only" icon={closeCircle} />
					</IonButton>
				</IonLabel>
			);
		});

		return (
			<IonGrid>
				<IonRow>
					<FormattedMessage
						{...Messages.addRobotsToGroups}
						values={{
							number: selectedRobots.length,
						}}
					/>
				</IonRow>
				<IonRow
					className={
						selectedRobots.length > 10
							? classes.selectedRobotsContainer
							: classes.selectedRobotsContainerNoScroll
					}
				>
					{robots}
				</IonRow>
			</IonGrid>
		);
	};

	return (
		<div>
			{duplicateSerialNoSnackBarOpen && (
				<Snackbar
					open={duplicateSerialNoSnackBarOpen}
					onClose={() => setDuplicateSerialNoSnackbarOpen(false)}
					message={<FormattedMessage {...Messages.duplicateSerialRobot} />}
					snackbarType="error"
				/>
			)}
			{isAddNewRobotShow ? (
				<div className={classes.addRobotBtnContainer}>
					<IonLabel color="primary" className={classes.addLb}>
						<FormattedMessage {...Messages.addRobot} />
					</IonLabel>
					<IonButton
						className={classes.addBtn}
						shape="round"
						onClick={() => setShowAddNewRobot(true)}
						data-cy="create_robot_button"
					>
						<IonIcon
							className={classes.addIcon}
							slot="icon-only"
							size="small"
							icon={add}
						/>
					</IonButton>
				</div>
			) : (
				''
			)}
			{(selectedOrganizationType === 'customer' ||
				selectedOrganizationType === 'solutionProvider') &&
			!getRole(account)?.match(/bor|spinout/g) ? (
				''
			) : selectRobots ? (
				<div className={classNames(classes.transferRobotsContainer)}>
					<IonButton
						className={
							selectedRobots.length > 0
								? classes.selectRobotBtn
								: classes.disabledSelectRobotBtn
						}
						onClick={selectedRobots.length > 0 ? () => setShowTransfer(true) : () => {}}
						data-cy="transfer_robot_button"
					>
						{selectedRobots.length ? (
							<FormattedMessage {...Messages.transfer} />
						) : (
							<FormattedMessage {...Messages.selectRobots} />
						)}
					</IonButton>
				</div>
			) : (
				showTransferRobot && (
					<div className={classes.transferRobotBtnContainer}>
						<IonLabel color="primary" className={classes.addLb}>
							<FormattedMessage {...Messages.transferRobot} />
						</IonLabel>
						<IonButton
							className={classes.addBtn}
							shape="round"
							onClick={() => setSelectRobots(true)}
							data-cy="transfer_robot_button"
						>
							<IonIcon
								className={classes.addIcon}
								slot="icon-only"
								size="small"
								icon={repeat}
							/>
						</IonButton>
					</div>
				)
			)}

			{showAddNewRobot ? (
				<AddNewRobotModal
					isOpen={showAddNewRobot}
					onError={onErrorDuplicateSerialNo}
					onDismiss={() => setShowAddNewRobot(false)}
				/>
			) : null}

			{showTransfer ? (
				<RobotTransferModal
					isOpen={selectedRobots.length && showTransfer}
					onDismiss={() => setShowTransfer(false)}
					organizationName={organizationName}
					collection={selectedRobots}
					unCheckAll={unCheckAll}
					setSelectRobots={setSelectRobots}
					renderSelectedRobots={renderSelectedRobots}
				/>
			) : null}

			<SearchBar
				searchText={searchText}
				placeholder={intl.formatMessage({
					id: 'RobotsGroup.searchRobots',
				})}
				onChange={(value: any) => setSearchText(value)}
			/>
			<FilterList
				filterValues={filterValues}
				selected={selectedFilter}
				onChange={(data: any) => {
					searchText && setSearchText('');
					setFilterValue(data);
				}}
			/>
		</div>
	);
};

export default injectIntl(isAuthenticated(RobotsOptions, 'RobotsOptions'));
