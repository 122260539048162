import React, { useEffect, useRef, useState } from 'react';
import './DynamicMedia.css';
import useDimensions from 'react-cool-dimensions';
import { Rnd } from 'react-rnd';

type MediaStylesOptions = {
	containerWidth: number;
	containerHeight: number;
	x1: number;
	x2: number;
};

const VIDEO_FRAME_WIDTH = 1280;
const VIDEO_FRAME_HEIGHT = 720;

export const UNCROPPED_VIDEO_FRAME_CONFIG = { x1: 0, x2: VIDEO_FRAME_WIDTH };

const computeMediaStyles = ({ containerWidth, containerHeight, x1, x2 }: MediaStylesOptions) => {
	const mediaUsableWidth = x2 - x1;
	const scaleFactor = containerWidth / mediaUsableWidth;
	const mediaDimensions = {
		width: VIDEO_FRAME_WIDTH * scaleFactor,
		height: VIDEO_FRAME_HEIGHT * scaleFactor,
	};
	const translateYValue = mediaDimensions.height / 2 - containerHeight + containerHeight / 2;
	const translateXValue = x1 * scaleFactor;

	const mediaStyles: React.CSSProperties = {
		...mediaDimensions,
		transform: `rotate(180deg) translateY(${translateYValue}px) translateX(${translateXValue}px)`,
		transformOrigin: 'center',
	};
	return Object.freeze(mediaStyles);
};

const INITIAL_MEDIA_STYLE = Object.freeze<React.CSSProperties>({
	width: VIDEO_FRAME_WIDTH,
	height: VIDEO_FRAME_HEIGHT,
	display: 'none',
});

type DynamicMediaProps = {
	className?: string;
	drivingMode: boolean;
	cropOptions: { x1: number; x2: number };
	videoRef: any;
	resizeNavCamera: (value: number) => void;
	fullScreenStatus: boolean;
	isExpanded: boolean;
	onFullScreenClick: any;
	mediaComponentProps: React.VideoHTMLAttributes<HTMLVideoElement>;
};

function DynamicMedia({
	className,
	mediaComponentProps,
	cropOptions,
	videoRef,
	drivingMode,
	fullScreenStatus,
	isExpanded,
	onFullScreenClick,
	resizeNavCamera,
}: DynamicMediaProps) {
	const [mediaStyles, setMediaStyles] = useState<Readonly<React.CSSProperties>>(
		INITIAL_MEDIA_STYLE
	);
	const { observe } = useDimensions({
		onResize: ({ observe, unobserve, width, height, entry }) => {

			// Triggered whenever the size of the target is changed...
			const newStyles = computeMediaStyles({
				containerWidth: width,
				containerHeight: height,
				...cropOptions,
			});
			setMediaStyles(newStyles);
		},
	});

	const [size, setSize] = useState({
		height: window.innerHeight,
		width: window.innerWidth,
	});
	const [dimensions, setDimensions] = React.useState({
		height: window.innerHeight,
		width: window.innerWidth,
	});

	useEffect(() => {
		setSize({ height: window.innerHeight, width: window.innerWidth });
		setDimensions({
			height: window.innerHeight,
			width: window.innerWidth,
		});
	}, []);


	useEffect(() => {
		function handleResize() {
			setDimensions({
				height: window.innerHeight,
				width: window.innerWidth,
			});
		}

		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const [position, setPosition] = useState({ x: 0, y: 0 });
	useEffect(() => {
		if (drivingMode) {
			setSize({ height: dimensions.height - 250, width: dimensions.width * 0.75 });
			setPosition({ x: dimensions.width * 0.125, y: 0 });
		} else {
			setSize({ height: dimensions.height, width: dimensions.width });
			setPosition({ x: 0, y: 0 });

		}
	}, [drivingMode, dimensions]);

	const style = {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	};
	return (
		<Rnd
			enableResizing={{
				top: false,
				right: false,
				bottom: drivingMode,
				left: false,
				topRight: false,
				bottomRight: false,
				bottomLeft: false,
				topLeft: false,
			}}
			disableDragging
			style={style}
			size={size}
			position={position}
			onResize={(e, direction, ref, delta, position) => {
				let height = parseInt(ref.style.height, 10);
				let width = (height * window.innerWidth) / window.innerHeight;
				setSize({
					width,
					height,
				});
				setPosition({ x: (window.innerWidth - width) / 2, y: 0 });
				resizeNavCamera(window.innerHeight - height);
			}}
		>
			<div className={`${className}`} ref={observe as any} style={{ borderRadius: drivingMode ? '1%' : 'none' }}>
				<video
					className="media"
					style={mediaStyles}
					ref={videoRef}
					{...mediaComponentProps}
				/>
				{fullScreenStatus ? (
					<div
						className={
							fullScreenStatus
								? 'showMenuSession'
								: 'showMenuSessionOut showMenuSession'
						}
						onClick={onFullScreenClick}
					>
						<img alt="" src="../assets/images/exit-fullscreen.svg" />
					</div>
				) : (
					<div
						className={
							!fullScreenStatus
								? isExpanded
									? 'showFullScreenSession showFullScreenSessionExpanded'
									: 'showFullScreenSession'
								: 'showFullScreenSession showFullScreenSessionOut'
						}
						onClick={onFullScreenClick}
					>
						<div className="showFullScreenWrapper">
							<img alt="" src="../assets/images/black-full-screen.svg" />
						</div>
					</div>
				)}
			</div>
		</Rnd>
	);
}

export default DynamicMedia;
