import { defineMessages } from 'react-intl';

export default defineMessages({
	title: {
		id: 'Organizations.new',
		defaultMessage: 'New Organization',
		description: 'New Organization',
	},
	info: {
		id: 'Organizations.info',
		defaultMessage: 'Organization info',
		description: 'Organization info',
	},
	contact: {
		id: 'Organizations.contact',
		defaultMessage: 'Contact person',
		description: 'Contact person',
	},
	allocate: {
		id: 'Organizations.allocate',
		defaultMessage: 'Allocate robots',
		description: 'Allocate robots',
	},
	next: {
		id: 'next',
		defaultMessage: 'Next',
		description: 'Next',
	},
	cancel: {
		id: 'Organizations.cancel',
		defaultMessage: 'Cancel',
		description: 'Cancel',
	},
	create: {
		id: 'create',
		defaultMessage: 'Create',
		description: 'Create organization',
	},
	availableRobots: {
		id: 'Organizations.availableRobots',
		defaultMessage: 'Available robots',
		description: 'Available robots',
	},
	selectedRobots: {
		id: 'Organizations.selectedRobots',
		defaultMessage: 'Selected robots',
		description: 'Selected robots',
	},
	// search: {
	// 	id: 'Organizations.search',
	// 	defaultMessage: 'Search robots',
	// 	description: 'Search robots',
	// },
	noSelection: {
		id: 'Address.noSelection',
		defaultMessage: 'No robots selected',
		description: 'No robots selected',
	},
	newDefaultUserGroupName: {
		id: 'Organizations.newDefaultUserGroupName',
		defaultMessage: 'New User Group',
		description: 'New User Group',
	},
	newDefaultDeviceGroupName: {
		id: 'Organizations.newDefaultDeviceGroupName',
		defaultMessage: 'New Robot Group',
		description: 'New Robot Group',
	},
});
