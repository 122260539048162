import { defineMessages } from 'react-intl';

export default defineMessages({
	organizationDetails: {
		id: 'Organizations.details',
		defaultMessage: 'Organization details',
		description: 'Organization details',
	},
	createdAt: {
		id: 'Organizations.createdOn',
		defaultMessage: 'Created on',
		description: 'Created on',
	},
	upload: {
		id: 'AccountManagementPage.upload',
		defaultMessage: 'Upload Picture',
		description: 'Upload Picture',
	},
	web: {
		id: 'AccountManagementPage.web',
		defaultMessage: 'From The Web',
		description: 'From The Web',
	},
	billingDetails: {
		id: 'Organizations.billingDetails',
		defaultMessage: 'Billing details',
		description: 'Billing details',
	},
	billingShipping: {
		id: 'Organizations.billingShipping',
		defaultMessage: 'Billing & Shipping',
		description: 'Billing & Shipping',
	},
	shippingDetails: {
		id: 'Organizations.shippingDetails',
		defaultMessage: 'Shipping details',
		description: 'Shipping details',
	},
	sameAs: {
		id: 'OrganizationSetup.sameAs',
		defaultMessage: 'Same as billing details',
		description: 'Same as billing details',
	},
});
