import { MqttRouter } from 'mqtt-sub-router';
import { store } from '../store/store';
import { ACCOUNT_CREATE_CONFIRMED_STATUS_UPDATED } from '../actions/types';
import { AccountCreateConfirmedStatus } from '../reducers/accountCreateConfirmedReducer';

const AccountCreateConfirmationRouter = new MqttRouter()
	.listen('accountCreateConfirmation/response',
	({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
		if (data === AccountCreateConfirmedStatus.ERROR_NOT_CREATED){
			store.dispatch({
				type: ACCOUNT_CREATE_CONFIRMED_STATUS_UPDATED,
				payload: { 
					status: AccountCreateConfirmedStatus.ERROR_NOT_CREATED,
				},
			});
			return;
		}
		if (data === AccountCreateConfirmedStatus.ERROR_ALREADY_CONFIRMED){
			store.dispatch({
				type: ACCOUNT_CREATE_CONFIRMED_STATUS_UPDATED,
				payload: { 
					status: AccountCreateConfirmedStatus.ERROR_ALREADY_CONFIRMED,
				},
			});
			return;
		}
		if (status === AccountCreateConfirmedStatus.SUCCESS) {
			store.dispatch({
				type: ACCOUNT_CREATE_CONFIRMED_STATUS_UPDATED,
				payload: { status: AccountCreateConfirmedStatus.SUCCESS },
			});
			return;
		}
	}
);
export default AccountCreateConfirmationRouter;
