import IndustryLightEot from './assets/Industry/Industry-Light.eot';
import IndustryLightWoff from './assets/Industry/Industry-Light.woff';
import IndustryLightTtf from './assets/Industry/Industry-Light.ttf';
import IndustryMediumEot from './assets/Industry/Industry-Medium.eot';
import IndustryMediumWoff from './assets/Industry/Industry-Medium.woff';
import IndustryMediumTtf from './assets/Industry/Industry-Medium.ttf';
import IndustryBoldEot from './assets/Industry/Industry-Bold.eot';
import IndustryBoldWoff from './assets/Industry/Industry-Bold.woff';
import IndustryBoldTtf from './assets/Industry/Industry-Bold.ttf';

const Industry = {
    light: {
        fontFamily: 'Industry',
        fontStyle: 'normal',
        fontWeight: 300,
        src: `
            local('Industry-Light'), local('Industry light'),
            url(${IndustryLightEot}?#iefix) format('embedded-opentype'),
            url(${IndustryLightWoff}) format('woff'),
            url(${IndustryLightTtf}) format('truetype');
        `
    },
    medium: {
        fontFamily: 'Industry',
        fontStyle: 'normal',
        fontWeight: 400,
        src: `
            local('Industry-Medium'), local('Industry Medium'),
            url(${IndustryMediumEot}?#iefix) format('embedded-opentype'),
            url(${IndustryMediumWoff}) format('woff'),
            url(${IndustryMediumTtf}) format('truetype');
        `
    },
    bold: {
        fontFamily: 'Industry',
        fontStyle: 'normal',
        fontWeight: 500,
        src: `
            local('Industry-Bold'), local('Industry Bold'),
            url(${IndustryBoldEot}?#iefix) format('embedded-opentype'),
            url(${IndustryBoldWoff}) format('woff'),
            url(${IndustryBoldTtf}) format('truetype');
        `
    }
};

export { Industry };
