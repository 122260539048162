import React, { FC, ReactNode, useState } from 'react';
import { IonList, IonItem, IonLabel, IonIcon } from '@ionic/react';
import { filter } from 'ionicons/icons';
import { FormattedMessage } from 'react-intl';
import classes from './FilterList.module.css';
import classNames from 'classnames';

interface FilterListProps {
	filterValues?: { id: string; defaultMessage: string }[] | any;
	children?: ReactNode;
	onChange: any;
	selected?: string;
}

const FilterList: FC<FilterListProps> = ({ filterValues, children, onChange, selected }) => {
	const [selectedFilter, setSelectedFilter] = useState<string | undefined>(selected);
	const onFilterChange = (id: string) => {
		setSelectedFilter(id);
		onChange(id);
	};
	return (
		<IonList className={classes.filterList} lines="none">
			<IonItem key="filter">
				<IonIcon color="primary" size="small" align-items="left" icon={filter} />
				<IonLabel color="primary" className={classes.filterLb}>
					<FormattedMessage id="Filter" />
				</IonLabel>
			</IonItem>
			{children}
			{filterValues
				? filterValues.map((item: any, index: number) =>
						item.id !== undefined ? (
							<IonItem
								className={
									selectedFilter === item.id
										? classNames(classes.filterItem, classes.selected)
										: classes.filterItem
								}
								key={index}
								button
								onClick={() => onFilterChange(item.id)}
							>
								<IonLabel>
									<FormattedMessage {...item} />
								</IonLabel>
							</IonItem>
						) : item ? (
							item.map((element: string, ind: number) => (
								<IonItem
									className={
										selectedFilter === element
											? classNames(classes.filterItem, classes.selected)
											: classes.filterItem
									}
									key={'name' + ind}
									button
									onClick={() => onFilterChange(element)}
								>
									<IonLabel>{element}</IonLabel>
								</IonItem>
							))
						) : null
				  )
				: null}
		</IonList>
	);
};

FilterList.defaultProps = {
	selected: 'all',
};

export default FilterList;
