import React, { useCallback, useEffect, useRef, useState } from 'react';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import Slider from '../slider';

import {
	SET_DRIVING_MODE_STATUS,
	SET_LOCAL_VOICE_VOLUME,
	SET_NAV_CAMERA_HEIGHT,
	SET_NAV_SPEED,
} from '../../actions/types';
import { AppRootState } from '../../../../../reducers';
import useHover from '../../utils/useHover';
import { PrimaryCameraState, RobotPrimaryCamera } from '../../types';
import { NAV_CAMERA_HIGH } from '../../pages/session/videos/navigationVideo';
import Tooltip from '../tooltip';

function reduxStateSelector(state: AppRootState) {
	return {
		dataChannel1: state.goBeState.sessionState.dataChannel1,
		localVoiceVolume: state.goBeState.sessionState.localVoiceVolume,
		navSpeed: state.goBeState.sessionState.navSpeed,
		hideNavOptionsStatus: state.goBeState.sessionState.hideNavOptionsStatus,
		microphoneLevel: (state.hardwareState as any).settings.microphoneVolume,
		drivingMode: state.goBeState.sessionState.drivingMode,
		navCameraHeight: state.goBeState.sessionState.navCameraHeight,
	};
}

type SessionOptionsProps = {
	togglePrimaryCamera: () => void;
	primaryCameraState: PrimaryCameraState;
	isSuperZoom1Enabled: boolean;
	onClickHangUp: () => void;
	localStream: MediaStream | null;
	hasPrimaryVideoStartedPlaying: boolean;
};

const NextCameraActionIcons: Record<RobotPrimaryCamera, string> = {
	[RobotPrimaryCamera.WIDE_CAM]: '../../assets/images/zoom-in.svg',
	[RobotPrimaryCamera.ZOOM_CAM]: '../../assets/images/zoom-out.svg',
};

/**
 * Returns a function that mimics Meisam's 'God' dispatch-thunk located at src/SubApp/gb_operator_module/src/actions/setParam.ts
 */
const buildParamSetter = (dispatch: Dispatch<any>) => (
	param: string,
	type: string,
	payload?: unknown
) => dispatch({ type, payload: { [param]: payload } });

export default function SessionOptions({
	localStream,
	togglePrimaryCamera,
	primaryCameraState,
	isSuperZoom1Enabled,
	onClickHangUp,
	hasPrimaryVideoStartedPlaying,
}: SessionOptionsProps) {
	const {
		dataChannel1,
		localVoiceVolume,
		navSpeed,
		hideNavOptionsStatus,
		microphoneLevel,
		drivingMode,
		navCameraHeight,
	} = useSelector(reduxStateSelector);
	const dispatch = useDispatch();
	const setParameter = useCallback(buildParamSetter(dispatch), [dispatch]);

	const localVoiceRef = useRef<any>(null);
	const localVoiceStatusRef = useRef<any>(null);
	const [localVoiceValue, changeLocalVoiceValue] = useState<any>('19');

	const onLocalVoiceChange = (value: number) => {
		setParameter('localVoiceVolume', SET_LOCAL_VOICE_VOLUME, value);
		changeLocalVoiceValue(value);
		if (dataChannel1 && dataChannel1.readyState === 'open') {
			dataChannel1.send(`VOL ${value}`);
		}
	};

	useEffect(() => {
		if (microphoneLevel) {
			setParameter('localVoiceVolume', SET_LOCAL_VOICE_VOLUME, microphoneLevel);
			changeLocalVoiceValue(microphoneLevel);
		}
	}, [microphoneLevel, setParameter]);

	const onLocalVoiceIconClick = () => {
		if (hasPrimaryVideoStartedPlaying && dataChannel1 && dataChannel1.readyState === 'open') {
			if (localVoiceVolume === '0') {
				if (localVoiceValue === '0') {
					if (dataChannel1 && dataChannel1.readyState === 'open') {
						dataChannel1.send(`VOL 19`);
					}
					setParameter('localVoiceVolume', SET_LOCAL_VOICE_VOLUME, '19');
				} else {
					if (dataChannel1 && dataChannel1.readyState === 'open') {
						dataChannel1.send(`VOL ${localVoiceValue}`);
					}
					setParameter('localVoiceVolume', SET_LOCAL_VOICE_VOLUME, localVoiceValue);
				}
			} else {
				if (dataChannel1 && dataChannel1.readyState === 'open') {
					dataChannel1.send(`VOL 0`);
				}
				setParameter('localVoiceVolume', SET_LOCAL_VOICE_VOLUME, '0');
			}
		}
	};

	const onNavSpeedChange = (value: number) => {
		setParameter('navSpeed', SET_NAV_SPEED, value);
	};

	useEffect(() => {
		if (localStream) {
			if (parseInt(localVoiceVolume) === 0) {
				localStream.getAudioTracks()[0].enabled = false;
			} else {
				localStream.getAudioTracks()[0].enabled = true;
			}
		}
	}, [localStream, localVoiceVolume]);

	const renderPrimaryCameraToggleButton = (
		primaryCameraState: PrimaryCameraState,
		togglePrimaryCamera: () => void,
		disabled: boolean
	) => {
		const isSwitchingPrimaryCamera = primaryCameraState.isChangingPrimaryCameraTo !== null;
		const isDisabled = isSwitchingPrimaryCamera || disabled;

		return (
			<div className={isDisabled ? 'circleRectangleDisabled' : 'blackCircle'}>
				<div
					className="iconWrapper zoomWrapper"
					onClick={isDisabled ? undefined : togglePrimaryCamera}
				>
					<img
						src={NextCameraActionIcons[primaryCameraState.currentPrimaryCamera]}
						alt=""
					/>
				</div>
			</div>
		);
	};

	const navVideoStatus = true;
	const [navVideoSpeedRef, isNavVideoSpeedHovered] = useHover<HTMLDivElement>();
	const isSessionOpen =
		hasPrimaryVideoStartedPlaying && dataChannel1 && dataChannel1.readyState === 'open';

	const handleToggleDrivingMode = () => {
		if (isSessionOpen) {
			setParameter('navCameraHeight', SET_NAV_CAMERA_HEIGHT, NAV_CAMERA_HIGH);
			setParameter('drivingMode', SET_DRIVING_MODE_STATUS, !drivingMode);
		}
	};

	return (
		<div
			style={
				drivingMode
					? {
							bottom: `${(navCameraHeight - NAV_CAMERA_HIGH) / 2}px`,
							right: `calc(50% - ${(navCameraHeight * 0.75) / 2 + 60}px)`,
					  }
					: undefined
			}
			className={
				hideNavOptionsStatus
					? navVideoStatus
						? 'sessionOptionsContainer'
						: 'sessionOptionsContainer sessionOptionsHideNav'
					: 'displayNone'
			}
		>
			<Tooltip
				text={isSessionOpen ? (drivingMode ? 'Meeting View' : 'Driving View') : undefined}
			>
				<div
					className={
						isSessionOpen ? 'blackCircle blackCircleOpacity' : 'circleRectangleDisabled'
					}
					onClick={handleToggleDrivingMode}
				>
					<div className="iconWrapper">
						{drivingMode ? (
							<img src="../../assets/images/meeting-view.svg" alt="" />
						) : (
							<img src="../../assets/images/driving-view.svg" alt="" />
						)}
					</div>
				</div>
			</Tooltip>

			<div
				className={
					isSessionOpen && primaryCameraState.currentPrimaryCamera === 'wide_cam'
						? 'circleRectangle '
						: 'circleRectangleDisabled'
				}
				ref={navVideoSpeedRef}
			>
				<div className="iconWrapper">
					<img src="../../assets/images/white-speed.svg" alt="" />
				</div>
				<div
					className={
						isSessionOpen &&
						primaryCameraState.currentPrimaryCamera === 'wide_cam' &&
						isNavVideoSpeedHovered
							? 'speedControllerContainer'
							: 'displayNone'
					}
				>
					<div className="navSpeedText">
						{(0.6 + (2.6 * parseInt(navSpeed)) / 100).toString().slice(0, 3)}
						<span>km/h</span>
					</div>
					<div className="rectangleNav">
						<Slider
							onChange={onNavSpeedChange}
							value={navSpeed}
							icon="speed-green.svg"
							id="navVideoSpeed"
						/>
					</div>
				</div>
			</div>

			{isSuperZoom1Enabled
				? renderPrimaryCameraToggleButton(
						primaryCameraState,
						togglePrimaryCamera,
						!isSessionOpen
				  )
				: null}

			<div
				className={isSessionOpen ? 'circleRectangle ' : 'circleRectangleDisabled'}
				ref={localVoiceStatusRef}
			>
				<div className="iconWrapper" onClick={onLocalVoiceIconClick}>
					<img
						src={`../../assets/images/${
							parseInt(localVoiceVolume) === 0 ? 'mic-off-red.svg' : 'mic.svg'
						}`}
						alt=""
					/>
				</div>

				<div className="localVoiceText">{localVoiceVolume}%</div>
				<div className="rectangleLocalVoice" ref={localVoiceRef}>
					<Slider
						onChange={onLocalVoiceChange}
						value={localVoiceVolume}
						icon={
							parseInt(localVoiceVolume) === 0
								? 'mic-off-red.svg'
								: 'mic-off-green.svg'
						}
						id="localVideoVolume"
					/>
				</div>
			</div>
			<Tooltip text={'Pause/End'}>
				<div className="redCircle blackCircle" onClick={onClickHangUp}>
					<div className="iconWrapper">
						<img src="../../assets/images/white-stop.svg" alt="" />
					</div>
				</div>
			</Tooltip>
		</div>
	);
}
