import React, { FC, useCallback, useState, useEffect, useRef } from 'react';
import { IonItem, IonLabel, IonInput, IonText, IonIcon } from '@ionic/react';
import classes from './FormInputListItem.module.css';
import classNames from 'classnames';
import { Controller } from 'react-hook-form';

interface FormInputListItemProps {
	name: string;
	type:
		| 'number'
		| 'time'
		| 'text'
		| 'tel'
		| 'url'
		| 'email'
		| 'search'
		| 'date'
		| 'password'
		| undefined;
	autocomplete?: 'on' | 'off' | 'new-password';
	defaultValue?: any;
	value?: string;
	label: string;
	placeholderText?: string;
	onIonChange?: (e: any) => void;
	onChange?: (e: any) => void;
	onKeyPress?: (e: any) => void;
	control?: any;
	rules?: any;
	required?: boolean;
	errorMsg?: any;
	minLength?: number;
	maxLength?: number;
	disabled?: boolean;
	iconUrl?: string;
	iconCallback?: (e: any) => void;
	pattern?: string;
	title?: string;
	className?: string;
	dataCy?: string;
}
const FormInputListItem: FC<FormInputListItemProps> = ({
	name,
	type,
	autocomplete,
	defaultValue,
	value,
	label,
	placeholderText,
	onIonChange,
	onChange,
	onKeyPress,
	control,
	rules,
	required,
	errorMsg,
	minLength,
	maxLength,
	disabled,
	iconUrl,
	iconCallback,
	pattern,
	title,
	className,
	dataCy,
}) => {
	const [hasFocus, setHasFocus] = useState(false);
	const [hasError, setHasError] = useState(false);
	const [mounted, setMounted] = useState(false);

	const IonInputRef = useRef<HTMLIonInputElement>(null);

	useEffect(() => {
		if (!IonInputRef.current || autocomplete !== 'new-password') {
			return;
		}
		IonInputRef.current.getInputElement().then((el: HTMLInputElement) => {
			el.setAttribute('data-lpignore', '');
		});
	});

	const onInputFocus = useCallback(
		(e: React.FocusEvent<HTMLIonInputElement>) => {
			const inputElement = e.currentTarget as HTMLIonInputElement;
			inputElement.getInputElement().then((el: HTMLInputElement) => {
				el.placeholder = placeholderText ? placeholderText : '';
			});
			setMounted(true);
			setHasFocus(true);
			setHasError(errorMsg ? true : false);
		},
		[placeholderText, errorMsg]
	);

	const onInputBlur = useCallback((e: React.FocusEvent<HTMLIonInputElement>) => {
		const inputElement = e.currentTarget as HTMLIonInputElement;
		inputElement.getInputElement().then((el: HTMLInputElement) => {
			el.placeholder = '';
		});
		setMounted(true);
		setHasFocus(false);
		setHasError(false);
	}, []);

	useEffect(() => {
		setHasError(errorMsg ? true : false);
	}, [errorMsg]);

	useEffect(() => {
		if (onChange) setMounted(true);
		return function cleanup() {
			setMounted(false);
		};
	}, [onChange]);

	return (
		<div className={className ? className : classes.formItemContainer}>
			<IonItem
				className={
					errorMsg ? classNames(classes.formItem, classes.errorItem) : classes.formItem
				}
				data-cy={dataCy}
			>
				<IonLabel
					position="floating"
					className={
						hasError && hasFocus
							? classes.colorError
							: hasFocus
							? classes.colorFocused
							: classes.lbColor
					}
				>
					{label}
					{required ? (
						<IonText className={hasError && hasFocus ? classes.colorError : ''}>
							*
						</IonText>
					) : null}
				</IonLabel>
				<div className={classes.inputIcon}>
					<Controller
						as={
							<IonInput
								type={type}
								ref={IonInputRef}
								// @ts-ignore
								autocomplete={autocomplete}
								onFocus={onInputFocus}
								onBlur={onInputBlur}
								onKeyPress={onKeyPress}
								onIonChange={onIonChange}
								autofocus
								minlength={minLength}
								maxlength={maxLength}
								disabled={disabled}
								pattern={pattern}
								title={title ? title : ''}
							/>
						}
						control={control}
						onChangeName="onIonChange"
						onChange={([selected]) => {
							if (!mounted) return;
							onChange ? onChange(selected) : console.log();
							// in case using google to remember login credentials, detail.value is not enough.
							return selected.detail.value || selected.target.children[0]?.value;
						}}
						name={name}
						defaultValue={defaultValue}
						rules={rules}
						value={value}
					/>
					{iconUrl ? (
						<IonIcon
							icon={iconUrl}
							className={classes.inputIconSize}
							onClick={iconCallback}
						/>
					) : (
						''
					)}
				</div>
			</IonItem>
			<IonText className={classes.errorMsg}>{errorMsg}</IonText>
		</div>
	);
};

export default FormInputListItem;
