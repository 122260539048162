import { Device } from '../types/types';
import { createSelector } from 'reselect';
import { AppRootState } from '../reducers';
import { toCamelCase, toLowerCaseFirstLetter } from './snakeCaseToCamelCase';

/*
 */
const getRobotsBasicInfo = (robots: Array<Device>) => {
	return robots.map(robot => ({
		name: robot.name,
		deviceId: robot.deviceId,
		orgId: robot.orgId,
		deviceType: robot.deviceType,
		deviceGroupsIds: robot.deviceGroupsIds,
	}));
};

const getRobotsTableInfo = (robots: Array<Device>) => {
	return robots.map(robot => ({
		name: robot.name,
		deviceId: robot.deviceId,
		location: robot.location,
		state: robot.status,
		battery: robot.battery
			? robot.battery
			: robot.status
			? JSON.parse(robot.status)?.battery
			: null,
		level: robot?.battery?.level
			? robot.battery.level
			: robot.status
			? JSON.parse(robot.status)?.battery.level
			: null,
		online: robot?.online
			? robot.online
			: robot.status
			? JSON.parse(robot.status)?.online
			: false,
		currentState: robot?.currentState
			? toLowerCaseFirstLetter(robot.currentState)
			: robot.status
			? robot.online
				? toLowerCaseFirstLetter(JSON.parse(robot.status)?.currentState)
				: 'offline'
			: 'offline',
		orgId: robot.orgId,
		deviceType: robot.deviceType,
		deviceGroupsIds: robot.deviceGroupsIds,
		deviceGroupName: robot?.deviceGroupName ? robot.deviceGroupName : 'Unassigned',
	}));
};

/*
 */
const getAllRobotsBasicInfo = (state: any, orgId?: string, tableInfo?: any) => {
	let deviceByOrg = state.deviceState?.devicesByOrganizationId as Record<
		any,
		Record<any, Device>
	>;
	if (!deviceByOrg) return [];
	if (orgId) deviceByOrg = deviceByOrg[orgId];
	if (!deviceByOrg || (deviceByOrg && Object.keys(deviceByOrg).length === 0)) return [];
	const devicesArray = Object.values<Record<any, Device>>(deviceByOrg)
		.map(devicesMap => {
			if (tableInfo) {
				// here get the robotgroupList and get the robotgroup name
				let deviceGroupByOrg = state.deviceGroupsState?.deviceGroupsByOrganizationId;
				if (devicesMap?.deviceGroupsIds) {
					let id = devicesMap.deviceGroupsIds[0];
					devicesMap.deviceGroupName =
						(orgId && deviceGroupByOrg[orgId][id]?.name) || null;
				}
			}
			return orgId ? (devicesMap as Device) : Object.values<Device>(devicesMap);
		})
		.flat();
	if (tableInfo) {
		return getRobotsTableInfo(devicesArray);
	}
	return getRobotsBasicInfo(devicesArray);
};

/*
 */
export const getDevices = createSelector([getAllRobotsBasicInfo], devices => {
	return devices;
});

const getSpecificEntityInfo = (
	state: AppRootState,
	entityState: keyof AppRootState,
	identifiers: { entityId?: string; orgId: string; propertyOptions: Array<string> }
) => {
	let { entityId, orgId, propertyOptions } = identifiers;
	let entity: Record<string, any> = {};
	let singleEntity: Record<string, any>;
	switch (entityState) {
		case 'deviceState':
			singleEntity = state[entityState].devicesByOrganizationId[orgId][entityId!];
			break;
		case 'usersState':
			singleEntity = state[entityState].usersByOrganizationId[orgId][entityId!];
			break;
		case 'userGroupsState':
			singleEntity = state[entityState].userGroupsByOrganizationId[orgId!][entityId!];
			break;
		case 'deviceGroupsState':
			singleEntity = state[entityState].deviceGroupsByOrganizationId[orgId][entityId!];
			break;
		case 'organizationState':
			singleEntity = state[entityState].organizations[orgId!];
			break;
		case 'accountState':
			singleEntity = state[entityState].user.devices[toCamelCase(entityId!)];
			break;
		default:
			break;
	}

	try {
		propertyOptions.forEach(key => {
			if (!singleEntity) return entity;
			else entity[key] = singleEntity[key];
		});
	} catch (error) {
		console.log(error);
	}

	return entity;
};

/*
 */
export const getStateEntityByOrgId = createSelector([getSpecificEntityInfo], entity => {
	return entity;
});

/*
 */
export const equalityFnc = (prevState: any, nextState: any, shouldUpdate = true) => {
	if (!shouldUpdate) return true;
	return (
		JSON.stringify(prevState, getCircularReplacer()) ===
		JSON.stringify(nextState, getCircularReplacer())
	);
};

// circular Json Error
export const getCircularReplacer = () => {
	const seen = new WeakSet();
	return (key: any, value: any) => {
		if (typeof value === 'object' && value !== null) {
			if (seen.has(value)) {
				return;
			}
			seen.add(value);
		}
		return value;
	};
};
