import React, { FC, useState, useEffect } from 'react';
import { IonGrid, IonRow, IonList, IonItem, IonLabel, IonListHeader, IonCol } from '@ionic/react';

import isAuthenticated from '../../components/Authentication/Authenticated';
import { injectIntl, FormattedMessage } from 'react-intl';
import Messages from './RobotForm.messages';

import classes from './RobotForm.module.css';
import classNames from 'classnames';
import { useTypedSelector } from '../../reducers';
import { getStateEntityByOrgId, equalityFnc } from '../../utils/conformState';

type Networks = {
	wifi: {
		connected: 'No';
		ssid: '';
		mac: '';
		ip: '';
	};
	lte: {
		connected: 'No';
		ssid: '';
		mac: '';
		ip: '';
	};
	gobe: {
		ip: '';
	};
};

interface RobotFormProps {
	deviceId: any;
	isEditable?: boolean;
	saved: any;
}

const RobotNetwork: FC<RobotFormProps> = props => {
	const { isEditable, deviceId } = props;

	const selectedOrganizationId = useTypedSelector(
		state => state.selectedOrganizationState.organization.orgId
	);
	const device = useTypedSelector(
		state =>
			getStateEntityByOrgId(state, 'deviceState', {
				entityId: deviceId,
				orgId: selectedOrganizationId,
				propertyOptions: ['settings'],
			}),
		(left, right) => equalityFnc(left, right)
	);

	const [deviceSettings, setDeviceSettings] = useState<Networks>(
		JSON.parse(device.settings || null)
	);

	useEffect(() => {
		if (device.settings) {
			setDeviceSettings(JSON.parse(device.settings));
		}
	}, [device.settings]);

	return (
		<IonGrid className={classes.formGrid}>
			<IonRow>
				<IonList className="ion-padding">
					<IonListHeader>
						<IonLabel>
							<FormattedMessage {...Messages.robotNetwork} />
						</IonLabel>
					</IonListHeader>
					<IonRow>
						<IonCol size="6">
							<IonItem disabled={!isEditable}>
								<IonLabel
									position="fixed"
									className={classNames(classes.subTitleLb, classes.formLb)}
								>
									<FormattedMessage {...Messages.wifi} />:
								</IonLabel>
							</IonItem>
							<IonItem disabled>
								<IonLabel position="fixed" className={classes.formLb}>
									<FormattedMessage {...Messages.connected} />:
								</IonLabel>
								<IonLabel className={classes.inputLb}>
									{deviceSettings?.wifi?.connected || 'No'}
								</IonLabel>
							</IonItem>
							<IonItem disabled>
								<IonLabel position="fixed" className={classes.formLb}>
									<FormattedMessage {...Messages.ssid} />:
								</IonLabel>
								<IonLabel className={classes.inputLb}>
									{deviceSettings?.wifi?.ssid || 'N/A'}
								</IonLabel>
							</IonItem>
							<IonItem disabled>
								<IonLabel position="fixed" className={classes.formLb}>
									<FormattedMessage {...Messages.mac} />:
								</IonLabel>
								<IonLabel className={classes.inputLb}>
									{deviceSettings?.wifi?.mac || 'N/A'}
								</IonLabel>
							</IonItem>
							<IonItem disabled>
								<IonLabel position="fixed" className={classes.formLb}>
									<FormattedMessage {...Messages.ip} />:
								</IonLabel>
								<IonLabel className={classes.inputLb}>
									{deviceSettings?.wifi?.ip || 'N/A'}
								</IonLabel>
							</IonItem>
							<IonItem disabled={!isEditable}>
								<IonLabel
									position="fixed"
									className={classNames(classes.subTitleLb, classes.formLb)}
								>
									<FormattedMessage {...Messages.lte} />:
								</IonLabel>
							</IonItem>
							<IonItem disabled>
								<IonLabel position="fixed" className={classes.formLb}>
									<FormattedMessage {...Messages.connected} />:
								</IonLabel>
								<IonLabel className={classes.inputLb}>
									{deviceSettings?.lte?.connected || 'No'}
								</IonLabel>
							</IonItem>
							<IonItem disabled>
								<IonLabel position="fixed" className={classes.formLb}>
									<FormattedMessage {...Messages.ssid} />:
								</IonLabel>
								<IonLabel className={classes.inputLb}>
									{deviceSettings?.lte?.ssid || 'N/A'}
								</IonLabel>
							</IonItem>
							<IonItem disabled>
								<IonLabel position="fixed" className={classes.formLb}>
									<FormattedMessage {...Messages.mac} />:
								</IonLabel>
								<IonLabel className={classes.inputLb}>
									{deviceSettings?.lte?.mac || 'N/A'}
								</IonLabel>
							</IonItem>
							<IonItem disabled>
								<IonLabel position="fixed" className={classes.formLb}>
									<FormattedMessage {...Messages.ip} />:
								</IonLabel>
								<IonLabel className={classes.inputLb}>
									{deviceSettings?.lte?.ip || 'N/A'}
								</IonLabel>
							</IonItem>
						</IonCol>
						<IonCol size="6">
							<IonItem disabled={!isEditable}>
								<IonLabel
									position="fixed"
									className={classNames(classes.subTitleLb, classes.formLb)}
								>
									<FormattedMessage {...Messages.goBeServer} />:
								</IonLabel>
							</IonItem>
							<IonItem disabled>
								<IonLabel position="fixed" className={classes.formLb}>
									<FormattedMessage {...Messages.ip} />:
								</IonLabel>
								<IonLabel className={classes.inputLb}>
									{deviceSettings?.gobe?.ip || 'N/A'}
								</IonLabel>
							</IonItem>
						</IonCol>
					</IonRow>
				</IonList>
			</IonRow>
		</IonGrid>
	);
};

export default injectIntl(isAuthenticated(RobotNetwork, 'RobotNetwork'));
