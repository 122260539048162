import {
	IonRow,
	IonCol,
	IonButton,
	IonIcon,
	IonLabel,
	IonGrid,
	IonList,
	IonListHeader,
} from '@ionic/react';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { setParameter } from '../../actions/setParam';
// Styling
import classes from './AddNewRobot.module.css';
// custom components
import isAuthenticated from '../Authentication/Authenticated';
import AddNewRobotForm from './AddNewRobotForm';
import Messages from './AddNewRobotModal.messages';
// icons
import { publish } from '../../actions/publish';
import { useForm } from 'react-hook-form';
import { useTypedSelector } from '../../reducers';
import { b64EncodeUnicode } from '../../utils/encoding';
import { checkRobotPassword } from '../../utils/validator';
import BasicModal from '../BasicModal/BasicModal';
const addRobotIcon = './assets/icons/robot-add.svg';

const AddNewRobot: React.FC = (props: any) => {
	const { selectedOrganization } = props;
	const { handleSubmit, control, watch, errors, reset, setError } = useForm();
	const username = useTypedSelector(state => state.accountState.user.username);
	const spinoutType = useTypedSelector(state => state.versionState.spinoutType);
	let encodedUser = b64EncodeUnicode(username);

	const selectedOrganizationId = useTypedSelector(
		state => state.selectedOrganizationState.organization.orgId
	);
	const devices = useTypedSelector(state => state.deviceState.devicesByOrganizationId);
	const [deviceVerifyNotification, setDeviceVerifyNotification] = useState<any>({});

	const handleDeviceNotification = useCallback(notification => {
		setDeviceVerifyNotification({
			domain: notification.domain || '',
			message: notification.message || '',
			status: notification?.status || 'error',
		});
		if (notification?.status == 'error') {
			if (notification.domain == 'Duplicate Serial Number') {
				setError('serialNumberUnique', {
					type: 'manual',
					message: 'error',
				});
				window.removeEventListener('deviceCreate', handleCustomEventListenerDevice);
				props.onError();
			}
		}
		setTimeout(() => {
			setDeviceVerifyNotification({});
		}, 5000);
	}, []);

	useEffect(() => {
		if (deviceVerifyNotification) {
			if (deviceVerifyNotification?.status == 'success') {
				props.onDismiss();
				reset();
			}
		}
	}, [deviceVerifyNotification]);

	const handleCustomEventListenerDevice = useCallback(event => {
		handleDeviceNotification((event as CustomEvent)?.detail || {});
	}, []);

	useEffect(() => {
		window.addEventListener('deviceCreate', handleCustomEventListenerDevice);
	}, [handleDeviceNotification]);

	const isDeviceNameTaken = (deviceName: string) => {
		if (devices[selectedOrganizationId]) {
			let devicesByOrg = devices[selectedOrganizationId];
			let isTaken = false;
			Object.values(devicesByOrg).forEach(element => {
				if (element.name === deviceName) isTaken = true;
			});
			return isTaken;
		} else return;
	};

	const onUpdate = handleSubmit(data => {
		data.name = data.name.trim();
		if (isDeviceNameTaken(data.name)) {
			setError('nameUnique', {
				type: 'manual',
				message: 'error',
			});
			return;
		}
		if (spinoutType !== 'uvd') {
			if (!checkRobotPassword(data.robotPassword)) return;
			const dataObj = {
				name: data.name,
				location: data.location || null,
				deviceId: data.serialNumber,
				orgId: selectedOrganization.orgId,
				deviceSaId: data.saId,
				deviceAuthId: data.authId,
				password: data.robotPassword,
			};

			publish(`microservice/${dataObj.orgId}/${encodedUser}/registerDevice`, {
				requestId: 'someId',
				data: {
					name: dataObj.name,
					orgId: dataObj.orgId,
					location: dataObj.location,
					deviceId: dataObj.deviceId,
					deviceSaId: dataObj.deviceSaId,
					deviceAuthId: dataObj.deviceAuthId,
					devicePassword: dataObj.password,
					deviceType: 'gobe1',
				},
			});
		} else {
			const dataObj = {
				name: data.name,
				location: data.location || null,
				deviceId: data.serialNumber,
				orgId: selectedOrganization.orgId,
			};

			publish(`microservice/${dataObj.orgId}/${encodedUser}/registerDevice`, {
				requestId: 'someId',
				data: {
					name: dataObj.name,
					orgId: dataObj.orgId,
					location: dataObj.location,
					deviceId: dataObj.deviceId,
					deviceType: 'uvd',
				},
			});
		}
		props.onDismiss();
		reset();
	});

	return (
		<BasicModal open={props.isOpen} onClose={props.onDismiss}>
			<form onSubmit={onUpdate} className={classes.addForm}>
				<IonList>
					<IonListHeader className={classes.modalHeader} lines="none">
						<div className={classes.headerContainer}>
							<IonLabel color="primary" className={classes.headerTitle}>
								<FormattedMessage {...Messages.title} />
							</IonLabel>
						</div>
					</IonListHeader>
				</IonList>
				<IonGrid className={classes.modalContent}>
					<IonRow>
						<AddNewRobotForm watch={watch} control={control} errors={errors} />
					</IonRow>
					<IonRow className={classes.btnRow}>
						<IonCol>
							<IonButton
								className={classes.cancelBtn}
								expand="block"
								shape="round"
								size="large"
								fill="outline"
								onClick={props.onDismiss}
							>
								<FormattedMessage {...Messages.cancel} />
							</IonButton>
						</IonCol>
						<IonCol className={classes.submitCol}>
							<IonButton
								id="add-robot-save-btn"
								expand="block"
								shape="round"
								type="submit"
								size="large"
								fill="outline"
								data-cy="create_robot_submit"
							>
								<FormattedMessage {...Messages.save} />
							</IonButton>
						</IonCol>
					</IonRow>
				</IonGrid>
			</form>
		</BasicModal>
	);
};

const mapStateToProps = (state: any) => ({
	client: state.mqttState.client,
	selectedOrganization: state.selectedOrganizationState.organization,
});

export default injectIntl(
	isAuthenticated(connect(mapStateToProps, { setParameter })(AddNewRobot), 'AddNewRobot')
);
