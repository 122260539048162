import { defineMessages } from 'react-intl';

export default defineMessages({
	title: {
		id: 'OrganizationSetup.title',
		defaultMessage: 'Organization Set Up',
		description: 'Organization Set Up title',
	},
	deleteTitle: {
		id: 'deleteTitle',
		defaultMessage: 'Delete this {item}',
		description: 'Delete this {item}',
	},
	account: {
		id: 'account',
		defaultMessage: 'Account',
		description: 'Account tab',
	},
	organization: {
		id: 'AccountManagementPage.organization',
		defaultMessage: 'Organization',
		description: 'Organization tab',
	},
	robots: {
		id: 'Menu.RobotsTitle',
		defaultMessage: 'Robots',
		description: 'Robots tab',
	},
	robotGroups: {
		id: 'InviteModal.deviceGroups',
		defaultMessage: 'Robot groups',
		description: 'Robot groups tab',
	},
	userGroups: {
		id: 'UsersPage.userGroups',
		defaultMessage: 'User groups',
		description: 'User groups tab',
	},
	profile: {
		id: 'AccountSetup.profile',
		defaultMessage: 'Profile',
		description: 'Profile',
	},
	notifications: {
		id: 'AccountSetup.notifications',
		defaultMessage: 'Notifications',
		description: 'Notifications',
	},
	organizationDetails: {
		id: 'Organizations.details',
		defaultMessage: 'Organization details',
		description: 'Organization details',
	},
	billingShipping: {
		id: 'Organizations.billingShipping',
		defaultMessage: 'Billing & Shipping',
		description: 'Billing & Shipping',
	},
});
