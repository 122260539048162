import React, { FC } from 'react';

import IframeResizer from 'iframe-resizer-react';

interface AgreementProps {
	agreement: { value: string; description: string; message: string };
}

const Frame: FC<AgreementProps> = props => {
	const { agreement } = props;
	const hostName = window.location.protocol + '//' + window.location.host + '/';
	return (
		<IframeResizer
			title={agreement.value}
			id={agreement.value}
			checkOrigin={false}
			style={{ width: '1px', minWidth: '100%', border: '0px' }}
			src={
				'data:text/html;charset=utf-8;http-equiv=content-type,' +
				escape(
					agreement.description
						.replace('72pt 72pt 72pt 72pt', '0')
						.replace(
							/<\/head>/g,
							`<script src='` +
								hostName +
								`iFrameResizer.contentWindow.js'></script></head>`
						)
						.replace(/<body /g, `<body id="${'body-' + agreement.value}" `)
						.replace(/href/g, ' target="_blank" href')
				)
			}
		/>
	);
};

export default Frame;
