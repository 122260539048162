import { defineMessages } from 'react-intl';

export default defineMessages({
	hint: {
		id: 'ForgotPasswordPage.welcome',
		defaultMessage:
			"Please provide your email address and we'll send you \r\n instructions on how to change your password.",
		description:
			"Please provide your email address and we'll send you \r\n instructions on how to change your password.",
	},
	backToLogin: {
		id: 'ForgotPasswordPage.backToLogin',
		defaultMessage: 'Back to login',
		description: 'Back to login',
	},
	reset: {
		id: 'ForgotPasswordPage.reset',
		defaultMessage: 'Reset password',
		description: 'Reset password',
	},
});
