import { defineMessages } from 'react-intl';

export default defineMessages({
	robotGroupDetails: {
		id: 'RobotSetupDetail.details',
		defaultMessage: 'Robot Group Details',
		description: 'Robot Group Details',
	},

	robotGroupName: {
		id: 'RobotGroupSetupDetails.name',
		defaultMessage: 'Group Name',
		description: 'Group Name',
	},

	robotLocation: {
		id: 'RobotSetupDetails.location',
		defaultMessage: 'Robot Location ',
		description: 'Robot Location',
	},

	robotTimeZone: {
		id: 'RobotSetupDetails.timeZone',
		defaultMessage: 'Robot time zone',
		description: 'Robot time zone',
	},

	createdOn: {
		id: 'RobotSetupDetails.createdOn',
		defaultMessage: 'Created on',
		description: 'Created on',
	},
	upload: {
		id: 'AccountManagementPage.upload',
		defaultMessage: 'Upload Picture',
		description: 'Upload Picture',
	},
	web: {
		id: 'AccountManagementPage.web',
		defaultMessage: 'From The Web',
		description: 'From The Web',
	},

	addRobots: {
		id: 'DeviceGroups.addRobots',
		defaultMessage: 'Add robots',
		description: 'Add robots',
	},

	createNewGroup: {
		id: 'RobotGroupSetupDetails.creatNewGroup',
		defaultMessage: 'Create New Robot Group',
		description: 'Create New Robot Group',
	},

	createGroup: {
		id: 'RobotGroups.createGroup',
		defaultMessage: 'Create robot group',
		description: 'Create robot group',
	},
	createGroupHint: {
		id: 'RobotGroups.createGroupHint',
		defaultMessage: 'Please complete to create new robot group',
		description: 'Please complete to create new robot group',
	},

	editGroupModal: {
		deleteTitle: {
			id: 'deleteTitle',
			defaultMessage: 'Delete this {item}',
			description: 'Delete this {item}',
		},
		addRobots: {
			id: 'DeviceGroups.addRobots',
			defaultMessage: 'Add robots',
			description: 'Add robots',
		},
		// addRobotsHint: {
		// 	id: 'DeviceGroups.addRobotsHint',
		// 	defaultMessage: 'Please choose robots to add to {group}',
		// 	description: 'Please choose robots to add to group',
		// },
		hasRobots: {
			id: 'UserGroups.hasRobots',
			defaultMessage: 'Has {number} robots',
			description: 'Number of robots in device group',
		},
	},
	addToGroupModal: {
		addToGroupsHint: {
			id: 'RobotsGroup.addToGroupsHint',
			defaultMessage: 'Please choose robots to add to {group}',
			description: 'Please choose robots for the selected group',
		},
	},
	created: {
		id: 'UserGroups.created',
		defaultMessage: '{name} has been created',
		description: 'A new user group has been created',
	},
	justCreated: {
		id: 'UserGroups.justCreated',
		defaultMessage: 'New',
		description: 'A new device group has just been created',
	},
	yearsAgo: {
		id: 'UserGroups.yearsAgo',
		defaultMessage: 'years ago',
		description: 'years ago',
	},

	availableRobots: {
		id: 'RobotGroups.availableRobots',
		defaultMessage: 'Available robots',
		description: 'Available robots',
	},

	selectedRobots: {
		id: 'RobotGroups.selectedRobots',
		defaultMessage: 'Selected Robots',
		description: 'Selected Robots',
	},

	robotsSelected: {
		id: 'RobotGroups.robotsSelected',
		defaultMessage:
			'{count, number} {count, plural, one {robot} other {robots}} has been selected',
		description: 'Number of selected robots',
	},
	noSelection: {
		id: 'RobotGroups.noSelection',
		defaultMessage: 'No robots selected',
		description: 'No robots selected',
	},

	noAvailable: {
		id: 'RobotGroups.noAvailable',
		defaultMessage: 'No robots available',
		description: 'No robots available',
	},
});
