import {
	ACCOUNT_CREATE_CONFIRMED_REQUEST_ID_CREATED,
	ACCOUNT_CREATE_CONFIRMED_STATUS_UPDATED,
} from '../actions/types';

export enum AccountCreateConfirmedStatus {
	PENDING = 'pending',
	SUCCESS = 'success',
	ERROR = 'error',
	ERROR_NOT_CREATED = 'notCreated',
	ERROR_ALREADY_CONFIRMED = 'alreadyConfirmed',
	AWAITING = 'awaiting'
}

export interface AccountCreateConfirmedState {
	requestId: string;
	status: AccountCreateConfirmedStatus;
}

const initialState: AccountCreateConfirmedState = {
	requestId: '',
	status: AccountCreateConfirmedStatus.AWAITING,
};

export default (state = initialState, action: Record<string, any>) => {
	switch (action.type) {
		case ACCOUNT_CREATE_CONFIRMED_STATUS_UPDATED:
			return {
				...state,
				status: action.payload.status,
			};
		case ACCOUNT_CREATE_CONFIRMED_REQUEST_ID_CREATED:
			return {
				...state,
				requestId: action.payload.requestId,
			};
		default:
			return state;
	}
};
