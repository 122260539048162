import React, { FC } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';

import { IonPopover } from '@ionic/react';

import classes from './PrivacyPolicyModal.module.css';
import isAuthenticated from '../../components/Authentication/Authenticated';
import PrivacyAndCookiePolicy160374175000EnGobe from './agreements/privacy-and-cookie-policy_160374175000_en_gobe.js';

import { FMButtonPrimary } from '../../ui-elements/FMButtons';

interface PrivacyPolicyModalProps {
	agreementId: string;
	intl: any;
	onAccept: () => void;
	onDismiss: () => void;
	isOpen: boolean;
}

const PrivacyPolicyModal: FC<PrivacyPolicyModalProps> = ({
	agreementId,
	intl,
	onAccept,
	onDismiss,
	isOpen,
}) => {
	const renderAgreement = () => {
		if (agreementId === 'privacy-and-cookie-policy_160374175000_en_gobe') {
			return <PrivacyAndCookiePolicy160374175000EnGobe />;
		}
	};

	return (
		<IonPopover cssClass={classes.privacyPolicyModal} isOpen={isOpen} onDidDismiss={onDismiss}>
			<div className={classes.agreementContainer}>{renderAgreement()}</div>
			<div className={classes.buttonContainer}>
				<FMButtonPrimary onClick={onDismiss}>
					<FormattedMessage id="Agreements.ok" />
				</FMButtonPrimary>
			</div>
		</IonPopover>
	);
};

const mapStateToProps = (state: any) => ({});

export default injectIntl(
	isAuthenticated(
		connect(mapStateToProps, { setParameter })(PrivacyPolicyModal),
		'PrivacyPolicyModal'
	)
);
