import React from 'react';
import { IonSplitPane } from '@ionic/react';
import classes from './FMBrandedSplitPane.module.css';

interface FMBrandedSplitPaneProps {
	contentId: string;
}

export const FMBrandedSplitPane: React.FC<FMBrandedSplitPaneProps> = ({ children, contentId }) => {
	return (
		<IonSplitPane
			contentId={contentId}
			when="(min-width: 992px)"
			className={classes.ionSplitPane}
		>
			{children}
		</IonSplitPane>
	);
};
