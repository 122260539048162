import { IClientOptions, MqttClient } from 'mqtt';
import mqtt from 'mqtt';
import _ from 'lodash';

export class SessionMqttConnectionError extends Error {}

const lastExecution = { client: undefined as MqttClient | undefined };

export default class SessionMqttFactory {
	/**
	 * Get the mqtt client used to initiate session, if any.
	 * Else creates a new mqtt client, and returns it.
	 * The client is cached, so that subsequent calls to this function will return the same instance.
	 *
	 * NB: Only to be used in the context of a session */
	public static async getMqttClient(mqttConfig: IClientOptions): Promise<MqttClient> {
		if (
			lastExecution.client?.connected &&
			_.isEqual(mqttConfig, lastExecution.client.options)
		) {
			return Promise.resolve(lastExecution.client);
		}

		return new Promise<MqttClient>((resolve, reject) => {
			lastExecution.client?.end();
			lastExecution.client = undefined;

			const onConnect = () => {
				console.log('session: Connected to MQTT broker');
				removeEventListeners();
				resolve(newMqttClient);
			};
			const onDisconnect = () => {
				console.log('session: Disconnected from MQTT broker');
				removeEventListeners();
				reject(new SessionMqttConnectionError('Disconnected from MQTT broker'));
			};
			const onError = (error: any) => {
				console.error('session: Error connecting to MQTT broker', error);
				removeEventListeners();
				reject(new SessionMqttConnectionError(error.message));
			};

			const removeEventListeners = () => {
				newMqttClient?.removeListener('connect', onConnect);
				newMqttClient?.removeListener('disconnect', onDisconnect);
				newMqttClient?.removeListener('error', onError);
			};

			const newMqttClient = mqtt
				.connect(mqttConfig)
				.on('connect', onConnect)
				.on('disconnect', onDisconnect)
				.on('error', onError);

			lastExecution.client = newMqttClient;

			return newMqttClient;
		});
	}
}
