import React, { FC, useEffect, useState, Fragment } from 'react';
import { IonGrid, IonRow, IonLabel, IonCol, IonToggle, IonText } from '@ionic/react';

import OrganizationSSOSandboxModeForm from '../OrganizationForm/OrganizationSSOSandboxModeForm';
import OrganizationSSOLiveModeForm from '../OrganizationForm/OrganizationSSOLiveModeForm';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';
import isAuthenticated from '../../components/Authentication/Authenticated';
import { injectIntl, FormattedMessage } from 'react-intl';
import Messages from './OrganizationForm.messages';

import classes from './OrganizationForm.module.css';

import 'react-phone-input-2/lib/style.css';

interface OrganizationSSOFormProps {
	organization: any;
	isEditable?: boolean;
	onSubmit: (data: any) => void;
	saved: boolean;
	segment: string;
	setEditable: any;
}

type FormData = {
	name: string;
	domains: string;
	idpEntryPoint: string;
	idpIssuer: string;
	idpCertificate: string;
	issuer: string;
	clientId: string;
	clientSecret: string;
};

const OrganizationSSOForm: FC<OrganizationSSOFormProps> = (props: any) => {
	const { isEditable } = props;
	const [viewMode, setViewMode] = useState<string>('live');

	useEffect(() => {
		if (isEditable) {
			setViewMode('sandbox');
		} else {
			setViewMode('live');
		}
	}, [isEditable]);

	return (
		<Fragment>
			<IonGrid className={classes.formGrid}>
				<IonRow className={classes.detailsHeader}>
					<IonLabel className={classes.labelFont}>
						<FormattedMessage {...Messages.sso} />
					</IonLabel>

					<IonText>{'sandbox' === viewMode ? 'sandbox mode' : 'live mode'}</IonText>
					{/* <IonLabel>Live</IonLabel>
						<IonToggle checked={'sandbox' === viewMode} onIonChange={e => setViewMode(viewMode === 'sandbox' ? 'live' : 'sandbox')} />
						<IonLabel>Sandbox</IonLabel> */}
				</IonRow>
			</IonGrid>

			{'sandbox' === viewMode ? (
				<OrganizationSSOSandboxModeForm {...props} />
			) : (
				<OrganizationSSOLiveModeForm {...props} />
			)}
		</Fragment>
	);
};
const mapStateToProps = (state: any) => ({
	organizations: state.organizationState.organizations,
});

const enhance = compose(connect(mapStateToProps, { setParameter }));

export default injectIntl(isAuthenticated(enhance(OrganizationSSOForm), 'OrganizationSSOForm'));
