import React, { FC, useState, useCallback, useEffect } from 'react';

import { injectIntl } from 'react-intl';
import isAuthenticated from '../Authentication/Authenticated';
import { useTypedSelector } from '../../reducers';
import ProfileSettingsSubmenu from './ProfileSettingsSubmenu';
import NotificationsSettingsSubmenu from './NotificationsSettingsSubmenu';
import AgreementsSettingsSubmenu from './AgreementsSettingsSubmenu';

import { b64EncodeUnicode } from '../../utils/encoding';
import { publish } from '../../actions/publish';
import ChangePasswordSubmenu from './ChangePasswordSubmenu';

interface AccountSetupProps {
	currentSubMenu: string;
	onSubMenuChange: (name: string) => void;
}

const AccountSetup: FC<AccountSetupProps> = (props: any) => {
	const { currentSubMenu, onSubMenuChange } = props;

	const [currentItem, setCurrentItem] = useState<string | undefined>(currentSubMenu);
	const [profile, setProfile] = useState<any>();

	const user = useTypedSelector(state => state.accountState.user);
	const spinoutType = useTypedSelector(state => state.versionState.spinoutType);

	// refresh latest accepted agreements
	useEffect(() => {
		publish(`microservice/${b64EncodeUnicode(user.username)}/getAcceptedAgreements`, {
			requestId: 'getAcceptedAgreementsId',
			data: {
				spinoutType: spinoutType ? spinoutType : '',
			},
		});
	}, [spinoutType]);

	useEffect(() => {
		let selectedItem = document.getElementById('row-' + currentItem);
		let top = selectedItem ? selectedItem.offsetTop + 30 : 30;
		let arrow = document.getElementById('arrow');
		if (arrow) {
			arrow.style.top = top.toString() + 'px';
		}
	});

	useEffect(() => {
		setCurrentItem(currentSubMenu);
	}, [currentSubMenu]);

	const goToItem = useCallback(
		(itemName: string) => {
			setCurrentItem(itemName);
			onSubMenuChange(itemName);
		},
		[onSubMenuChange]
	);

	const renderContent = useCallback(() => {
		if (!profile) setProfile(user);
		switch (currentItem) {
			case 'profile':
				return <ProfileSettingsSubmenu goTo={goToItem} />;
			case 'notifications':
				return <NotificationsSettingsSubmenu goTo={goToItem} />;
			case 'agreements':
				return <AgreementsSettingsSubmenu goTo={goToItem} />;
			case 'changePassword':
				return <ChangePasswordSubmenu goTo={goToItem} />;
			default:
				return null;
		}
	}, [currentItem, goToItem, profile, user]);

	return renderContent();
};

export default injectIntl(isAuthenticated(AccountSetup, 'AccountSetup'));
