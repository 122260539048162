import { APPLICATION_VERSION } from '../actions/types';

const initialState = {
	version: '',
	platform: '',
	spinoutType: '',
};

export default (state = initialState, action: Record<string, any>) => {
	switch (action.type) {
		case APPLICATION_VERSION:
			return {
				...state,
				version: action.payload.version,
				platform: action.payload.platform,
				spinoutType: action.payload.spinoutType,
			};
		default:
			return state;
	}
};
