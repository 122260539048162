import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import BasicModal from '../BasicModal/BasicModal';
import { IonButton, IonCol, IonLabel, IonRow } from '@ionic/react';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		padding: {
			padding: '2.5rem',
		},
		headerTitle: {
			fontFamily: 'Industry',
			fontSize: '28px !important',
			textTransform: 'capitalize',
		},
		submitCol: {
			'--color': 'var(--ion-color-primary-contrast)',
			'--color-hover': 'var(--ion-color-primary)',
			'--border-width': '1px',
			'--background': 'var(--ion-color-primary)',
			'--background-hover': 'var(--ion-color-primary-contrast)',
			'--background-hover-opacity': '1',
			'--transition': '0.5s',
		},
	})
);

interface IBasicDialogue {
	children: any;
	onClose: Function;
	open: boolean;

	title: string;
	options: IBasicDialogueOption[];
}

interface IBasicDialogueOption {
	prompt: string;
	callback: Function;
	primary?: boolean;
}

export default function BasicDialogue(props: IBasicDialogue) {
	const { children, onClose, title, options } = props;
	let { open } = props;

	const classes = useStyles();

	return (
		<BasicModal open={open} onClose={onClose} className={classes.padding}>
			<IonLabel color="primary" className={classes.headerTitle}>
				Confirm!
			</IonLabel>
			{children}
			<IonRow>
				{options.map((option: IBasicDialogueOption) => {
					return (
						<IonCol>
							<IonButton
								className={option.primary ? classes.submitCol : ''}
								expand="block"
								shape="round"
								size="default"
								fill="outline"
								onClick={() => {
									option.callback();
								}}
							>
								{option.prompt}
							</IonButton>
						</IonCol>
					);
				})}
			</IonRow>
		</BasicModal>
	);
}
