import { defineMessages } from 'react-intl';

export default defineMessages({
	pilotApp: {
		id: 'Menu.PilotApp',
		defaultMessage: 'Pilot App',
		description: 'Pilot App tab',
	},

	robot: {
		id: 'Menu.RobotTitle',
		defaultMessage: 'Robot',
		description: 'robot tab',
	},

	account: {
		id: 'Menu.AccountTitle',
		defaultMessage: 'Profile',
		description: 'Account tab',
	},

	tab: {
		id: 'Menu.TabTitle',
		defaultMessage: 'TabTitle',
		description: 'tab',
	},

	profile: {
		id: 'AccountSetup.profile',
		defaultMessage: 'Profile',
		description: 'Profile',
	},
	notifications: {
		id: 'AccountSetup.notifications',
		defaultMessage: 'Notifications',
		description: 'Notifications',
	},

	changePassword: {
		id: 'AccountSetup.changePassword',
		defaultMessage: 'Change Password',
		description: 'Change Password',
	},

	agreements: {
		id: 'AccountSetup.agreements',
		defaultMessage: 'Agreements',
		description: 'Agreements',
	},

	audioVideo: {
		id: 'AccountSetup.audioVideo',
		defaultMessage: 'Audio/Video',
		description: 'audioVideo',
	},

	views: {
		id: 'AccountSetup.views',
		defaultMessage: 'Views',
		description: 'audioViewsVideo',
	},
	preferences: {
		id: 'AccountSetup.preferences',
		defaultMessage: 'Preferences',
		description: 'preferences',
	},
});
