import React, { useState } from 'react';
import useDelayedShowOrHide from '../../../../utils/useDelayedShowOrHide';
import './index.scss';

type Props = {
	isMenuExpanded: boolean;
	isAbsoluteMenuVisible: boolean;
	isDrivingImpaired: boolean;
};

const showOrHideConfig = {
	showDelayMs: 0, // show the indicator immediately when driving is impaired
	hideDelayMs: 1000, // hide the indicator after a delay of X ms, when driving is no longer impaired
};

export default function ImpairedDrivingIndicator({
	isMenuExpanded,
	isAbsoluteMenuVisible,
	isDrivingImpaired,
}: Props) {
	const [isMinimized, setIsMinimized] = useState(false);
	const isVisible = useDelayedShowOrHide(isDrivingImpaired, showOrHideConfig);

	return isMinimized ? (
		<div
			className={
				isVisible
					? isAbsoluteMenuVisible
						? isMenuExpanded
							? 'drivingImpairmentCircle containerMenuExpanded'
							: 'drivingImpairmentCircle containerMenuCollapsed'
						: 'drivingImpairmentCircle'
					: 'displayNone'
			}
			onClick={() => setIsMinimized(false)}
		>
			<div className="warningIconWrapper">
				<img src="../../assets/images/green-warning.svg" alt="" />
			</div>
		</div>
	) : (
		<div
			className={
				isVisible
					? isAbsoluteMenuVisible
						? isMenuExpanded
							? 'drivingImpairmentContainer containerMenuExpanded'
							: 'drivingImpairmentContainer containerMenuCollapsed'
						: 'drivingImpairmentContainer'
					: 'displayNone'
			}
		>
			<div className="headingContainer">
				<div className="warningIconWrapper">
					<img src="../../assets/images/green-warning.svg" alt="" />
				</div>
				<div className="impairedHeading">Driving Impaired</div>
				<div className="closeCircleIconWrapper" onClick={() => setIsMinimized(true)}>
					<img src="../../assets/images/grey-close-circle.svg" alt="" />
				</div>
			</div>
			<div className="textSessionConnectionContainer">
				The driving speed has been limited due to network problems or your device being
				under a high load.
			</div>
		</div>
	);
}
