import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import './index.scss';
import { connect } from 'react-redux';
import { setParameter } from '../../../../actions/setParam';
import { SET_HIDE_NAV_OPTIONS_STATUS } from '../../../../actions/types';
import { ConnectedProps } from 'react-redux';
import { AppRootState } from '../../../../../../../reducers';
import { Rnd } from 'react-rnd';
import Joystick from '../../navigation/joystick';
import { NavController } from '../../navigation/useNavController';
import { Size } from '../../../../types';

const reduxConnector = connect(
	(state: AppRootState) => ({
		hideNavOptionsStatus: state.goBeState.sessionState.hideNavOptionsStatus,
		drivingMode: state.goBeState.sessionState.drivingMode,
		navCameraHeight: state.goBeState.sessionState.navCameraHeight,
	}),
	{ setParameter }
);

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
type PropsFromParent = {
	isGreyedOut: boolean;
	isPaused: boolean;
	mediaStream: MediaStream;
	navController: Pick<NavController, 'onNavCommand'>;
	handleJoystickEnabled: Function;
	isJoystickMounted: boolean;
	isDrivingAllowed: boolean;
};
type ComponentProps = PropsFromRedux & PropsFromParent;

export const NAV_CAMERA_HIGH = 250;

const NavigationVideo: React.FC<ComponentProps> = ({
	mediaStream,
	setParameter,
	drivingMode,
	hideNavOptionsStatus,
	isGreyedOut,
	isPaused,
	navCameraHeight,
	navController,
	handleJoystickEnabled,
	isJoystickMounted,
	isDrivingAllowed,
}) => {
	const videoRef = useRef<HTMLVideoElement | null>(null);
	useEffect(() => {
		videoRef.current!.srcObject = mediaStream;
	}, [mediaStream]);

	const navHideRef = useRef<any>(true);
	const navHoverRef = useRef<any>(true);

	const [navHover, changeNavHover] = useState(false);
	const [isJoystickEnabled, setIsJoystickEnabled] = useState<boolean>(false);
	const [isJoystickHovered, setIsJoystickHovered] = useState<boolean>(false);

	const [isLoadingIndicatorVisible, setIsLoadingIndicatorVisible] = useState(true);
	const [keyDown, changeKeyDown] = useState<Array<string | number>>([]);
	useLayoutEffect(() => {
		const onDocumentMouseMove = () => {
			if (keyDown.length === 0) {
				clearTimeout(navHideRef.current);
				clearTimeout(navHoverRef.current);
				setParameter('hideNavOptionsStatus', SET_HIDE_NAV_OPTIONS_STATUS, true);
				changeNavHover(true);
				navHoverRef.current = setTimeout(() => {
					changeNavHover(false);
				}, 1000);
				navHideRef.current = setTimeout(() => {
					setParameter('hideNavOptionsStatus', SET_HIDE_NAV_OPTIONS_STATUS, false);
				}, 3000);
			}
		};
		document.addEventListener('mousemove', onDocumentMouseMove);
		return () => {
			document.removeEventListener('mousemove', onDocumentMouseMove);
		};
	}, [keyDown.length, setParameter]);

	useLayoutEffect(() => {
		const onDocumentKeyUp = (event: KeyboardEvent) => {
			if (keyDown.includes(event.key)) {
				let arr = keyDown.filter((item: string | number) => {
					return item !== event.key;
				});
				changeKeyDown(arr);
			}
		};
		document.addEventListener('keyup', onDocumentKeyUp);
		return () => {
			document.removeEventListener('keyup', onDocumentKeyUp);
		};
	}, [keyDown]);

	useLayoutEffect(() => {
		const onDocumentKeyDown = (event: KeyboardEvent) => {
			if (!event.repeat) {
				if (!keyDown.includes(event.key)) {
					changeKeyDown([...keyDown, event.key]);
				}
			}
		};
		document.addEventListener('keydown', onDocumentKeyDown);
		return () => {
			document.removeEventListener('keydown', onDocumentKeyDown);
		};
	}, [keyDown]);

	useEffect(() => {
		if (keyDown.length > 0) {
			clearTimeout(navHideRef.current);
			clearTimeout(navHoverRef.current);
			setParameter('hideNavOptionsStatus', SET_HIDE_NAV_OPTIONS_STATUS, true);
			changeNavHover(true);
		} else {
			navHoverRef.current = setTimeout(() => {
				changeNavHover(false);
			}, 1000);
			navHideRef.current = setTimeout(() => {
				setParameter('hideNavOptionsStatus', SET_HIDE_NAV_OPTIONS_STATUS, false);
			}, 3000);
		}
	}, [keyDown, setParameter]);

	useEffect(() => {
		return () => {
			clearTimeout(navHideRef.current);
			clearTimeout(navHoverRef.current);
		};
	}, []);

	const onCanPlay = () => {
		if (isPaused) videoRef.current?.pause();
		else {
			videoRef.current
				?.play()
				.catch(error => console.warn('Unable to play NavigationVideo', error));
		}
		setIsLoadingIndicatorVisible(true);
	};

	useEffect(() => {
		if (isPaused) videoRef.current?.pause();
		else {
			videoRef.current
				?.play()
				.catch(error => console.warn('Unable to play NavigationVideo', error));
		}
	}, [isPaused]);

	const [dimensions, setDimensions] = React.useState<Size>({
		height: window.innerHeight,
		width: window.innerWidth,
	});
	useEffect(() => {
		function handleResize() {
			setDimensions({
				height: window.innerHeight,
				width: window.innerWidth,
			});
		}
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const [size, setSize] = useState<Size>({ height: NAV_CAMERA_HIGH, width: 180 });
	const [position, setPosition] = useState({
		x: (window.innerWidth - 180) / 2,
		y: window.innerHeight - NAV_CAMERA_HIGH,
	});

	useEffect(() => {
		setSize({ height: NAV_CAMERA_HIGH, width: 180 });
		return () => {
			setSize({ height: NAV_CAMERA_HIGH, width: 180 });
		};
	}, []);

	useEffect(() => {
		setSize({ height: NAV_CAMERA_HIGH, width: 180 });
		setPosition({ x: (dimensions.width - 180) / 2, y: dimensions.height - NAV_CAMERA_HIGH });
	}, [drivingMode, dimensions, navCameraHeight]);

	useEffect(() => {
		setSize({ width: navCameraHeight * 0.75, height: navCameraHeight });
		setPosition({
			x: (dimensions.width - navCameraHeight * 0.75) / 2,
			y: dimensions.height - navCameraHeight,
		});
	}, [dimensions.height, dimensions.width, navCameraHeight]);

	const style = {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	};
	const renderVideoLoading = () => {
		return (
			<div className={drivingMode ? 'navVideoWrapper drivingView' : 'navVideoWrapper'}>
				<div className={isLoadingIndicatorVisible ? 'displayNone' : ' showNavLoading '}>
					<div className="navLoading" />
				</div>
				<video
					ref={videoRef}
					onCanPlay={onCanPlay}
					playsInline
					loop
					className={
						isLoadingIndicatorVisible
							? isGreyedOut
								? 'navigationVideo greyVideo'
								: 'navigationVideo'
							: 'displayNone'
					}
					muted
					style={
						drivingMode && isDrivingAllowed
							? {
									border: '2px solid #56AE4D',
									borderLeft: 'none',
							  }
							: undefined
					}
				/>
			</div>
		);
	};

	const handleHovered = (isHovered: boolean) => {
		setIsJoystickHovered(isHovered);
		handleJoystickEnabled(isHovered || isJoystickEnabled);
	};
	const handleEnabled = (isEnabled: boolean) => {
		setIsJoystickEnabled(isEnabled);
		handleJoystickEnabled(isEnabled || isJoystickHovered);
	};
	return (
		<Rnd enableResizing={false} disableDragging style={style} size={size} position={position}>
			{isJoystickMounted && isLoadingIndicatorVisible && (
				<Joystick
					size={size}
					handleHovered={handleHovered}
					handleEnabled={handleEnabled}
					navController={navController}
				/>
			)}
			<div
				id="navigationVideoContainer"
				className={
					isJoystickEnabled || hideNavOptionsStatus || isJoystickHovered
						? drivingMode
							? 'navigationVideoContainer drivingView'
							: 'navigationVideoContainer'
						: 'displayNone'
				}
				style={{ height: size.width, width: size.height }} // the height is the width in this case because of the rotate(90deg)
			>
				{renderVideoLoading()}
			</div>
		</Rnd>
	);
};

export default reduxConnector(NavigationVideo);
