import React, { FC } from 'react';
import {
	IonGrid,
	IonRow,
	IonList,
	IonItem,
	IonLabel,
	IonListHeader,
} from '@ionic/react';

import isAuthenticated from '../../components/Authentication/Authenticated';
import { injectIntl, FormattedMessage } from 'react-intl';
import Messages from './RobotForm.messages';
import ProgressBar from './../ProgressBar/ProgressBar';

import classes from './RobotForm.module.css';
import { UVDMongoDevice } from '../../types/types';
import classNames from 'classnames';

interface RobotFormProps {
	deviceId: any;
	isEditable?: boolean;
	device: UVDMongoDevice;
}

const RobotHealth: FC<RobotFormProps> = props => {
	const { isEditable, device } = props;

	function getDistancePercentageOfTotal() {
		if (!device.uvdStatus) {
			return 0;
		}

		let decimalFraction =
			device.uvdStatus?.distanceTravelledDisinfection / device.uvdStatus?.distanceTravelled;

		return Math.floor(decimalFraction * 100);
	}

	function getTruPhoneGBFromMB(mb: number) {
		return Math.floor(mb * 0.0009765625 * 100) / 100;
	}

	function getDifferenceInDays(date: Date) {
		var today = new Date().getTime();
		var diffInMs = Math.abs(today - date.getTime());
		return Math.floor(diffInMs / (1000 * 60 * 60 * 24));
	}	

	return (
		<IonGrid className={classes.formGrid}>
			<IonRow>
				<IonList className="ion-padding">
					<IonListHeader>
						<IonLabel>
							<FormattedMessage {...Messages.robotHealth} />
						</IonLabel>
					</IonListHeader>

					<IonItem disabled>
						<IonLabel
							position="fixed"
							className={classNames(classes.formLb, classes.formLbRobotsHealth)}
						>
							<FormattedMessage {...Messages.inUseSince} />
						</IonLabel>
						<IonLabel className={classes.inputLb}>
							{getDifferenceInDays(
								device.installedAt ? device.installedAt : new Date()
							)}{' '}
							days
						</IonLabel>
					</IonItem>

					<IonItem disabled>
						<IonLabel
							position="fixed"
							className={classNames(classes.formLb, classes.formLbRobotsHealth)}
						>
							<FormattedMessage {...Messages.operationHoursTotal} />:
						</IonLabel>
						<IonLabel className={classes.inputLb}>
							{device.uvdStatus?.operationHours || 0} h
						</IonLabel>
					</IonItem>

					<IonItem disabled={!isEditable}>
						<IonLabel
							position="fixed"
							className={classNames(classes.formLb, classes.formLbRobotsHealth)}
						>
							<FormattedMessage {...Messages.disinfectionHoursTubes} />
						</IonLabel>

						<ProgressBar
							leftText={(device.uvdStatus?.uvcTubes.toString() || "0") + ' h used'}
							rightText="Max 12000 h"
							value={device.uvdStatus?.uvcTubes || 0}
							maxValue={12000}
						/>
					</IonItem>
					<IonItem disabled={!isEditable}>
						<IonLabel
							position="fixed"
							className={classNames(classes.formLb, classes.formLbRobotsHealth)}
						>
							<FormattedMessage {...Messages.distanceTravelledTotal} />
						</IonLabel>

						<IonLabel className={classes.inputLb}>
							{device.uvdStatus?.distanceTravelled || 0} m
						</IonLabel>
					</IonItem>
					<IonItem disabled={!isEditable}>
						<IonLabel
							position="fixed"
							className={classNames(classes.formLb, classes.formLbRobotsHealth)}
						>
							<FormattedMessage {...Messages.distanceTravelledDuringDisinfection} />
						</IonLabel>

						<IonLabel className={classes.inputLb}>
							{device.uvdStatus?.distanceTravelledDisinfection || 0} m (
							{getDistancePercentageOfTotal()}% of Total)
						</IonLabel>
					</IonItem>
				</IonList>
			</IonRow>
		</IonGrid>
	);
};

export default injectIntl(isAuthenticated(RobotHealth, 'RobotHealth'));
