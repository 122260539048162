import { defineMessages } from 'react-intl';

export default defineMessages({
	deleteMsg: {
		id: 'confirm',
		defaultMessage: 'Are you sure that you want to delete {br} {item}?',
		description: 'Are you sure that you want to delete {br} {item}?',
	},
	delete: {
		id: 'delete',
		defaultMessage: 'Delete',
		description: 'Delete',
	},
	cancel: {
		id: 'cancel',
		defaultMessage: 'Cancel',
		description: 'Cancel',
	},
	errorMsg: {
		id: 'error',
		defaultMessage: 'Error, the organization it has linked devices',
		description: 'Error, the organization it has linked devices',
	},
});
