const { createHash } = require('crypto');

/** Simple UUID generator.
 * This serves the purpose of generating a random sequence of numbers;
 * a third-party package is not necessary */
export const generateUUID = () => {
	let result, i, j;
	result = '';
	for (j = 0; j < 32; j++) {
		if (j === 8 || j === 12 || j === 16 || j === 20) result = result + '-';
		i = Math.floor(Math.random() * 16)
			.toString(16)
			.toUpperCase();
		result = result + i;
	}
	return result;
};

/**
 * SHA256 hash
 * @param value Value to hash
 * @returns SHA256 hash of @value
 */
export const SHA256Hash = (value: string) => {
	return createHash('sha256')
		.update(value)
		.digest('hex');
};
