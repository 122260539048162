/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import './index.scss';
import { setParameter } from '../../actions/setParam';
import { SET_BATTERY_STATUS } from '../../actions/types';
import { connect } from 'react-redux';
import Battery from '../battery';
import { ConnectedProps } from 'react-redux';
import { AppRootState } from '../../../../../reducers';
import { unsubscribe, publish, subscribe } from 'redux-mqtt';
import { useDispatch } from 'react-redux';

const reduxConnector = connect(
	(state: AppRootState) => ({
		batteryStatus: state.goBeState.sessionState.batteryStatus,
		robotId: state.goBeState.sessionState.sessionInfo?.robot?.id,
	}),
	{ setParameter, subscribe, unsubscribe }
);

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
type PropsFromParent = {
	subscribe: typeof subscribe;
	unsubscribe: typeof unsubscribe;
	isLocalVideoExpanded: boolean;
	onToggleLocalVideoExpansion: () => void;
};
type ComponentProps = PropsFromRedux & PropsFromParent;
type Battery = {
	level: string;
	charging: boolean;
};
type Network = {
	quality: number;
	ssid: string | null;
};

const WifiIcons: Record<'medium' | 'low' | 'bad' | 'high', string> = {
	low: '../../assets/images/wifi-signal-low.svg',
	medium: '../../assets/images/wifi-signal-medium.svg',
	bad: '../../assets/images/wifi-signal-bad.svg',
	high: '../../assets/images/wifi.svg',
};

const LocalVideoHeader: React.FC<ComponentProps> = ({
	setParameter,
	batteryStatus,
	robotId,
	isLocalVideoExpanded,
	onToggleLocalVideoExpansion,
}) => {
	const [batteryDetails, changeBatteryDetails] = useState<Battery>({
		level: '40',
		charging: false,
	});
	const [networkDetails, changeNetworkDetails] = useState<Network | null>(null);

	const onBatteryClick = () => {
		setParameter('batteryStatus', SET_BATTERY_STATUS, !batteryStatus);
	};
	const dispatch = useDispatch();

	const batteryStatusTopic = `${robotId}/status/update`;

	useEffect(() => {
		dispatch(
			subscribe(batteryStatusTopic, ({ message }: any) => {
				changeBatteryDetails(message.data.battery);
				changeNetworkDetails(message.data.network);
			})
		);
	}, [batteryStatusTopic, dispatch]);

	useEffect(() => {
		return () => {
			dispatch(unsubscribe(batteryStatusTopic));
		};
	}, [batteryStatusTopic, dispatch]);

	const wifiIcon = () => {
		if (networkDetails) {
			const quality = networkDetails.quality;
			switch (true) {
				case quality >= 80:
					return WifiIcons.high;
				case quality < 80 && quality >= 66:
					return WifiIcons.medium;

				case quality < 66 && quality >= 56:
					return WifiIcons.low;

				case quality < 56:
					return WifiIcons.bad;
			}
		} else {
			return WifiIcons.high;
		}
	};

	return (
		<div
			className={
				isLocalVideoExpanded
					? ' wifiBatteryContainer'
					: 'miniWifiBatteryContainer wifiBatteryContainer'
			}
		>
			<div className="removeIconWrapper" onClick={onToggleLocalVideoExpansion}>
				<img
					src={`../../assets/images/${
						isLocalVideoExpanded ? 'remove.svg ' : 'GoBe-head.svg'
					}`}
				/>
			</div>
			<div className="wifiIconWrapper">
				<img src={wifiIcon()} />
			</div>
			<div className="batteryIconContainer">
				<Battery
					onClick={onBatteryClick}
					batteryPercent={batteryDetails.level}
					charging={batteryDetails.charging}
					online
				/>
			</div>
		</div>
	);
};

export default reduxConnector(LocalVideoHeader);
