import React from 'react';
import { InSessionPilotIcon } from '../icons/InSessionPilotIcon';
import '../../../../index.scss';
import { InSessionGuestIcon } from '../icons/InSessionGuestIcon';

interface Props {
	isGuestSession?: boolean;
	guestSessionInProgress?: boolean;
}

export const InSessionStatus = ({
	isGuestSession = false,
	guestSessionInProgress = false,
}: Props) => {
	return (
		<div className="currentStatusWrapper">
			{isGuestSession && guestSessionInProgress ? (
				<InSessionGuestIcon />
			) : (
				<InSessionPilotIcon />
			)}
			<span className="statusLabel">In Session</span>
		</div>
	);
};
