/* eslint-disable camelcase */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { Device } from '../../../../../types/types';
import { SortMethod } from '../../reducers/roasterReducers';
import { IDeviceCurrentState } from '../../utils/statusConverter';

export type FilterRequiredDeviceInfo = Pick<
	Device,
	'deviceId' | 'orgId' | 'name' | 'serialNumber' | 'location' | 'deviceGroupsIds'
> & {
	currentState: IDeviceCurrentState;
};
// See this StackOverflow question/answer https://stackoverflow.com/a/6969486/4906477
// to better understand what's going on here.
/** Escape regex-special characters from a text. This makes it safe to pass the text as the constructor of RegExp */
function escapeRegExp(text: string) {
	return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}
function escapeReplacement(text: string) {
	return text.replace(/\$/g, '$$$$');
}

type HookArgs = {
	sort: { sortMethod: SortMethod; isSortedAscending: boolean };
	searchTerm: string;
	userDevicesById: Record<
		string,
		Pick<
			Device,
			'deviceId' | 'orgId' | 'serialNumber' | 'name' | 'location' | 'deviceGroupsIds'
		> & {
			currentState: IDeviceCurrentState;
		}
	>;
	devicesCurrentStateById: Record<string, { online: boolean; currentState: IDeviceCurrentState }>;
	deviceGroupsNamesById: Record<string, string>;
	userDevicesIds: string[];
	devicesByFavourite: string[];
	isRelatedDataLoading: boolean;
	isInPreSessionState: boolean;
};

type SortFunctionArgs = {
	sort: { sortMethod: SortMethod; isSortedAscending: boolean };
	devicesIds: string[];
	devicesByFavourite: string[];
	deviceGroupsNamesById: HookArgs['deviceGroupsNamesById'];
	userDevicesById: HookArgs['userDevicesById'];
	devicesCurrentStateById: HookArgs['devicesCurrentStateById'];
};
const SORT_DEBOUNCE_TIME_MS = 10 * 1000;

type SearchTermFilterFunctionArgs = {
	searchTerm: string;
	devicesIds: string[];
	deviceGroupsNamesById: HookArgs['deviceGroupsNamesById'];
	devicesByFavourite: string[];
	userDevicesById: HookArgs['userDevicesById'];
};
const SEARCH_DEBOUNCE_TIME_MS = 200;

export default function useFilteredRosterDevicesIds(props: HookArgs): string[] {
	const {
		sort,
		searchTerm,
		userDevicesById,
		devicesCurrentStateById,
		devicesByFavourite,
		deviceGroupsNamesById,
		userDevicesIds: devicesIds,
		isRelatedDataLoading,
		isInPreSessionState,
	} = props;

	const [filteredDevicesIds, setFilteredDevicesIds] = useState<Set<string>>(new Set(devicesIds));
	const applySearchFilter = useCallback(
		_.debounce(
			(args: SearchTermFilterFunctionArgs) => {
				const { searchTerm, devicesIds, deviceGroupsNamesById, userDevicesById } = args;

				if (!searchTerm) {
					setFilteredDevicesIds(new Set(devicesIds));
				} else {
					const safeSearchTerm = escapeRegExp(
						searchTerm.trim().replace(/\s{2,}/gi, ' ') // remove duplicate spaces
					);
					const filteredDevicesIds = devicesIds.filter(deviceId => {
						const device = userDevicesById[deviceId];

						const textToSearchFrom = (
							[device?.name, device?.location].join(' ') +
							' ' +
							((device?.deviceGroupsIds ?? []) as string[])
								?.map(deviceGroupId => deviceGroupsNamesById[deviceGroupId])
								.join(' ')
						)
							// remove multi-white spaces
							.replace(/\s{2,}/gi, ' ');

						const searchTexts = [
							new RegExp(safeSearchTerm, 'gi'),
							// search also for each word individually
							...safeSearchTerm.split(' ').map(text => new RegExp(text, 'gi')),
						];
						return searchTexts.some(text => text.test(textToSearchFrom));
					});
					setFilteredDevicesIds(new Set(filteredDevicesIds));
				}
			},
			SEARCH_DEBOUNCE_TIME_MS,
			{}
		),
		[]
	);

	// redo search whenever the search term changes
	useEffect(() => {
		applySearchFilter({
			searchTerm,
			devicesIds,
			deviceGroupsNamesById,
			userDevicesById,
			devicesByFavourite,
		});
	}, [deviceGroupsNamesById, devicesIds, applySearchFilter, searchTerm, userDevicesById]);

	const [sortedDevicesIds, setSortedDevicesIds] = useState<string[]>([...devicesIds]);
	const applySorting = useCallback(
		_.debounce((args: SortFunctionArgs) => {
			const {
				sort,
				devicesIds,
				deviceGroupsNamesById,
				userDevicesById,
				devicesCurrentStateById,
				devicesByFavourite,
			} = args;

			function deviceNameSortComparator(a: string, b: string): number {
				if (userDevicesById[a]?.name?.trim() < userDevicesById[b]?.name?.trim()) return -1;
				else if (userDevicesById[a]?.name?.trim() > userDevicesById[b]?.name?.trim())
					return +1;
				else return 0;
			}

			function deviceIsFavouriteSortComparator(a: any, b: any): number {
				let first: any = userDevicesById[a]?.serialNumber;
				let second: any = userDevicesById[b]?.serialNumber;

				if (devicesByFavourite[first] < devicesByFavourite[second]) return +1;
				else if (devicesByFavourite[first] > devicesByFavourite[second]) return -1;
				else return 0;
			}

			function deviceGroupSortComparator(a: string, b: string): number {
				const a_deviceGroupsNames = ((userDevicesById[a]?.deviceGroupsIds ??
					[]) as string[])
					.map(id => deviceGroupsNamesById[id])
					.sort()
					.join('');
				const b_deviceGroupsNames = ((userDevicesById[b]?.deviceGroupsIds ??
					[]) as string[])
					.map(id => deviceGroupsNamesById[id])
					.sort()
					.join('');
				if (a_deviceGroupsNames < b_deviceGroupsNames) return -1;
				else if (a_deviceGroupsNames > b_deviceGroupsNames) return +1;
				else return 0;
			}

			function deviceAvailabilitySortComparator(a: string, b: string): number {
				const onlineAndAvailableDiff =
					+(
						!!devicesCurrentStateById[b]?.online &&
						!!(devicesCurrentStateById[b]?.currentState === 'available')
					) -
					+(
						!!devicesCurrentStateById[a]?.online &&
						!!(userDevicesById[a]?.currentState === 'available')
					);

				const onlineDiff =
					+!!devicesCurrentStateById[b]?.online - +!!devicesCurrentStateById[a]?.online;

				// online-and-available devices first, then online devices next
				return Math.max(-1, Math.min(1, 2 * onlineAndAvailableDiff + onlineDiff));
			}

			function deviceLastUsageSortComparator(a: string, b: string): number {
				return 0; // TODO: Implement this
			}

			const getComparatorForSortMethod = () => {
				const directionMultiplier = sort.isSortedAscending ? 1 : -1;
				return (a: string, b: string) => {
					switch (sort.sortMethod) {
						case 'name':
							return directionMultiplier * deviceNameSortComparator(a, b);
						case 'deviceGroup':
							return directionMultiplier * deviceGroupSortComparator(a, b);
						case 'availability':
							return directionMultiplier * deviceAvailabilitySortComparator(a, b);
						case 'lastUsage':
							return directionMultiplier * deviceLastUsageSortComparator(a, b);
						case 'isFavourite':
							return directionMultiplier * deviceIsFavouriteSortComparator(a, b);
					}
				};
			};
			setSortedDevicesIds([...devicesIds].sort(getComparatorForSortMethod()));
		}, SORT_DEBOUNCE_TIME_MS),
		[]
	);

	const previousSort = usePrevious(sort);
	useEffect(() => {
		if (isInPreSessionState) {
			applySorting.cancel();
			return;
		}

		applySorting({
			sort,
			devicesIds,
			deviceGroupsNamesById,
			userDevicesById,
			devicesCurrentStateById,
			devicesByFavourite,
		});

		// apply new sort immediately, without waiting for debounce
		if (sort !== previousSort) applySorting.flush();
	}, [
		applySorting,
		deviceGroupsNamesById,
		devicesCurrentStateById,
		devicesByFavourite,
		devicesIds,
		isInPreSessionState,
		previousSort,
		sort,
		userDevicesById,
	]);

	const resultingDeviceIds = useMemo(() => {
		return sortedDevicesIds.filter(deviceId => filteredDevicesIds.has(deviceId));
	}, [filteredDevicesIds, sortedDevicesIds]);

	return resultingDeviceIds;
}

function usePrevious<T extends any = any>(value: T) {
	// The ref object is a generic container whose current property is mutable ...
	// ... and can hold any value, similar to an instance property on a class
	const ref = useRef<T>();
	// Store current value in ref
	useEffect(() => {
		ref.current = value;
	}, [value]); // Only re-run if value changes
	// Return previous value (happens before update in useEffect above)
	return ref.current;
}
