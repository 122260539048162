import React from 'react';
import { IDeviceCurrentState } from '../../../../../../../utils/statusConverter';
import '../../../index.scss';
import { InSessionStatus } from './components/InSessionStatus';
import { ReadyForCallStatus } from './components/ReadyForCallStatus';
import { ConfiguringIcon } from './icons/ConfiguringIcon';
import { OfflineIcon } from './icons/OfflineIcon';
import { OnHoldIcon } from './icons/OnHoldIcon';
import { UnavailableIcon } from './icons/UnavailableIcon';
import { UpdatingIcon } from './icons/UpdatingIcon';

interface Props {
	status: IDeviceCurrentState;
	isGuest?: boolean;
	isCalling: boolean;
}

export const DefaultStatus = ({ status, isGuest, isCalling }: Props) => {
	switch (status) {
		case 'inSession':
			return isCalling ? (
				<ReadyForCallStatus isCalling={isCalling} />
			) : (
				<InSessionStatus isGuestSession={isGuest || false} />
			);
		case 'available':
			return <ReadyForCallStatus isCalling={isCalling} />;
		case 'configuring':
			return (
				<div className="currentStatusWrapper">
					<ConfiguringIcon />
					<span className="statusLabel">Configuring</span>
				</div>
			);
		case 'onHold':
			return (
				<div className="currentStatusWrapper">
					<OnHoldIcon />
					<span className="statusLabel">On Hold</span>
				</div>
			);
		case 'unavailable':
			return (
				<div className="currentStatusWrapper">
					<UnavailableIcon />
					<span className="statusLabel">Unavailable</span>
				</div>
			);
		case 'updating':
			return (
				<div className="currentStatusWrapper">
					<UpdatingIcon />
					<span className="statusLabel">Updating</span>
				</div>
			);
		case 'offline':
			return (
				<div className="currentStatusWrapper">
					<OfflineIcon />
					<span className="statusLabel">Offline</span>
				</div>
			);
		default:
			return <div style={{ color: 'white' }}>default</div>;
	}
};
