import React from 'react';
import {
	IonRow,
	IonCol,
	IonButton,
	IonIcon,
	IonLabel,
	IonGrid,
	IonList,
	IonListHeader,
} from '@ionic/react';
import { syncOutline } from 'ionicons/icons';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';
import { injectIntl, FormattedMessage } from 'react-intl';
import isAuthenticated from '../Authentication/Authenticated';

import classes from './StayLoginModal.module.css';

import Messages from './StayLoginModal.message';
import BasicModal from '../BasicModal/BasicModal';

const StayLoginModal: React.FC = (props: any) => {
	const onCancel = () => {
		props.onDismiss();
	};
	const onSubmit = () => {
		props.onConfirm();
	};
	return (
		<BasicModal
			className={classes.addModal}
			open={props.isOpen}
			onClose={() => {
				return false;
			}}
		>
			<IonList>
				<IonListHeader className={classes.modalHeader} lines="none">
					<div className={classes.headerContainer}>
						<IonLabel color="primary" className={classes.headerTitle}>
							<FormattedMessage {...Messages.title} />
						</IonLabel>
					</div>
				</IonListHeader>
			</IonList>

			<IonRow className={classes.sessionContent}>
				<IonCol>
					<IonIcon className={classes.headerIcon} color="primary" icon={syncOutline} />
				</IonCol>
				<IonCol>
					<IonLabel className={classes.headerTitle}>
						<FormattedMessage {...Messages.SubTitle} />
					</IonLabel>
				</IonCol>
				<IonCol>
					<IonLabel className={classes.headerHint}>
						<FormattedMessage {...Messages.hint} />
					</IonLabel>
				</IonCol>
			</IonRow>
			<IonRow className={classes.btnRow}>
				<IonCol>
					<IonButton
						className={classes.cancelBtn}
						expand="block"
						shape="round"
						size="large"
						fill="outline"
						onClick={onCancel}
					>
						<FormattedMessage {...Messages.logout} />
					</IonButton>
				</IonCol>
				<IonCol className={classes.submitCol}>
					<IonButton
						expand="block"
						shape="round"
						type="submit"
						size="large"
						fill="outline"
						onClick={onSubmit}
					>
						<FormattedMessage {...Messages.refresh} />
					</IonButton>
				</IonCol>
			</IonRow>
		</BasicModal>
	);
};

const mapStateToProps = (state: any) => ({});

export default injectIntl(
	isAuthenticated(connect(mapStateToProps, { setParameter })(StayLoginModal), 'StayLoginModal')
);
