import React from 'react';
import { Device } from '../../../../../../../../../types/types';
import { Header } from './Header';

import { Status } from './Status';

interface Props {
	device: Partial<Device>;
	onClickStartSession: (device: Device) => void;
	onFavoriteClick: (device: Device) => void;
	isCalling: boolean;
	isFavorite: boolean;
}

export const LaunchCardBody = ({
	device,
	isFavorite,
	onFavoriteClick,
	isCalling,
	onClickStartSession,
}: Props) => {
	return (
		<div className="body bodyShadow">
			<Header
				device={device}
				isFavorite={isFavorite}
				onFavoriteClick={onFavoriteClick}
				isCalling={isCalling}
			/>
			<Status
				device={device}
				isCalling={isCalling}
				onClickStartSession={onClickStartSession}
			/>
		</div>
	);
};
