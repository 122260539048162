export { Industry } from './Industry';
export { fontFamilies } from './fontFamilies';

export enum FontWeight {
    Light = 300,
    Normal = 400,
    Bold = 500,
    ExtraBold = 700
}

export enum TextTransform {
    Uppercase = 'uppercase'
}
