import React, { ReactNode, useEffect, useState } from 'react';
import { IActiveNavInput } from '../../../../types';
import './index.scss';


type Props = {
	activeNavInput: IActiveNavInput | null;
	isMenuExpanded: boolean;
	isMenuAbsolute: boolean;
};

const DrivingDisabledIndicatorConfig: { icon: string; label: ReactNode } = {
	label: <p>Driving isn't allowed</p>,
	icon: '../../assets/images/warning.svg',
};
const NavInputIndicatorsConfig: Record<
	IActiveNavInput,
	{ icon: string; label: React.ReactNode }
> = {
	keyboard: {
		icon: '../../assets/images/keyboard-controller.svg',
		label: (
			<p>
				The <strong className="greenText">Keyboard</strong> is activated
			</p>
		),
	},
	'auto-docking': {
		icon: '../../assets/images/Docking-Successful.svg',
		label: (
			<p>
				<strong className="greenText">GoBe Park</strong> is activated
			</p>
		),
	},
	joystick: {
		icon: '../../assets/images/joystick-controller.svg',
		label: (
			<p>
				The <strong className="greenText">Joystick</strong> is activated
			</p>
		),
	},
};

const ActiveNavigationInput: React.FC<Props> = ({
	activeNavInput,
	isMenuExpanded,
	isMenuAbsolute,
}) => {
	const [className, setClassName] = useState<string>('container');
	useEffect(() => { 
		switch (true) {
			case isMenuExpanded:
				setClassName('container containerMenuExpanded');
				break;
			case isMenuAbsolute:
				setClassName('container containerMenuCollapsed');
				break;
			default:
				setClassName('container');
				break;
		}
	}, [isMenuExpanded, isMenuAbsolute]);

	const indicatorConfig =
		activeNavInput === null
			? DrivingDisabledIndicatorConfig
			: NavInputIndicatorsConfig[activeNavInput];
	return (
		<div className={className}>
			<img className="navIcon" src={indicatorConfig.icon} alt="" />
			{indicatorConfig.label}
		</div>
	);
};

export default ActiveNavigationInput;
