import classNames from 'classnames';
import React from 'react';
import { IoPlay, IoRemoveCircle } from 'react-icons/io5';
import { IDeviceCurrentState, Reservation } from '../../../../../../utils/statusConverter';
import '../../index.scss';
import { DefaultStatusIcon } from './DefaultStatusIcon';

interface Props {
	reservation: Reservation | null | undefined;
	deviceStatus: IDeviceCurrentState;
	isPermanentDevice: boolean | undefined;
	isCalling: boolean;
}

export const ReservationStatusIcon = ({
	reservation,
	deviceStatus,
	isPermanentDevice,
	isCalling,
}: Props) => {
	if (reservation?.isGuest && !isPermanentDevice) {
		switch (reservation?.status) {
			case 'beforeReservationDay':
			case 'reservationDay':
				return <IoRemoveCircle className="statusIcon" />;
			case 'reservationTime':
				return (
					<DefaultStatusIcon
						isCalling={isCalling}
						status={deviceStatus}
						isGuest={reservation?.isGuest}
					/>
				);
			case 'inSession':
				return (
					<IoPlay
						className={classNames('statusIcon', {
							goBePrimaryColor: reservation?.isGuest,
						})}
					/>
				);
			default:
				return null;
		}
	} else if (reservation?.isGuest && isPermanentDevice) {
		switch (reservation?.status) {
			case 'beforeReservationDay':
			case 'reservationDay':
			case 'reservationTime':
				return (
					<DefaultStatusIcon
						isCalling={isCalling}
						status={deviceStatus}
						isGuest={reservation?.isGuest}
					/>
				);
			case 'inSession':
				return (
					<IoPlay
						className={classNames('statusIcon', {
							goBePrimaryColor: reservation?.isGuest,
						})}
					/>
				);
			default:
				return null;
		}
	} else {
		switch (reservation?.status) {
			case 'beforeReservationDay':
			case 'reservationDay':
				return (
					<DefaultStatusIcon
						isCalling={isCalling}
						status={deviceStatus}
						isGuest={reservation?.isGuest}
					/>
				);
			case 'reservationTime':
				return <IoRemoveCircle className="statusIcon" />;
			case 'inSession':
				return (
					<IoPlay
						className={classNames('statusIcon', {
							goBePrimaryColor: reservation?.isGuest,
						})}
					/>
				);
			default:
				return null;
		}
	}
};
