import React, { FC, useState, useCallback, useEffect } from 'react';
import RobotSetupDetails from './RobotSetupDetails';

import isAuthenticated from '../../components/Authentication/Authenticated';
import { publish } from '../../actions/publish';
import { equalityFnc } from '../../utils/conformState';
import { useTypedSelector } from '../../reducers';

interface RobotsSetupProps {
	currentSubMenu: string;
	onSubMenuChange: (name: string) => void;
	subMenuCount: number;
}

const RobotsSetup: FC<RobotsSetupProps> = (props: any) => {
	const { currentSubMenu, onSubMenuChange, subMenuCount } = props;

	const [currentItem, setCurrentItem] = useState<string | undefined>(currentSubMenu);
	const [hideArrow, setHideArrow] = useState(false);
	const [robot, setRobot] = useState<any>();

	const username = useTypedSelector(state => state.accountState.user.username);
	const selectedOrganization = useTypedSelector(
		state => state.selectedOrganizationState.organization,
		(left, right) => equalityFnc(left, right)
	);

	const devices = useTypedSelector(
		state => state.deviceState.devicesByOrganizationId[selectedOrganization.orgId]
	);

	const encodedUser = window.btoa(username);

	const updateFunction = useCallback(() => {
		if (
			devices[robot.deviceId].name !== robot.name ||
			devices[robot.deviceId].location !== robot.location ||
			devices[robot.deviceId].timeZone !== robot.timeZone
		) {
			publish(`microservice/${selectedOrganization.orgId}/${encodedUser}/updateDeviceInfo`, {
				requestId: 'updateDeviceInfoId',
				data: {
					device_type: robot.deviceType,
					device_id: robot.deviceId,
					deviceGroupsIds: Array.isArray(robot.deviceGroupsIds)
						? robot.deviceGroupsIds
						: [robot.deviceGroupsIds],
					name: robot.name,
					location: robot.location,
					time_zone: robot.timeZone,
				},
			});
		}
	}, [devices, encodedUser, robot, selectedOrganization.orgId]);

	useEffect(() => {
		if (robot.deviceId !== currentSubMenu) {
			updateFunction();
		}
		setCurrentItem(currentSubMenu);
		let selectedItem: any = document.getElementById('row-' + currentItem);
		if (selectedItem) selectedItem.scrollIntoView();
	}, [currentItem, currentSubMenu, robot, updateFunction]);

	useEffect(() => {
		if (subMenuCount < 10) {
			let selectedItem = document.getElementById('row-' + currentItem);
			let top = selectedItem ? selectedItem.offsetTop + 30 : 30;
			let arrow = document.getElementById('arrow');
			if (arrow) {
				arrow.style.top = top.toString() + 'px';
			}
			setHideArrow(false);
		} else {
			setHideArrow(true);
		}
	}, [currentItem, subMenuCount]);

	const goToItem = useCallback(
		(itemName: string) => {
			setCurrentItem(itemName);
			onSubMenuChange(itemName);
		},
		[onSubMenuChange]
	);

	const handleRobotChange = useCallback(
		(label: string, value: any) => {
			let tempRobot = JSON.parse(JSON.stringify(robot));
			switch (label) {
				case 'robotName':
					tempRobot.name = value[label];
					break;
				case 'robotLocation':
					tempRobot.location = value[label];
					break;
				case 'robotTimeZone':
					tempRobot.timeZone = value;
					break;
			}
			setRobot(tempRobot);
		},
		[robot]
	);

	const renderContent = useCallback(() => {
		const availableRobots = devices;

		if (currentItem) {
			if (!robot || robot.deviceId !== currentItem) setRobot(availableRobots[currentItem]);
			return (
				<RobotSetupDetails
					robot={availableRobots[currentItem]}
					goTo={goToItem}
					parentComponentRobotUpdate={handleRobotChange}
				/>
			);
		}
		return null;
	}, [currentItem, devices, goToItem, handleRobotChange, robot]);

	return renderContent();
};

export default isAuthenticated(RobotsSetup, 'RobotsSetup');
