import React from 'react';
import '../index.scss';

interface Props {
	robotName: string;
	robotGroupName?: string | null;
}

export const LaunchCardFooter = ({ robotName, robotGroupName }: Props) => {
	return (
		<div className="footer bodyShadow">
			<p className="footerName">{robotName}</p>
			<p className="bodyM textAlignCenter">{robotGroupName || ''}</p>
		</div>
	);
};
