import React, { FC } from 'react';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';
import styled from 'styled-components';

const Wrapper = styled.div(({
	display: "flex",
	"> div": {
		display: "flex",
		flex: 1,
		flexDirection: "column",
		"> div": {
			display: "flex",
			"> div": {
				width: 0,
				flexGrow: 1,
				overflow: "hidden",
				textOverflow: "ellipsis",
				whiteSpace: "nowrap",
			}
		}
	},
}));

interface EllipsisTextProps {
	text: string;
}
const EllipsisText: FC<EllipsisTextProps> = (props: EllipsisTextProps) => {
	const {
		text,
	} = props;

	return (
		<Wrapper>
			<div>
				<div>
					<div title={text}>
						{text}
					</div>
				</div>
			</div>
		</Wrapper>
	);
};

export default connect(null, { setParameter })(EllipsisText);
