import {
	CONFIRM_ACCOUNT,
	CONFIRM_ACCOUNT_RESPONSE,
	CONFIRM_ACCOUNT_CREATE_RESPONSE,
	CLEAR_PASSWORD_REDUCER,
	ACCEPT_INVITE_RESPONSE,
	PASSWORD_RESET,
	PASSWORD_RESET_RESPONSE,
} from '../actions/types';

const initialState = {
	id: '',
	type: '',
	success: undefined,
};

export default (state = initialState, action) => {
	let id;
	let success;
	switch (action.type) {
		case CONFIRM_ACCOUNT:
			return {
				...state,
				id: action.payload.request.id,
				type: action.payload.request.type,
			};
		case CONFIRM_ACCOUNT_RESPONSE:
			id = action.payload.requestId;
			success = action.payload.status;

			if (id !== state.id || state.type !== 'ConfirmAccount') return state;
			return {
				...state,
				success: success,
			};
		case CONFIRM_ACCOUNT_CREATE_RESPONSE:
			id = action.payload.requestId;
			success = action.payload.status;

			if (id !== state.id || state.type !== 'AccountCreateConfirm') return state;
			return {
				...state,
				success: success,
			};
		case ACCEPT_INVITE_RESPONSE:
			id = action.payload.requestId;
			success = action.payload.status;

			if (id !== state.id || state.type !== 'AcceptInvite') return state;
			return state;
		case CLEAR_PASSWORD_REDUCER:
			return {
				...state,
				id: '',
				type: '',
				success: undefined,
			};
		case PASSWORD_RESET:
			const { status, requestId } = action.payload;

			if (requestId.includes('generatePasswordReset')) {
				return {
					...state,
					type: 'resetPassword',
					success: status,
				};
			}

			return state;

		case PASSWORD_RESET_RESPONSE:
			const resetStatus = action.payload.status;
			const resetRequestId = action.payload.requestId;

			if (resetRequestId.includes('resetPassword')) {
				return {
					...state,
					id: resetRequestId,
					type: 'resetPassword',
					success: resetStatus,
				};
			}

			return state;

		default:
			return state;
	}
};
