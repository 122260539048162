import { applyMiddleware, createStore } from 'redux';
import reducers from '../reducers';
import ReduxThunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import { buildReduxMqttMiddleware, MqttClientOptions } from 'redux-mqtt';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from 'redux-devtools-extension';
import fetch from 'sync-fetch';

let mqttConfig = fetch('/conf/mqttConfig.json').json()[
	`mqttConfig${process.env.REACT_APP_FM || ''}`
];

const persistConfig = {
	key: 'root',
	storage,
};
const pReducer = persistReducer(persistConfig, reducers);
const middleware = composeWithDevTools(
	applyMiddleware(ReduxThunk, buildReduxMqttMiddleware(mqttConfig))
);
const store = createStore(pReducer, {}, middleware);
const persistor = persistStore(store);
export { persistor, store };
