const MAXBYTES = 1024000 / 2;

function _getBinarySize(str) {
	return Buffer.byteLength(str, 'utf8');
}

function getScaleFactor(str) {
	let size = _getBinarySize(str);
	if (size > MAXBYTES) {
		return MAXBYTES / size;
	}
	return 1;
}

function makeQuadratic(image) {
	const canvas = document.createElement('canvas');
	const context = canvas.getContext('2d');
	const h = image.height,
		w = image.width;
	const borderLength = h <= w ? h : w;
	const offset = Math.abs(h - w) / 2;
	const x = h < w ? offset : 0;
	const y = h <= w ? 0 : offset;

	canvas.height = borderLength;
	canvas.width = borderLength;
	context.drawImage(image, x, y, borderLength, borderLength, 0, 0, borderLength, borderLength);
	return canvas.toDataURL();
}

const scaleImage = async (encodeImage, props, callback) => {
	const canvas = document.createElement('canvas');
	const context = canvas.getContext('2d');
	let objectSrc = encodeImage;
	let image = new Image();
	image.crossOrigin = '';
	image.src = objectSrc;

	image.onload = () => {
		let factor = getScaleFactor(image.src);
		if (image.height !== image.width) {
			image.src = makeQuadratic(image);
			return;
		}

		if (factor === 1) {
			callback(objectSrc);
			return;
		}

		canvas.height = image.height * factor;
		canvas.width = image.width * factor;
		context.drawImage(image, 0, 0, image.width * factor, image.height * factor);

		callback(canvas.toDataURL());
	};
};

export default scaleImage;
