import React from 'react';
import classes from './FMFooterLogo.module.css';

export const FMFooterLogo: React.FC = () => (
	<div className={classes.wrapper}>
		<div className={classes.line} />
		<div className={classes.blueOceanLogo}>
			<img src="./assets/img/blue-ocean-logo.svg" alt="Blue Ocean Robotics" />
		</div>
		<div className={classes.line} />
	</div>
);
