import React, { FC } from 'react';
import { IonList, IonItem, IonLabel, IonListHeader, IonButton } from '@ionic/react';
import { injectIntl, FormattedMessage } from 'react-intl';
import isAuthenticated from '../../components/Authentication/Authenticated';

import classes from './DeleteModal.module.css';
import { RouteComponentProps } from 'react-router';

import Messages from '../DeleteModal/DeleteModal.messages';
import BasicModal from '../BasicModal/BasicModal';

interface DeleteModalProps extends RouteComponentProps {
	title: any;
	isOpen: boolean;
	onConfirm: any;
	onDismiss: any;
	itemName: string;
}

const DeleteModal: FC<DeleteModalProps> = (props: any) => {
	const onDismissModal = () => {
		props.onDismiss();
	};

	return (
		<BasicModal open={props.isOpen} onClose={onDismissModal}>
			<IonList className={classes.modalContent}>
				{/* <IonListHeader className={classes.modalHeader} lines="none">
					<div className={classes.headerContainer}>
						<IonLabel className={classes.headerTitle}>{props.title}</IonLabel>
					</div>
				</IonListHeader> */}
				<IonItem lines="none" className={classes.msgContainer}>
					<div className={classes.headerContainer}>
						<IonLabel className={classes.headerTitle}>{props.title}</IonLabel>
						<IonLabel className={classes.msg}>
							<FormattedMessage
								{...Messages.deleteMsg}
								values={{ item: props.itemName, br: <br /> }}
							/>
						</IonLabel>
					</div>
				</IonItem>
				<IonItem lines="none" className={classes.btnContainer}>
					<IonButton
						expand="block"
						shape="round"
						size="large"
						fill="outline"
						className={classes.cancelBtn}
						onClick={props.onDismiss}
					>
						<FormattedMessage {...Messages.cancel} />
					</IonButton>
					<IonButton
						expand="block"
						shape="round"
						size="large"
						fill="outline"
						className={classes.deleteBtn}
						onClick={props.onConfirm}
					>
						<FormattedMessage {...Messages.delete} />
					</IonButton>
				</IonItem>
			</IonList>
		</BasicModal>
	);
};

export default injectIntl(isAuthenticated(DeleteModal));
