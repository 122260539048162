import React, { FC, useState, useEffect } from 'react';

import isAuthenticated from '../../components/Authentication/Authenticated';
import { injectIntl } from 'react-intl';
import { publish } from '../../actions/publish';
import { useTypedSelector } from '../../reducers';
import { b64EncodeUnicode } from '../../utils/encoding';
import UserFormEditable from './UserFormEditable';
import UserFormNonEditable from './UserFormNonEditable';

interface UserFormProps {
	user: any;
	isEditable?: boolean;
	save: boolean;
	control?: any;
	cancel: boolean;
	setEditable: any;
	onClose: any;
}

const UserForm: FC<UserFormProps> = (props: any) => {
	const { isEditable, errors, save, cancel, setEditable, onClose, user, control } = props;
	const usernameForEncoding = useTypedSelector(state => state.accountState.user.username);
	const [isAdmin, setIsAdmin] = useState(false);
	const [values, setValues] = useState<Record<string, any>>({});
	const [encodedUser] = useState(b64EncodeUnicode(usernameForEncoding));
	const orgId = useTypedSelector(state => state.selectedOrganizationState.organization.orgId);
	const orgType = useTypedSelector(state => state.selectedOrganizationState.organization.orgType);
	const userGroups = useTypedSelector(
		state => state.userGroupsState.userGroupsByOrganizationId[orgId]
	);
	const deviceGroups = useTypedSelector(
		state => state.deviceGroupsState.deviceGroupsByOrganizationId[orgId]
	);

	useEffect(() => {
		return function cleanup() {
			if (!window.location.pathname.includes('/fleetManagement')) onClose();
		};
	}, [onClose]);

	useEffect(() => {
		setIsAdmin(user.role && user.role?.match(/admin|Admin/g) ? true : false);
	}, [user.role]);

	useEffect(() => {
		if (save) {
			onEditUserSubmit(control.values);
			setEditable(false);
			setValues({});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [save]);

	useEffect(() => {
		if (cancel) {
			setEditable(false);
			setValues({});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cancel]);

	const onEditUserRole = () => {
		const currentRole = user.role && user.role?.match(/admin|Admin/g) ? true : false;

		if (currentRole !== isAdmin) {
			let topic, data;
			if (currentRole === false) {
				topic = `microservice/${orgId}/${encodedUser}/assignRoleToUsers/${orgType}`;
				topic = isAdmin ? topic + 'Admin' : topic + 'User';
				data = {
					usersIds: [user.username],
					roleId: orgType + (isAdmin ? 'Admin' : 'User'),
				};
			} else {
				topic = `microservice/${orgId}/${encodedUser}/unassignRoleFromUsers`;
				data = {
					usersIds: [user.username],
				};
			}

			publish(topic, {
				requestId: 'unassignFromRoleId',
				data,
			});
		}
	};

	const onEditUserSubmit = (data: any) => {
		const reviseEntities: Array<string> = [
			'userGroupsIds',
			// 'devicesIds',
			'deviceGroupsIds',
		];
		let userObj: Record<string, any> = {
			firstName: data.firstName,
			lastName: data.lastName,
			username: user.username,
		};

		// for (const key in userObj) {
		// 	if (userObj[key] === undefined || userObj[key] === null) {
		// 		delete userObj[key];
		// 	}
		// 	if (
		// 		key === 'userGroupsIds' ||
		// 		key === 'removedUserGroupsIds' ||
		// 		key === 'deviceGroupsIds' ||
		// 		key === 'removedDeviceGroupsIds' ||
		// 		key === 'devicesIds' ||
		// 		key === 'removedUserDevices'
		// 	) {
		// 		delete userObj[key];
		// 	}
		// }
		if (
			userObj.firstName !== user.firstName ||
			userObj.lastName !== user.lastName ||
			userObj.username !== user.username
		)
			publish(`microservice/${orgId}/${encodedUser}/updateUserInfo`, {
				requestId: 'someId',
				data: {
					user: user,
					updates: userObj,
				},
			});
		setTimeout(() => {
			onEditUserRole();
		}, 250);
	};

	if (isEditable) {
		return (
			<UserFormEditable
				currentUser={control.values}
				errors={errors}
				setAdmin={(edit: boolean) => {
					setIsAdmin(edit);
					control.setFieldValue('isAdmin', edit);
				}}
				orgId={orgId}
				setValues={(value: any, key: string) => {
					control.setFieldValue(key, value);
					const copy = values;
					copy[key] = value;
					setValues(copy);
				}}
			/>
		);
	} else {
		return <UserFormNonEditable user={user} orgId={orgId} />;
	}
};

export default injectIntl(isAuthenticated(UserForm, 'UserForm'));
