import { defineMessages } from 'react-intl';

export default defineMessages({
	audioVideoSettings: {
		id: 'PilotApp.audioVideoSettings',
		defaultMessage: 'Audio/Video Settings',
		description: 'Audio/Video Settings title',
	},

	microphone: {
		id: 'PilotApp.microphone',
		defaultMessage: 'Microphone',
		description: 'microphone label',
	},

	speakers: {
		id: 'PilotApp.speakers',
		defaultMessage: 'Speakers',
		description: 'Speakers label',
	},

	camera: {
		id: 'PilotApp.speakers',
		defaultMessage: 'Test',
		description: 'Test label',
	},

	test: {
		id: 'PilotApp.test',
		defaultMessage: 'Test',
		description: 'Test label',
	},
});
